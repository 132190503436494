import { useState } from "react";

export default function useModalOpener(): [boolean, () => void, () => void] {

    const [isOpen, setOpen] = useState(false);
    const openModal = () => setOpen(true);
    const closeModal = () => setOpen(false);

    return [isOpen, openModal, closeModal];
}
