import React, { useEffect, RefObject } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./SakuseiKubunContent.module.scss";
import { State } from "../../../../state/store";
import { get } from "../../../../state/usecase/tablecommon/action";
import { TableCommonUpdater } from "./TableCommonUpdater";
import { checkboxWith } from "../../assessmentsheet/components/sections/fields";

export function SakuseiKubunContent(props: Props) {

    const { projectId, activated } = props;

    const dispatch = useDispatch();
    const { tableCommon } = useSelector((state: State) => state.tableCommon.slots[projectId]) ?? { tableCommon: null };

    const tableUpdater = new TableCommonUpdater(projectId, tableCommon, activated);

    useEffect(() => {
        dispatch(get(projectId));
    }, [dispatch, projectId]);

    return <div className={styles.container}>

            {tableUpdater.group(tableUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        <div className={styles.saveIndicator}>{tableUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            <div className={styles.horizontal}>
                                {tableUpdater.of<boolean>(
                                    "sakuseiKubunFirst",
                                    checkboxWith("初回")
                                )}
                                {tableUpdater.of<boolean>(
                                    "sakuseiKubunIntroduce",
                                    checkboxWith("紹介")
                                )}
                                {tableUpdater.of<boolean>(
                                    "sakuseiKubunRenew",
                                    checkboxWith("継続")
                                )}
                            </div>
                        </div>
                    </div>
                </div>;
            })}

    </div>
}

type Props = {
    projectId: string
    globalHeaderRef: RefObject<HTMLDivElement>,
    projectHeaderRef: RefObject<HTMLDivElement>,
    activated: boolean,
};