import React, { useState } from "react";
import { PasswordInput } from "./PasswordInput";

type Props = {
    value: string;
    setValue: (value: string) => void;
};

export function EnhancedPasswordInput(props: Props) {
    const { value, setValue } = props;
    
    const [maskState, setMaskState] = useState<boolean>(true);
    const mask = () => setMaskState(true);
    const unMask = () => setMaskState(false);

    return (
        <PasswordInput 
            value={value}
            setValue={setValue}
            masked={maskState}
            mask={mask}
            unMask={unMask}
        />
    )
}
