import React, { ButtonHTMLAttributes, ReactNode } from "react";
import styles from "./CancelButton.module.scss";
import { styleForWidth, Width } from "../element/Width";

export function CancelButton(props: ButtonHTMLAttributes<any> & Props) {
    const { children, width, leading, trailing, ...rest } = props;
    const buttonStyle = styleForWidth(width ?? "intrinsic");
    return <button className={styles.button} style={buttonStyle} {...rest}>
        <span className={styles.focus}>
            {leading && <span className={styles.leading}>{leading}</span>}
            <span className={styles.text}>{children}</span>
            {trailing && <span className={styles.trailing}>{trailing}</span>}
        </span>
    </button>
}

type Props = {
    width?: Width,
    leading?: ReactNode,
    trailing?: ReactNode,
};
