import React from "react";
import { CategoryShokakikeiShikkanIcon } from "../components/icon/diseaseknowledge/CategoryShokakikeiShikkanIcon";
import { CategoryKansenshoIcon } from "../components/icon/diseaseknowledge/CategoryKansenshoIcon";
import { CategoryGanIcon } from "../components/icon/diseaseknowledge/CategoryGanIcon";
import { CategoryKokyukikeiShikkanIcon } from "../components/icon/diseaseknowledge/CategoryKokyukikeiShikkanIcon";
import { CategoryJunkankikeiShikkanIcon } from "../components/icon/diseaseknowledge/CategoryJunkankikeiShikkanIcon";
import { CategoryNoshinkeikeiShikkanIcon } from "../components/icon/diseaseknowledge/CategoryNoshinkeikeiShikkanIcon";
import { CategoryTaishanaibunpitsukeiShikkanIcon } from "../components/icon/diseaseknowledge/CategoryTaishanaibunpitsukeiShikkanIcon";
import { CategoryKinkokkakukeiShikkanIcon } from "../components/icon/diseaseknowledge/CategoryKinkokkakukeiShikkanIcon";
import { CategoryJinhinyokikeiShikkanIcon } from "../components/icon/diseaseknowledge/CategoryJinhinyokikeiShikkanIcon";
import { CategoryHifuShikkanIcon } from "../components/icon/diseaseknowledge/CategoryHifuShikkanIcon";
import { CategoryKankakukikeiShikkanIcon } from "../components/icon/diseaseknowledge/CategoryKankakukikeiShikkanIcon";

export function DiseaseCategory(props: Props) {
    const { categoryId } = props;

    switch (categoryId) {

        case "1":
            // 消化器系疾患
            return <CategoryShokakikeiShikkanIcon/>;
        case "2":
            // 呼吸器系疾患
            return <CategoryKokyukikeiShikkanIcon/>;
        case "3":
            // 循環器系疾患
            return <CategoryJunkankikeiShikkanIcon/>;
        case "4":
            // 脳神経系疾患
            return <CategoryNoshinkeikeiShikkanIcon/>;
        case "5":
            // 代謝内分泌系疾患
            return <CategoryTaishanaibunpitsukeiShikkanIcon/>;
        case "6":
            // 筋骨格系疾患
            return <CategoryKinkokkakukeiShikkanIcon/>;
        case "7":
            // 腎泌尿器系疾患
            return <CategoryJinhinyokikeiShikkanIcon/>;
        case "8":
            // 皮膚疾患
            return <CategoryHifuShikkanIcon/>;
        case "9":
            // 感覚器系疾患
            return <CategoryKankakukikeiShikkanIcon/>;
        case "10":
            // 感染症
            return <CategoryKansenshoIcon/>;
        case "11":
            // がん
            return <CategoryGanIcon/>;
    }
}

export type CategoryId = "1"| "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9" | "10" | "11";

type Props = {
    categoryId: CategoryId,
};
