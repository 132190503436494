import React, { FormEvent, MouseEvent, useState } from "react";
import styles from "./CareRecipientEditingDialog.module.scss";
import { SimpleCareRecipient } from "../../../../domain/carerecipient/SimpleCareRecipient";
import { TextField } from "../../components/field/TextField";
import { CloseIcon } from "../../components/icon/CloseIcon";
import { CareRecipientRegistrationField } from "./CareRecipientRegistrationField";
import { CareRecipientResponsibleUserField } from "./CareRecipientResponsibleUserField";
import { CareRecipientRegistrationButton } from "./CareRecipientRegistrationButton";
import { CareRecipientApi } from "../../../../state/api/carerecipient/CareRecipientApi";
import { Update } from "../../../../state/api/carerecipient/Update";

export function CareRecipientEditingDialog(props: Props) {
    const { careRecipient, onComplete, onRequestClose } = props;

    const [name, setName] = useState(careRecipient.name);
    const [phonetic, setPhonetic] = useState(careRecipient.phonetic);
    const [responsibleUserId, setResponsibleUserId] = useState(careRecipient.responsibleUser?.id);
    const [fieldDisabled, setFieldDisabled] = useState<boolean>(false);
    const buttonDisabled = fieldDisabled || name === "" || phonetic === "" || !responsibleUserId;

    const onClickContainer = (event: MouseEvent<any>) => event.stopPropagation();

    const onSubmit = (event: FormEvent) => {
        event.preventDefault();

        if (!responsibleUserId) return;
        setFieldDisabled(true)
        CareRecipientApi.update(careRecipient.id, name, phonetic, responsibleUserId)
            .then(res => {
                setFieldDisabled(false)
                if (res instanceof Update) {
                    onComplete();
                    onRequestClose();
                } else {
                    // TODO 更新失敗時の処理
                }
            })
    };

    return <div className={styles.container} onClick={onClickContainer}>
        <h2 className={styles.title}>利用者情報を編集する</h2>
        <p className={styles.description}>利用者の氏名、フリガナ、担当者を変更できます。</p>
        <form onSubmit={onSubmit}>
            <CareRecipientRegistrationField label={"氏名"}>
                <TextField get={name}
                    set={setName}
                    required={true}
                    maxLength={20}
                    disabled={fieldDisabled}/>
            </CareRecipientRegistrationField>
            <CareRecipientRegistrationField label={"フリガナ"}>
                <TextField get={phonetic}
                    set={setPhonetic}
                    required={true}
                    maxLength={30}
                    pattern={"^[ァ-ー 　]*$"}
                    disabled={fieldDisabled}/>
            </CareRecipientRegistrationField>
            <CareRecipientRegistrationField label={"担当者"}>
                <CareRecipientResponsibleUserField get={responsibleUserId}
                    set={setResponsibleUserId}
                    required={true}
                    disabled={fieldDisabled}/>
            </CareRecipientRegistrationField>
            <CareRecipientRegistrationButton disabled={buttonDisabled}>変更</CareRecipientRegistrationButton>
        </form>
        <button className={styles.close} onClick={onRequestClose}><CloseIcon/></button>
    </div>
}

type Props = {
    careRecipient: SimpleCareRecipient,
    onComplete: () => void,
    onRequestClose: () => void,
};
