import { Need } from "./need/Need";
import { SubItems } from "./SubItems";
import { LongTermGoal } from "./longtermgoal/LongTermGoal";
import { ShortTermGoal } from "./shorttermgoal/ShortTermGoal";
import { SupportService } from "./supportservice/SupportService";
import { SupportServiceType } from "./supportservicetype/SupportServiceType";

const INIT_INDEX = 0;

const CarePlanTableTwoType = Symbol();

// 2表
export class CarePlanTableTwo {
    [CarePlanTableTwoType]: any;

    needs: SubItems<Need>;

    projectId: string;

    // 要素を更新する際に使用
    private readonly indexAndChildren: IndexAndChildren;

    constructor(
        subItems: SubItems<Need>,
        projectId: string,
    ) {
        this.needs = subItems;
        this.projectId = projectId;
        this.indexAndChildren = buildIndexAndChildren(INIT_INDEX, subItems);
    }

    replaceNeed(need: Need): CarePlanTableTwo {
        const { index: needIndex } = this.indexAndChildren.children[need.id];
        this.needs.items[needIndex] = need;
        return new CarePlanTableTwo(this.needs, this.projectId);
    }

    replaceLongTermGoal(
        need: Need,
        longTermGoal: LongTermGoal,
    ): CarePlanTableTwo {
        const { index: needIndex, children: needChildren } = this.indexAndChildren.children[need.id];
        const { index: longTermGoalIndex } = needChildren[longTermGoal.id];
        this.needs.items[needIndex].subItems.items[longTermGoalIndex] = longTermGoal;
        return new CarePlanTableTwo(this.needs, this.projectId);
    }

    replaceShortTermGoal(
        need: Need,
        longTermGoal: LongTermGoal,
        shortTermGoal: ShortTermGoal,
    ): CarePlanTableTwo {
        const { index: needIndex, children: needChildren } = this.indexAndChildren.children[need.id];
        const { index: longTermGoalIndex, children: longTermGoalChildren } = needChildren[longTermGoal.id];
        const { index: shortTermGoalIndex } = longTermGoalChildren[shortTermGoal.id];
        this.needs.items[needIndex].subItems.items[longTermGoalIndex].subItems.items[shortTermGoalIndex] = shortTermGoal;
        return new CarePlanTableTwo(this.needs, this.projectId);
    }

    replaceSupportService(
        need: Need,
        longTermGoal: LongTermGoal,
        shortTermGoal: ShortTermGoal,
        supportService: SupportService,
    ): CarePlanTableTwo {
        const { index: needIndex, children: needChildren } = this.indexAndChildren.children[need.id];
        const { index: longTermGoalIndex, children: longTermGoalChildren } = needChildren[longTermGoal.id];
        const { index: shortTermGoalIndex, children: shortTermGoalChildren } = longTermGoalChildren[shortTermGoal.id];
        const { index: supportServiceIndex } = shortTermGoalChildren[supportService.id];
        this.needs.items[needIndex].subItems.items[longTermGoalIndex].subItems.items[shortTermGoalIndex].subItems.items[supportServiceIndex] = supportService;
        return new CarePlanTableTwo(this.needs, this.projectId);
    }

    replaceSupportServiceType(
        need: Need,
        longTermGoal: LongTermGoal,
        shortTermGoal: ShortTermGoal,
        supportService: SupportService,
        supportServiceType: SupportServiceType,
    ): CarePlanTableTwo {
        const { index: needIndex, children: needChildren } = this.indexAndChildren.children[need.id];
        const { index: longTermGoalIndex, children: longTermGoalChildren } = needChildren[longTermGoal.id];
        const { index: shortTermGoalIndex, children: shortTermGoalChildren } = longTermGoalChildren[shortTermGoal.id];
        const { index: supportServiceIndex, children: supportServiceChildren } = shortTermGoalChildren[supportService.id];
        const { index: supportServiceTypeIndex } = supportServiceChildren[supportServiceType.id];
        this.needs.items[needIndex].subItems.items[longTermGoalIndex].subItems.items[shortTermGoalIndex].subItems.items[supportServiceIndex].subItems.items[supportServiceTypeIndex] = supportServiceType;
        return new CarePlanTableTwo(this.needs, this.projectId);
    }

    isFirstSubItem(need: Need): boolean {
        return this.needs.items.findIndex((item) => need === item) === 0;
    }

    isLastSubItem(need: Need): boolean {
        return this.needs.items.findIndex((item) => need === item) === this.needs.items.length - 1;
    }
}

// 要素のIDで該当要素のINDEXを取得できる
type IndexAndChildren = {
    index: number,
    children: { [key: string]: IndexAndChildren },
};

function buildIndexAndChildren(parentIndex: number, subItems: SubItems<Need | LongTermGoal | ShortTermGoal | SupportService | SupportServiceType>): IndexAndChildren {
    const entries = subItems.items.map((subItem, index) => {
        const child = subItem instanceof SupportServiceType ? { index, children: {} } : buildIndexAndChildren(index, subItem.subItems);
        return [subItem.id, child];
    });
    return { index: parentIndex, children: Object.fromEntries(entries) };
}
