
export class ChatgptManageUsage {

    userId: string;
    usageType: number;
    textLimit: number;
    fileLimit: number;
    sendLimit: number;

    constructor(
        userId: string,
        usageType: number,
        textLimit: number,
        fileLimit: number,
        sendLimit: number
    ) {
        this.userId = userId;
        this.usageType = usageType;
        this.textLimit = textLimit;
        this.fileLimit = fileLimit;
        this.sendLimit = sendLimit;
    }

    static async extract(response: Response): Promise<ChatgptManageUsage> {
        const json = await response.json();
        return new ChatgptManageUsage(
            json.userId, 
            json.usageType.value, 
            json.textLimit.value,
            json.fileLimit.value,
            json.sendLimit.value
        );
    }

}
