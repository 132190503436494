import React, { ReactNode, RefObject, useRef } from "react";
import styles from "./TableTwoBody.module.scss";
import classNames from "classnames";
import { CarePlanTableTwo } from "../../../../domain/careplan/tabletwo/CarePlanTableTwo";
import { NeedComponent } from "./NeedComponent";
import { ChangeLog } from "../../../../domain/changelog/ChangeLog";

export function TableTwoBody(props: Props) {
    const { projectId, carePlanTableTwo, activated, globalHeaderHeight, projectHeaderHeight, headerHeight, refresh } = props;

    const bodyHeaderRef = useRef<HTMLDivElement>(null);
    const bodyRef = useRef<HTMLDivElement>(null);
    if (bodyHeaderRef.current && bodyRef.current) {
        stickyPageHeaderIf(globalHeaderHeight, projectHeaderHeight, headerHeight, bodyHeaderRef.current, bodyRef.current);
    }

    return <div className={styles.container}>
        <div className={styles.tableHeader} ref={bodyHeaderRef}>
            <div className={classNames(styles.group, styles.needText)}>
                <span className={styles.content}>
                    生活全般の<br/>
                    解決すべき課題<br/>
                    （ニーズ）<br/>
                </span>
            </div>
            <div className={classNames(styles.group, styles.goal)}>
                <div className={styles.header}>
                    <span className={styles.content}>目標</span>
                </div>
                <div className={styles.children}>
                    <div className={styles.longTermGoalText}>
                        <span className={styles.content}>長期目標</span>
                    </div>
                    <div className={styles.longTermGoalPeriod}>
                        <span className={styles.content}>期間</span>
                    </div>
                    <div className={styles.shortTermGoalText}>
                        <span className={styles.content}>短期目標</span>
                    </div>
                    <div className={styles.shortTermGoalPeriod}>
                        <span className={styles.content}>期間</span>
                    </div>
                </div>
            </div>
            <div className={classNames(styles.group, styles.assistanceContent)}>
                <div className={styles.header}>
                    <span className={styles.content}>援助内容</span>
                </div>
                <div className={styles.children}>
                    <div className={styles.supportServiceText}>
                        <span className={styles.content}>サービス内容</span>
                    </div>
                    <div className={styles.supportServiceTypeInsuranceBenefit}>
                        <span className={styles.content}>※1</span>
                    </div>
                    <div className={styles.supportServiceTypeText}>
                        <span className={styles.content}>サービス種別</span>
                    </div>
                    <div className={styles.supportServiceTypeEstablishment}>
                        <span className={styles.content}>※2</span>
                    </div>
                    <div className={styles.supportServiceTypeFrequency}>
                        <span className={styles.content}>頻度</span>
                    </div>
                    <div className={styles.supportServiceTypePeriod}>
                        <span className={styles.content}>期間</span>
                    </div>
                </div>
            </div>
        </div>
        <div className={styles.tableBody} data-table-two-body={"true"} ref={bodyRef}>
            {carePlanTableTwo && renderTableBody(projectId, carePlanTableTwo, activated, refresh, bodyHeaderRef )}
        </div>
    </div>;
}

type Props = {
    projectId: string,
    carePlanTableTwo: CarePlanTableTwo | null,
    activated: boolean,
    globalHeaderHeight: () => number,
    projectHeaderHeight: () => number,
    headerHeight: () => number,
    refresh: (carePlanTableTwo: CarePlanTableTwo, changeLog: ChangeLog) => void,
};

function renderTableBody(
    projectId: string,
    carePlanTableTwo: CarePlanTableTwo,
    activated: boolean,
    refresh: (carePlanTableTwo: CarePlanTableTwo, changeLog: ChangeLog) => void,
    bodyHeaderRef: RefObject<HTMLDivElement>
): ReactNode {
    return carePlanTableTwo.needs.items.map(need => {
        return <NeedComponent
            key={need.id}
            projectId={projectId}
            carePlanTableTwo={carePlanTableTwo}
            need={need}
            activated={activated}
            refresh={refresh}
            tableTwoHeaderRef={bodyHeaderRef}
        />;
    });
}

function stickyPageHeaderIf(
    globalHeaderHeight: () => number,
    projectHeaderHeight: () => number,
    headerHeight: () => number,
    bodyHeaderRef: HTMLDivElement,
    bodyRef: HTMLDivElement
) {
    bodyHeaderRef.style.position = "fixed";
    bodyHeaderRef.style.top = `${globalHeaderHeight() + projectHeaderHeight() + headerHeight()}px`;
    bodyRef.style.paddingTop = `${headerHeight() + bodyHeaderRef.clientHeight}px`;
}
