import "whatwg-fetch";
import { CarePlanTableTwoPeriod } from "../../../../../domain/careplan/tabletwo/CarePlanTableTwoPeriod";
import { ShiftAction } from "../../../../../domain/careplan/tabletwo/ShiftAction";
import { ApiError } from "../../../ApiError";
import { post } from "../../../functions";
import { EditedLongTermGoal } from "./EditedLongTermGoal";
import { toPeriodObject } from "../PeriodObject";
import { EditedCaraPlanTableTwo } from "../EditedCaraPlanTableTwo";

export const LongTermGoalApi = new class {
    async update(
        id: string,
        text: string | null,
        period: CarePlanTableTwoPeriod | null,
    ): Promise<EditedLongTermGoal | ApiError> {
        const body = {
            id,
            text,
            period: period && toPeriodObject(period),
        };
        const response = await post("/api/tableTwo/longTermGoal/update", body);
        return (response.ok ? EditedLongTermGoal.extract : ApiError.extract)(response);
    }

    async addShortTermGoal(
        longTermGoalId: string,
        shortTermGoalId: string,
    ): Promise<EditedLongTermGoal | ApiError> {
        const body = {
            longTermGoalId,
            shortTermGoalId,
        };
        const response = await post("/api/tableTwo/longTermGoal/addSubItem", body);
        return (response.ok ? EditedLongTermGoal.extract : ApiError.extract)(response);
    }

    async shiftShortTermGoal(
        longTermGoalId: string,
        shortTermGoalId: string,
        action: ShiftAction,
    ): Promise<EditedLongTermGoal | ApiError> {
        const body = {
            longTermGoalId,
            shortTermGoalId,
            action,
        };
        const response = await post("/api/tableTwo/longTermGoal/shiftSubItem", body);
        return (response.ok ? EditedLongTermGoal.extract : ApiError.extract)(response);
    }

    async removeShortTermGoal(
        longTermGoalId: string,
        shortTermGoalId: string,
    ): Promise<EditedLongTermGoal | ApiError> {
        const body = {
            longTermGoalId,
            shortTermGoalId,
        };
        const response = await post("/api/tableTwo/longTermGoal/removeSubItem", body);
        return (response.ok ? EditedLongTermGoal.extract : ApiError.extract)(response);
    }

    async copyPeriod(
        longTermGoalId: string,
    ): Promise<EditedCaraPlanTableTwo | ApiError> {
        const body = {
            longTermGoalId,
        }
        const response = await post("/api/tableTwo/longTermGoal/copyPeriod", body);
        return (response.ok ? EditedCaraPlanTableTwo.extract : ApiError.extract)(response);
    }
}();
