import React, { useState, useEffect, useCallback, useRef } from "react";
import styles from "./TableThreeNonWeeklyService.module.scss";
import { useSelector } from "react-redux";
import { State } from "../../../../state/store";
import { NonWeeklyServiceApi } from "../../../../state/api/careplan/tablethree/nonweeklyservice/NonWeeklyServiceApi";
import { NonWeeklyService } from "../../../../domain/careplan/tablethree/nonweeklyservice/NonWeeklyService";
import { CarePlanTableTwoApi } from "../../../../state/api/careplan/tabletwo/CarePlanTableTwoApi";
import { CarePlanTableTwoResponse } from "../../../../state/api/careplan/tabletwo/CarePlanTableTwoResponse";
import { CarePlanTableTwo } from "../../../../domain/careplan/tabletwo/CarePlanTableTwo";
import { TableThreeNonWeeklyServiceForm } from "./TableThreeNonWeeklyServiceForm";
import { Get } from "../../../../state/api/careplan/tablethree/nonweeklyservice/Get";

export function TableThreeNonWeeklyService(props: Props) {
    const { projectId, refleshTableThree, activated } = props;

    const [nonWeeklyServices, setNonWeeklyServices] = useState<NonWeeklyService[]>([]);
    const [carePlanTableTwo, setCarePlanTableTwo] = useState<CarePlanTableTwo>();
    const [tableTwoEstablishmentList, setTableTwoEstablishmentList] = useState<string[]>([]);
    const [tableThreeId, setTableThreeId] = useState("");

    const isFirstRender = useRef(true);

    const { tableThree } = useSelector(
        (state: State) =>
            state.tableThree.slots[projectId] ?? {
                tableThree: null,
            }
    );

    useEffect(() => {
        if (!tableThree) return;
        if (tableThreeId !== tableThree.id) {
            setTableThreeId(tableThree.id);
        }
        // eslint-disable-next-line
    }, [tableThree]);

    useEffect(() => {
        let mounted = true;
        CarePlanTableTwoApi.get(projectId).then((res) => {
            if (res instanceof CarePlanTableTwoResponse && mounted) {
                setCarePlanTableTwo(res.carePlanTableTwo);
            }
        });
        return () => {
            mounted = false;
        };
    }, [projectId]);

    const findValues = useCallback((obj: any, key: string) => {
        let results: any[] = [];

        if (Array.isArray(obj)) {
            obj.forEach((item) => {
                results = results.concat(findValues(item, key));
            });
        } else if (typeof obj === "object" && obj !== null) {
            Object.keys(obj).forEach((k) => {
                if (k === key) {
                    results.push(obj[k]);
                } else {
                    results = results.concat(findValues(obj[k], key));
                }
            });
        }
        return results;
    }, []);

    useEffect(() => {
        setTableTwoEstablishmentList(findValues(carePlanTableTwo, "establishment"));
    }, [carePlanTableTwo, findValues]);

    const retrieveNonWeeklyServices = useCallback(() => {
        if (!tableThreeId) return;
        NonWeeklyServiceApi.get(tableThreeId).then((res) => {
            if (res instanceof Get) {
                setNonWeeklyServices(res.nonWeeklyServices);
            }
        });
        if (isFirstRender.current) {
            isFirstRender.current = false;
        } else {
            refleshTableThree();
        }
        // eslint-disable-next-line
    }, [tableThreeId]);

    useEffect(() => {
        retrieveNonWeeklyServices();
    }, [retrieveNonWeeklyServices]);

    return (
        <div className={styles.container}>
            <div className={styles.title}>週単位以外のサービス</div>
            <div className={styles.contents}>
                <div className={styles.header}>
                    <div className={styles.periodLabel}>期間</div>
                    <div className={styles.establishNameLabel}>事業所名</div>
                    <div className={styles.serviceNameLabel}>サービス名</div>
                    <div className={styles.unitCountLabel}>単位数</div>
                    <div className={styles.productCodeLabel}>商品コード</div>
                </div>
                {nonWeeklyServices &&
                    nonWeeklyServices.map((nonWeeklyService, index) => (
                        <TableThreeNonWeeklyServiceForm
                            projectId={projectId}
                            nonWeeklyServices={nonWeeklyServices}
                            nonWeeklyService={nonWeeklyService}
                            tableTwoEstablishmentList={tableTwoEstablishmentList}
                            tableThree={tableThree}
                            refresh={retrieveNonWeeklyServices}
                            index={index}
                            activated={activated}
                            key={index}
                        />
                    ))}
            </div>
        </div>
    );
}

type Props = {
    projectId: string;
    activated: boolean;
    refleshTableThree: () => void;
};
