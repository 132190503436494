import React, { useState, useEffect } from "react";
import styles from "./TableThreeMainActivities.module.scss";
import { MainActivitiesApi } from "../../../../state/api/careplan/tablethree/mainactivities/MainActivitiesApi";
import { useSelector } from "react-redux";
import { State } from "../../../../state/store";
import { MainActivities } from "../../../../domain/careplan/tablethree/mainactivities/MainActivities";
import { TableThreeMainActivitiesInput } from "./TableThreeMainActivitiesInput";
import { Get } from "../../../../state/api/careplan/tablethree/mainactivities/Get";

export function TableThreeMainActivities(props: Props) {
    const { projectId, activated } = props;

    const { tableThree } = useSelector(
        (state: State) =>
            state.tableThree.slots[projectId] ?? {
                tableThree: null,
            }
    );

    const [mainActivities, setMainActivities] = useState<MainActivities[]>([]);
    const [tableThreeId, setTableThreeId] = useState("");

    useEffect(() => {
        if (!tableThree) return;
        if (tableThreeId !== tableThree.id) {
            setTableThreeId(tableThree.id);
        }
        // eslint-disable-next-line
    }, [tableThree]);

    useEffect(() => {
        if (!tableThreeId) return;
        let mounted = true;
        MainActivitiesApi.get(tableThreeId).then((res) => {
            if (res instanceof Get && mounted) {
                setMainActivities(res.mainActivities);
            }
        });
        return () => {
            mounted = false;
        };
    }, [tableThreeId]);

    const onUpdate = (time: number, value: string, mainActivity: MainActivities | undefined) => {
        if (mainActivity) {
            MainActivitiesApi.update(mainActivity.id, mainActivity.carePlanTableThreeId, mainActivity.jikan, value);
        } else {
            MainActivitiesApi.register(tableThree.id, time, value);
        }
    };

    return (
        <div className={styles.container}>
            {Array(25)
                .fill(0)
                .map((_, index) => {
                    const mainActivity = mainActivities.find((ele) => {
                        return ele.jikan === index;
                    });
                    return (
                        <TableThreeMainActivitiesInput
                            initialText={mainActivity?.activityContent ?? null}
                            onUpdate={onUpdate}
                            activated={activated}
                            time={index}
                            mainActivity={mainActivity}
                            key={index}
                        />
                    );
                })}
        </div>
    );
}

type Props = {
    projectId: string;
    activated: boolean;
};
