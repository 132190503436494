import React, { ButtonHTMLAttributes } from "react";
import { RightArrowIcon } from "./RightArrowIcon";
import styles from "./RightArrowButton.module.scss";
import classNames from "classnames";

export function RightArrowButton(props: Props & ButtonHTMLAttributes<any>) {
    const { onClick, visible } = props;

    return <button className={classNames(styles.container, { [styles.hidden] : !visible, [styles.visible] : visible })} onClick={onClick}>
        <RightArrowIcon/>
    </button>
}

type Props = {
    visible: boolean
}