import React, { useEffect, useRef, useState, MouseEvent } from "react";
import styles from "./Detail.module.scss";
import { BackButton } from "./components/BackButton";
import { JigyoshoInfo } from "./components/JigyoshoInfo";
import { OtherInfo } from "./components/OtherInfo";
import { FetchEstablishmentApi } from "../../../../state/api/suggestion/establishment/EstablishmentApi";
import { FetchEstablishmentDetail } from "../../../../state/api/suggestion/establishment/FetchEstablishmentDetail";
import { EstablishmentDetail } from "../../../../domain/establishment/EstablishmentDetail";
import { Establishment } from "../../../../domain/establishment/Establishment";
import { ErrorPage } from "../error/ErrorPage";
import { EstablishmentFavorite } from "../../../../domain/establishment/EstablishmentFavorite";
import { toLabel } from "../../suggestion/components/establishment/components/ServiceType";
import { Loading } from "../../components/loading/Loading";

export function Detail(props: Props) {
    const { projectId, establishment, setDetailVisible, establishmentFavoriteList, setEstablishmentFavoriteList, confirmEstablishments } = props;

    const [establishmentDetail, setEstablishmentDetail] = useState<EstablishmentDetail>();
    const [detailFetchingError, setDetailFetchingError] = useState<boolean>(false);
    useEffect(() => {
        let mounted = true;
        if (!establishment) return;
        FetchEstablishmentApi.fetchEstablishmentDetail(
            projectId,
            establishment.jigyoshoJoho.jigyoshobango,
            establishment.jigyoshoJoho.serviceShubetsuCode
        ).then((res) => {
            if (!mounted) return;
            if (res instanceof FetchEstablishmentDetail) {
                setEstablishmentDetail(res.establishmentDetail);
            } else {
                setDetailFetchingError(true);
            }
        });
        return () => {
            mounted = false;
        };
    }, [projectId, establishment]);

    const dialogContainerRef = useRef<HTMLDivElement>(null);
    const jigyoshoInfoRef = useRef<HTMLDivElement>(null);

    const onClickSelect = (event: MouseEvent) => {
        confirmEstablishments(event, {
            supportServiceTypeId: parseInt(establishmentDetail?.jigyoshoJoho?.serviceShubetsuCode ?? ""),
            supportServiceType: toLabel(establishmentDetail?.jigyoshoJoho?.serviceShubetsuCode ?? ""),
            establishmentName: establishmentDetail?.jigyoshoJoho?.jigyoshomei ?? "",
            establishmentId: establishmentDetail?.jigyoshoJoho?.jigyoshobango ?? ""
        });
    };

    const onClickClose = () => {
        setDetailFetchingError(false);
        setDetailVisible(false);
    };

    if (detailFetchingError) {
        return <ErrorPage onClickClose={onClickClose} />;
    }
    return (
        <div className={styles.container} ref={dialogContainerRef}>
            <BackButton onClick={() => setDetailVisible(false)} />
            {!establishmentDetail || !establishment ? (
                <Loading />
            ) : (
                <>
                    <div ref={jigyoshoInfoRef}>
                        {establishmentDetail && (
                            <JigyoshoInfo
                                establishment={establishment}
                                establishmentDetail={establishmentDetail}
                                projectId={projectId}
                                establishmentFavoriteList={establishmentFavoriteList}
                                setEstablishmentFavoriteList={setEstablishmentFavoriteList}
                            />
                        )}
                    </div>
                    <OtherInfo
                        establishmentDetail={establishmentDetail}
                        dialogContainerRef={dialogContainerRef}
                        jigyoshoInfoRef={jigyoshoInfoRef}
                        selectEstablishment={onClickSelect}
                    />
                </>
            )}
        </div>
    );
}

type Props = {
    projectId: string;
    establishment: Establishment | null;
    setDetailVisible: (value: boolean) => void;
    establishmentFavoriteList: EstablishmentFavorite[];
    setEstablishmentFavoriteList: (value: EstablishmentFavorite[]) => void;
    confirmEstablishments: (event: MouseEvent, establishment: { supportServiceType: string; supportServiceTypeId: number; establishmentName: string; establishmentId: string }) => void;
};
