import { WeeklyService } from "../../../../../domain/careplan/tablethree/weeklyservice/WeeklyService";

export class Get {
    type: "SUCCESS" = "SUCCESS";

    weeklyServices: WeeklyService[];

    constructor(weeklyServices: WeeklyService[]) {
        this.weeklyServices = weeklyServices;
    }

    static path = "/api/weeklyService/getList";

    static async extract(response: Response): Promise<Get> {
        const json = await response.json();
        return new Get(json.weeklyServices);
    }
}
