import React from "react";
import styles from "./Question.module.scss";

export function Question(props: Props) {
    const { question } = props;
    return <div className={styles.container}>
        <div className={styles.textArea}>
            <div className={styles.prefix}>Q</div>
            <div className={styles.question}>{ question }</div>
        </div>
        <div>
            <img src={"/images/accordion_icon.svg"} alt={""} />
        </div>
    </div>
}

type Props = {
    question: string
}
