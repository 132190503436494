import React, { useCallback, useEffect, useState } from "react";
import { get } from "../../../../state/api/functions";
import { Notification } from "../../../../domain/notification/Notification";
import styles from "./AdminNotification.module.scss";
import { GlobalContainer } from "../../components/GlobalContainer";
import { GlobalHeader } from "../../components/GlobalHeader";
import AdminNotificationListItem from "./AdminNotificationListItem";
import { PrimaryButton } from "../../components/button/PrimaryButton";
import { NewIcon } from "../../components/icon/NewIcon";
import { Modal } from "../../components/Modal";
import NotificationForm from "./NotificationForm";
import { Pager } from "../../components/button/Pager";
import usePager from "../../../hooks/common/usePager";

export default function AdminNotification() {

    const [openRegisterDialog, setOpenRegisterDialog] = useState(false);
    const [pageCount, currentPage, notifications, setNotifications, displayNotifications, onChangePage] = usePager<Notification>(3)

    const getNotifications = useCallback(() => {
        get("/api/notification/getList").then(
            async response => {
                const json = await response.json();
                setNotifications(json.notifications);
            }
        );
    }, [setNotifications]);

    useEffect(() => {
        getNotifications();
    }, [getNotifications])

    const onClickAddNotification = () => {
        setOpenRegisterDialog(true);
    }

    const onRequestClose = () => {
        setOpenRegisterDialog(false);
    }

    const render = () => {
        if (!notifications || notifications.length === 0) {
            return <div>お知らせが登録されていません</div>
        }
        return <>
            <div className={styles.listContainer}>
                <ul>
                    { displayNotifications.map(notification => {
                        return <AdminNotificationListItem
                            notification={notification}
                            key={notification.id}
                            refreshNotifications={getNotifications}
                        />
                    })}
                </ul>
            </div>
            <div className={styles.pagerButtonArea}>
                <Pager
                    pageCount={pageCount}
                    onChangePage={onChangePage}
                    currentPage={currentPage}
                />
            </div>
        </>
    }

    return <GlobalContainer>
        <GlobalHeader/>
        <div className={styles.container}>
            <div className={styles.header}>
                <h1 className={styles.pageTitle}>お知らせ一覧</h1>
                <div>
                    <PrimaryButton onClick={onClickAddNotification} leading={<NewIcon/>}>お知らせ追加</PrimaryButton>
                    <Modal open={openRegisterDialog} onRequestClose={onRequestClose}>
                        <NotificationForm onRequestClose={onRequestClose} refreshNotifications={getNotifications} notification={null} type={"登録"}/>
                    </Modal>
                </div>
            </div>
            { render() }
        </div>
    </GlobalContainer>
}
