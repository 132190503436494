import { SubItems } from "../SubItems";
import { SupportServiceType } from "../supportservicetype/SupportServiceType";

// ドメイン名と重複するため末尾にSymbolと付与している
const SupportServiceTypeSymbol = Symbol();

// サービス内容
export class SupportService {
    [SupportServiceTypeSymbol]: any;

    id: string;

    text: string | null;

    subItems: SubItems<SupportServiceType>;

    constructor(
        id: string,
        text: string | null,
        subItems: SubItems<SupportServiceType>,
    ) {
        this.id = id;
        this.text = text;
        this.subItems = subItems;
    }

    isFirstSubItem(supportServiceType: SupportServiceType): boolean {
        return this.subItems.items.findIndex((item) => supportServiceType === item) === 0;
    }

    isLastSubItem(supportServiceType: SupportServiceType): boolean {
        return this.subItems.items.findIndex((item) => supportServiceType === item) === this.subItems.items.length - 1;
    }
}
