import { ChangeLog } from "./../../../../domain/changelog/ChangeLog";
import { changeLog } from "./../../changelog/LatestChangeLog";
import { TableThree } from "../../../../domain/careplan/tablethree/TableThree";

export class Get {
    type: "SUCCESS" = "SUCCESS";

    projectId: string;

    tableThree: TableThree;

    latestChangeLog: ChangeLog | null;

    constructor(projectId: string, tableThree: TableThree, latestChangeLog: ChangeLog | null) {
        this.projectId = projectId;
        this.tableThree = tableThree;
        this.latestChangeLog = latestChangeLog;
    }

    static path = "/api/tableThree/get";

    static async extract(response: Response): Promise<Get> {
        const json = await response.json();
        const tableThree = new TableThree(json.tableThree.id, json.project.id);
        const latestChangeLog = json.latestChangeLog ? changeLog(json.latestChangeLog) : null;
        return new Get(json.project.id, tableThree, latestChangeLog);
    }
}
