import "whatwg-fetch";
import { ApiError } from "../../../ApiError";
import { Get } from "./Get";
import { get, queryString, post, del } from "../../../functions";

export const WeeklyServiceApi = new (class {
    async get(carePlanTableThreeId: string): Promise<Get | ApiError> {
        const params = { carePlanTableThreeId };
        const response = await get(`${Get.path}?${queryString(params)}`);
        return (response.ok ? Get.extract : ApiError.extract)(response);
    }

    async register(carePlanTableThreeId: string, dayOfWeek: string, startTime: string, endTime: string, establishmentId: string, establishmentName: string, supportServiceTypeId: number | null, supportServiceType: string, kaigoServiceName: string, remarks: string) {
        const body = {
            carePlanTableThreeId,
            dayOfWeek,
            startTime,
            endTime,
            establishmentId,
            establishmentName,
            supportServiceTypeId,
            supportServiceType,
            kaigoServiceName,
            remarks,
        };
        const response = await post("/api/weeklyService/register", body);
        if (!response.ok) {
            return ApiError.extract(response);
        }
    }

    async update(id: string, carePlanTableThreeId: string, dayOfWeek: string, startTime: string, endTime: string, establishmentId: string, establishmentName: string, supportServiceTypeId: number | null, supportServiceType: string, kaigoServiceName: string, remarks: string) {
        const body = {
            id,
            carePlanTableThreeId,
            dayOfWeek,
            startTime,
            endTime,
            establishmentId,
            establishmentName,
            supportServiceTypeId,
            supportServiceType,
            kaigoServiceName,
            remarks,
        };
        const response = await post("/api/weeklyService/update", body);
        if (!response.ok) {
            return ApiError.extract(response);
        }
    }

    async delete(id: string): Promise<void | ApiError> {
        const response = await del(`/api/weeklyService/delete/${id}`);
        if (!response.ok) {
            throw new ApiError(response.status.toString(), response.statusText);
        }
    }
})();
