import React, { Attributes, RefObject, useState } from "react";
import styles from "./SupportServiceTypeComponent.module.scss";
import { CarePlanTableTwoPeriod } from "../../../../domain/careplan/tabletwo/CarePlanTableTwoPeriod";
import { LongTermGoal } from "../../../../domain/careplan/tabletwo/longtermgoal/LongTermGoal";
import { Need } from "../../../../domain/careplan/tabletwo/need/Need";
import { ShortTermGoal } from "../../../../domain/careplan/tabletwo/shorttermgoal/ShortTermGoal";
import { SupportService } from "../../../../domain/careplan/tabletwo/supportservice/SupportService";
import { changeInsuranceBenefit, InsuranceBenefit } from "../../../../domain/careplan/tabletwo/supportservicetype/InsuranceBenefit";
import { SupportServiceType } from "../../../../domain/careplan/tabletwo/supportservicetype/SupportServiceType";
import { TableTwoPeriod } from "./TableTwoPeriod";
import { TableTwoTextarea } from "./TableTwoTextarea";
import { Link } from "react-router-dom";
import { ShiftAction } from "../../../../domain/careplan/tabletwo/ShiftAction";
import { TableTwoCellWithIcons } from "./TableTwoCellWithIcons";
import { SuggestionEstablishmentDialog } from "../../suggestion/components/SuggestionEstablishmentDialog";
import { Modal } from "../../components/Modal";
import { EstablishmentPage } from "../../suggestion/components/establishment/EstablishmentPage";
import { SupportServiceTypeApi } from "../../../../state/api/careplan/tabletwo/supportservicetype/SupportServiceTypeApi";
import { EditedSupportServiceType } from "../../../../state/api/careplan/tabletwo/supportservicetype/EditedSupportServiceType";
import { CarePlanTableTwo } from "../../../../domain/careplan/tabletwo/CarePlanTableTwo";
import { ChangeLog } from "../../../../domain/changelog/ChangeLog";
import { SupportServiceApi } from "../../../../state/api/careplan/tabletwo/supportservice/SupportServiceApi";
import { EditedSupoprtService } from "../../../../state/api/careplan/tabletwo/supportservice/EditedSupoprtService";
import { EditedCaraPlanTableTwo } from "../../../../state/api/careplan/tabletwo/EditedCaraPlanTableTwo";

export function SupportServiceTypeComponent(props: Props) {
    const { projectId, carePlanTableTwo, need, longTermGoal, shortTermGoal, supportService, supportServiceType, activated, refresh, tableTwoHeaderRef } = props;

    const update = (
        supportServiceTypeId:string,
        supportServiceTypeCode: string | null,
        text: string | null,
        insuranceBenefit: InsuranceBenefit | null,
        establishment: string | null,
        frequency: string | null,
        period: CarePlanTableTwoPeriod | null) => {
        SupportServiceTypeApi.update(
            supportServiceTypeId,
            supportServiceTypeCode,
            text,
            insuranceBenefit,
            establishment,
            frequency,
            period,
        ).then(res => {
            if (res instanceof EditedSupportServiceType) {
                refresh(carePlanTableTwo.replaceSupportServiceType(need, longTermGoal, shortTermGoal, supportService, res.supportServiceType), res.changeLog)
            }
        });
    }

    const handleInsuranceBenefitChange = () => {
        const changedInsuranceBenefit = changeInsuranceBenefit(supportServiceType.insuranceBenefit);
        update(supportServiceType.id, supportServiceType.code, supportServiceType.text, changedInsuranceBenefit, supportServiceType.establishment, supportServiceType.frequency, supportServiceType.period);
    };

    const handleTextUpdate = (text: string | null) => {
        update(supportServiceType.id, supportServiceType.code, text, supportServiceType.insuranceBenefit, supportServiceType.establishment, supportServiceType.frequency, supportServiceType.period);
    };

    const handleEstablishmentUpdate = (establishment: string | null) => {
        update(supportServiceType.id, supportServiceType.code, supportServiceType.text, supportServiceType.insuranceBenefit, establishment, supportServiceType.frequency, supportServiceType.period);
    };

    const handleFrequencyUpdate = (frequency: string | null) => {
        update(supportServiceType.id, supportServiceType.code, supportServiceType.text, supportServiceType.insuranceBenefit, supportServiceType.establishment, frequency, supportServiceType.period);
    };

    const handlePeriodUpdate = (period: CarePlanTableTwoPeriod) => {
        update(supportServiceType.id, supportServiceType.code, supportServiceType.text, supportServiceType.insuranceBenefit, supportServiceType.establishment, supportServiceType.frequency, period);
    };

    const handleAdd = () => {
        SupportServiceApi.addSupportServiceType(supportService.id, supportServiceType.id)
            .then(res => {
                if (res instanceof EditedSupoprtService) {
                    refresh(carePlanTableTwo.replaceSupportService(need, longTermGoal, shortTermGoal, res.supportService), res.changeLog)
                }
            });
    };

    const shiftSupportServiceType = (supportServiceId: string , supportServiceTypeId: string, shiftAction: ShiftAction ) => {
        SupportServiceApi.shiftSupportServiceType(supportServiceId, supportServiceTypeId, shiftAction)
            .then(res => {
                if (res instanceof EditedSupoprtService) {
                    refresh(carePlanTableTwo.replaceSupportService(need, longTermGoal, shortTermGoal, res.supportService), res.changeLog)
                }
            });
    }

    const handleShiftUp = () => {
        shiftSupportServiceType(supportService.id, supportServiceType.id, ShiftAction.UP)
    };

    const handleShiftDown = () => {
        shiftSupportServiceType(supportService.id, supportServiceType.id, ShiftAction.DOWN)
    };

    const handleRemove = () => {
        SupportServiceApi.removeSupportServiceType(supportService.id, supportServiceType.id)
            .then(res => {
                if (res instanceof EditedSupoprtService) {
                    refresh(carePlanTableTwo.replaceSupportService(need, longTermGoal, shortTermGoal, res.supportService), res.changeLog)
                }
            });
    };

    const onClickCopyPeriodButton = () => {
        SupportServiceTypeApi.copyPeriod(supportServiceType.id)
            .then(res => {
                if (res instanceof EditedCaraPlanTableTwo) {
                    refresh(res.carePlanTableTwo, res.changeLog);
                }
            });
    };

    const [openEstablishmentPage, setOpenEstablishmentPage] = useState(false);

    const onRequestEstablishmentPageOpen = () => {
        setOpenEstablishmentPage(true);
    }

    const onRequestEstablishmentPageClose = () => {
        setOpenEstablishmentPage(false);
    }

    return <div className={styles.container}>
        <div className={styles.insuranceBenefitCell}>
            <div className={styles.content}>
                <Link className={styles.insuranceBenefit} to={"#"} onClick={handleInsuranceBenefitChange}>
                    {supportServiceType.insuranceBenefit === InsuranceBenefit.INCLUDED ? "○" : ""}
                </Link>
            </div>
        </div>
        <div className={styles.textCell}>
            <TableTwoCellWithIcons
                initialText={supportServiceType.text}
                isFirstRowCell={supportService.isFirstSubItem(supportServiceType)}
                isLastRowCell={supportService.isLastSubItem(supportServiceType)}
                handleTextUpdate={handleTextUpdate}
                handleAdd={handleAdd}
                handleShiftUp={handleShiftUp}
                handleShiftDown={handleShiftDown}
                handleRemove={handleRemove}
                activated={activated}
                suggestionDialog={(open, _) => {
                    return <SuggestionEstablishmentDialog
                        open={open}
                        projectId={projectId}
                        supportServiceText={supportService.text}
                        shortTermGoal={shortTermGoal.text}
                        onRequestEstablishmentPageOpen={onRequestEstablishmentPageOpen}
                    />
                }}
                tableTwoHeaderRef={tableTwoHeaderRef}
            />
            <Modal open={openEstablishmentPage} onRequestClose={onRequestEstablishmentPageClose}>
                <EstablishmentPage
                    carePlanTableTwo={carePlanTableTwo}
                    need={need}
                    longTermGoal={longTermGoal}
                    shortTermGoal={shortTermGoal}
                    supportServiceText={supportService.text}
                    projectId={projectId}
                    onRequestClose={onRequestEstablishmentPageClose}
                    supportServiceId={supportService.id}
                    supportServiceTypeId ={supportServiceType.id}
                    refresh={refresh}
                />
            </Modal>
        </div>
        <div className={styles.establishmentCell}>
            <div className={styles.content}>
                <TableTwoTextarea
                    initialText={supportServiceType.establishment}
                    onUpdate={handleEstablishmentUpdate}
                    activated={activated}
                />
            </div>
        </div>
        <div className={styles.frequencyCell}>
            <div className={styles.content}>
                <TableTwoTextarea
                    initialText={supportServiceType.frequency}
                    onUpdate={handleFrequencyUpdate}
                    activated={activated}/>
            </div>
        </div>
        <div className={styles.periodCell}>
            <TableTwoPeriod
                period={supportServiceType.period}
                category={"SupportServiceType"}
                forShortTermGoalPeriodCopy={shortTermGoal.period}
                onChange={handlePeriodUpdate}
                onClickCopyPeriod={onClickCopyPeriodButton}
                activated={activated}/>
        </div>
    </div>;
}

type Props = Attributes & {
    projectId: string,
    carePlanTableTwo: CarePlanTableTwo,
    need: Need,
    longTermGoal: LongTermGoal,
    shortTermGoal: ShortTermGoal,
    supportService: SupportService,
    supportServiceType: SupportServiceType,
    activated: boolean,
    refresh: (carePlanTableTwo: CarePlanTableTwo, changeLog: ChangeLog) => void,
    tableTwoHeaderRef: RefObject<HTMLDivElement>
};
