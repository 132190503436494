// THIS CODE IS GENERATED CODE. DO NOT EDIT.

import React, { useEffect, useState } from "react";
import styles from "./Section.module.scss";
import { AssessmentSheetUpdater } from "./AssessmentSheetUpdater";
import { AssessmentSheetField } from "./AssessmentSheetField";
import {
    KaigoHokenRiyoShaFutanWariai,
    KogakuKaigoServiceHiGaitoRiyoShaFutan,
    KokiKoreiShaIryoHokenIchibuFutanKin,
    NinchishoHantei,
    Seibetsu,
    SeishinShogaiShaHokenFukushiTecho,
    ShinshoTechoTokyuKyu,
    ShinshoTechoTokyuShu,
    ShogaiKoreiShaHantei,
    TechoNadoNoUmu,
    UketsukeBasho,
    YoKaigoNintei,
} from "./types";
import {
    kaigoHokenRiyoShaFutanWariaiLabels,
    kogakuKaigoServiceHiGaitoRiyoShaFutanLabels,
    kokiKoreiShaIryoHokenIchibuFutanKinLabels,
    ninchishoHanteiLabels,
    seibetsuLabels,
    seishinShogaiShaHokenFukushiTechoLabels,
    shinshoTechoTokyuKyuLabels,
    shinshoTechoTokyuShuLabels,
    shogaiKoreiShaHanteiLabels,
    techoNadoNoUmuLabels,
    uketsukeBashoLabels,
    yoKaigoNinteiLabels,
} from "./labels";
import { dateWith, positiveIntegerWith, textWith } from "./fields";
import { today as getToday } from "../../../../../domain/fundamental/LocalDate";
import dayjs from "dayjs";

export function FaceSheetSection(props: Props) {

    const { assessmentSheetUpdater, activated } = props;
    const assessmentSheetField = new AssessmentSheetField(activated);

    const calcAge = (date: string): number | null => {
        if (!date) {
            return null;
        }
        const today = getToday();
        const birthday = dayjs(date)
        return today.diff(birthday, "year");
    }

    const [birthday, setBirthday] = useState("");
    const [age, setAge] = useState<number | null>(null);

    useEffect(() => {
        setAge(calcAge(birthday));
    }, [birthday]);

    return <>
        <div className={styles.card}>
            <h3 className={styles.title}>相談受付</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        相談受付日
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderDateField<string | null>(
                        assessmentSheetUpdater,
                        "faceSheet.sodanUketsukeBi",
                        dateWith({ width: 280 })
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        受付場所
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<UketsukeBasho | null>(
                        assessmentSheetUpdater,
                        "faceSheet.uketsukeBasho",
                        uketsukeBashoLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        その他内容
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "faceSheet.sonotaNaiyo",
                        textWith({ width: 280 })
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        初回相談受付者
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "faceSheet.shokaiSodanUketsukeSha",
                        textWith({ width: 280 })
                    )}
                </div>;
            })}
        </div>
        <div className={styles.card}>
            <h3 className={styles.title}>本人情報</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        氏名
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "faceSheet.honninNitsuite.shimei",
                        textWith({ width: 280, maxLength: 20 })
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        性別
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<Seibetsu | null>(
                        assessmentSheetUpdater,
                        "faceSheet.honninNitsuite.seibetsu",
                        seibetsuLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        生年月日
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderDateField<string | null>(
                        assessmentSheetUpdater,
                        "faceSheet.honninNitsuite.seinengappi",
                        dateWith({ width: 280, from: 100, to: 0, initialDate: "1960-01-01", set: setBirthday })
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        年齢
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<number | null>(
                        assessmentSheetUpdater,
                        "faceSheet.honninNitsuite.nenrei",
                        (_) => { return <>{ age }</> }
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        住所
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "faceSheet.jusho.jusho",
                        textWith()
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        電話番号
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "faceSheet.jusho.denwaBango",
                        textWith({ width: 280 })
                    )}
                </div>;
            })}
        </div>
        <div className={styles.card}>
            <h3 className={styles.title}>緊急連絡先</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        氏名
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "faceSheet.kinkyuRenrakuSaki.shimei",
                        textWith({ width: 280 })
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        性別
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<Seibetsu | null>(
                        assessmentSheetUpdater,
                        "faceSheet.kinkyuRenrakuSaki.seibetsu",
                        seibetsuLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        年齢
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<number | null>(
                        assessmentSheetUpdater,
                        "faceSheet.kinkyuRenrakuSaki.nenrei",
                        positiveIntegerWith({ width: 70 })
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        本人との関係
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "faceSheet.kinkyuRenrakuSaki.honninToNoTsuzukigara",
                        textWith({ width: 70 })
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        住所
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "faceSheet.kinkyuRenrakuSaki.jusho",
                        textWith()
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        電話番号
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "faceSheet.kinkyuRenrakuSaki.denwaBango",
                        textWith({ width: 280 })
                    )}
                </div>;
            })}
        </div>
        <div className={styles.card}>
            <h3 className={styles.title}>相談者</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        氏名
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "faceSheet.sodansha.shimei",
                        textWith({ width: 280 })
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        性別
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<Seibetsu | null>(
                        assessmentSheetUpdater,
                        "faceSheet.sodansha.seibetsu",
                        seibetsuLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        年齢
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<number | null>(
                        assessmentSheetUpdater,
                        "faceSheet.sodansha.nenrei",
                        positiveIntegerWith({ width: 70 })
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        本人との関係
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "faceSheet.sodansha.honninToNoTsuzukigara",
                        textWith({ width: 70 })
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        住所
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "faceSheet.sodansha.jusho",
                        textWith()
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        電話番号
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "faceSheet.sodansha.denwaBango",
                        textWith({ width: 280 })
                    )}
                </div>;
            })}
        </div>
        <div className={styles.card}>
            <h3 className={styles.title}>相談経路（紹介者）</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        相談経路（紹介者）
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "faceSheet.sodanKeiro",
                        textWith({ width: 280 })
                    )}
                </div>;
            })}
        </div>
        <div className={styles.card}>
            <h3 className={styles.title}>居宅サービス計画作成依頼の届出</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        届出年月日
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderDateField<string | null>(
                        assessmentSheetUpdater,
                        "faceSheet.kyotakuServiceKeikakuSakuseiIraiNoTodokedeNengappi",
                        dateWith({ width: 280 })
                    )}
                </div>;
            })}
        </div>
        <div className={styles.card}>
            <h3 className={styles.title}>相談内容（主訴/本人・家族の希望・困っていることや不安、思い）</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        本人
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderTextareaField(
                        assessmentSheetUpdater,
                        "faceSheet.sodanNaiyo.honnin",
                        { width: "auto", rows: 5, maxLength: 1000 }
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        介護者・家族
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderTextareaField(
                        assessmentSheetUpdater,
                        "faceSheet.sodanNaiyo.kaigoShaKazoku",
                        { width: "auto", rows: 5, maxLength: 1000 }
                    )}
                </div>;
            })}
        </div>
        <div className={styles.card}>
            <h3 className={styles.title}>これまでの生活の経過（主な生活史）</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        これまでの生活の経過（主な生活史）
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderTextareaField(
                        assessmentSheetUpdater,
                        "faceSheet.koreMadeNoSeikatsuNoKeika",
                        { width: "auto", rows: 5 }
                    )}
                </div>;
            })}
        </div>
        <div className={styles.card}>
            <h3 className={styles.title}>介護保険</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        利用者負担割合
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<KaigoHokenRiyoShaFutanWariai | null>(
                        assessmentSheetUpdater,
                        "faceSheet.kaigoHoken.riyoShaFutanWariai",
                        kaigoHokenRiyoShaFutanWariaiLabels
                    )}
                </div>;
            })}
        </div>
        <div className={styles.card}>
            <h3 className={styles.title}>後期高齢者医療保険（75歳以上）</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        一部負担金
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<KokiKoreiShaIryoHokenIchibuFutanKin | null>(
                        assessmentSheetUpdater,
                        "faceSheet.kokiKoreiShaIryoHoken.ichibuFutanKin",
                        kokiKoreiShaIryoHokenIchibuFutanKinLabels
                    )}
                </div>;
            })}
        </div>
        <div className={styles.card}>
            <h3 className={styles.title}>高額介護サービス費該当</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        利用者負担
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<KogakuKaigoServiceHiGaitoRiyoShaFutan | null>(
                        assessmentSheetUpdater,
                        "faceSheet.kogakuKaigoServiceHiGaito.riyoShaFutan",
                        kogakuKaigoServiceHiGaitoRiyoShaFutanLabels,
                        { radioPattern: "vertical" }
                    )}
                </div>;
            })}
        </div>
        <div className={styles.card}>
            <h3 className={styles.title}>要介護認定</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        済
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<YoKaigoNintei | null>(
                        assessmentSheetUpdater,
                        "faceSheet.yoKaigoNintei.sumi",
                        yoKaigoNinteiLabels,
                        { radioPattern: "vertical" }
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        認定日
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderDateField<string | null>(
                        assessmentSheetUpdater,
                        "faceSheet.yoKaigoNintei.ninteiBi",
                        dateWith({ width: 280 })
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        認定の有効期間(開始日)
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderDateField<string | null>(
                        assessmentSheetUpdater,
                        "faceSheet.yoKaigoNintei.ninteiNoYukoKikanKaishiBi",
                        dateWith({ width: 280 })
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        認定の有効期間(終了日)
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderDateField<string | null>(
                        assessmentSheetUpdater,
                        "faceSheet.yoKaigoNintei.ninteiNoYukoKikanShuryoBi",
                        dateWith({ width: 280 })
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        未（見込み）
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<YoKaigoNintei | null>(
                        assessmentSheetUpdater,
                        "faceSheet.yoKaigoNintei.mi",
                        yoKaigoNinteiLabels,
                        { radioPattern: "vertical" }
                    )}
                </div>;
            })}
        </div>
        <div className={styles.card}>
            <h3 className={styles.title}>身障手帳</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        有無
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<TechoNadoNoUmu | null>(
                        assessmentSheetUpdater,
                        "faceSheet.shinshoTecho.umu",
                        techoNadoNoUmuLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        等級 種
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<ShinshoTechoTokyuShu | null>(
                        assessmentSheetUpdater,
                        "faceSheet.shinshoTecho.tokyuShu",
                        shinshoTechoTokyuShuLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        等級 級
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<ShinshoTechoTokyuKyu | null>(
                        assessmentSheetUpdater,
                        "faceSheet.shinshoTecho.tokyuKyu",
                        shinshoTechoTokyuKyuLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        備考
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "faceSheet.shinshoTecho.biko",
                        textWith({ width: "auto" })
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        交付日
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderDateField<string | null>(
                        assessmentSheetUpdater,
                        "faceSheet.shinshoTecho.kofuBi",
                        dateWith({ width: 280 })
                    )}
                </div>;
            })}
        </div>
        <div className={styles.card}>
            <h3 className={styles.title}>療育手帳</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        有無
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<TechoNadoNoUmu | null>(
                        assessmentSheetUpdater,
                        "faceSheet.ryoikuTecho.umu",
                        techoNadoNoUmuLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        程度
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "faceSheet.ryoikuTecho.teido",
                        textWith({ width: 280 })
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        備考
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "faceSheet.ryoikuTecho.biko",
                        textWith({ width: "auto" })
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        交付日
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderDateField<string | null>(
                        assessmentSheetUpdater,
                        "faceSheet.ryoikuTecho.kofuBi",
                        dateWith({ width: 280 })
                    )}
                </div>;
            })}
        </div>
        <div className={styles.card}>
            <h3 className={styles.title}>精神障害者保健福祉手帳</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        有無
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<TechoNadoNoUmu | null>(
                        assessmentSheetUpdater,
                        "faceSheet.seishinShogaiShaHokenFukushiTecho.umu",
                        techoNadoNoUmuLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        等級 級
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<SeishinShogaiShaHokenFukushiTecho | null>(
                        assessmentSheetUpdater,
                        "faceSheet.seishinShogaiShaHokenFukushiTecho.tokyuKyu",
                        seishinShogaiShaHokenFukushiTechoLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        備考
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "faceSheet.seishinShogaiShaHokenFukushiTecho.biko",
                        textWith({ width: "auto" })
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        交付日
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderDateField<string | null>(
                        assessmentSheetUpdater,
                        "faceSheet.seishinShogaiShaHokenFukushiTecho.kofuBi",
                        dateWith({ width: 280 })
                    )}
                </div>;
            })}
        </div>
        <div className={styles.card}>
            <h3 className={styles.title}>障害福祉サービス受給者証</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        有無
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<TechoNadoNoUmu | null>(
                        assessmentSheetUpdater,
                        "faceSheet.shogaiFukushiServiceJukyuShaSho.umu",
                        techoNadoNoUmuLabels
                    )}
                </div>;
            })}
        </div>
        <div className={styles.card}>
            <h3 className={styles.title}>自立支援医療受給者証</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        有無
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<TechoNadoNoUmu | null>(
                        assessmentSheetUpdater,
                        "faceSheet.jiritsuShienIryoJukyuShaSho.umu",
                        techoNadoNoUmuLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        障害支援区分
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "faceSheet.jiritsuShienIryoJukyuShaSho.shogaiShienKubun",
                        textWith({ width: 280 })
                    )}
                </div>;
            })}
        </div>
        <div className={styles.card}>
            <h3 className={styles.title}>日常生活自立度 障害高齢者</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        判定
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<ShogaiKoreiShaHantei | null>(
                        assessmentSheetUpdater,
                        "faceSheet.nichijoSeikatsuJiritsuDo.shogaiKoreiSha.hantei",
                        shogaiKoreiShaHanteiLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        判定者
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "faceSheet.nichijoSeikatsuJiritsuDo.shogaiKoreiSha.hanteiSha",
                        textWith({ width: 280 })
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        機関名
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "faceSheet.nichijoSeikatsuJiritsuDo.shogaiKoreiSha.kikanMei",
                        textWith({ width: 280 })
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        判定日
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderDateField<string | null>(
                        assessmentSheetUpdater,
                        "faceSheet.nichijoSeikatsuJiritsuDo.shogaiKoreiSha.hanteiBi",
                        dateWith({ width: 280 })
                    )}
                </div>;
            })}
        </div>
        <div className={styles.card}>
            <h3 className={styles.title}>日常生活自立度 認知症</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        判定
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<NinchishoHantei | null>(
                        assessmentSheetUpdater,
                        "faceSheet.nichijoSeikatsuJiritsuDo.ninchisho.hantei",
                        ninchishoHanteiLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        判定者
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "faceSheet.nichijoSeikatsuJiritsuDo.ninchisho.hanteiSha",
                        textWith({ width: 280 })
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        機関名
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "faceSheet.nichijoSeikatsuJiritsuDo.ninchisho.kikanMei",
                        textWith({ width: 280 })
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        判定日
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderDateField<string | null>(
                        assessmentSheetUpdater,
                        "faceSheet.nichijoSeikatsuJiritsuDo.ninchisho.hanteiBi",
                        dateWith({ width: 280 })
                    )}
                </div>;
            })}
        </div>
        <div className={styles.card}>
            <h3 className={styles.title}>アセスメント実施日</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        初回
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderDateField<string | null>(
                        assessmentSheetUpdater,
                        "faceSheet.assessmentJisshiBi.shokaiJisshiBi",
                        dateWith({ width: 280 })
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        更新
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderDateField<string | null>(
                        assessmentSheetUpdater,
                        "faceSheet.assessmentJisshiBi.koshinBi",
                        dateWith({ width: 280 })
                    )}
                </div>;
            })}
        </div>
    </>;
}

type Props = {
    assessmentSheetUpdater: AssessmentSheetUpdater,
    activated: boolean,
};
