import { CarePlanTableTwoPeriod } from "../../../../domain/careplan/tabletwo/CarePlanTableTwoPeriod";
import { localDate } from "../../../../domain/fundamental/LocalDate";

export type PeriodObject = {
    start: string,
    end: string,
};

export function fromPeriodObject(periodObject: PeriodObject): CarePlanTableTwoPeriod {
    const start = localDate(periodObject.start);
    const end = localDate(periodObject.end);
    return new CarePlanTableTwoPeriod(start, end);
}

export function toPeriodObject(carePlanTableTwoPeriod: CarePlanTableTwoPeriod): PeriodObject {
    // toISOStringでISO 8601形式の文字列に変換できるが、UTCで正規化してしまうのでformatを用いる
    const start = carePlanTableTwoPeriod.start.format("YYYY-MM-DD");
    const end = carePlanTableTwoPeriod.end.format("YYYY-MM-DD");
    return { start, end };
}
