import React, { ChangeEvent, InputHTMLAttributes } from "react";
import styles from "./CheckboxField.module.scss";
import classNames from "classnames";

export function CheckboxField(props: CheckboxFieldProps) {
    const { disabled, children, get, set, ...rest } = props;

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        set?.(event.target.checked);
    };

    const fieldClassName = classNames(styles.field, {
        [styles.checked]: get,
        [styles.disabled]: disabled,
    });

    return <label className={fieldClassName}>
        <input type={"checkbox"} className={styles.input} disabled={disabled} checked={get} onChange={onChange} {...rest}/>
        <span className={styles.display}>
            <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M10 1.65685L4.19601 8L0 3.41421L1.51603 1.75736L4.19601 4.68629L8.48397 0L10 1.65685Z" fill="white"/>
            </svg>
        </span>
        <span className={styles.label}>{children}</span>
    </label>;
}

type Props = {
    get?: boolean,
    set?: (value: boolean) => void,
};

export type CheckboxFieldProps = InputHTMLAttributes<any> & Props;
