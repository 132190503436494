import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./ProjectPage.module.scss";
import { useParams } from "react-router";
import { ProjectSheetType } from "../../../domain/project/ProjectSheetType";
import { GlobalContainer } from "../components/GlobalContainer";
import { GlobalHeader } from "../components/GlobalHeader";
import { ProjectHeader } from "./components/ProjectHeader";
import { ProjectSheetTabBar } from "./components/ProjectSheetTabBar";
import { AssessmentSheetFragment } from "../assessmentsheet/AssessmentSheetFragment";
import { TableOneFragment } from "../tableone/TableOneFragment";
import { TableTwoFragment } from "../tabletwo/TableTwoFragment";
import { SimpleCareRecipient } from "../../../domain/carerecipient/SimpleCareRecipient";
import { ProjectApi } from "../../../state/api/project/ProjectApi";
import { ProjectListResponse } from "../../../state/api/project/ProjectListResponse";
import { Project } from "../../../domain/project/Project";
import { State } from "../../../state/store";
import { TableThreeFragment } from "../tablethree/TableThreeFragment";

export function ProjectPage(props: Props) {
    const { sheetType } = props;
    const { careRecipientId, projectId } = useParams<PathParams>();

    const [careRecipient, setCareRecipient] = useState<SimpleCareRecipient | null>(null);
    const [projects, setProjects] = useState< Project[]>([]);

    const rsLoginIn = useSelector((state: State) => state.authentication.rsLoginIn);

    const globalHeaderRef = useRef<HTMLDivElement>(null);
    const projectHeaderRef = useRef<HTMLDivElement>(null);
    const projectSheetTabBarRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);

    const onScroll = useCallback(() => {
        if (!globalHeaderRef.current || !projectHeaderRef.current || !projectSheetTabBarRef.current || !contentRef.current) return;
        const scrollTop = (document.scrollingElement ?? document.documentElement).scrollTop;
        stickyPageHeaderIf(scrollTop, globalHeaderRef.current, projectHeaderRef.current, projectSheetTabBarRef.current, contentRef.current);
    }, [globalHeaderRef, projectHeaderRef, projectSheetTabBarRef, contentRef]);

    const retrieveProject = useCallback(() => {
        ProjectApi.getList(careRecipientId)
            .then(res => {
                if (res instanceof ProjectListResponse) {
                    setCareRecipient(res.careRecipient);
                    setProjects(res.projects);
                }
            })
    }, [careRecipientId])

    const onCompleteEditCareRecipient = () => {
        retrieveProject();
    }

    useEffect(() => {
        retrieveProject()
    }, [retrieveProject]);

    useEffect(() => {
        document.addEventListener("scroll", onScroll);
        return () => document.removeEventListener("scroll", onScroll);
    }, [onScroll]);

    const renderFragment = () => {
        switch (sheetType) {
            case ProjectSheetType.ASSESSMENT_SHEET:
                return <AssessmentSheetFragment projectId={projectId}
                    globalHeaderHeight={() => globalHeaderRef.current?.clientHeight ?? 0}
                    projectHeaderHeight={() => projectHeaderRef.current?.clientHeight ?? 0}
                    activated={careRecipient?.activated ?? true}/>;
            case ProjectSheetType.TABLE_ONE:
                return <TableOneFragment projectId={projectId}
                    globalHeaderHeight={() => globalHeaderRef.current?.clientHeight ?? 0}
                    projectHeaderHeight={() => projectHeaderRef.current?.clientHeight ?? 0}
                    globalHeaderRef={globalHeaderRef}
                    projectHeaderRef={projectHeaderRef}
                    activated={careRecipient?.activated ?? true}/>;
            case ProjectSheetType.TABLE_TWO:
                return <TableTwoFragment projectId={projectId}
                    globalHeaderHeight={() => globalHeaderRef.current?.clientHeight ?? 0}
                    projectHeaderHeight={() => projectHeaderRef.current?.clientHeight ?? 0}
                    globalHeaderRef={globalHeaderRef}
                    projectHeaderRef={projectHeaderRef}
                    activated={careRecipient?.activated ?? true}
                    careRecipient={careRecipient}/>;
            case ProjectSheetType.TABLE_THREE:
                return <TableThreeFragment projectId={projectId}
                    globalHeaderHeight={() => globalHeaderRef.current?.clientHeight ?? 0}
                    projectHeaderHeight={() => projectHeaderRef.current?.clientHeight ?? 0}
                    globalHeaderRef={globalHeaderRef}
                    projectHeaderRef={projectHeaderRef}
                    activated={careRecipient?.activated ?? true}/>;
        }
    };

    return <>
        <GlobalContainer>
            <div ref={globalHeaderRef} className={styles.globalHeader}>
                <GlobalHeader/>
            </div>
            <div ref={projectHeaderRef} className={styles.projectHeader}>
                <ProjectHeader careRecipient={careRecipient} projectId={projectId} sheetType={sheetType} projects={projects} refresh={retrieveProject} globalHeaderRef={globalHeaderRef} onCompleteEditCareRecipient={onCompleteEditCareRecipient}/>
            </div>
            <div ref={contentRef}>
                {renderFragment()}
            </div>
            <div ref={projectSheetTabBarRef} className={styles.projectSheetTabBar}>
                {!rsLoginIn && <ProjectSheetTabBar careRecipientId={careRecipientId} projectId={projectId} sheetType={sheetType}/> }
            </div>
        </GlobalContainer>
    </>;
}

type Props = {
    sheetType: ProjectSheetType,
};

type PathParams = {
    careRecipientId: string,
    projectId: string,
};

function stickyPageHeaderIf(
    scrollTop: number,
    globalHeader: HTMLDivElement,
    projectHeader: HTMLDivElement,
    projectSheetTabBar: HTMLDivElement,
    content: HTMLDivElement,
) {
    globalHeader.style.position = "fixed";
    projectHeader.style.position = "fixed";
    projectHeader.style.top = `${globalHeader.clientHeight}px`;
    content.style.paddingTop = `${globalHeader.clientHeight + projectHeader.clientHeight}px`;

    // スタイルの変更でどうしてもスクロール位置が狂うので、正しい値に戻す
    const currentScrollTop = (document.scrollingElement ?? document.documentElement).scrollTop;
    if (scrollTop !== currentScrollTop) applyScrollTop(scrollTop);
}

function applyScrollTop(scrollTop: number) {
    (document.scrollingElement ?? document.documentElement).scrollTop = scrollTop;
}
