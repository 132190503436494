import React, { useEffect, useState } from "react";
import styles from "./CareRecipientHeader.module.scss";
import { useHistory, Redirect } from "react-router-dom";
import { Paths } from "../../../../Paths";
import { TextButton } from "../../components/button/TextButton";
import { LeftArrowIcon } from "../../components/icon/LeftArrowIcon";
import { ProjectApi } from "../../../../state/api/project/ProjectApi";
import { ProjectListResponse } from "../../../../state/api/project/ProjectListResponse";

export function CareRecipientHeader(props: Props) {
    const { careRecipientId } = props;

    const [projectId, setProjectId] = useState<string>("");

    const history = useHistory();

    useEffect(() => {
        let mounted = true;
        ProjectApi.getList(careRecipientId)
            .then(res => {
                if (res instanceof ProjectListResponse && mounted) {
                    setProjectId(res.projects[0].id)
                }
            })
        return () => {
            mounted = false;
        }
    }, [careRecipientId]);

    const onClickBackButton = () => {
        history.push(Paths.careRecipients);
    };

    if (projectId) {
        return <Redirect to={Paths.assessmentSheet.build({ careRecipientId, projectId })}/>;
    }

    return <div className={styles.container}>
        <TextButton onClick={onClickBackButton} leading={<LeftArrowIcon/>}>利用者一覧</TextButton>
    </div>;
}

type Props = {
    careRecipientId: string,
};
