import "whatwg-fetch";
import { GetList } from "./GetList";
import { Register } from "./Register";
import { Update } from "./Update";
import { Activate } from "./Activate";
import { Deactivate } from "./Deactivate";
import { ApiError } from "../ApiError";
import { get, post, queryString } from "../functions";

export const CareRecipientApi = new class {
    async getList(scopeByResponsibleUser: boolean, scopeByActivated: boolean): Promise<GetList | ApiError> {
        const params = {
            scopeByResponsibleUser: scopeByResponsibleUser ? "MYSELF" : "ALL",
            scopeByActivated: scopeByActivated ? "true" : "false",
        };
        const response = await get(`${GetList.path}?${queryString(params)}`);
        return (response.ok ? GetList.extract : ApiError.extract)(response);
    }

    async register(name: string, phonetic: string, responsibleUserId: string): Promise<Register | ApiError> {
        const body = { name, phonetic, responsibleUserId };
        const response = await post(Register.path, body);
        return (response.ok ? Register.extract : ApiError.extract)(response);
    }

    async update(id: string, name: string, phonetic: string, responsibleUserId: string): Promise<Update | ApiError> {
        const body = { id, name, phonetic, responsibleUserId };
        const response = await post(Update.path, body)
        return (response.ok ? Update.extract : ApiError.extract)(response);
    }

    async activate(id: string): Promise<Activate | ApiError> {
        const body = { id };
        const response = await post(Activate.path, body);
        return response.ok ? new Activate() : ApiError.extract(response);
    }

    async deactivate(id: string): Promise<Deactivate | ApiError> {
        const body = { id };
        const response = await post(Deactivate.path, body);
        return response.ok ? new Deactivate() : ApiError.extract(response);
    }

    async delete(id: string): Promise<void | ApiError> {
        const response: Response = await post("/api/careRecipient/delete", { id });
        if (!response.ok) {
            throw new ApiError(response.status.toString(), response.statusText);
        }
    }
}();
