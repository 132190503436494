import React from "react";
import styles from "./CareRecipientFilteredList.module.scss";
import { CareRecipientRow } from "../../../../domain/carerecipient/CareRecipientRow";
import { CareRecipientListItem } from "./CareRecipientListItem";
import { Link } from "react-router-dom";

export function CareRecipientFilteredList(props: Props) {
    const { careRecipients, keyword, onClearKeyword, refresh } = props;
    const filteredCareRecipients = careRecipients.filter(careRecipient => careRecipient.matches(keyword));

    const onClickClearKeyword = () => {
        onClearKeyword?.();
    };

    const renderList = () => {
        if (filteredCareRecipients.length) {
            return filteredCareRecipients.map(careRecipient => {
                return <li key={careRecipient.id} className={styles.item}>
                    <CareRecipientListItem careRecipient={careRecipient} refresh={refresh}/>
                </li>;
            });
        } else {
            return <p className={styles.noResult}>該当する利用者が0人でした。</p>;
        }
    };

    return <>
        <div className={styles.result}>
            <span className={styles.message}>[{keyword}]の検索結果({filteredCareRecipients.length}件)</span>
            <Link className={styles.clearKeyword} to={"#"} onClick={onClickClearKeyword}>検索結果をクリア</Link>
        </div>
        <ul>
            {renderList()}
        </ul>
    </>;
}

type Props = {
    careRecipients: CareRecipientRow[],
    keyword: string,
    onClearKeyword?: () => void,
    refresh: () => void,
};
