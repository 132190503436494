import React, { useState } from "react";
import styles from "./UserList.module.scss";
import { PrimaryButton } from "../../components/button/PrimaryButton";
import { Modal } from "../../components/Modal";
import UserForm from "./UserForm";
import UserListItem from "./UserListItem";
import useUsers from "../../../hooks/admin/user/useUsers";

export default function UserList() {

    const [openRegisterDialog, setOpenRegisterDialog] = useState(false);

    const { users, retrieveUsers } = useUsers();

    const onRequestClose = async () => {
        setOpenRegisterDialog(false);
        await retrieveUsers()
    }

    return <div>
        <div className={styles.registerButtonArea}>
            <PrimaryButton onClick={() => setOpenRegisterDialog(true)}>ユーザー新規登録</PrimaryButton>
            <Modal open={openRegisterDialog} onRequestClose={onRequestClose}>
                <UserForm type={"register"} onRequestClose={onRequestClose} refresh={retrieveUsers}/>
            </Modal>
        </div>
        <ul>
            {users && users.map((user) => {
                return <UserListItem user={user} key={user.id} refresh={retrieveUsers}/>
            })}
        </ul>
    </div>
}
