// THIS CODE IS GENERATED CODE. DO NOT EDIT.

import React from "react";
import styles from "./Section.module.scss";
import { AssessmentSheetUpdater } from "./AssessmentSheetUpdater";
import { AssessmentSheetField } from "./AssessmentSheetField";
import {
    KazokuJisshi,
    YoEnjoKeikaku,
} from "./types";
import {
    kazokuJisshiLabels,
    yoEnjoKeikakuLabels,
} from "./labels";
import { checkboxWith } from "./fields";

export function IryoKenkoKankeiSection(props: Props) {

    const { assessmentSheetUpdater, activated } = props;
    const assessmentSheetField = new AssessmentSheetField(activated);

    return <>
        <div className={styles.card}>
            <h3 className={styles.title}>要介護認定項目</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        処置内容
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.vertical}>
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.tentekiNoKanri",
                                checkboxWith("1. 点滴の管理")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.chushinJomyakuEiyo",
                                checkboxWith("2. 中心静脈栄養")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.toseki",
                                checkboxWith("3. 透析")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.stomaNoShochi",
                                checkboxWith("4. ストーマ（人工肛門）の処置")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.sansoRyoho",
                                checkboxWith("5. 酸素療法")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.respirator",
                                checkboxWith("6. レスピレーター（人工呼吸器）")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.kikanSekkaiNoShochi",
                                checkboxWith("7. 気管切開の処置")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.totsuNoKango",
                                checkboxWith("8. 疼痛の看護")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.keiKanEiyo",
                                checkboxWith("9. 経管栄養")
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        特別な対応
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.vertical}>
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.monitorSokutei",
                                checkboxWith("10. モニター測定（血圧、心拍、酸素飽和度等）")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.jokusoNoShochi",
                                checkboxWith("11. じょくそうの処置")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.catheter",
                                checkboxWith("12. カテーテル（コンドームカテーテル、留置カテーテル、ウロストーマ等）")
                            )}
                        </div>
                    </div>
                </div>;
            })}
        </div>
        <div className={styles.card}>
            <h3 className={styles.title}>測定・観察</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        家族実施
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<KazokuJisshi | null>(
                        assessmentSheetUpdater,
                        "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.sokuteiKansatsu.enjoNoGenjo.kazokuJisshi",
                        kazokuJisshiLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        サービス実施
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.sokuteiKansatsu.enjoNoGenjo.serviceJisshi",
                                checkboxWith()
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        希望
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.sokuteiKansatsu.kibo",
                                checkboxWith()
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        要援助→計画
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<YoEnjoKeikaku | null>(
                        assessmentSheetUpdater,
                        "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.sokuteiKansatsu.yoEnjoKeikaku",
                        yoEnjoKeikakuLabels
                    )}
                </div>;
            })}
        </div>
        <div className={styles.card}>
            <h3 className={styles.title}>薬剤の管理</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        家族実施
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<KazokuJisshi | null>(
                        assessmentSheetUpdater,
                        "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.yakuzaiNoKanri.enjoNoGenjo.kazokuJisshi",
                        kazokuJisshiLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        サービス実施
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.yakuzaiNoKanri.enjoNoGenjo.serviceJisshi",
                                checkboxWith()
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        希望
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.yakuzaiNoKanri.kibo",
                                checkboxWith()
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        要援助→計画
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<YoEnjoKeikaku | null>(
                        assessmentSheetUpdater,
                        "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.yakuzaiNoKanri.yoEnjoKeikaku",
                        yoEnjoKeikakuLabels
                    )}
                </div>;
            })}
        </div>
        <div className={styles.card}>
            <h3 className={styles.title}>薬剤の使用</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        家族実施
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<KazokuJisshi | null>(
                        assessmentSheetUpdater,
                        "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.yakuzaiNoShiyo.enjoNoGenjo.kazokuJisshi",
                        kazokuJisshiLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        サービス実施
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.yakuzaiNoShiyo.enjoNoGenjo.serviceJisshi",
                                checkboxWith()
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        希望
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.yakuzaiNoShiyo.kibo",
                                checkboxWith()
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        要援助→計画
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<YoEnjoKeikaku | null>(
                        assessmentSheetUpdater,
                        "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.yakuzaiNoShiyo.yoEnjoKeikaku",
                        yoEnjoKeikakuLabels
                    )}
                </div>;
            })}
        </div>
        <div className={styles.card}>
            <h3 className={styles.title}>受診・検査介助</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        家族実施
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<KazokuJisshi | null>(
                        assessmentSheetUpdater,
                        "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.jushinKensaKaijo.enjoNoGenjo.kazokuJisshi",
                        kazokuJisshiLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        サービス実施
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.jushinKensaKaijo.enjoNoGenjo.serviceJisshi",
                                checkboxWith()
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        希望
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.jushinKensaKaijo.kibo",
                                checkboxWith()
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        要援助→計画
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<YoEnjoKeikaku | null>(
                        assessmentSheetUpdater,
                        "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.jushinKensaKaijo.yoEnjoKeikaku",
                        yoEnjoKeikakuLabels
                    )}
                </div>;
            })}
        </div>
        <div className={styles.card}>
            <h3 className={styles.title}>リハビリテーション</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        家族実施
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<KazokuJisshi | null>(
                        assessmentSheetUpdater,
                        "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.rehabilitation.enjoNoGenjo.kazokuJisshi",
                        kazokuJisshiLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        サービス実施
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.rehabilitation.enjoNoGenjo.serviceJisshi",
                                checkboxWith()
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        希望
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.rehabilitation.kibo",
                                checkboxWith()
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        要援助→計画
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<YoEnjoKeikaku | null>(
                        assessmentSheetUpdater,
                        "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.rehabilitation.yoEnjoKeikaku",
                        yoEnjoKeikakuLabels
                    )}
                </div>;
            })}
        </div>
        <div className={styles.card}>
            <h3 className={styles.title}>医療処置の管理</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        家族実施
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<KazokuJisshi | null>(
                        assessmentSheetUpdater,
                        "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.iryoShochiNoKanri.enjoNoGenjo.kazokuJisshi",
                        kazokuJisshiLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        サービス実施
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.iryoShochiNoKanri.enjoNoGenjo.serviceJisshi",
                                checkboxWith()
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        希望
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.iryoShochiNoKanri.kibo",
                                checkboxWith()
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        要援助→計画
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<YoEnjoKeikaku | null>(
                        assessmentSheetUpdater,
                        "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.iryoShochiNoKanri.yoEnjoKeikaku",
                        yoEnjoKeikakuLabels
                    )}
                </div>;
            })}
        </div>
        <div className={styles.card}>
            <h3 className={styles.title}>具体的内容</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        現状
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.vertical}>
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.genjo.vitalsignsNoCheck",
                                checkboxWith("バイタルサインのチェック")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.genjo.teikiTekiNaByojoKansatsu",
                                checkboxWith("定期的な病状観察")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.genjo.naifukuyaku",
                                checkboxWith("内服薬")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.genjo.zayaku",
                                checkboxWith("坐薬（緩下剤、解熱剤等）")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.genjo.meMimiHanaNadoNoGaiyoyakuNoShiyoNado",
                                checkboxWith("眼・耳・鼻等の外用薬の使用等")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.genjo.onReiampoShippuChofuNado",
                                checkboxWith("温・冷あん法、湿布貼付等")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.genjo.chusha",
                                checkboxWith("注射")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.genjo.kyuin",
                                checkboxWith("吸引")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.genjo.kyunyu",
                                checkboxWith("吸入")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.genjo.jikoChusha",
                                checkboxWith("自己注射（インスリン療法）")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.genjo.keiKanEiyoHo",
                                checkboxWith("経管栄養法")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.genjo.chushinJomyakuEiyoHo",
                                checkboxWith("中心静脈栄養法")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.genjo.sansoRyoho",
                                checkboxWith("酸素療法")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.genjo.jinkoKokyuRyoho",
                                checkboxWith("人工呼吸療法")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.genjo.kikanCannulaKanri",
                                checkboxWith("気管カニューレ管理")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.genjo.jikoDonyo",
                                checkboxWith("自己導尿")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.genjo.jikoFukumakuKanryu",
                                checkboxWith("自己腹膜灌流")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.genjo.bokoRyuchiCatheterKanri",
                                checkboxWith("膀胱留置カテーテル管理")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.genjo.jinkoKomonJinkobokoKanri",
                                checkboxWith("人工肛門・人工膀胱管理")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.genjo.totsuKanri",
                                checkboxWith("疼痛管理")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.genjo.jokusoKanri",
                                checkboxWith("褥瘡管理")
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        計画
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.vertical}>
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.keikaku.vitalsignsNoCheck",
                                checkboxWith("バイタルサインのチェック")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.keikaku.teikiTekiNaByojoKansatsu",
                                checkboxWith("定期的な病状観察")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.keikaku.naifukuyaku",
                                checkboxWith("内服薬")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.keikaku.zayaku",
                                checkboxWith("坐薬（緩下剤、解熱剤等）")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.keikaku.meMimiHanaNadoNoGaiyoyakuNoShiyoNado",
                                checkboxWith("眼・耳・鼻等の外用薬の使用等")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.keikaku.onReiampoShippuChofuNado",
                                checkboxWith("温・冷あん法、湿布貼付等")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.keikaku.chusha",
                                checkboxWith("注射")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.keikaku.kyuin",
                                checkboxWith("吸引")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.keikaku.kyunyu",
                                checkboxWith("吸入")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.keikaku.jikoChusha",
                                checkboxWith("自己注射（インスリン療法）")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.keikaku.keiKanEiyoHo",
                                checkboxWith("経管栄養法")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.keikaku.chushinJomyakuEiyoHo",
                                checkboxWith("中心静脈栄養法")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.keikaku.sansoRyoho",
                                checkboxWith("酸素療法")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.keikaku.jinkoKokyuRyoho",
                                checkboxWith("人工呼吸療法")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.keikaku.kikanCannulaKanri",
                                checkboxWith("気管カニューレ管理")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.keikaku.jikoDonyo",
                                checkboxWith("自己導尿")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.keikaku.jikoFukumakuKanryu",
                                checkboxWith("自己腹膜灌流")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.keikaku.bokoRyuchiCatheterKanri",
                                checkboxWith("膀胱留置カテーテル管理")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.keikaku.jinkoKomonJinkobokoKanri",
                                checkboxWith("人工肛門・人工膀胱管理")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.keikaku.totsuKanri",
                                checkboxWith("疼痛管理")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.keikaku.jokusoKanri",
                                checkboxWith("褥瘡管理")
                            )}
                        </div>
                    </div>
                </div>;
            })}
        </div>
        <div className={styles.card}>
            <h3 className={styles.title}>特記、生活上配慮すべき課題など</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        特記、生活上配慮すべき課題など
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderTextareaField(
                        assessmentSheetUpdater,
                        "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.iryoKenkoKankei.tokkiSeikatsuJoHairyoSuBekiKadaiNado",
                        { width: "auto", rows: 5 }
                    )}
                </div>;
            })}
        </div>
    </>;
}

type Props = {
    assessmentSheetUpdater: AssessmentSheetUpdater,
    activated: boolean,
};
