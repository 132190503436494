import "whatwg-fetch";
import { Login } from "./Login";
import { Logout } from "./Logout";
import { Verify } from "./Verify";
import { ApiError } from "../ApiError";
import { post, postOptions, verify } from "../functions";

export const AuthenticationApi = new class {
    async login(identifier: string, password: string): Promise<void | ApiError> {
        const body = { identifier, password };
        const response = await postCredential(body);
        if (!response.ok) {
            throw new ApiError(response.statusText.toString(), response.statusText);
        }
    }

    async logout(): Promise<Logout | ApiError> {
        const response = await post(Logout.path, {});
        return (response.ok ? Logout.extract : ApiError.extract)(response);
    }

    async verify(): Promise<Verify | ApiError> {
        const response = await verify();
        if (!response.ok)
            return new ApiError("FAILED_VERIFY", "failed to verify.")
        return Verify.extract(response);
    }
}()

async function postCredential(body: any): Promise<Response> {
    const options = postOptions(body);
    return await fetch(Login.path, options)
}
