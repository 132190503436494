import React, { ReactNode, useCallback, useEffect, useRef } from "react";
import styles from "./Modeless.module.scss";
import classNames from "classnames";

export function Modeless(props: Props) {
    const { open, onRequestClose, className, children } = props;

    const containerRef = useRef<HTMLDivElement>(null);
    const onClick = useCallback((event: MouseEvent) => {
        if (event.target instanceof Node && containerRef.current?.contains(event.target)) return;
        onRequestClose?.();
    }, [containerRef, onRequestClose]);

    useEffect(() => {
        if (!open) return;
        document.addEventListener("click", onClick);
        return () => document.removeEventListener("click", onClick);
    }, [open, onClick]);

    if (!open) return null;

    return <div className={classNames(styles.container, className)} ref={containerRef}>
        {children}
    </div>;
}

type Props = {
    open: boolean,
    onRequestClose?: () => void,
    className?: string,
    children?: ReactNode,
};
