import { GlobalHeader } from "../components/GlobalHeader";
import { GlobalContainer } from "../components/GlobalContainer";
import React, { useState } from "react";
import styles from "./PasswordResetPage.module.scss";
import { PrimaryButton } from "../components/button/PrimaryButton";
import { useHistory } from "react-router-dom";
import { Paths } from "../../../Paths";
import { TextField } from "../components/field/TextField";
import { MyselfApi } from "../../../state/api/myself/MyselfApi";

export function PasswordResetRequestPage() {

    const [email, setEmail] = useState<string>("")
    const [emailDirty, setEmailDirty] = useState<boolean>(false)
    const [done, setDone] = useState<boolean>(false)
    const history = useHistory()

    const isEmailValid = (): boolean => {
        const pattern = new RegExp( /^[A-Za-z0-9][A-Za-z0-9_.-]*@[A-Za-z0-9_.-]+\.[A-Za-z0-9]+$/)
        return pattern.test(email)
    }

    const isEmailAddressInValid = (): boolean => {
        return emailDirty && !isEmailValid()
    }

    const changeEmail = (email: string) => {
        setEmail(email)
        setEmailDirty(true)
    }

    const requestReset = (): void => {
        MyselfApi.requestPasswordReset(email)
        setDone(true)
    }

    const backToLogin = () => {
        history.push(Paths.userSettings)
    }

    return (
        <GlobalContainer>
            <GlobalHeader/>
            <div className={styles.card}>
                <div className={styles.contents}>
                    <h2 className={styles.title}>パスワード再設定</h2>

                    {done ?
                        <div className={styles.description}>
                            <div>再設定用メールを送信しました。メールボックスをご確認ください。</div>
                            <div>数分経っても再設定用メールが届かない場合は、</div>
                            <div>入力ミスがないか確認し、時間を置いて再度お試しください。</div>
                        </div> :
                        <div className={styles.description}>
                            <div>登録済みのメールアドレスを入力してください。</div>
                            <div>再設定用メールをお送りします。</div>
                        </div>
                    }
                    <div className={styles.field}>
                        <div className={styles.label}>
                            メールアドレス
                        </div>
                        <TextField
                            placeholder={"milmo@milmo.co.jp"}
                            width={280}
                            get={email}
                            set={changeEmail}
                            error={isEmailAddressInValid()}
                        />
                        {isEmailAddressInValid() &&
                        <p className={styles.error}>メールアドレスの形式が正しくありません</p>}
                    </div>

                    <div className={styles.button}>
                        <PrimaryButton
                            width={256}
                            onClick={requestReset}
                            disabled={!isEmailValid()}
                        >
                            {done ? "再送信" : "送信"}
                        </PrimaryButton>
                    </div>

                    <div className={styles.button}>
                        <span className={styles.textLink} onClick={backToLogin}>
                            ログイン画面に戻る
                        </span>
                    </div>
                </div>
            </div>
        </GlobalContainer>
    )
}
