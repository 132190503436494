import { useCallback, useEffect, useState } from "react";
import { Corporation } from "../../../../domain/corporation/Corporation";
import { AdminCorporationApi } from "../../../../state/api/admin/corporation/AdminCorporationApi";
import useMounted from "../../common/useMounted";

export default function useCorporations(): { corporations: Corporation[], retrieveCorporations: () => void } {

    const [corporations, setCorporations] = useState<Corporation[]>([])
    const mounted = useMounted();

    const retrieveCorporations = useCallback(() => {
        AdminCorporationApi.corporations()
            .then(res => {
                mounted.current && setCorporations(res)
            })
    }, [mounted]);

    useEffect(() => {
        retrieveCorporations();
    }, [retrieveCorporations]);

    return { corporations, retrieveCorporations };
}
