import { OffsetDateTime } from "../fundamental/OffsetDateTime";
import { ProjectCompletion } from "./ProjectCompletion";
import { ChangeLog } from "../changelog/ChangeLog";

const projectType = Symbol();

export class Project {
    [projectType]: any;

    id: string;

    careRecipientId: string;

    version: number;

    createdAt: OffsetDateTime;

    completion: ProjectCompletion | null;

    latestChangeLog: ChangeLog | null;

    lastEditedAt: OffsetDateTime;

    constructor(
        id: string,
        careRecipientId: string,
        version: number,
        createdAt: OffsetDateTime,
        completion: ProjectCompletion | null,
        latestChangeLog: ChangeLog | null,
        lastEditedAt: OffsetDateTime
    ) {
        this.id = id;
        this.careRecipientId = careRecipientId;
        this.version = version;
        this.createdAt = createdAt;
        this.completion = completion;
        this.latestChangeLog = latestChangeLog;
        this.lastEditedAt = lastEditedAt;
    }

    get isCompleted(): boolean {
        return !!this.completion;
    }

    replaceLatestChangeLog(changeLog: ChangeLog): Project {
        return new Project(
            this.id,
            this.careRecipientId,
            this.version,
            this.createdAt,
            this.completion,
            changeLog,
            this.lastEditedAt
        );
    }
}
