import React, { useEffect, useState } from "react";
import { GlobalContainer } from "../components/GlobalContainer";
import { GlobalHeader } from "../components/GlobalHeader";
import { get } from "../../../state/api/functions";
import styles from "./NotificationPage.module.scss";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { Notification } from "../../../domain/notification/Notification";
import { Pager } from "../components/button/Pager";
import { Loading } from "../components/loading/Loading";
import { Paths } from "../../../Paths";
import useMounted from "../../hooks/common/useMounted";
import usePager from "../../hooks/common/usePager";

export default function NotificationPage() {

    const [loading, setLoading] = useState(false);
    const mounted = useMounted();
    const [pageCount, currentPage, notifications, setNotifications, displayNotifications, onChangePage] = usePager<Notification>()

    useEffect(() => {
        setLoading(true);
        get("/api/notification/getList").then(async (res) => {
            if (!mounted) return;
            const json = await res.json();
            const allNotifications = json.notifications;
            setNotifications(allNotifications.filter((item: Notification) => {
                return dayjs(item.publishedDate).isBefore(dayjs())
            }));
        }).finally(() => {setLoading(false)});
    }, [mounted, setNotifications]);

    const render = () => {
        if (loading) return <Loading/>

        if (!notifications || notifications.length === 0) {
            return <div className={styles.nothingNotificationMessage}>
                お知らせはありません
            </div>
        }
        return <>
            <div className={styles.listContainer}>
                <ul>
                    { displayNotifications.map((notification) => {
                        return <li key={notification.id} className={styles.row}>
                            <Link to={Paths.notification.build({ notificationId: notification.id })}>
                                <div className={styles.publishedDate}>{dayjs(notification.publishedDate).format("YYYY年MM月DD日")}</div>
                                <div className={styles.title}>{notification.title}</div>
                            </Link>
                        </li>
                    })}
                </ul>
            </div>
            <div className={styles.pagerButtonArea}>
                <Pager pageCount={pageCount} onChangePage={onChangePage} currentPage={currentPage}/>
            </div>
        </>
    }

    return <GlobalContainer>
        <GlobalHeader/>
        <div className={styles.container}>
            <div>
                <span className={styles.link}><Link to={"/"}>トップページ</Link></span>
                <span className={styles.delimiter}>&gt;</span>
                <span className={styles.normalLink}>お知らせ一覧</span>
            </div>
            <h1 className={styles.pageTitle}>お知らせ一覧</h1>
            { render() }
        </div>
    </GlobalContainer>
}
