import React from "react";
import styles from "./TableThreeWeeklyService.module.scss";
import { TableThreeWeeklyServiceSchedule } from "./TableThreeWeeklyServiceSchedule";
import { TableThreeMainActivities } from "./TableThreeMainActivities";

export function TableThreeWeeklyService(props: Props) {
    const { projectId, activated, refleshTableThree } = props;

    return (
        <div className={styles.container}>
            <div className={styles.division}>
                <div className={styles.timeDivision}>
                    <span className={styles.midnight}>深夜</span>
                    <span className={styles.earlyMorning}>早朝</span>
                    <span className={styles.morning}>午前</span>
                    <span className={styles.afternoon}>午後</span>
                    <span className={styles.night}>夜間</span>
                    <span className={styles.midnight2}>深夜</span>
                </div>
                <div className={styles.time}>
                    <span className={styles.timeContents}>0:00</span>
                    <span className={styles.timeContents}>1:00</span>
                    <span className={styles.timeContents}>2:00</span>
                    <span className={styles.timeContents}>3:00</span>
                    <span className={styles.timeContents}>4:00</span>
                    <span className={styles.timeContents}>5:00</span>
                    <span className={styles.timeContents}>6:00</span>
                    <span className={styles.timeContents}>7:00</span>
                    <span className={styles.timeContents}>8:00</span>
                    <span className={styles.timeContents}>9:00</span>
                    <span className={styles.timeContents}>10:00</span>
                    <span className={styles.timeContents}>11:00</span>
                    <span className={styles.timeContents}>12:00</span>
                    <span className={styles.timeContents}>13:00</span>
                    <span className={styles.timeContents}>14:00</span>
                    <span className={styles.timeContents}>15:00</span>
                    <span className={styles.timeContents}>16:00</span>
                    <span className={styles.timeContents}>17:00</span>
                    <span className={styles.timeContents}>18:00</span>
                    <span className={styles.timeContents}>19:00</span>
                    <span className={styles.timeContents}>20:00</span>
                    <span className={styles.timeContents}>21:00</span>
                    <span className={styles.timeContents}>22:00</span>
                    <span className={styles.timeContents}>23:00</span>
                    <span className={styles.timeContentsLast}>24:00</span>
                </div>
            </div>
            <div className={styles.schedule}>{<TableThreeWeeklyServiceSchedule projectId={projectId} activated={activated} refleshTableThree={refleshTableThree} />}</div>
            <div className={styles.activity}>{<TableThreeMainActivities projectId={projectId} activated={activated} />}</div>
        </div>
    );
}

type Props = {
    projectId: string;
    activated: boolean;
    refleshTableThree: () => void;
};
