import React from "react";
import { CheckListItem } from "../CheckListItem";
import { EnhancedPasswordInput } from "./EnhancedPasswordInput";
import { FieldContainer } from "./FieldContainer";

type Props = {
    label: string;
    value: string;
    setValue: (value: string) => void;
    containsNumber: boolean;
    containsUppercase: boolean;
    containsLowercase: boolean;
    isEightOrMoreCharacters: boolean;
};

export function NewPasswordField(props: Props) {
    const { label, value, setValue, containsNumber, containsUppercase, containsLowercase, isEightOrMoreCharacters } = props;

    return (
        <>
            <FieldContainer label={label}>
                <EnhancedPasswordInput value={value} setValue={setValue} />
            </FieldContainer>
            <CheckListItem checked={containsNumber}>数字を含む</CheckListItem>
            <CheckListItem checked={containsUppercase}>大文字を含む</CheckListItem>
            <CheckListItem checked={containsLowercase}>小文字を含む</CheckListItem>
            <CheckListItem checked={isEightOrMoreCharacters}>8文字以上</CheckListItem>
        </>
    )
}
