import React  from "react";
import { Corporation } from "../../../../domain/corporation/Corporation";
import styles from "./CorporationListItem.module.scss";
import { PrimaryButton } from "../../components/button/PrimaryButton";
import { DangerButton } from "../../components/button/DangerButton";
import { Modal } from "../../components/Modal";
import DeleteCorporationConfirmation from "./DeleteCorporationConfirmation";
import CorporationForm from "./CorporationForm";
import useModalOpener from "../../../hooks/common/useModalOpener";

export default function CorporationListItem(props: Props) {

    const { corporation, refreshCorporations } = props;

    const [isOpenEditDialog, openEditDialog, closeEditDialog] = useModalOpener();
    const [isOpenDeleteConfirmation, openDeleteConfirmation, closeDeleteConfirmation] = useModalOpener();

    return <li key={corporation.name} className={styles.row}>
        <div className={styles.corporationName}>
            {corporation.name}
        </div>
        <div className={styles.buttonArea}>
            <div className={styles.editButtonArea}>
                <PrimaryButton onClick={openEditDialog}>編集</PrimaryButton>
                <Modal open={isOpenEditDialog} onRequestClose={closeEditDialog}>
                    <CorporationForm corporation={corporation} type={"update"} onRequestClose={closeEditDialog} refreshCorporations={refreshCorporations}/>
                </Modal>
            </div>
            <div>
                <DangerButton onClick={openDeleteConfirmation}>削除</DangerButton>
                <Modal open={isOpenDeleteConfirmation} onRequestClose={closeDeleteConfirmation}>
                    <DeleteCorporationConfirmation corporation={corporation} onRequestClose={closeDeleteConfirmation} refreshCorporations={refreshCorporations}/>
                </Modal>
            </div>
        </div>
    </li>
}

type Props = {
    corporation: Corporation,
    refreshCorporations: () => void
}
