import React, { ReactNode, MouseEvent } from "react";
import styles from "./DialogBase.module.scss";
import { CloseIcon } from "../../components/icon/CloseIcon";

export default function DialogBase(props: Props) {

    const { children, onRequestClose } = props;

    const onClickContainer = (event: MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
    }

    const onClickClose = () => {
        onRequestClose();
    }

    return <div className={styles.container} onClick={onClickContainer}>
        { children }
        <button className={styles.closeButton} onClick={onClickClose}>
            <CloseIcon/>
        </button>
    </div>
}

type Props = {
    children: ReactNode,
    onRequestClose: () => void
}