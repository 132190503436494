import { LoginHistoriesResponse } from "./LoginHistoriesResponse";
import { ApiError } from "../../ApiError";
import { get } from "../../functions";

export const AdminHistoryApi = new class {

    async histories(startDate: string, endDate: string): Promise<LoginHistoriesResponse> {
        const response = await get(`/api/admin/history/getList?startDate=${startDate}&endDate=${endDate}`);
        if (response.ok) return LoginHistoriesResponse.extract(response)
        throw new ApiError(response.status.toString(), response.statusText);
    }
}();
