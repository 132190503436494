import { ReactNode, useState } from "react";
import { useDispatch } from "react-redux";
import { updateItem } from "../../../../state/usecase/tablecommon/action";
import { TableCommon } from "../../../../domain/careplan/tablecommon/TableCommon";
import { TableCommonPath } from "../../../../domain/careplan/tablecommon/TableCommonPath";
import { TableCommonUpdaterOf, FieldProps } from "./TableCommonUpdaterOf";
import { GroupedTableCommonUpdater } from "./GroupedTableCommonUpdater";

export class TableCommonUpdater implements TableCommonUpdaterOf {
    private readonly projectId: string;

    private readonly tableCommon: TableCommon | null;

    private readonly activated: boolean;

    constructor(projectId: string, tableCommon: TableCommon | null, activated: boolean) {
        this.projectId = projectId;
        this.tableCommon = tableCommon;
        this.activated = activated;
    }

    of<T>(path: string, build: (props: FieldProps<T>) => ReactNode): ReactNode {
        const pathObject = new TableCommonPath(path);

        const [readonly, setReadonly] = useState(false);
        const dispatch = useDispatch();

        const value = this.tableCommon?.getValue<T>(pathObject);
        const update = (value: T) => {
            const originalValue = this.tableCommon?.getValue<T>(pathObject);
            if (originalValue === undefined) return;
            if (value === originalValue) return;
            setReadonly(true);
            const onSuccess = () => {
                setReadonly(false);
            };
            const onFailure = () => {
                setReadonly(false);
            };
            dispatch(updateItem(this.projectId, pathObject, value, originalValue, onSuccess, onFailure));
        };
        const disabled = (value === undefined || !this.activated);

        return build({ path, value, update, disabled, readonly });
    }

    group(build: (groupedTableCommonUpdater: GroupedTableCommonUpdater) => ReactNode): ReactNode {
        const groupedTableCommonUpdater = new GroupedTableCommonUpdater(this.projectId, this.tableCommon, this.activated);
        return build(groupedTableCommonUpdater);
    }
}
