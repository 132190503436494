import React, { MouseEvent } from "react";
import styles from "./EstablishmentDetailIndex.module.scss";
import { PrimaryButton } from "../../../components/button/PrimaryButton";
import { HashLink } from "react-router-hash-link";
import { EstablishmentDetailSection, establishmentDetailSectionId } from "./EstablishmentDetailSection";
import classNames from "classnames";

export function EstablishmentDetailIndex(props: Props) {
    const { activeSection, selectEstablishment } = props;

    const renderLink = (section: EstablishmentDetailSection, label: string, sectionsForJudge: EstablishmentDetailSection[] = [section]) => {
        const to = `#${establishmentDetailSectionId(section)}`;
        const active = activeSection ? sectionsForJudge.includes(activeSection) : false;
        const className = classNames(styles.link, { [styles.active]: active });
        return (
            <HashLink to={to} smooth className={className}>
                {label}
            </HashLink>
        );
    };

    return (
        <div className={styles.container}>
            <ul className={styles.indexContainer}>
                {renderLink(EstablishmentDetailSection.RINEN_TOKUCHO, "理念・特徴")}
                {renderLink(EstablishmentDetailSection.EIGYO_JIKAN, "営業時間")}
                {renderLink(EstablishmentDetailSection.SHOKUJI, "食事")}
                {renderLink(EstablishmentDetailSection.NYUUYOKU, "入浴")}
                {renderLink(EstablishmentDetailSection.STAFF, "スタッフ")}
                {renderLink(EstablishmentDetailSection.RYOUKIN, "料金")}
                {renderLink(EstablishmentDetailSection.RECREATION, "レクリエーション")}
                {renderLink(EstablishmentDetailSection.REHABILITATION, "リハビリ・機能訓練")}
            </ul>
            <div className={styles.buttonArea}>
                <PrimaryButton width={256} onClick={selectEstablishment}>
                    選択する
                </PrimaryButton>
            </div>
        </div>
    );
}

type Props = {
    activeSection: EstablishmentDetailSection | null;
    selectEstablishment: (event: MouseEvent) => void;
};
