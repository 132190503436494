import React from "react";
import { ServiceType } from "../../../components/ServiceType";
import { HolidayStatus } from "../../../components/HolidayStatus";
import styles from "./Header.module.scss";
import { Favorite } from "../../../components/Favorite";
import { Establishment } from "../../../../../../../../domain/establishment/Establishment";
import { EstablishmentFavorite } from "../../../../../../../../domain/establishment/EstablishmentFavorite";

export function Header(props: Props) {

    const {
        serviceTypeCode,
        saturday,
        sunday,
        publicHoliday,
        establishment,
        projectId,
        establishmentFavoriteList,
        setEstablishmentFavoriteList,
    } = props;

    return <div className={styles.container}>
        <ServiceType serviceTypeCode={serviceTypeCode} />
        <div className={styles.group}>
            <HolidayStatus saturday={saturday} sunday={sunday} publicHoliday={publicHoliday} />
            <Favorite
                establishment={establishment}
                projectId={projectId}
                establishmentFavoriteList={establishmentFavoriteList}
                setEstablishmentFavoriteList={setEstablishmentFavoriteList}
            />
        </div>
    </div>
}

type Props = {
    serviceTypeCode: string,
    saturday: boolean,
    sunday: boolean,
    publicHoliday: boolean
    establishment: Establishment,
    projectId: string,
    establishmentFavoriteList: EstablishmentFavorite[]
    setEstablishmentFavoriteList: (value: EstablishmentFavorite[]) => void
}
