import { UsersResponse } from "./UsersResponse";
import { ApiError } from "../../ApiError";
import { del, get, post } from "../../functions";
import { UserDetail } from "../../../../domain/user/UserDetail";
import { UserWithManageUsageDetail } from "../../../../domain/user/UserWithManageUsageDetail";

export const AdminUserApi = new class {

    async users(): Promise<UserDetail[]> {
        const response = await get("api/admin/users");
        return response.json();
    }

    async usersWithManageUsage(): Promise<UserWithManageUsageDetail[]> {
        const response = await get("api/admin/usersWithAiUsage");
        return response.json();
    }

    async getList(): Promise<UsersResponse | ApiError> {
        const response = await get("api/admin/user/getList");
        return (response.ok ? UsersResponse.extract : ApiError.extract)(response);
    }

    async register(
        displayName: string,
        email: string,
        officeId: string,
        isAdmin: boolean,
        password: string,
        loginName: string
    ): Promise<void | ApiError> {
        const body = { displayName, email, officeId, isAdmin, password, loginName };
        const response = await post("api/admin/user/register", body);
        if (!response.ok) {
            throw new ApiError(response.status.toString(), response.statusText);
        }
    }

    async delete(
        userId: string
    ): Promise<void | ApiError> {
        const response = await del(`api/admin/user/delete/${userId}`);
        if (!response.ok) {
            throw new ApiError(response.status.toString(), response.statusText);
        }
    }

    async update(
        userId: string,
        displayName: string,
        officeId: string,
        isAdmin: boolean,
        loginName: string
    ): Promise<void | ApiError> {
        const body  = { userId, officeId, displayName, isAdmin, loginName };
        const response = await post("api/admin/user/update", body);
        if (!response.ok) {
            return ApiError.extract(response);
        }
    }
}();
