import React from "react";
import { PrimaryButton } from "../../../../components/button/PrimaryButton";
import styles from "./ErrorPage.module.scss";

export function ErrorPage(props: Props) {

    const { onClickClose } = props;

    return <div className={styles.container}>
        <h1 className={styles.mainMessage}>申し訳ありません。現在、事業所提案機能を使えません。</h1>
        <div className={styles.subMessageArea}>
            <div>
                <p>ミルモネットが停止中のため、事業所提案を使えません。</p>
                <p>時間を置いて再度お試しください。</p>
            </div>
        </div>
        <div className={styles.buttonArea}>
            <PrimaryButton onClick={onClickClose} width={256}>戻る</PrimaryButton>
        </div>
    </div>
}

type Props = {
    onClickClose: () => void
}