import React, { ButtonHTMLAttributes } from "react";
import styles from "./SelectedButton.module.scss";
import { LargeCheckIcon } from "../../../../components/icon/LargeCheckIcon";

export function SelectedButton(props: ButtonHTMLAttributes<any>) {

    const { ...rest } = props

    return <button className={styles.button} {...rest}>
        <span className={styles.focus}>
            <span className={styles.leading}><LargeCheckIcon/></span>
            <span className={styles.text}>選択済み</span>
        </span>
    </button>
}
