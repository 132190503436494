import React, { useState, MouseEvent } from "react";
import { TextField } from "../../components/field/TextField";
import { PrimaryButton } from "../../components/button/PrimaryButton";
import styles from "./CorporationForm.module.scss";
import { Corporation } from "../../../../domain/corporation/Corporation";
import { AdminCorporationApi } from "../../../../state/api/admin/corporation/AdminCorporationApi";
import { CloseIcon } from "../../components/icon/CloseIcon";

export default function CorporationForm(props: Props) {

    const { corporation, type, onRequestClose, refreshCorporations } = props;
    const [corporationName, setCorporationName] = useState(corporation?.name ?? "");
    const [hasError, setHasError] = useState(false);

    const onClick = (e: MouseEvent<any>) => {
        e.stopPropagation();

        if (type === "register") {
            AdminCorporationApi.register(corporationName)
                .then(() => {
                    setHasError(false);
                    refreshCorporations();
                    onRequestClose();
                })
                .catch(() => {
                    setHasError(true);
                });
        } else {
            AdminCorporationApi.update(corporation!!.id, corporationName)
                .then(() => {
                    setHasError(false);
                    refreshCorporations();
                    onRequestClose();
                })
                .catch(() => {
                    setHasError(true);
                });
        }
    };

    const onClickContainer = (e: MouseEvent<any>) => {
        e.stopPropagation();
    }

    const renderType = () => {
        if (type === "register") {
            return "登録";
        }
        return "更新"
    }

    const disabled = corporationName.length < 1;

    return <div className={ styles.container } onClick={ e => onClickContainer(e) }>
        <div className={ styles.title }>
            法人を{ renderType() }します
        </div>
        { hasError && <div className={ styles.errorMessage }>
            法人{ renderType() }が失敗しました。
        </div> }
        <div className={ styles.corporationName }>法人名</div>
        <TextField get={ corporationName } set={ setCorporationName } maxLength={ 20 }/>
        <div className={ styles.buttonArea }>
            <PrimaryButton onClick={ e => onClick(e) } width={ 256 } disabled={ disabled }>{ renderType() }</PrimaryButton>
        </div>
        <button onClick={ onRequestClose } className={ styles.closeIcon }>
            <CloseIcon/>
        </button>
    </div>
}

type Props = {
    corporation?: Corporation,
    type: "register" | "update",
    onRequestClose: () => void,
    refreshCorporations: () => void
}
