import React, { FormEvent, useEffect, MouseEvent } from "react";
import styles from "./DiseaseKnowledgeListDialog.module.scss";
import { TextField } from "../../components/field/TextField";
import { SearchIcon } from "../../components/icon/SearchIcon";
import { PrimaryButton } from "../../components/button/PrimaryButton";
import { DiseaseKnowledgeSearchResult } from "./DiseaseKnowledgeSearchResult";
import { DiseaseKnowledgeList } from "./DiseaseKnowledgeList";
import { DiseaseKnowledgeDialogMode } from "../../components/MedicalKnowledgeButton";

export function DiseaseKnowledgeListDialog(props: Props) {

    const { keyword, setNewKeyword, setDialogMode } = props

    const onSubmit = (event: FormEvent) => event.preventDefault();

    const onClickSearch = () => {
    };

    const onClickClearKeyword = () => {
        setNewKeyword("");
    };

    const onClickContainer = (event: MouseEvent<any>) => event.stopPropagation();

    const searchButton = () => {
        return <button type={"submit"} className={styles.searchButton} tabIndex={-1} onClick={onClickSearch} >
            <SearchIcon/>
        </button>;
    };

    const content = () => {
        if (keyword === undefined || keyword === "") {
            return <DiseaseKnowledgeList setDialogMode={setDialogMode}/>;
        } else {
            return <DiseaseKnowledgeSearchResult
                onClickClear={onClickClearKeyword}
                keyword={keyword}
                setDialogMode={setDialogMode}/>;
        }
    };

    useEffect(() => {
        setNewKeyword(keyword ?? "")
    }, [keyword, setNewKeyword])

    return <div className={styles.container} onClick={onClickContainer}>
        <h2 className={styles.title}>医療知識一覧</h2>
        <form className={styles.form} onSubmit={onSubmit}>
            <TextField get={keyword}
                set={setNewKeyword}
                placeholder={"疾患名検索"}
                width={280}
                trailing={searchButton()}/>
            <PrimaryButton style={{ marginLeft: "16px" }} onClick={onClickSearch}>検索</PrimaryButton>
        </form>
        {content()}
    </div>;
}

type Props = {
    setDialogMode: (dialogMode: DiseaseKnowledgeDialogMode) => void,
    setNewKeyword: (newKeyword: string) => void,
    keyword?: string,
};
