import "whatwg-fetch";
import { CarePlanTableTwoPeriod } from "../../../../../domain/careplan/tabletwo/CarePlanTableTwoPeriod";
import { ShiftAction } from "../../../../../domain/careplan/tabletwo/ShiftAction";
import { ApiError } from "../../../ApiError";
import { post } from "../../../functions";
import { EditedShortTermGoal } from "./EditedShortTermGoal";
import { toPeriodObject } from "../PeriodObject";
import { EditedCaraPlanTableTwo } from "../EditedCaraPlanTableTwo";

export const ShortTermGoalApi = new class {
    async update(
        id: string,
        text: string | null,
        period: CarePlanTableTwoPeriod | null,
    ): Promise<EditedShortTermGoal | ApiError> {
        const body = {
            id,
            text,
            period: period && toPeriodObject(period),
        };
        const response = await post("/api/tableTwo/shortTermGoal/update", body);
        return (response.ok ? EditedShortTermGoal.extract : ApiError.extract)(response);
    }

    async addSupportService(
        shortTermGoalId: string,
        supportServiceId: string,
    ): Promise<EditedShortTermGoal | ApiError> {
        const body = {
            shortTermGoalId,
            supportServiceId,
        };
        const response = await post("/api/tableTwo/shortTermGoal/addSubItem", body);
        return (response.ok ? EditedShortTermGoal.extract : ApiError.extract)(response);
    }

    async shiftSupportService(
        shortTermGoalId: string,
        supportServiceId: string,
        action: ShiftAction,
    ): Promise<EditedShortTermGoal | ApiError> {
        const body = {
            shortTermGoalId,
            supportServiceId,
            action,
        };
        const response = await post("/api/tableTwo/shortTermGoal/shiftSubItem", body);
        return (response.ok ? EditedShortTermGoal.extract : ApiError.extract)(response);
    }

    async removeSupportService(
        shortTermGoalId: string,
        supportServiceId: string,
    ): Promise<EditedShortTermGoal | ApiError> {
        const body = {
            shortTermGoalId,
            supportServiceId,
        };
        const response = await post("/api/tableTwo/shortTermGoal/removeSubItem", body);
        return (response.ok ? EditedShortTermGoal.extract : ApiError.extract)(response);
    }

    async copyPeriod(
        shortTermGoalId: string
    ): Promise<EditedCaraPlanTableTwo | ApiError> {
        const body = {
            shortTermGoalId
        }
        const response = await post("/api/tableTwo/shortTermGoal/copyPeriod", body);
        return (response.ok ? EditedCaraPlanTableTwo.extract : ApiError.extract)(response);
    }
}();
