import { ChangeLog } from "../../../../domain/changelog/ChangeLog";
import { changeLog } from "../../changelog/LatestChangeLog";

export class UpdateItem {
    type: "SUCCESS" = "SUCCESS";

    changeLog: ChangeLog;

    constructor(changeLog: ChangeLog) {
        this.changeLog = changeLog;
    }

    static path = "/api/tableOne/updateItem";

    static async extract(response: Response): Promise<UpdateItem> {
        const json = await response.json();
        return new UpdateItem(changeLog(json.changeLog));
    }
}
