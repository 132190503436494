import { longTermGoal, LongTermGoalObject } from "../longtermgoal/LongTermGoalObject";
import { Need } from "../../../../../domain/careplan/tabletwo/need/Need";
import { SubItems } from "../../../../../domain/careplan/tabletwo/SubItems";

export type NeedObject = {
    id: string,
    details: {
        text: string | null,
    },
    longTermGoals: LongTermGoalObject[]
};

export function need(object: NeedObject): Need {
    return new Need(
        object.id,
        object.details.text,
        new SubItems(object.longTermGoals.map(longTermGoal)),
    );
}
