import React, { useEffect, useRef, useState } from "react";
import { NonWeeklyService } from "../../../../domain/careplan/tablethree/nonweeklyservice/NonWeeklyService";
import { TableThreeNonWeeklyServiceInput } from "./TableThreeNonWeeklyServiceInput";
import { PlusIcon } from "../../components/icon/PlusIcon";
import { MinusIcon } from "../../components/icon/MinusIcon";
import { NonWeeklyServiceApi } from "../../../../state/api/careplan/tablethree/nonweeklyservice/NonWeeklyServiceApi";
import { Modal } from "../../components/Modal";
import { TableThreeEstablishmentPage } from "./TableThreeEstablishmentPage";
import styles from "./TableThreeNonWeeklyServiceForm.module.scss";
import classNames from "classnames";
import { TableThree } from "../../../../domain/careplan/tablethree/TableThree";
import { PrimaryButton } from "../../components/button/PrimaryButton";

export function TableThreeNonWeeklyServiceForm(props: Props) {
    const { projectId, nonWeeklyServices, nonWeeklyService, tableTwoEstablishmentList, tableThree, refresh, index, activated } = props;

    const [openEstablishmentPage, setOpenEstablishmentPage] = useState(false);

    const [startDate, setStartDate] = useState(nonWeeklyService.startDate ?? "");
    const [endDate, setEndDate] = useState(nonWeeklyService.endDate ?? "");
    const [establishmentName, setEstablishmentName] = useState(nonWeeklyService.establishmentName ?? "");
    const [kaigoServiceName, setKaigoServiceName] = useState(nonWeeklyService.kaigoServiceName ?? "");
    const [numberOfCredits, setNumberOfCredits] = useState(nonWeeklyService.numberOfCredits ?? "");
    const [productCode, setProductCode] = useState(nonWeeklyService.productCode ?? "");

    const isFirstRender = useRef(true);

    useEffect(() => {
        isFirstRender.current = false;
    }, []);

    useEffect(() => {
        if (isFirstRender.current) return;
        setStartDate(nonWeeklyService.startDate ?? "");
        setEndDate(nonWeeklyService.endDate ?? "");
        setEstablishmentName(nonWeeklyService.establishmentName ?? "");
        setKaigoServiceName(nonWeeklyService.kaigoServiceName ?? "");
        setNumberOfCredits(nonWeeklyService.numberOfCredits ?? "");
        setProductCode(nonWeeklyService.productCode ?? "");
        // eslint-disable-next-line
    }, [nonWeeklyServices]);

    const onRequestEstablishmentPageOpen = () => {
        setOpenEstablishmentPage(true);
    };

    const onRequestEstablishmentPageClose = () => {
        setOpenEstablishmentPage(false);
    };

    const updateNonWeeklyService = (newNonWeeklyService: NonWeeklyService) => {
        if (isFirstRender.current) return;
        NonWeeklyServiceApi.update(
            newNonWeeklyService.id,
            newNonWeeklyService.carePlanTableThreeId,
            newNonWeeklyService.ordinal,
            newNonWeeklyService.startDate,
            newNonWeeklyService.endDate,
            newNonWeeklyService.establishmentId,
            newNonWeeklyService.establishmentName,
            newNonWeeklyService.kaigoServiceName,
            newNonWeeklyService.numberOfCredits,
            newNonWeeklyService.productCode
        ).then(() => {
            refresh();
        });
    };

    const updateStartDate = (text: string | null) => {
        setStartDate(text ?? "");
        const newNonWeeklyService = {
            ...nonWeeklyService,
            startDate: text,
        };
        updateNonWeeklyService(newNonWeeklyService);
    };
    const updateEndDate = (text: string | null) => {
        setEndDate(text ?? "");
        const newNonWeeklyService = {
            ...nonWeeklyService,
            endDate: text,
        };
        updateNonWeeklyService(newNonWeeklyService);
    };

    const updateEstablishmentName = (name: string | null) => {
        setEstablishmentName(name ?? "");
        const newNonWeeklyService = {
            ...nonWeeklyService,
            establishmentName: name,
        };
        updateNonWeeklyService(newNonWeeklyService);
    };

    const updateEstablishment = (id: string, name: string) => {
        setEstablishmentName(name);
        const newNonWeeklyService = {
            ...nonWeeklyService,
            establishmentId: id,
            establishmentName: name,
        };
        updateNonWeeklyService(newNonWeeklyService);
    };

    const updateKaigoServiceName = (name: string | null) => {
        setKaigoServiceName(name ?? "");
        const newNonWeeklyService = {
            ...nonWeeklyService,
            kaigoServiceName: name,
        };
        updateNonWeeklyService(newNonWeeklyService);
    };

    const updateNumberOfCredits = (text: string | null) => {
        setNumberOfCredits(text ?? "");
        const newNonWeeklyService = {
            ...nonWeeklyService,
            numberOfCredits: text,
        };
        updateNonWeeklyService(newNonWeeklyService);
    };

    const updateProductCode = (text: string | null) => {
        setProductCode(text ?? "");
        const newNonWeeklyService = {
            ...nonWeeklyService,
            productCode: text,
        };
        updateNonWeeklyService(newNonWeeklyService);
    };

    const createService = () => {
        if (!tableThree) return;
        NonWeeklyServiceApi.register(tableThree.id, nonWeeklyServices[nonWeeklyServices.length - 1].ordinal + 1).then(() => {
            refresh();
        });
    };

    const deleteService = () => {
        NonWeeklyServiceApi.delete(nonWeeklyService.id).then(() => {
            refresh();
        });
    };

    return (
        <div className={styles.form} style={nonWeeklyServices.length - 1 === index ? undefined : { borderBottom: "2px solid #e6e7f2" }}>
            <div className={styles.period}>
                <span className={styles.periodContent}>
                    <TableThreeNonWeeklyServiceInput type={"date"} initialText={startDate} onUpdate={updateStartDate} activated={activated} width={"100%"} />
                    〜
                    <TableThreeNonWeeklyServiceInput type={"date"} initialText={endDate} onUpdate={updateEndDate} activated={activated} width={"100%"} />
                </span>
            </div>
            <div className={styles.establishName}>
                <TableThreeNonWeeklyServiceInput initialText={establishmentName} onUpdate={updateEstablishmentName} activated={activated} width={"70%"} popup={true} />
                <span className={styles.search}>
                    <PrimaryButton onClick={() => onRequestEstablishmentPageOpen()}>検索</PrimaryButton>
                </span>
            </div>
            <div className={styles.serviceName}>
                <TableThreeNonWeeklyServiceInput initialText={kaigoServiceName} onUpdate={updateKaigoServiceName} activated={activated} width={"80%"} />
            </div>
            <div className={styles.unitCount}>
                <TableThreeNonWeeklyServiceInput initialText={numberOfCredits} onUpdate={updateNumberOfCredits} activated={activated} width={"80%"} />
            </div>
            <div className={styles.productCode}>
                <TableThreeNonWeeklyServiceInput initialText={productCode} onUpdate={updateProductCode} activated={activated} width={"80%"} />
            </div>
            {activated && (
                <>
                    {nonWeeklyServices.length === 1 ? (
                        <button className={classNames(styles.button, styles.buttonAdd)} tabIndex={-1} onClick={createService}>
                            <PlusIcon />
                            <span className={styles.tooltip}>行追加</span>
                        </button>
                    ) : nonWeeklyServices.length - 1 === index ? (
                        <>
                            <button className={classNames(styles.topButton, styles.buttonAdd)} tabIndex={-1} onClick={createService}>
                                <PlusIcon />
                                <span className={styles.tooltip}>行追加</span>
                            </button>
                            <button className={classNames(styles.bottomButton, styles.buttonRemove)} onClick={deleteService}>
                                <MinusIcon />
                                <span className={styles.tooltip}>行削除</span>
                            </button>
                        </>
                    ) : (
                        <button className={classNames(styles.button, styles.buttonRemove)} onClick={deleteService}>
                            <MinusIcon />
                            <span className={styles.tooltip}>行削除</span>
                        </button>
                    )}
                </>
            )}
            <Modal open={openEstablishmentPage} onRequestClose={onRequestEstablishmentPageClose}>
                <TableThreeEstablishmentPage supportServiceText={kaigoServiceName} projectId={projectId} onRequestClose={onRequestEstablishmentPageClose} tableTwoEstablishmentList={tableTwoEstablishmentList} updateEstablishment={updateEstablishment} />
            </Modal>
        </div>
    );
}

type Props = {
    projectId: string;
    nonWeeklyServices: NonWeeklyService[];
    nonWeeklyService: NonWeeklyService;
    tableTwoEstablishmentList: string[];
    tableThree: TableThree;
    refresh: () => void;
    index: number;
    activated: boolean;
};
