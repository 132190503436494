import { Reducer } from "redux";
import { AuthenticationActions, MyselfActionType } from "./type";
import { AuthenticationStatus } from "../../../domain/authentication/AuthenticationStatus";
import { User } from "../../../domain/user/User";

export type AuthenticationState = Readonly<{
    status: AuthenticationStatus | null,
    user: User | null,
    updateFailed: boolean,
    loggingIn: boolean,
    loginFailed: boolean,
    rsLoginIn: boolean,
}>;

const initialState: AuthenticationState = {
    status: null,
    user: null,
    updateFailed: false,
    loggingIn: false,
    loginFailed: false,
    rsLoginIn: false
};

export const authenticationReducer: Reducer<AuthenticationState, AuthenticationActions> = (state= initialState, action): AuthenticationState => {
    switch (action.type) {
        case "AUTHENTICATION_LOGIN":
            return {
                ...state,
                loggingIn: true,
                loginFailed: false,
            };
        case "AUTHENTICATION_LOGIN_SUCCESS":
            return {
                ...state,
                status: AuthenticationStatus.AUTHENTICATED,
                user: action.payload.user,
                loggingIn: false,
            };
        case "AUTHENTICATION_LOGIN_FAILURE":
            return {
                ...state,
                status: AuthenticationStatus.UNAUTHENTICATED,
                loggingIn: false,
                loginFailed: true,
            };
        case "AUTHENTICATION_LOGOUT":
            return state;
        case "AUTHENTICATION_LOGOUT_FAILURE":
            return state;
        case "AUTHENTICATION_VERIFY":
            return {
                ...state,
                status: AuthenticationStatus.PENDING,
            };
        case "AUTHENTICATION_VERIFY_SUCCESS":
            return {
                ...state,
                status: AuthenticationStatus.AUTHENTICATED,
                user: action.payload.user,
                rsLoginIn: action.payload.rsLoginIn,
            };
        case "AUTHENTICATION_VERIFY_FAILURE":
            return {
                ...state,
                status: AuthenticationStatus.UNAUTHENTICATED,
            };
        case MyselfActionType.MYSELF_UPDATE_SUCCESS:
            return {
                ...state,
                user: action.payload?.myself ?? state.user,
                updateFailed: false,
            };
        case MyselfActionType.MYSELF_UPDATE_FAILURE:
            return {
                ...state,
                updateFailed: true,
            };
        case MyselfActionType.MYSELF_CLEAR_FAILURE:
            return {
                ...state,
                updateFailed: false,
            };
        default:
            return state;
    }
};
