// THIS CODE IS GENERATED CODE. DO NOT EDIT.

import React from "react";
import styles from "./Section.module.scss";
import { AssessmentSheetUpdater } from "./AssessmentSheetUpdater";
import { AssessmentSheetField } from "./AssessmentSheetField";
import {
    NaiTokidokiAru,
} from "./types";
import {
    naiTokidokiAruLabels,
} from "./labels";

export function SeishinKodoShogaiSection(props: Props) {

    const { assessmentSheetUpdater, activated } = props;
    const assessmentSheetField = new AssessmentSheetField(activated);

    return <>
        <div className={styles.card}>
            <h3 className={styles.title}>要介護認定項目</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        4-1 被害妄想（物を盗られたなど）
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<NaiTokidokiAru | null>(
                        assessmentSheetUpdater,
                        "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seishinKodoShogai.higaimoso",
                        naiTokidokiAruLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        4-2 作話をする
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<NaiTokidokiAru | null>(
                        assessmentSheetUpdater,
                        "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seishinKodoShogai.sakuwaOSuru",
                        naiTokidokiAruLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        4-3 感情が不安定になる
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<NaiTokidokiAru | null>(
                        assessmentSheetUpdater,
                        "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seishinKodoShogai.kanjoGaFuanteiNiNaru",
                        naiTokidokiAruLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        4-4 昼夜の逆転
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<NaiTokidokiAru | null>(
                        assessmentSheetUpdater,
                        "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seishinKodoShogai.chuyaNoGyakuten",
                        naiTokidokiAruLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        4-5 しつこく同じ話をする
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<NaiTokidokiAru | null>(
                        assessmentSheetUpdater,
                        "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seishinKodoShogai.shitsukokuOnajiHanashiOSuru",
                        naiTokidokiAruLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        4-6 大声を出す
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<NaiTokidokiAru | null>(
                        assessmentSheetUpdater,
                        "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seishinKodoShogai.ogoeODasu",
                        naiTokidokiAruLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        4-7 介護に抵抗する
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<NaiTokidokiAru | null>(
                        assessmentSheetUpdater,
                        "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seishinKodoShogai.kaigoNiTeikoSuru",
                        naiTokidokiAruLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        4-8 落ち着きがない（「家に帰る」等）
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<NaiTokidokiAru | null>(
                        assessmentSheetUpdater,
                        "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seishinKodoShogai.ochitsukiGaNai",
                        naiTokidokiAruLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        4-9 外に出たがり目が離せない
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<NaiTokidokiAru | null>(
                        assessmentSheetUpdater,
                        "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seishinKodoShogai.sotoNiDeTaGariMeGaHanaseNai",
                        naiTokidokiAruLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        4-10 ものを集める、無断でもってくる
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<NaiTokidokiAru | null>(
                        assessmentSheetUpdater,
                        "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seishinKodoShogai.monoOAtsumeruMudanDeMotteKuru",
                        naiTokidokiAruLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        4-11 物を壊す、衣類を破く
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<NaiTokidokiAru | null>(
                        assessmentSheetUpdater,
                        "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seishinKodoShogai.monoOKowasuIruiOYabuku",
                        naiTokidokiAruLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        4-12 ひどい物忘れ
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<NaiTokidokiAru | null>(
                        assessmentSheetUpdater,
                        "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seishinKodoShogai.hidoiMonowasure",
                        naiTokidokiAruLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        4-13 独り言や独り笑い
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<NaiTokidokiAru | null>(
                        assessmentSheetUpdater,
                        "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seishinKodoShogai.hitorigotoYaHitoriWarai",
                        naiTokidokiAruLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        4-14 自分勝手な行動
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<NaiTokidokiAru | null>(
                        assessmentSheetUpdater,
                        "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seishinKodoShogai.jibunkatteNaKodo",
                        naiTokidokiAruLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        4-15 話がまとまらない、会話にならない
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<NaiTokidokiAru | null>(
                        assessmentSheetUpdater,
                        "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seishinKodoShogai.hanashiGaMatomaraNaiKaiwaNiNaraNai",
                        naiTokidokiAruLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        4-16 幻視・幻聴
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<NaiTokidokiAru | null>(
                        assessmentSheetUpdater,
                        "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seishinKodoShogai.genshiGencho",
                        naiTokidokiAruLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        4-17 暴言・暴力
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<NaiTokidokiAru | null>(
                        assessmentSheetUpdater,
                        "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seishinKodoShogai.bogenBoryoku",
                        naiTokidokiAruLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        4-18 目的なく動き回る
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<NaiTokidokiAru | null>(
                        assessmentSheetUpdater,
                        "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seishinKodoShogai.mokutekiNakuUgokimawaru",
                        naiTokidokiAruLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        4-19 火の始末・管理
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<NaiTokidokiAru | null>(
                        assessmentSheetUpdater,
                        "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seishinKodoShogai.hiNoShimatsuKanri",
                        naiTokidokiAruLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        4-20 不潔行為
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<NaiTokidokiAru | null>(
                        assessmentSheetUpdater,
                        "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seishinKodoShogai.fuketsuKoi",
                        naiTokidokiAruLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        4-21 異食行動
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<NaiTokidokiAru | null>(
                        assessmentSheetUpdater,
                        "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.seishinKodoShogai.ishokuKodo",
                        naiTokidokiAruLabels
                    )}
                </div>;
            })}
        </div>
    </>;
}

type Props = {
    assessmentSheetUpdater: AssessmentSheetUpdater,
    activated: boolean,
};
