export class ApiError {
    // TODO: ちょっと雑なまとめ方。あとで考える
    type: "ERROR" = "ERROR";

    code: string;

    message: string;

    constructor(code: string, message: string) {
        this.code = code;
        this.message = message;
    }

    static async extract(response: Response): Promise<ApiError> {
        const json = await response.json();
        return new ApiError(json.error.code, json.error.message);
    }
}
