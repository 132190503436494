export class GetCarePlanTableTwoUrl {
    static path = "/rsapi/getCarePlanTableTwoUrl";

    type: "SUCCESS" = "SUCCESS";

    url: string;

    constructor(url: string) {
        this.url = url;
    }

    static async extract(response: Response): Promise<GetCarePlanTableTwoUrl> {
        const json = await response.json();
        const url = json.url;
        return new GetCarePlanTableTwoUrl(url);
    }
}