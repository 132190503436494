import React from "react";

export function CategoryKinkokkakukeiShikkanIcon() {
    return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.00773 20.6669C7.86856 20.8389 7.61259 20.8703 7.43602 20.7369C7.25945 20.6035 7.22913 20.3558 7.3683 20.1838L9.8885 17.068C10.4128 16.4857 11.0914 16.0532 11.85 15.818L12.0894 15.7223C12.4869 15.594 12.8598 15.4021 13.1924 15.1544C13.2689 14.9604 12.8924 13.8145 11.3863 12.6766C9.88022 11.5388 8.63827 11.4619 8.44962 11.5935C8.29076 11.9631 8.19271 12.3541 8.15908 12.7523L8.12485 12.9674C8.06865 13.745 7.79333 14.4945 7.32887 15.1342L4.80852 18.2501C4.66943 18.4221 4.41362 18.4534 4.23715 18.3201C4.06068 18.1868 4.03038 17.9393 4.16947 17.7673L6.68983 14.6514C7.06398 14.1187 7.28177 13.4984 7.31974 12.8573L7.35439 12.6371C7.38527 12.0966 7.54554 11.5695 7.82253 11.0974C8.39898 10.3847 10.2616 10.8226 11.8905 12.0533C13.5194 13.2839 14.4084 14.9255 13.8322 15.6378C13.4239 16.0097 12.9338 16.2869 12.3995 16.4482L12.1535 16.5467C11.5292 16.7319 10.9678 17.0785 10.5297 17.5488L8.00773 20.6669Z" fill="#7B7C88"/>
        <path d="M10.6165 10.1314C9.70705 9.44432 9.70672 8.52636 10.6158 7.40247C11.0182 7.00758 11.5573 6.77144 12.1273 6.74038C12.6396 6.70426 13.1285 6.51375 13.5254 6.19562C13.982 5.74278 14.7777 4.70859 15.3592 3.95367C15.4975 3.77429 15.6268 3.60645 15.7417 3.45899C15.8777 3.28437 16.1335 3.25275 16.3102 3.38627C16.4869 3.51978 16.5177 3.76788 16.3818 3.94257C16.2702 4.08596 16.145 4.24862 16.0113 4.42221C15.4182 5.22743 14.7824 6.00251 14.1065 6.7443C13.5937 7.18312 12.951 7.45323 12.2718 7.51534C11.8216 7.59703 11.4318 7.6683 11.2561 7.8856C10.3902 8.95601 10.8479 9.30174 11.1197 9.50709C11.4635 9.7639 11.8583 9.94964 12.2801 10.053L12.4536 10.1076C13.0026 10.3971 13.4796 10.7986 13.852 11.2845C14.0801 11.5454 14.3271 11.7902 14.5911 12.0171C15.0053 12.33 15.4723 12.5587 16.2204 11.6338C16.3956 11.4172 16.3705 11.0337 16.3426 10.5903C16.2372 9.93692 16.3514 9.26641 16.6684 8.67738C17.2614 7.85047 17.8977 7.05331 18.5748 6.28904C18.7048 6.1358 18.8261 5.99311 18.9326 5.86673C19.0755 5.69726 19.3322 5.66635 19.5089 5.79987C19.6856 5.93338 19.7155 6.18081 19.5727 6.35032C19.4625 6.48111 19.3367 6.62908 19.202 6.78759C18.5712 7.52999 17.7079 8.54685 17.3602 9.08982C17.1373 9.54012 17.0659 10.0458 17.1559 10.5353C17.2654 11.0789 17.1595 11.6442 16.8592 12.1188C15.9991 13.1821 15.04 13.3631 14.0856 12.642C13.7828 12.3886 13.5012 12.1123 13.2434 11.8159C12.9442 11.4469 12.5898 11.1233 12.1917 10.8557L12.0218 10.802C11.5113 10.6715 11.0336 10.4435 10.6165 10.1314Z" fill="#7B7C88"/>
        <path d="M12.2 10.8482L12.4666 10.1012C12.6851 10.2025 12.9314 10.2351 13.1711 10.1944C13.2561 10.1799 13.3389 10.1565 13.4179 10.1248C13.6432 10.0344 13.9317 10.0498 14.0712 10.2417C14.1851 10.3984 14.1608 10.6178 13.9911 10.7191C13.7753 10.8478 13.535 10.9354 13.2833 10.9757C12.9162 11.0345 12.5403 10.9902 12.2 10.8482Z" fill="#7B7C88"/>
    </svg>
    ;
}
