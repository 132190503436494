import { useCallback, useEffect, useState } from "react";
import { AdminUserApi } from "../../../../state/api/admin/user/AdminUserApi";
import useMounted from "../../common/useMounted";
import { UserWithManageUsageDetail } from "../../../../domain/user/UserWithManageUsageDetail";

export default function useUsers(): {users: UserWithManageUsageDetail[], retrieveUsers: () => void } {

    const [users, setUsers] = useState<UserWithManageUsageDetail[]>([]);
    const mounted = useMounted();

    const retrieveUsers = useCallback(() => {
        AdminUserApi.usersWithManageUsage()
            .then(res => {
                mounted.current && setUsers(res);
            })
    }, [mounted])

    useEffect(() => {
        retrieveUsers();
    }, [retrieveUsers]);

    return { users, retrieveUsers };
}
