import React from "react";
import styles from "./Features.module.scss";
import { Tokucho } from "../../../../../domain/establishment/Establishment";
import { Feature } from "../../../suggestion/components/establishment/components/Feature";

export function Features(props: Props) {
    const { tokucho, categoryCode } = props;

    const tsusho = () => {
        return (
            <>
                <Feature active={tokucho?.kinokunren}>機能訓練</Feature>
                <Feature active={tokucho?.nyuyokuKoyoku}>個浴</Feature>
                <Feature active={tokucho?.nyuyokuKikaiyoku}>機械浴</Feature>
                <Feature active={tokucho?.shukuhaku}>宿泊</Feature>
                <Feature active={tokucho?.staffKangoshi}>Ns</Feature>
                <Feature active={tokucho?.staffPt}>PT</Feature>
                <Feature active={tokucho?.staffOt}>OT</Feature>
                <Feature active={tokucho?.staffSt}>ST</Feature>
                <Feature active={tokucho?.staffKanrieiyoshi}>管理栄養士</Feature>
                <Feature active={tokucho?.staffShikaeiseishi}>歯科衛生士</Feature>
            </>
        );
    };

    const homon = () => {
        return (
            <>
                <Feature active={tokucho?.tsuinJokokaijo}>通院等乗降介護</Feature>
                <Feature active={tokucho?.tanjikankaijo}>短時間介助可</Feature>
                <Feature active={tokucho?.taio24jikan}>24時間対応</Feature>
                <Feature active={tokucho?.staffKangoshi}>Ns</Feature>
                <Feature active={tokucho?.staffPt}>PT</Feature>
                <Feature active={tokucho?.staffOt}>OT</Feature>
                <Feature active={tokucho?.staffSt}>ST</Feature>
                <Feature active={tokucho?.terminalCareKasan}>ターミナルケア加算</Feature>
            </>
        );
    };

    const tanki = () => {
        return (
            <>
                <Feature active={tokucho?.koshitsu}>個室</Feature>
                <Feature active={tokucho?.kyoshitsuUnit}>ユニット型</Feature>
                <Feature active={tokucho?.kinokunren}>機能訓練</Feature>
                <Feature active={tokucho?.nyuyokuKoyoku}>個浴</Feature>
                <Feature active={tokucho?.sogei}>送迎</Feature>
                <Feature active={tokucho?.staffKangoshi}>Ns</Feature>
                <Feature active={tokucho?.staffPt}>PT</Feature>
                <Feature active={tokucho?.staffOt}>OT</Feature>
                <Feature active={tokucho?.staffSt}>ST</Feature>
                <Feature active={tokucho?.staffKanrieiyoshi}>管理栄養士</Feature>
                <Feature active={tokucho?.kinkyujiukeire}>緊急時受入可</Feature>
            </>
        );
    };

    const render = () => {
        switch (categoryCode) {
            case "通所系サービス":
                return tsusho();
            case "訪問系サービス":
                return homon();
            case "短期入所系サービス":
                return tanki();
        }
    };

    return <div className={styles.container}>{render()}</div>;
}

type Props = {
    tokucho: Tokucho | null;
    categoryCode: "訪問系サービス" | "通所系サービス" | "短期入所系サービス";
};
