import React, { InputHTMLAttributes } from "react";
import { InputField, InputFieldProps } from "./InputField";

export function TextField(props: TextFieldProps) {
    const encode = (value: string) => value;
    const decode = (value: string) => value;

    return <InputField encode={encode} decode={decode} {...props}>
        {props => <input type={"text"} {...props}/>}
    </InputField>;
}

export type TextFieldProps = InputFieldProps<InputHTMLAttributes<any>, string>;
