import { LongTermGoal } from "../longtermgoal/LongTermGoal";
import { SubItems } from "../SubItems";

const NeedType = Symbol();

// ニーズ
export class Need {
    [NeedType]: any;

    id: string;

    text: string | null;

    subItems: SubItems<LongTermGoal>;

    constructor(
        id: string,
        text: string | null,
        subItems: SubItems<LongTermGoal>,
    ) {
        this.id = id;
        this.text = text;
        this.subItems = subItems;
    }

    isFirstSubItem(longTermGoal: LongTermGoal): boolean {
        return this.subItems.items.findIndex((item) => longTermGoal === item) === 0;
    }

    isLastSubItem(longTermGoal: LongTermGoal): boolean {
        return this.subItems.items.findIndex((item) => longTermGoal === item) === this.subItems.items.length - 1;
    }
}
