import { LoginHistoryItem } from "../../../../domain/user/LoginHistoryItem";
import { OperationHistoryItem } from "../../../../domain/user/OperationHistoryItem";
import { ProjectsMadeCountItem } from "../../../../domain/user/ProjectsMadeCountItem";
import { CareRecipientCreatedCountItem } from "../../../../domain/user/CareRecipientCreatedCountItem";

export class LoginHistoriesResponse {

    loginHistory: LoginHistoryItem[];

    operationHistory: OperationHistoryItem[];

    projectsMadeCount: ProjectsMadeCountItem[];

    careRecipientCreatedHistory: CareRecipientCreatedCountItem[];

    constructor(
        loginHistory: LoginHistoryItem[],
        operationHistory: OperationHistoryItem[],
        projectsMadeCount: ProjectsMadeCountItem[],
        careRecipientCreatedHistory: CareRecipientCreatedCountItem[]
    ) {
        this.loginHistory = loginHistory;
        this.operationHistory = operationHistory;
        this.projectsMadeCount = projectsMadeCount;
        this.careRecipientCreatedHistory = careRecipientCreatedHistory;
    }

    static async extract(response: Response): Promise<LoginHistoriesResponse> {
        const json = await response.json();
        return new LoginHistoriesResponse(
            json.loginHistory,
            json.operationHistory,
            json.projectsMadeCount,
            json.careRecipientCreatedHistory
        );
    }
}
