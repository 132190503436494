import { Dispatch } from "react";
import { GetActions, UpdateItemActions } from "./type";
import { AssessmentSheetApi } from "../../api/assessmentsheet/AssessmentSheetApi";
import { AssessmentSheetPath } from "../../../domain/assessmentsheet/AssessmentSheetPath";
import { ChangeLog } from "../../../domain/changelog/ChangeLog";

export const get = (projectId: string) => async (dispatch: Dispatch<GetActions>) => {
    dispatch({
        type: "ASSESSMENT_SHEET_GET",
        payload: {},
    });
    const result = await AssessmentSheetApi.get(projectId);
    switch (result.type) {
        case "SUCCESS":
            dispatch({
                type: "ASSESSMENT_SHEET_GET_SUCCESS",
                payload: {
                    projectId: result.projectId,
                    assessmentSheet: result.assessmentSheet,
                    latestChangeLog: result.latestChangeLog,
                },
            })
            break;
        case "ERROR":
            dispatch({
                type: "ASSESSMENT_SHEET_GET_FAILURE",
                payload: {},
            })
            break;
    }
};

export const updateItem = (
    projectId: string,
    path: AssessmentSheetPath,
    value: any,
    originalValue: any,
    onSuccess: (changeLog: ChangeLog) => void,
    onFailure: () => void,
) => async (dispatch: Dispatch<UpdateItemActions>) => {
    dispatch({
        type: "ASSESSMENT_SHEET_UPDATE_ITEM",
        payload: {
            projectId,
            path,
            value,
        },
    });
    const result = await AssessmentSheetApi.updateItem(projectId, path, value);
    switch (result.type) {
        case "SUCCESS":
            dispatch({
                type: "ASSESSMENT_SHEET_UPDATE_ITEM_SUCCESS",
                payload: {
                    projectId,
                    changeLog: result.changeLog,
                },
            });
            onSuccess(result.changeLog);
            break;
        case "ERROR":
            dispatch({
                type: "ASSESSMENT_SHEET_UPDATE_ITEM_FAILURE",
                payload: {
                    projectId,
                    path,
                    value: originalValue,
                },
            });
            onFailure();
            break;
    }
};
