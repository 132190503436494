import { Reducer } from "redux";
import { TableThreeActions } from "./type";
import { ChangeLog } from "../../../domain/changelog/ChangeLog";
import { TableThree } from "../../../domain/careplan/tablethree/TableThree";

type Slot = {
    tableThree: TableThree;
    latestChangeLog: ChangeLog | null;
};

export type TableThreeState = Readonly<{
    slots: { [key: string]: Slot };
    getting: boolean;
}>;

const initialState: TableThreeState = {
    slots: {},
    getting: false,
};

export const tableThreeReducer: Reducer<TableThreeState, TableThreeActions> = (state = initialState, action): TableThreeState => {
    switch (action.type) {
        case "TABLETHREE_GET":
            return {
                ...state,
                getting: true,
            };
        case "TABLETHREE_GET_SUCCESS":
            return {
                ...state,
                slots: {
                    ...state.slots,
                    [action.payload.projectId]: {
                        tableThree: action.payload.tableThree,
                        latestChangeLog: action.payload.latestChangeLog,
                    },
                },
                getting: false,
            };
        case "TABLETHREE_GET_FAILURE":
            return {
                ...state,
                getting: false,
            };
        default:
            return state;
    }
};
