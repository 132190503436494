import { SimpleCareRecipient } from "../../../domain/carerecipient/SimpleCareRecipient";
import { Project } from "../../../domain/project/Project";
import { ProjectCompletion } from "../../../domain/project/ProjectCompletion";
import { User } from "../../../domain/user/User";
import { offsetDateTime } from "../../../domain/fundamental/OffsetDateTime";
import { changeLog, LatestChangeLog } from "../changelog/LatestChangeLog";

export class ProjectListResponse {
    type: "SUCCESS" = "SUCCESS";

    careRecipient: SimpleCareRecipient;

    projects: Project[];

    constructor(careRecipient: SimpleCareRecipient, projects: Project[]) {
        this.careRecipient = careRecipient;
        this.projects = projects;
    }

    static path = "/api/project/getList";

    static async extract(response: Response): Promise<ProjectListResponse> {
        const json = await response.json();
        const projects = json.projects.map(project);
        return new ProjectListResponse(json.careRecipient, projects);
    }
}

type Completion = {
    completedAt: string,
    completedUser: User,
};

type Item = {
    id: string,
    careRecipientId: string,
    version: number,
    createdAt: string,
    completion: Completion | null,
    latestChangeLog: LatestChangeLog | null,
    lastEditedAt: string,
};

function project(item: Item): Project {
    return new Project(
        item.id,
        item.careRecipientId,
        item.version,
        offsetDateTime(item.createdAt),
        item.completion ? completion(item.completion) : null,
        item.latestChangeLog ? changeLog(item.latestChangeLog) : null,
        offsetDateTime(item.lastEditedAt)
    )
}

function completion(completion: Completion): ProjectCompletion {
    const { completedAt, completedUser } = completion;
    return { completedAt: offsetDateTime(completedAt), completedUser };
}
