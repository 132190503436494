import { ChatGptLongTermGoal } from "../longtermgoal/ChatGptLongTermGoal";

// 2表叩き台 ニーズ
export class ChatGptNeed {
    text: string; // 内容
    longTermGoals: ChatGptLongTermGoal[] | null; // 長期目標

    constructor(text: string, longTermGoals: ChatGptLongTermGoal[] | null) {
        this.text = text;
        this.longTermGoals = longTermGoals;
    }
}
