import React, { MouseEvent, useState } from "react";
import { Office } from "../../../../domain/office/Office";
import styles from "./DeleteOfficeConfirmation.module.scss";
import { DangerButton } from "../../components/button/DangerButton";
import { CancelButton } from "../../components/button/CancelButton";
import { CloseIcon } from "../../components/icon/CloseIcon";
import { AdminOfficeApi } from "../../../../state/api/admin/office/AdminOfficeApi";
import { ApiError } from "../../../../state/api/ApiError";

export default function DeleteOfficeConfirmation(props: Props) {

    const { office, onRequestClose, refreshOffices } = props;

    const [hasError, setHasError] = useState(false);

    const onClickContainer = (event: MouseEvent<any>) => event.stopPropagation();

    const onClick = (event: MouseEvent<any>) => {
        event.stopPropagation();
        AdminOfficeApi.delete(office.id).then(
            value => {
                if (value instanceof  ApiError) {
                    setHasError(true);
                } else {
                    setHasError(false);
                    refreshOffices();
                    onRequestClose();
                }
            }
        )
    }

    return <div className={ styles.container } onClick={e => onClickContainer(e)}>
        <div className={ styles.title }>
            法人を削除する
        </div>
        <div className={ styles.description }>
            以下の事業所を削除します。この操作は取り消せません。キャンセルを押すと削除を中止できます。
            <div className={ styles.officeName}>
                { office.displayName }
            </div>
            <div className={ styles.officeAddress}>
                { office.address}
            </div>
            { hasError && <div className={styles.errorMessage}>
                事業所削除に失敗しました。
            </div> }
        </div>
        <div className={ styles.buttonArea }>
            <div className={ styles.dangerButtonArea }>
                <DangerButton width={ 256 } onClick={ (e) => onClick(e) }>削除する</DangerButton>
            </div>
            <div className={ styles.cancelButtonArea }>
                <CancelButton width={ 256 } onClick={ onRequestClose }>キャンセル</CancelButton>
            </div>
        </div>
        <button onClick={ onRequestClose } className={ styles.closeIcon }>
            <CloseIcon/>
        </button>
    </div>

}

type Props = {
    office: Office,
    onRequestClose: () => void,
    refreshOffices: () => void,
}
