export class GetSumCount {
    count: number;

    constructor(count: number) {
        this.count = count;
    }

    static async extract(response: Response): Promise<GetSumCount> {
        const json = await response.json();
        return new GetSumCount(json.count);
    }
}
