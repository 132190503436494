import "whatwg-fetch";
import { AssessmentSheetPath } from "../../../domain/assessmentsheet/AssessmentSheetPath";
import { Get } from "./Get";
import { UpdateItem } from "./UpdateItem";
import { ApiError } from "../ApiError";
import { get, post, queryString } from "../functions";

export const AssessmentSheetApi = new class {
    async get(projectId: string): Promise<Get | ApiError> {
        const params = { projectId };
        const response = await get(`${Get.path}?${queryString(params)}`);
        return (response.ok ? Get.extract : ApiError.extract)(response);
    }

    async updateItem(projectId: string, path: AssessmentSheetPath, value: any): Promise<UpdateItem | ApiError> {
        const item = { ...pathToItem(path), value };
        const body = { projectId, item };
        const response = await post(UpdateItem.path, body);
        return (response.ok ? UpdateItem.extract : ApiError.extract)(response);
    }
}();

function pathToItem(path: AssessmentSheetPath) {
    const { placeholder, indices } = path.parse();
    const indicesObject = Object.fromEntries(indices.map((index, position) => [`index${position + 1}`, index]));
    return { path: placeholder, ...indicesObject };
}
