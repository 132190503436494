import { SuggestedCondition } from "../../state/usecase/suggestion/establishment/reducer";
import { SupportServiceTypeCategory } from "./SupportServiceTypeCategory";

export enum ConditionCode {
    サービス種別 = "supportServiceTypes",
    範囲 = "areaCode",
    土 = "doyo",
    日 = "nichiyo",
    祝 = "shukujitsu",
    生活保護の受入有 = "seikatsuhogo",
    PT = "staffPt",
    OT = "staffOt",
    ST = "staffSt",
    機能訓練 = "kinokunren",
    入浴あり = "nyuyoku",
    リフト車あり = "sogeiLift",
    宿泊あり = "shukuhaku",
    緊急時訪問あり = "kinkyujihomon",
    二十四時間対応 = "taio24jikan",
    ターミナルケア加算 = "terminalCareKasan",
    特定医療行為可 = "tokuteiiryokoi",
    通院等乗降介助 = "tsuinJokokaijo",
    短時間の訪問介助 = "tanjikankaijo",
    個室あり = "koshitsu",
    ユニット型 = "kyoshitsuUnit",
    機械浴 = "nyuyokuKikaiyoku",
    個浴 = "nyuyokuKoyoku",
    大浴槽 = "nyuyokuDaiyokuso",
    個別機能訓練加算Ⅰ = "kinokurenKobetsukinokunren1",
    個別機能訓練加算Ⅱ = "kinokurenKobetsukinokunren2",
}

export enum SupportServiceType {
    通所介護 = 6,
    認知症対応型通所介護 = 7,
    指定療養通所介護 = 8,
    通所リハビリテーション = 9,
    地域密着型通所介護 = 33,
    訪問介護 = 1,
    訪問入浴介護 = 3,
    訪問看護 = 4,
    訪問リハビリテーション = 5,
    定期巡回_随時対応型訪問介護看護 = 31,
    短期入所生活介護 = 25,
    短期入所療養介護_療養病床を有する病院等 = 28,
    短期入所療養介護_介護老人保健施設 = 30,
}

type AvailableSupportServiceType = {
    [key in SupportServiceTypeCategory]: SupportServiceType[]
}

export const availableSupportServiceType: AvailableSupportServiceType = {
    "通所系": [SupportServiceType.通所介護, SupportServiceType.認知症対応型通所介護, SupportServiceType.指定療養通所介護, SupportServiceType.通所リハビリテーション, SupportServiceType.地域密着型通所介護],
    "訪問系": [SupportServiceType.訪問介護, SupportServiceType.訪問入浴介護, SupportServiceType.訪問看護, SupportServiceType.訪問リハビリテーション, SupportServiceType.定期巡回_随時対応型訪問介護看護],
    "短期入所系": [SupportServiceType.短期入所生活介護, SupportServiceType.短期入所療養介護_療養病床を有する病院等, SupportServiceType.短期入所療養介護_介護老人保健施設]
}

export const SupportServiceTypeLabels: { [key in SupportServiceType]: string } = {
    [SupportServiceType.通所介護]: "通所介護",
    [SupportServiceType.認知症対応型通所介護]: "認知症対応型通所介護",
    [SupportServiceType.指定療養通所介護]: "指定療養通所介護",
    [SupportServiceType.通所リハビリテーション]: "通所リハビリテーション",
    [SupportServiceType.地域密着型通所介護]: "地域密着型通所介護",
    [SupportServiceType.訪問介護]: "訪問介護",
    [SupportServiceType.訪問入浴介護]: "訪問入浴介護",
    [SupportServiceType.訪問看護]: "訪問看護",
    [SupportServiceType.訪問リハビリテーション]: "訪問リハビリテーション",
    [SupportServiceType.定期巡回_随時対応型訪問介護看護]: "定期巡回・随時対応型訪問介護看護",
    [SupportServiceType.短期入所生活介護]: "短期入所生活介護",
    [SupportServiceType.短期入所療養介護_療養病床を有する病院等]: "短期入所療養介護(療養病床を有する病院等)",
    [SupportServiceType.短期入所療養介護_介護老人保健施設]: "短期入所療養介護(介護老人保健施設)",
}

export const ConditionLabels: { [key in ConditionCode]: string } = {
    [ConditionCode.サービス種別]: "",
    [ConditionCode.範囲]: "",
    [ConditionCode.土]: "土",
    [ConditionCode.日]: "日",
    [ConditionCode.祝]: "祝",
    [ConditionCode.生活保護の受入有]: "生活保護の受け入れあり",
    [ConditionCode.PT]: "PT",
    [ConditionCode.OT]: "OT",
    [ConditionCode.ST]: "ST",
    [ConditionCode.機能訓練]: "",
    [ConditionCode.入浴あり]: "",
    [ConditionCode.リフト車あり]: "リフト車あり",
    [ConditionCode.宿泊あり]: "宿泊あり",
    [ConditionCode.緊急時訪問あり]: "緊急訪問あり",
    [ConditionCode.二十四時間対応]: "24時間対応",
    [ConditionCode.ターミナルケア加算]: "ターミナルケア加算",
    [ConditionCode.特定医療行為可]: "特定医療行為可",
    [ConditionCode.通院等乗降介助]: "通院等乗降介助",
    [ConditionCode.短時間の訪問介助]: "短時間の訪問介助",
    [ConditionCode.個室あり]: "個室あり",
    [ConditionCode.ユニット型]: "ユニット型",
    [ConditionCode.機械浴]: "機械浴",
    [ConditionCode.個浴]: "個浴",
    [ConditionCode.大浴槽]: "大浴槽",
    [ConditionCode.個別機能訓練加算Ⅰ]: "機能訓練(個別機能訓練加算Ⅰ)",
    [ConditionCode.個別機能訓練加算Ⅱ]: "機能訓練(個別機能訓練加算Ⅱ)",
}

export type CheckboxCondition =
    ConditionCode.土 |
    ConditionCode.日 |
    ConditionCode.祝 |
    ConditionCode.生活保護の受入有 |
    ConditionCode.PT |
    ConditionCode.OT |
    ConditionCode.ST |
    ConditionCode.機能訓練 |
    ConditionCode.入浴あり |
    ConditionCode.リフト車あり |
    ConditionCode.宿泊あり |
    ConditionCode.緊急時訪問あり |
    ConditionCode.二十四時間対応 |
    ConditionCode.ターミナルケア加算 |
    ConditionCode.特定医療行為可 |
    ConditionCode.通院等乗降介助 |
    ConditionCode.短時間の訪問介助 |
    ConditionCode.個室あり |
    ConditionCode.ユニット型 |
    ConditionCode.機械浴 |
    ConditionCode.個浴 |
    ConditionCode.大浴槽 |
    ConditionCode.個別機能訓練加算Ⅰ |
    ConditionCode.個別機能訓練加算Ⅱ;

type Condition = {
    [ConditionCode.サービス種別]: Set<number>,
    [ConditionCode.範囲]: string,
    checkboxes: {
        [key in CheckboxCondition]: boolean
    }
}

export class EstablishmentSearchCondition {

    condition: Condition;
    suggestedCondition: SuggestedCondition;

    constructor(condition: Condition, suggestedCondition: SuggestedCondition) {
        this.condition = condition;
        this.suggestedCondition = suggestedCondition;
    }

    updateCondition = (key: CheckboxCondition, value: boolean) => {
        this.condition.checkboxes[key] = value;
        return this;
    }

    updateRangeCondition = (value: string) => {
        this.condition[ConditionCode.範囲] = value;
        return this;
    }

    updateSupportServiceType = (value: number, checked: boolean) => {
        if (checked) {
            this.condition[ConditionCode.サービス種別].add(value);
        } else {
            this.condition[ConditionCode.サービス種別].delete(value);
        }
        return this;
    }

    toQueryString = (): string => {
        // カテゴリごとに、サービス種別が1つもチェックされていない場合は全サービス種別を対象に検索を実行します。
        let serviceTypes = Array.from(this.condition[ConditionCode.サービス種別]);
        if (!serviceTypes.some((value) => availableSupportServiceType["通所系"].includes(value))) {
            serviceTypes = serviceTypes.concat(availableSupportServiceType["通所系"]);
        }
        if (!serviceTypes.some((value) => availableSupportServiceType["訪問系"].includes(value))) {
            serviceTypes = serviceTypes.concat(availableSupportServiceType["訪問系"]);
        }
        if (!serviceTypes.some((value) => availableSupportServiceType["短期入所系"].includes(value))) {
            serviceTypes = serviceTypes.concat(availableSupportServiceType["短期入所系"]);
        }

        const supportServiceTypesQuery = serviceTypes.map(
            (code) => {
                return `${ConditionCode.サービス種別}=${code}`
            }
        ).join("&");
        const range = `${ConditionCode.範囲}=${this.condition[ConditionCode.範囲]}`;
        const conditions = Object.entries(this.condition.checkboxes).map(([key, value]) => {
            return `${key}=${value}`;
        }).join("&");
        const { supportServiceTypes, ...condition } = this.suggestedCondition;
        const recommendSupportServiceTypes = supportServiceTypes?.map((code) => `recommend.supportServiceType=${code}`).join("&");
        const recommendCondition = Object.entries(condition).map(([key, value]) => `recommend.${key}=${value}`).join("&");
        const recommend = [recommendSupportServiceTypes, recommendCondition].filter(Boolean).join("&");
        return [supportServiceTypesQuery, range, conditions, recommend].filter(Boolean).join("&");
    }

    static fromSuggestedCondition = (suggestedCondition: SuggestedCondition): EstablishmentSearchCondition => {
        return new EstablishmentSearchCondition(
            {
                [ConditionCode.サービス種別]: new Set<number>(suggestedCondition.supportServiceTypes),
                [ConditionCode.範囲]: "3",
                checkboxes: {
                    [ConditionCode.土]: false,
                    [ConditionCode.日]: false,
                    [ConditionCode.祝]: false,
                    [ConditionCode.生活保護の受入有]: suggestedCondition.seikatsuhogo,
                    [ConditionCode.PT]: suggestedCondition.staffPt,
                    [ConditionCode.OT]: suggestedCondition.staffOt,
                    [ConditionCode.ST]: suggestedCondition.staffSt,
                    [ConditionCode.機能訓練]: false,
                    [ConditionCode.入浴あり]: false,
                    [ConditionCode.リフト車あり]: false,
                    [ConditionCode.宿泊あり]: false,
                    [ConditionCode.緊急時訪問あり]: false,
                    [ConditionCode.二十四時間対応]: false,
                    [ConditionCode.ターミナルケア加算]: false,
                    [ConditionCode.特定医療行為可]: false,
                    [ConditionCode.通院等乗降介助]: suggestedCondition.tsuintojokokaijo,
                    [ConditionCode.短時間の訪問介助]: false,
                    [ConditionCode.個室あり]: false,
                    [ConditionCode.ユニット型]: false,
                    [ConditionCode.機械浴]: false,
                    [ConditionCode.個浴]: false,
                    [ConditionCode.大浴槽]: false,
                    [ConditionCode.個別機能訓練加算Ⅰ]: suggestedCondition.kobetsukinokunren1,
                    [ConditionCode.個別機能訓練加算Ⅱ]: suggestedCondition.kobetsukinokunren2,
                }
            }
            , suggestedCondition)
    }
}
