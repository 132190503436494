import React, { RefObject, ReactNode } from "react";
import { useSelector } from "react-redux";
import { State } from "../../../../state/store";
import styles from "./TableTwoHeader.module.scss";
import { ChangeLog } from "../../../../domain/changelog/ChangeLog";
import { TableTwoDiseaseKnowledge } from "./TableTwoDiseaseKnowledge";
import { ExcelDownLoadButton } from "../../components/button/ExcelDownloadButton";
import { Paths } from "../../../../Paths";
import { jpFormat } from "dayjs-jp-format";
import dayjs from "dayjs";
import { SakuseiKubunContent } from "../../tablecommon/components/SakuseiKubunContent";
import { PrimaryButton } from "../../components/button/PrimaryButton";
import { ChatgptManageUsage } from "../../../../domain/chatgpt/ChatgptManageUsage";

dayjs.extend(jpFormat);

export function TableTwoHeader(props: Props) {
    const { projectId, latestChangeLog, globalHeaderRef, projectHeaderRef, activated, chatGptManageUsage, onRequestOpenChatGptDialog } = props;

    const rsLoginIn = useSelector((state: State) => state.authentication.rsLoginIn);

    const stylesLastEditedAt = rsLoginIn ? styles.lastEditedAtRsLogin : styles.lastEditedAt;

    const chatGptUsageType = chatGptManageUsage? chatGptManageUsage.usageType : 0;
    const disabledChatGptButton = chatGptUsageType == 0;

    const formatEditedAt = (latestChangeLog: ChangeLog | null) => {
        return latestChangeLog?.when.toDate().toLocaleDateString("ja-JP-u-ca-japanese", { era: "narrow", year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric" });
    };

    const handleClick = () => {
        onRequestOpenChatGptDialog()
    };

    return (
        <>
            <div className={styles.container}>
                <h2 className={styles.title}>居宅サービス計画(2)</h2>
                <div className={styles.row}>
                    <div className={styles.diseaseKnowledge}>{!rsLoginIn && <TableTwoDiseaseKnowledge projectId={projectId} globalHeaderRef={globalHeaderRef} projectHeaderRef={projectHeaderRef} />}</div>
                    <div>
                        {!rsLoginIn && (
                            <>
                                <SakuseiKubunContent projectId={projectId} globalHeaderRef={globalHeaderRef} projectHeaderRef={projectHeaderRef} activated={activated} />
                            </>
                        )}
                    </div>
                </div>
                <div className={styles.row}>
                    {/* <div className={styles.diseaseKnowledge}>{!rsLoginIn && <TableTwoDiseaseKnowledge projectId={projectId} globalHeaderRef={globalHeaderRef} projectHeaderRef={projectHeaderRef} />}</div> */}
                    {renderCatGptButton(rsLoginIn, disabledChatGptButton, handleClick)}
                    <div className={styles.menu}>
                        {latestChangeLog && <div className={stylesLastEditedAt}>最終更新: {formatEditedAt(latestChangeLog)}</div>}
                        {!rsLoginIn && <ExcelDownLoadButton path={Paths.tableTwoDownloadExcel.build({ projectId })} />}
                    </div>
                </div>
            </div>
        </>
    );
}

type Props = {
    projectId: string;
    latestChangeLog: ChangeLog | null;
    globalHeaderRef: RefObject<HTMLDivElement>;
    projectHeaderRef: RefObject<HTMLDivElement>;
    activated: boolean;

    chatGptManageUsage: ChatgptManageUsage | undefined;
    onRequestOpenChatGptDialog: () => void;
};

function renderCatGptButton(isRsLogin: boolean, disabledButton: boolean, handleClick : () => void): ReactNode {
    if (!isRsLogin)
    {
        return <>
            <div className={styles.button}>
                <PrimaryButton onClick={handleClick} disabled={disabledButton}>生成AIを利用</PrimaryButton>
                <div className={styles.aiMessages}>
                    <p>※ 生成AIを利用して入力された内容は、利用者のニーズや情報を十分に考慮し修正してご利用ください。</p>
                </div>
            </div>
        </>;
    }
    return <>
        <div></div>
    </>;
}
