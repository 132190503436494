import React, { FormEvent, useState } from "react";
import styles from "./LoginPage.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../../state/store";
import { login } from "../../../state/usecase/authentication/action";
import { GlobalHeader } from "../components/GlobalHeader";
import { GlobalContainer } from "../components/GlobalContainer";
import { TextField } from "../components/field/TextField";
import { LoginLogo } from "./components/LoginLogo";
import { LoginField } from "./components/LoginField";
import { LoginButton } from "./components/LoginButton";
import { LoginError } from "./components/LoginError";
import { PasswordField } from "../components/field/PasswordField";

export function LoginPage() {
    const [identifier, setIdentifier] = useState("");
    const [password, setPassword] = useState("");
    const dispatch = useDispatch();
    const loggingIn = useSelector((state: State) => state.authentication.loggingIn);
    const loginFailed = useSelector((state: State) => state.authentication.loginFailed);
    const fieldDisabled = loggingIn;
    const buttonDisabled = fieldDisabled || identifier === "" || password === "";

    const onSubmit = (event: FormEvent) => {
        event.preventDefault();

        dispatch(login(identifier, password));
    };

    return <GlobalContainer>
        <GlobalHeader/>
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <LoginLogo/>
                {loginFailed ? <LoginError/> : null}
                <form className={styles.form} onSubmit={onSubmit}>
                    <LoginField label={"メールアドレス"}>
                        <TextField get={identifier} set={setIdentifier} disabled={fieldDisabled}/>
                    </LoginField>
                    <div className={styles.field}>
                        <PasswordField
                            label="パスワード"
                            value={password}
                            setValue={setPassword}
                        />
                        <div className={styles.resetGuide}>
                            <a href="/password/reset/request">パスワードを忘れた場合</a>
                        </div>
                    </div>
                    <div className={styles.row}/>
                    <LoginButton disabled={buttonDisabled}>ログイン</LoginButton>
                </form>
                <p className={styles.hint}>
                    ログインができない場合やユーザー名またはメールアドレスをお忘れの場合は、<a
                        href={"mailto:info_milmoplan@welmo.co.jp"}>info_milmoplan@welmo.co.jp</a> または <a
                        href={"tel:+81362057308"}>03-6205-7308</a> (受付時間 平⽇10:00～18:00) までお気軽にお問い合わせください。
                </p>
            </div>
        </div>
    </GlobalContainer>;
}
