import { CarePlanTableTwoPeriod } from "../CarePlanTableTwoPeriod";
import { InsuranceBenefit } from "./InsuranceBenefit";

const SupportServiceTypeType = Symbol();

// サービス種別
export class SupportServiceType {
    [SupportServiceTypeType]: any;

    id: string;

    code: string | null;

    text: string | null;

    insuranceBenefit: InsuranceBenefit | null;

    establishment: string | null;

    frequency: string | null;

    period: CarePlanTableTwoPeriod | null;

    constructor(
        id: string,
        code: string | null,
        text: string | null,
        insuranceBenefit: InsuranceBenefit | null,
        establishment: string | null,
        frequency: string | null,
        period: CarePlanTableTwoPeriod | null,
    ) {
        this.id = id;
        this.code = code;
        this.text = text;
        this.insuranceBenefit = insuranceBenefit;
        this.establishment = establishment;
        this.frequency = frequency;
        this.period = period;
    }
}
