import React, { ReactNode } from "react";
import styles from "./FieldContainer.module.scss";
import { styleForWidth, Width } from "../element/Width";

export function FieldContainer(props: FieldContainerProps) {
    const { label, width, children } = props;
    const containerStyle = styleForWidth(width ?? "auto");
    return <label style={containerStyle}>
        <span className={styles.label}>{label}</span>
        {children}
    </label>;
}

export type FieldContainerProps = {
    label: string,
    width?: Width,
    children?: ReactNode,
};
