import React, { useState } from "react";
import styles from "./OfficeList.module.scss";
import { PrimaryButton } from "../../components/button/PrimaryButton";
import OfficeListItem from "./OfficeListItem";
import { Modal } from "../../components/Modal";
import OfficeForm from "./OfficeForm";
import useOffices from "../../../hooks/admin/office/useOffices";

export default function OfficeList() {

    const { offices, retrieveOffices } = useOffices();

    const [openRegisterDialog, setOpenRegisterDialog] = useState(false);

    const onRequestClose = async () => setOpenRegisterDialog(false);

    return <>
        <div className={ styles.registerButtonArea }>
            <PrimaryButton onClick={ () => setOpenRegisterDialog(true) }>事業所新規登録</PrimaryButton>
            <Modal open={ openRegisterDialog } onRequestClose={ onRequestClose }>
                <OfficeForm type={ "register" } onRequestClose={ onRequestClose } refreshOffices={retrieveOffices}/>
            </Modal>
        </div>
        <ul>
            { offices && offices.map((office) => {
                return <OfficeListItem office={ office } key={ office.id } refreshOffices={ retrieveOffices }/>
            }) }
        </ul>
    </>
}
