type KeyValueMap<T> = { [key: string]: T };

type Params = KeyValueMap<string | string[]>;

type Headers = KeyValueMap<string>;

const DEFAULT_OPTIONS: RequestInit = {
    mode: "same-origin",
    credentials: "same-origin",
};

export function queryString(params: Params = {}): string {
    const urlSearchParams = new URLSearchParams();
    Object.entries(params).forEach(pair => {
        const [name, value] = pair;
        if (typeof value === "string") {
            urlSearchParams.append(name, value);
        } else {
            value.forEach(v => urlSearchParams.append(name, v));
        }
    });
    return urlSearchParams.toString();
}

export function postOptions(body: any | null = null, headers: Headers = {}): RequestInit {
    return options(body, headers, "POST");
}

function putOptions(body: any | null = null, headers: Headers = {}): RequestInit {
    return options(body, headers, "PUT");
}

function options(body: any, headers: Headers, method: string) {
    const options = { ...DEFAULT_OPTIONS, method: method };
    if (body === null) {
        return {
            ...options,
            headers: headers,
            body: null,
        }
    } else {
        return {
            ...options,
            headers: {
                "Content-Type": "application/json",// eslint-disable-line
                ...headers
            },
            body: JSON.stringify(body),
        }
    }
}

async function request(path: string, init?: RequestInit): Promise<Response> {
    const response = await fetch(path, init);
    if (response.status === 401) {
        window.location.href = "/login"
    }
    return response
}

export async function get(path: string): Promise<Response> {
    const options = { ...DEFAULT_OPTIONS, method: "GET" };
    return await request(path, options)
}

export async function post(path: string, body: any, headers: Headers = {}): Promise<Response> {
    const options = postOptions(body, headers);
    return await request(path, options)
}

export async function put(path: string, body: any, headers: Headers = {}): Promise<Response> {
    const options = putOptions(body, headers);
    return await request(path, options)
}

export async function del(path: string): Promise<Response> {
    const options = { ...DEFAULT_OPTIONS, method: "DELETE" };
    return await request(path, options)
}

export async function verify():  Promise<Response> {
    const options = { ...DEFAULT_OPTIONS, method: "GET" };
    return await fetch("/api/authentication/verify", options);
}
