import React from "react";
import styles from "./TableThreeBody.module.scss";
import classNames from "classnames";
import { TableThreeWeeklyService } from "./TableThreeWeeklyService";
import { TableThreeNonWeeklyService } from "./TableThreeNonWeeklyService";

export function TableThreeBody(props: Props) {
    const { projectId, activated, refleshTableThree } = props;

    return (
        <div className={styles.container}>
            <div className={styles.tableHeader}>
                <div className={styles.time}></div>
                <div className={styles.groupYoubi}>
                    <span className={styles.content}>日</span>
                    <span className={styles.content}>月</span>
                    <span className={styles.content}>火</span>
                    <span className={styles.content}>水</span>
                    <span className={styles.content}>木</span>
                    <span className={styles.content}>金</span>
                    <span className={styles.content}>土</span>
                </div>
                <div className={classNames(styles.activity, styles.activityContent)}>主な日常生活上の活動</div>
            </div>
            <div className={styles.tableBody}>
                <TableThreeWeeklyService projectId={projectId} activated={activated} refleshTableThree={refleshTableThree} />
            </div>
            <div>
                <TableThreeNonWeeklyService projectId={projectId} activated={activated} refleshTableThree={refleshTableThree} />
            </div>
        </div>
    );
}

type Props = {
    projectId: string;
    activated: boolean;
    refleshTableThree: () => void;
};
