import React from "react";
import { FieldContainer } from "../../../components/field/FieldContainer";
import { TextField, TextFieldProps } from "../../../components/field/TextField";

export function DisplayNameTextField(props: TextFieldProps) {
    const { get, set } = props;

    return (
        <FieldContainer label="表示名">
            <TextField
                get={get}
                set={set}
                required={true}
                maxLength={20}
            >
            </TextField>
        </FieldContainer>
    );
}
