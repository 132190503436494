import { OfficesResponse } from "./OfficesResponse";
import { ApiError } from "../../ApiError";
import { del, get, post } from "../../functions";
import { Office } from "../../../../domain/office/Office";

export const AdminOfficeApi = new class {

    async offices(): Promise<Office[]> {
        const response = await get("api/admin/offices");
        return response.json();
    }

    async getList(): Promise<OfficesResponse | ApiError> {
        const response = await get("api/admin/office/getList");
        return (response.ok ? OfficesResponse.extract : ApiError.extract)(response);
    }

    async register(
        displayName: string,
        loginName: string,
        corporationId: string,
        address: string
    ): Promise<void | ApiError> {
        const body = { displayName, loginName, address, corporationId }
        const response = await post("api/admin/office/register", body);
        if (!response.ok) {
            return ApiError.extract(response);
        }
    }

    async delete(
        officeId: string
    ): Promise<void | ApiError> {
        const response = await del(`api/admin/office/delete/${officeId}` );
        if (!response.ok) {
            return ApiError.extract(response);
        }
    }

    async update(
        officeId: string,
        displayName: string,
        loginName: string,
        address: string,
        corporationId: string
    ): Promise<void | ApiError> {
        const body = {
            officeId,
            displayName,
            loginName,
            address,
            corporationId
        };
        const response = await post("api/admin/office/update", body);
        if (!response.ok) {
            return ApiError.extract(response);
        }
    }
}();
