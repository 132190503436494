import React, { ChangeEvent, FocusEvent, MouseEvent, ReactNode, useEffect, useState, InputHTMLAttributes } from "react";
import styles from "./TableThreeNonWeeklyServiceInput.module.scss";

export function TableThreeNonWeeklyServiceInput(props: InputHTMLAttributes<any> & Props) {
    const { initialText, onUpdate, activated, suggestionDialog, onChange: givenOnChange, onFocus: givenOnFocus, onBlur: givenOnBlur, onClick: givenOnClick, width, popup, ...rest } = props;

    const [text, setText] = useState(initialText ?? "");
    const [openSuggestionDialog, setOpenSuggestionDialog] = useState(false);

    const fieldStyle = { width: width ?? "auto" };

    useEffect(() => {
        setText(initialText ?? "");
    }, [initialText]);

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        givenOnChange?.(event);
        setText(event.target.value);
    };

    const onFocus = (event: FocusEvent<HTMLInputElement>) => {
        givenOnFocus?.(event);
        setOpenSuggestionDialog(true);
    };

    const onBlur = (event: FocusEvent<HTMLInputElement>) => {
        givenOnBlur?.(event);
        const currentText = text === "" ? null : text;
        if (currentText !== initialText) onUpdate?.(currentText);
        setOpenSuggestionDialog(false);
    };

    const onClick = (event: MouseEvent<HTMLInputElement>) => {
        givenOnClick?.(event);
        setOpenSuggestionDialog(true);
    };

    const onSelect = (selectedText: string) => {
        // 入力テキストの後ろに選択内容を追加
        onUpdate?.(text + selectedText);
        setOpenSuggestionDialog(false);
    };

    return (
        <span className={styles.field} style={fieldStyle}>
            <label className={styles.label}>
                <span className={styles.focus}>
                    <input disabled={!activated} className={styles.input} value={text} onChange={onChange} onFocus={onFocus} onBlur={onBlur} onClick={onClick} {...rest} />
                </span>
                {popup && initialText && <div className={styles.popup}>{initialText}</div>}
            </label>
            {suggestionDialog?.(openSuggestionDialog, onSelect)}
        </span>
    );
}

type Props = {
    initialText: string | undefined;
    onUpdate?: ((value: string | null) => void) | null;
    activated: boolean;
    suggestionDialog?: (open: boolean, onSelect: (text: string) => void) => ReactNode;
    width?: string;
    popup?: boolean;
};
