import React, { RefObject, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../../../state/store";
import styles from "./TableTwoDiseaseKnowledge.module.scss";
import { DiseaseKnowledgeButton } from "../../components/button/DiseaseKnowledgeButton";
import { get } from "../../../../state/usecase/assessmentsheet/action";
import { DiseaseKnowledgeApi } from "../../../../state/api/diseaseknowledge/DiseaseKnowledgeApi";
import { Lookup } from "../../../../state/api/diseaseknowledge/Lookup";
import { Disease } from "../../../../domain/diseaseknowledge/Disease";
import { DiseaseKnowledgeDialog } from "../../diseaseknowledge/DiseaseKnowledgeDialog";
import { DiseaseKnowledgeDialogMode } from "../../components/MedicalKnowledgeButton";

export function TableTwoDiseaseKnowledge(props: Props) {
    const { projectId, globalHeaderRef, projectHeaderRef } = props;
    const dispatch = useDispatch();
    const { assessmentSheet } = useSelector((state: State) => state.assessmentSheet.slots[projectId]) ?? { assessmentSheet: null };
    const [extractedDiseases, setExtractedDiseases] = useState<Disease[]>([]);
    const [dialogMode, setDialogMode] = useState<DiseaseKnowledgeDialogMode>(null);

    const onClick = (diseaseId: string) => () => {
        setDialogMode({ diseaseId: diseaseId });
        // stacking context の影響でモーダルがうまく開かないのでヘッダーのz-indexを一時的になくす
        globalHeaderRef.current!.style!.zIndex = "0";
        projectHeaderRef.current!.style!.zIndex = "0";
    };

    const onClose = () => {
        setDialogMode(null);
        // root.scssでも使用しています!
        globalHeaderRef.current!.style!.zIndex = "150";
        projectHeaderRef.current!.style!.zIndex = "100";
    };

    useEffect(() => {
        if (assessmentSheet) return;
        dispatch(get(projectId));
    }, [dispatch, projectId, assessmentSheet]);

    useEffect(() => {
        const diseaseNames = assessmentSheet?.content.honninNoKenkoJotaiJushinNadoNoJokyo.genzaiNoJushinJokyo.map((genzaiNoJushinJokyo: any) => genzaiNoJushinJokyo.byomei)
        if (!diseaseNames) return;
        let mounted = true;
        DiseaseKnowledgeApi.lookup(diseaseNames)
            .then(res => {
                if (res instanceof Lookup && mounted) {
                    setExtractedDiseases(res.extractedDiseases);
                }
            })
        return () => {
            mounted = false;
        }
    }, [assessmentSheet]);

    return <>
        <div className={styles.container}>
            <label className={styles.title}>関連医療知識</label>
            {extractedDiseases.map((extractedDisease, index) => {
                return <DiseaseKnowledgeButton key={index} onClick={onClick(extractedDisease.id)} label={extractedDisease.name}/>;
            })}
        </div>
        <DiseaseKnowledgeDialog
            dialogMode={dialogMode}
            setDialogMode={setDialogMode}
            onClose={onClose} />
    </>;
}

type Props = {
    projectId: string,
    globalHeaderRef: RefObject<HTMLDivElement>,
    projectHeaderRef: RefObject<HTMLDivElement>,
};
