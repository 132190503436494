import { User } from "../../../domain/user/User";

export class Verify {
    type: "SUCCESS" = "SUCCESS";

    user: User;

    constructor(user: User) {
        this.user = user;
    }

    static async extract(response: Response): Promise<Verify> {
        const json = await response.json();
        return new Verify(json.user);
    }
}
