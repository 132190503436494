import { Dispatch } from "react";
import { GetActions, UpdateItemActions } from "./type";
import { TableCommonApi } from "../../api/careplan/tablecommon/TableCommonApi";
import { TableCommonPath } from "../../../domain/careplan/tablecommon/TableCommonPath";
import { ChangeLog } from "../../../domain/changelog/ChangeLog";

export const get = (projectId: string) => async (dispatch: Dispatch<GetActions>) => {
    dispatch({
        type: "TABLE_COMMON_GET",
        payload: {},
    });
    const result = await TableCommonApi.get(projectId);
    switch (result.type) {
        case "SUCCESS":
            dispatch({
                type: "TABLE_COMMON_GET_SUCCESS",
                payload: {
                    projectId: result.projectId,
                    tableCommon: result.tableCommon,
                    latestChangeLog: result.latestChangeLog,
                },
            })
            break;
        case "ERROR":
            dispatch({
                type: "TABLE_COMMON_GET_FAILURE",
                payload: {},
            })
            break;
    }
};

export const updateItem = (
    projectId: string,
    path: TableCommonPath,
    value: any,
    originalValue: any,
    onSuccess: (changeLog: ChangeLog) => void,
    onFailure: () => void,
) => async (dispatch: Dispatch<UpdateItemActions>) => {
    dispatch({
        type: "TABLE_COMMON_UPDATE_ITEM",
        payload: {
            projectId,
            path,
            value,
        },
    });
    const result = await TableCommonApi.updateItem(projectId, path, value);
    switch (result.type) {
        case "SUCCESS":
            dispatch({
                type: "TABLE_COMMON_UPDATE_ITEM_SUCCESS",
                payload: {
                    projectId,
                    changeLog: result.changeLog,
                },
            });
            onSuccess(result.changeLog);
            break;
        case "ERROR":
            dispatch({
                type: "TABLE_COMMON_UPDATE_ITEM_FAILURE",
                payload: {
                    projectId,
                    path,
                    value: originalValue,
                },
            });
            onFailure();
            break;
    }
};
