import { AssessmentSheetPath } from "./AssessmentSheetPath";

const assessmentSheetType = Symbol();

export class AssessmentSheet {
    [assessmentSheetType]: any;

    content: any; // とりあえず

    constructor(content: any) {
        this.content = content;
    }

    getValue<T>(path: AssessmentSheetPath): T {
        const { parent, property } = path.find(this.content);
        return parent[property];
    }

    updateValue<T>(path: AssessmentSheetPath, value: T): AssessmentSheet {
        const { parent, property } = path.find(this.content);
        // 本来非破壊的にするべきだが、TypeScriptでLensのような仕組み導入するのに心が折れてしまったので、もう破壊しちゃう
        parent[property] = value;
        return this;
    }
}
