export type Width = "intrinsic" | "auto" | number;

export function styleForWidth(width: Width) {
    switch (width) {
        case "intrinsic":
            return {};
        case "auto":
            return { display: "inline-block", width: "100%" };
        default:
            return { display: "inline-block", width: `${width}px` };
    }
}
