import { TableOne } from "../../../../domain/careplan/tableone/TableOne";
import { ChangeLog } from "../../../../domain/changelog/ChangeLog";
import { changeLog } from "../../changelog/LatestChangeLog";

export class Get {
    type: "SUCCESS" = "SUCCESS";

    projectId: string;

    tableOne: TableOne;

    latestChangeLog: ChangeLog | null;

    constructor(projectId: string, tableOne: TableOne, latestChangeLog: ChangeLog | null) {
        this.projectId = projectId;
        this.tableOne = tableOne;
        this.latestChangeLog = latestChangeLog;
    }

    static path = "/api/tableOne/get";

    static async extract(response: Response): Promise<Get> {
        const json = await response.json();
        const tableOne = new TableOne(json.tableOne);
        const latestChangeLog = json.latestChangeLog ? changeLog(json.latestChangeLog) : null;
        return new Get(json.project.id, tableOne, latestChangeLog);
    }
}
