import React, { ChangeEvent, FocusEvent, KeyboardEvent, MouseEvent, useEffect, useState, InputHTMLAttributes, useRef } from "react";
import styles from "./TableThreeMainActivitiesInput.module.scss";
import { styleForWidth, Width } from "../../components/element/Width";
import { MainActivities } from "../../../../domain/careplan/tablethree/mainactivities/MainActivities";

export function TableThreeMainActivitiesInput(props: InputHTMLAttributes<any> & Props) {
    const { initialText, onUpdate, activated, onChange: givenOnChange, onFocus: givenOnFocus, onBlur: givenOnBlur, onClick: givenOnClick, width, time, mainActivity, ...rest } = props;

    const [text, setText] = useState(initialText ?? "");

    const inputRef = useRef<HTMLInputElement>(null);

    const fieldStyle = styleForWidth(width ?? "auto");

    useEffect(() => {
        setText(initialText ?? "");
    }, [initialText]);

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        givenOnChange?.(event);
        setText(event.target.value);
    };

    const onFocus = (event: FocusEvent<HTMLInputElement>) => {
        givenOnFocus?.(event);
    };

    const onBlur = (event: FocusEvent<HTMLInputElement>) => {
        givenOnBlur?.(event);
        const currentText = text === "" ? null : text;
        if (currentText !== initialText) onUpdate?.(time, currentText ?? "", mainActivity);
    };

    const onEnter = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            inputRef.current?.blur();
        }
    };

    const onClick = (event: MouseEvent<HTMLInputElement>) => {
        givenOnClick?.(event);
    };

    return (
        <div className={styles.container}>
            <span className={styles.field} style={fieldStyle}>
                <label>
                    <span className={styles.focus}>
                        <input
                            disabled={!activated}
                            ref={inputRef}
                            className={styles.input}
                            value={text}
                            onChange={onChange}
                            onFocus={onFocus}
                            onKeyDown={onEnter}
                            onBlur={onBlur}
                            onClick={onClick}
                            {...rest}
                        />
                    </span>
                </label>
            </span>
        </div>
    );
}

type Props = {
    initialText: string | null;
    onUpdate?: (time: number, value: string, mainActivity: MainActivities | undefined) => void;
    activated: boolean;
    width?: Width;
    time: number;
    mainActivity: MainActivities | undefined;
};
