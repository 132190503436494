import { Reducer } from "redux";
import { AssessmentSheetActions } from "./type";
import { AssessmentSheet } from "../../../domain/assessmentsheet/AssessmentSheet";
import { ChangeLog } from "../../../domain/changelog/ChangeLog";

type Slot = {
    assessmentSheet: AssessmentSheet,
    latestChangeLog: ChangeLog | null,
};

export type AssessmentSheetState = Readonly<{
    slots: { [key: string]: Slot },
    getting: boolean,
}>;

const initialState: AssessmentSheetState = {
    slots: {},
    getting: false,
};

export const assessmentSheetReducer: Reducer<AssessmentSheetState, AssessmentSheetActions> = (state= initialState, action): AssessmentSheetState => {
    switch (action.type) {
        case "ASSESSMENT_SHEET_GET":
            return {
                ...state,
                getting: true,
            };
        case "ASSESSMENT_SHEET_GET_SUCCESS":
            return {
                ...state,
                slots: {
                    ...state.slots,
                    [action.payload.projectId]: {
                        assessmentSheet: action.payload.assessmentSheet,
                        latestChangeLog: action.payload.latestChangeLog,
                    },
                },
                getting: false,
            };
        case "ASSESSMENT_SHEET_GET_FAILURE":
            return {
                ...state,
                getting: false,
            };
        case "ASSESSMENT_SHEET_UPDATE_ITEM":
            return updateSlot(state, action.payload.projectId, slot => {
                return {
                    assessmentSheet: slot.assessmentSheet.updateValue(action.payload.path, action.payload.value),
                    latestChangeLog: slot.latestChangeLog,
                };
            });
        case "ASSESSMENT_SHEET_UPDATE_ITEM_SUCCESS":
            return updateSlot(state, action.payload.projectId, slot => {
                return {
                    assessmentSheet: slot.assessmentSheet,
                    latestChangeLog: action.payload.changeLog,
                };
            });
        case "ASSESSMENT_SHEET_UPDATE_ITEM_FAILURE":
            return updateSlot(state, action.payload.projectId, slot => {
                return {
                    assessmentSheet: slot.assessmentSheet.updateValue(action.payload.path, action.payload.value),
                    latestChangeLog: slot.latestChangeLog,
                };
            });
        default:
            return state;
    }
};

function updateSlot(
    state: AssessmentSheetState,
    projectId: string,
    update: (slot: Slot) => Slot
): AssessmentSheetState {
    const slot = state.slots[projectId];
    if (!slot) return state;
    return {
        ...state,
        slots: {
            ...state.slots,
            [projectId]: update(slot),
        }
    };
}
