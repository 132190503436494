import "whatwg-fetch";
import { GetCarePlanTableTwoUrl } from "./GetCarePlanTableTwoUrl";
import { GetRsLoginStatus } from "./GetRsLoginStatus";
import { Logout } from "../authentication/Logout";
import { ApiError } from "../ApiError";
import { get, post } from "../functions";

const DEFAULT_OPTIONS: RequestInit = {
    mode: "same-origin",
    credentials: "same-origin",
};

export const EntryPointApi = new class {
    async getCarePlanTableTwoUrl(projectId: string, token: string): Promise<GetCarePlanTableTwoUrl | ApiError> {
        await post(Logout.path, {});
        const response = await getCredential(projectId, token);
        return (response.ok ? GetCarePlanTableTwoUrl.extract : ApiError.extract)(response)
    }

    async getRsLoginStatus(): Promise<GetRsLoginStatus | ApiError> {
        const response = await get(GetRsLoginStatus.path);
        return (response.ok ? GetRsLoginStatus.extract : ApiError.extract)(response)
    }
}()

async function getCredential(projectId: string, token: string): Promise<Response> {
    const headers =  {
        "Authorization": "Bearer " + token,
    }
    const options = { ...DEFAULT_OPTIONS, method: "GET", headers: { ...headers } };
    return await fetch(GetCarePlanTableTwoUrl.path + "/" + projectId, options)
}