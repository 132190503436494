import { SupportServiceType } from "../../../../../domain/careplan/tabletwo/supportservicetype/SupportServiceType";
import { supportServiceType } from "./SupportServiceTypeObject";
import { ChangeLog } from "../../../../../domain/changelog/ChangeLog";
import { changeLog } from "../../../changelog/LatestChangeLog";

export class EditedSupportServiceType {
    supportServiceType: SupportServiceType;

    changeLog: ChangeLog;

    constructor(supportServiceType: SupportServiceType, changeLog: ChangeLog) {
        this.supportServiceType = supportServiceType;
        this.changeLog = changeLog;
    }

    static async extract(response: Response): Promise<EditedSupportServiceType> {
        const json = await response.json();
        return new EditedSupportServiceType(supportServiceType(json.supportServiceType), changeLog(json.changeLog));
    }
}
