import { CarePlanTableTwo } from "../../../../domain/careplan/tabletwo/CarePlanTableTwo";
import { carePlanTableTwo } from "./CarePlanTableTwoObject";
import { ChangeLog } from "../../../../domain/changelog/ChangeLog";
import { changeLog } from "../../changelog/LatestChangeLog";
import { Need } from "../../../../domain/careplan/tabletwo/need/Need";
import { SubItems } from "../../../../domain/careplan/tabletwo/SubItems";
import { need } from "./need/NeedObject";

export class RegisterTableTwo {
    carePlanTableTwo: CarePlanTableTwo;

    addedNeeds: SubItems<Need>;

    changeLog: ChangeLog;

    constructor(carePlanTableTwo: CarePlanTableTwo, addedNeeds: SubItems<Need>, changeLog: ChangeLog) {
        this.carePlanTableTwo = carePlanTableTwo;
        this.addedNeeds = addedNeeds;
        this.changeLog = changeLog;
    }

    static async extract(response: Response): Promise<RegisterTableTwo> {
        const json = await response.json();
        return new RegisterTableTwo(carePlanTableTwo(json.carePlanTableTwo), new SubItems(json.addedNeeds.map(need)), changeLog(json.changeLog));
    }
}
