import { Need } from "../../../../../domain/careplan/tabletwo/need/Need";
import { need } from "./NeedObject";
import { ChangeLog } from "../../../../../domain/changelog/ChangeLog";
import { changeLog } from "../../../changelog/LatestChangeLog";

export class EditedNeed {
    need: Need;

    changeLog: ChangeLog;

    constructor(need: Need, changeLog: ChangeLog) {
        this.need = need;
        this.changeLog = changeLog;
    }

    static async extract(response: Response): Promise<EditedNeed> {
        const json = await response.json();
        return new EditedNeed(need(json.need), changeLog(json.changeLog));
    }
}
