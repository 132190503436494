import React, { MouseEvent, ReactNode, RefObject, useCallback, useEffect, useRef, useState } from "react";
import styles from "./OtherInfo.module.scss";
import classNames from "classnames";
import { EstablishmentDetailSection, establishmentDetailSectionId } from "./EstablishmentDetailSection";
import { EstablishmentDetailIndex } from "./EstablishmentDetailIndex";
import { EstablishmentDetail } from "../../../../../domain/establishment/EstablishmentDetail";

export function OtherInfo(props: Props) {
    const { establishmentDetail, dialogContainerRef, jigyoshoInfoRef, selectEstablishment } = props;
    const { jigyoshoJoho, eigyojikan, shokuji, nyuyoku, staff, ryokinKasan, recreation, rehabilitation } = establishmentDetail;

    const [activeSection, setActiveSection] = useState<EstablishmentDetailSection | null>(EstablishmentDetailSection.RINEN_TOKUCHO);
    const rinenTokuchoSectionRef = useRef<HTMLHeadingElement>(null);
    const eigyojikanSectionRef = useRef<HTMLHeadingElement>(null);
    const shokujiSectionRef = useRef<HTMLHeadingElement>(null);
    const nyuuyokuSectionRef = useRef<HTMLHeadingElement>(null);
    const staffSectionRef = useRef<HTMLHeadingElement>(null);
    const ryokinSectionRef = useRef<HTMLHeadingElement>(null);
    const recreationSectionRef = useRef<HTMLHeadingElement>(null);
    const rehabilitationSectionRef = useRef<HTMLHeadingElement>(null);
    const sectionRefs = [
        { section: EstablishmentDetailSection.RINEN_TOKUCHO, value: rinenTokuchoSectionRef },
        { section: EstablishmentDetailSection.EIGYO_JIKAN, value: eigyojikanSectionRef },
        { section: EstablishmentDetailSection.SHOKUJI, value: shokujiSectionRef },
        { section: EstablishmentDetailSection.NYUUYOKU, value: nyuuyokuSectionRef },
        { section: EstablishmentDetailSection.STAFF, value: staffSectionRef },
        { section: EstablishmentDetailSection.RYOUKIN, value: ryokinSectionRef },
        { section: EstablishmentDetailSection.RECREATION, value: recreationSectionRef },
        { section: EstablishmentDetailSection.REHABILITATION, value: rehabilitationSectionRef },
    ];

    const indexRef = useRef<HTMLDivElement>(null);
    const onScroll = useCallback(() => {
        const dialogScrollHeight = dialogContainerRef.current?.scrollHeight ?? 0;
        const dialogScrollTop = dialogContainerRef.current?.scrollTop ?? 0;
        const dialogOffsetHeight = dialogContainerRef.current?.offsetHeight ?? 0;
        const dialogScrollBottom = dialogScrollTop + dialogOffsetHeight;
        if (sectionRefs.every((sectionRef) => sectionRef.value.current)) {
            const sectionOffsetBottoms = sectionRefs.map((sectionRef) => {
                const { section, value: ref } = sectionRef;
                const offsetTop = ref.current!.offsetTop;
                const offsetHeight = ref.current!.offsetHeight;
                const offsetBottom = offsetTop + offsetHeight;
                return { section, offsetTop, offsetBottom };
            });
            sectionOffsetBottoms.forEach((sectionOffsetBottom) => {
                if (dialogScrollTop < dialogScrollHeight / 2) {
                    if (dialogScrollTop > sectionOffsetBottom.offsetTop) setActiveSection(sectionOffsetBottom.section);
                } else {
                    if (dialogScrollBottom > sectionOffsetBottom.offsetBottom) setActiveSection(sectionOffsetBottom.section);
                }
            });
        }
        if (indexRef.current) {
            const jigyoshoInfoHeight = jigyoshoInfoRef.current?.clientHeight ?? 0;
            const rinenTokuchoSectionHeight = rinenTokuchoSectionRef.current?.clientHeight ?? 0;
            stickyIndexIf(dialogScrollTop, jigyoshoInfoHeight, rinenTokuchoSectionHeight, indexRef.current);
        }
    }, [sectionRefs, dialogContainerRef, jigyoshoInfoRef, indexRef]);

    useEffect(() => {
        const current = dialogContainerRef.current;
        current?.addEventListener("scroll", onScroll);
        return () => {
            current?.removeEventListener("scroll", onScroll);
        };
    }, [dialogContainerRef, onScroll]);

    const renderTableContent = (
        record: {
            title: ReactNode;
            value: any;
            suffix?: string;
            bold?: boolean;
        }[]
    ) => {
        return (
            <div className={styles.tableContent}>
                {record.map((obj, key) => {
                    const { title, value, suffix, bold } = obj;
                    const titleStyle = classNames(styles.title, { [styles.boldTitle]: bold });
                    return (
                        <div className={styles.row} key={key}>
                            <div className={titleStyle}>{title}</div>
                            <div className={styles.content}>{value ? value + (suffix ?? "") : ""}</div>
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <div className={styles.container}>
            <div className={styles.left}>
                <h3
                    className={styles.sectionTitle}
                    id={establishmentDetailSectionId(EstablishmentDetailSection.RINEN_TOKUCHO)}
                    ref={rinenTokuchoSectionRef}
                >
                    <span className={styles.sectionTitleContent}>理念・特徴</span>
                </h3>
                <div className={styles.rinen}>{jigyoshoJoho.tokuchorinen}</div>
                <h3
                    className={styles.sectionTitle}
                    id={establishmentDetailSectionId(EstablishmentDetailSection.EIGYO_JIKAN)}
                    ref={eigyojikanSectionRef}
                >
                    <span className={styles.sectionTitleContent}>営業時間</span>
                </h3>
                {renderTableContent([
                    { title: "平日", value: eigyojikan?.eigyojikanHeijitsu },
                    { title: "土曜", value: eigyojikan?.eigyojikanDoyo },
                    { title: "日曜", value: eigyojikan?.eigyojikanNichiyo },
                    { title: "祝日", value: eigyojikan?.eigyojikanShukujitsu },
                    { title: "定休日", value: eigyojikan?.eigyojikanTeikyubi },
                    { title: "サービス提供時間", value: eigyojikan?.servicesTeikyoJikanList?.join("、") },
                    { title: "サービス提供地域", value: jigyoshoJoho.serviceTeikyochiiki },
                    { title: "備考", value: eigyojikan?.eigyojikanBiko },
                ])}
                <h3 className={styles.sectionTitle} id={establishmentDetailSectionId(EstablishmentDetailSection.SHOKUJI)} ref={shokujiSectionRef}>
                    <span className={styles.sectionTitleContent}>食事</span>
                </h3>
                {renderTableContent([
                    { title: "食事提供時間", value: shokuji?.shokujiTeikyoJikanList?.join("、") },
                    { title: "調理場所", value: shokuji?.choribasho },
                    { title: "備考(料金)", value: shokuji?.shokujiBiko },
                ])}
                <h3 className={styles.sectionTitle} id={establishmentDetailSectionId(EstablishmentDetailSection.NYUUYOKU)} ref={nyuuyokuSectionRef}>
                    <span className={styles.sectionTitleContent}>入浴</span>
                </h3>
                {renderTableContent([
                    { title: "浴室の数", value: nyuyoku?.yokushitsunokazu },
                    { title: "浴槽タイプ", value: nyuyoku?.yokusoTypes?.join("、") },
                    { title: "同性介助", value: nyuyoku?.doseikaijo },
                    { title: "その他施設等", value: nyuyoku?.sonotasetsubi },
                ])}
                <h3 className={styles.sectionTitle} id={establishmentDetailSectionId(EstablishmentDetailSection.STAFF)} ref={staffSectionRef}>
                    <span className={styles.sectionTitleContent}>スタッフ</span>
                </h3>
                {renderTableContent([
                    { title: "職員数", value: "", bold: true },
                    { title: "生活相談員", value: staff?.seikatsusodanin, suffix: "人" },
                    { title: "看護職員", value: staff?.kangoshokuin, suffix: "人" },
                    { title: "介護職員", value: staff?.kaigoshokuin, suffix: "人" },
                    { title: "機能訓練指導員", value: staff?.kinokunrenshidoin, suffix: "人" },
                    { title: "その他職員", value: staff?.sonotaShokuinGokei, suffix: "人" },
                    { title: "職員一人当たりのご利用者数", value: staff?.shokuinWariai, suffix: "人" },
                    { title: "有資格者数", value: "", bold: true },
                    { title: "理学療法士", value: staff?.rigakuryohoshi, suffix: "人" },
                    { title: "作業療法士", value: staff?.sagyoryohoshi, suffix: "人" },
                    { title: "言語聴覚士", value: staff?.gengochokakushi, suffix: "人" },
                    { title: "柔道整復師", value: staff?.judoseifukushi, suffix: "人" },
                    {
                        title: (
                            <>
                                あん摩マッサージ
                                <br />
                                指圧師
                            </>
                        ),
                        value: staff?.ammaMassageShiatsushi,
                        suffix: "人",
                    },
                ])}
                <h3 className={styles.sectionTitle} id={establishmentDetailSectionId(EstablishmentDetailSection.RYOUKIN)} ref={ryokinSectionRef}>
                    <span className={styles.sectionTitleContent}>料金</span>
                </h3>
                {renderTableContent([
                    { title: "介護報酬加算", value: "", bold: true },
                    { title: "共通", value: ryokinKasan?.kaigohoshukasanKyotsuList?.join("、") },
                    { title: "要介護のみ", value: ryokinKasan?.kaigohoshukasanYokaigoList?.join("、") },
                ])}
                <h3
                    className={styles.sectionTitle}
                    id={establishmentDetailSectionId(EstablishmentDetailSection.RECREATION)}
                    ref={recreationSectionRef}
                >
                    <span className={styles.sectionTitleContent}>レクリエーション</span>
                </h3>
                {renderTableContent([
                    { title: "個別レク", value: recreation?.kobetsuReku },
                    { title: "集団レク", value: recreation?.shudanReku },
                    { title: "アピールしたいレク", value: recreation?.appealRecreations?.join("、") },
                    { title: "外部講師によるレク", value: recreation?.gaibuRecreations?.join("、") },
                ])}
                <h3
                    className={styles.sectionTitle}
                    id={establishmentDetailSectionId(EstablishmentDetailSection.REHABILITATION)}
                    ref={rehabilitationSectionRef}
                >
                    <span className={styles.sectionTitleContent}>リハビリ・機能訓練</span>
                </h3>
                {renderTableContent([
                    { title: "個別機能訓練", value: "", bold: true },
                    { title: "個別対応", value: rehabilitation?.kobetsutaio },
                    { title: "内容", value: rehabilitation?.kobetsuKinokunrenNaiyoList?.join("、") },
                    { title: "時間(分)", value: rehabilitation?.kobetsuKinokunrenJikan },
                    { title: "集団訓練", value: "", bold: true },
                    { title: "集団対応", value: rehabilitation?.shudantaio },
                    { title: "内容", value: rehabilitation?.shudanKunrenNaiyoList?.join("、") },
                    { title: "時間(分)", value: rehabilitation?.shudanKunrenJikan },
                    { title: "介護報酬加算", value: "", bold: true },
                    { title: "柔道整復師", value: staff?.judoseifukushi, suffix: "人" },
                    {
                        title: (
                            <>
                                あん摩マッサージ
                                <br />
                                指圧師
                            </>
                        ),
                        value: staff?.ammaMassageShiatsushi,
                        suffix: "人",
                    },
                ])}
            </div>
            <div className={styles.index}>
                <div ref={indexRef}>
                    <EstablishmentDetailIndex activeSection={activeSection} selectEstablishment={selectEstablishment} />
                </div>
                {/*
                 * indexをfixedにした際、要素サイズが無くなりcontentが広がってしまう。
                 * それを防ぐため、indexと同等のサイズのものを配置することによってcontentが広がるのを防ぐ
                 * もちろん目に見えてはいけないため非表示。
                 */}
                <div className={styles.hidden}>
                    <EstablishmentDetailIndex activeSection={EstablishmentDetailSection.RINEN_TOKUCHO} selectEstablishment={selectEstablishment} />
                </div>
            </div>
        </div>
    );
}

function stickyIndexIf(scrollTop: number, jigyoshoInfoHeight: number, rinenTokuchoSectionHeight: number, index: HTMLDivElement) {
    const threshold = jigyoshoInfoHeight + 100;
    if (scrollTop >= threshold) {
        index.style.position = "fixed";
        index.style.top = `${rinenTokuchoSectionHeight}px`;
    } else {
        index.style.position = "static";
        index.style.top = "0";
    }
}

type Props = {
    establishmentDetail: EstablishmentDetail;
    dialogContainerRef: RefObject<HTMLDivElement>;
    jigyoshoInfoRef: RefObject<HTMLDivElement>;
    selectEstablishment: (event: MouseEvent) => void;
};
