import React from "react";
import styles from "./Successful.module.scss";
import { Player } from "@lottiefiles/react-lottie-player";
import animationJson from "./successful.json";

export function Successful() {
    return <div className={styles.container}>
        <Player src={animationJson}
            lottieRef={animationItem => {
                animationItem.addEventListener("complete", () => {
                    animationItem.goToAndStop(animationItem.totalFrames - 1, true);
                })
            }}
            background={"transparent"}
            style={{ width: "20px", height: "20px" }}
            autoplay/>
    </div>;
}
