import React, { ReactNode } from "react";
import styles from "./Answer.module.scss"
import { BaseContent } from "../BaseContent";

export function Answer(props: Props) {
    const { children } = props;
    return <BaseContent>
        <div className={styles.textArea}>
            <div className={styles.prefix}>
                A
            </div>
            <div className={styles.answer}>
                { children }
            </div>
        </div>
    </BaseContent>

}

type Props = {
    children: ReactNode
}