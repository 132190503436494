import React, { FormEvent, useCallback, useEffect, useRef, useState } from "react";
import styles from "./CareRecipientListPage.module.scss";
import { GlobalContainer } from "../components/GlobalContainer";
import { GlobalHeader } from "../components/GlobalHeader";
import { PrimaryButton } from "../components/button/PrimaryButton";
import { TextField } from "../components/field/TextField";
import { NewIcon } from "../components/icon/NewIcon";
import { Modal } from "../components/Modal";
import { SearchButton } from "../components/button/SearchButton";
import { CareRecipientListScopeByResponsibleUserField } from "./components/CareRecipientListScopeByResponsibleUserField";
import { CareRecipientListScopeByActivatedField } from "./components/CareRecipientListScopeByActivatedField";
import { CareRecipientListHeader } from "./components/CareRecipientListHeader";
import { CareRecipientList } from "./components/CareRecipientList";
import { CareRecipientNoList } from "./components/CareRecipientNoList";
import { CareRecipientFilteredList } from "./components/CareRecipientFilteredList";
import { CareRecipientRegistrationDialog } from "./components/CareRecipientRegistrationDialog";
import { CareRecipientApi } from "../../../state/api/carerecipient/CareRecipientApi";
import { GetList } from "../../../state/api/carerecipient/GetList";
import { CareRecipientRow } from "../../../domain/carerecipient/CareRecipientRow";
import NewNotificationBox from "../notification/NewNotificationBox";

export function CareRecipientListPage() {
    const [openCareRecipientRegistrationDialog, setOpenCareRecipientRegistrationDialog] = useState(false);
    const [keyword, setKeyword] = useState<string>("");
    const [scopeByResponsibleUser, setScopeByResponsibleUser] = useState<boolean>(true);
    const [scopeByActivated, setScopeByActivated] = useState<boolean>(true);
    const [careRecipients, setCareRecipients] = useState<CareRecipientRow[]>([]);
    const [onFetching, setOnFetching] = useState<boolean>(false);
    const containerRef = useRef(null);

    const retrieveCareRecipients = useCallback(() => {
        setOnFetching(true)
        CareRecipientApi.getList(scopeByResponsibleUser, scopeByActivated)
            .then(res => {
                setOnFetching(false)
                if (res instanceof GetList) {
                    setCareRecipients(res.careRecipients)
                }
            })
    }, [scopeByResponsibleUser, scopeByActivated])

    useEffect(() => {
        retrieveCareRecipients()
    }, [retrieveCareRecipients]);

    const toggleScopeByResponsibleUser = (value: boolean) => {
        setScopeByResponsibleUser(!value)
    }

    const toggleScopeByActivated = (value: boolean) => {
        setScopeByActivated(!value)
    }

    const onSubmit = (event: FormEvent) => event.preventDefault();

    const onClickRegistrationButton = () => {
        setOpenCareRecipientRegistrationDialog(true);
    };

    const onRequestCloseCareRecipientRegistrationDialog = () => {
        setOpenCareRecipientRegistrationDialog(false);
    };

    const renderList = () => {
        if (!careRecipients) return null;
        if (keyword) {
            const onClearKeyword = () => setKeyword("");
            return <CareRecipientFilteredList careRecipients={careRecipients} keyword={keyword} onClearKeyword={onClearKeyword} refresh={retrieveCareRecipients}/>;
        } else if (careRecipients.length) {
            return <CareRecipientList careRecipients={careRecipients} refresh={retrieveCareRecipients}/>;
        } else {
            if (onFetching) return null;
            return <CareRecipientNoList>
                <PrimaryButton width={256} onClick={onClickRegistrationButton}>新規利用者登録</PrimaryButton>
            </CareRecipientNoList>;
        }
    };

    return <>
        <GlobalContainer>
            <GlobalHeader/>
            <div className={styles.container} ref={containerRef}>
                <NewNotificationBox containerRef={containerRef}/>
                <h2 className={styles.title}>利用者一覧</h2>
                <div className={styles.toolbar}>
                    <form className={styles.form} onSubmit={onSubmit}>
                        <TextField get={keyword}
                            set={setKeyword}
                            placeholder={"利用者検索"}
                            width={280}
                            trailing={<SearchButton/>}/>
                        <CareRecipientListScopeByResponsibleUserField get={!scopeByResponsibleUser} set={toggleScopeByResponsibleUser} />
                        <CareRecipientListScopeByActivatedField get={!scopeByActivated} set={toggleScopeByActivated} />
                    </form>
                    <PrimaryButton onClick={onClickRegistrationButton} leading={<NewIcon/>}>新規利用者登録</PrimaryButton>
                    <Modal open={openCareRecipientRegistrationDialog} onRequestClose={onRequestCloseCareRecipientRegistrationDialog}>
                        <CareRecipientRegistrationDialog onRequestClose={onRequestCloseCareRecipientRegistrationDialog}/>
                    </Modal>
                </div>
                <CareRecipientListHeader/>
                {renderList()}
            </div>
        </GlobalContainer>
    </>;
}
