import React from "react";

type Props = {
    checked: boolean;
};

export function CheckIcon(props: Props) {
    const { checked } = props;

    return checked
        ?
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M11.797 5.7621C12.0703 6.03547 12.0703 6.47869 11.797 6.75205L7.97428 10.5747C7.70091 10.8481 7.25769 10.8481 6.98433 10.5747L4.49497 8.08539C4.22161 7.81202 4.22161 7.3688 4.49497 7.09544L4.7621 6.82831C5.03547 6.55494 5.47869 6.55494 5.75205 6.82831L6.98433 8.06058C7.25769 8.33395 7.70091 8.33395 7.97428 8.06058L10.5399 5.49497C10.8132 5.22161 11.2565 5.22161 11.5298 5.49497L11.797 5.7621Z" fill="#007EFC"/>
        </svg>
        :
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M11.797 5.7621C12.0703 6.03547 12.0703 6.47869 11.797 6.75205L7.97428 10.5747C7.70091 10.8481 7.25769 10.8481 6.98433 10.5747L4.49497 8.08539C4.22161 7.81202 4.22161 7.3688 4.49497 7.09544L4.7621 6.82831C5.03547 6.55494 5.47869 6.55494 5.75205 6.82831L6.98433 8.06058C7.25769 8.33395 7.70091 8.33395 7.97428 8.06058L10.5399 5.49497C10.8132 5.22161 11.2565 5.22161 11.5298 5.49497L11.797 5.7621Z" fill="#7B7C88"/>
        </svg>
}
