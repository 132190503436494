import React, { ReactNode, useEffect, useRef, useState } from "react";
import styles from "./AutoSavedIndicator.module.scss";
import { CSSTransition } from "react-transition-group";
import { Successful } from "../../assessmentsheet/components/sections/Successful";


export enum AutoSavedIndicatorStatus {
    INITIAL,
    VISIBLE,
    HIDDEN,
}

export function AutoSavedIndicator(props: Props) {
    const { status } = props;

    const [start, setStart] = useState(false);
    const transitionRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (status === AutoSavedIndicatorStatus.VISIBLE) {
            setStart(true);
        }
    }, [status]);

    return <CSSTransition in={start} timeout={0} nodeRef={transitionRef}>
        {() => {
            return <div className={styles.container} ref={transitionRef}>
                {renderStatus(status)}
            </div>;
        }}
    </CSSTransition>;
}

type Props = {
    status: AutoSavedIndicatorStatus,
};

function renderStatus(status: AutoSavedIndicatorStatus): ReactNode {
    switch (status) {
        case AutoSavedIndicatorStatus.INITIAL:
            return null;
        case AutoSavedIndicatorStatus.VISIBLE:
            return <>
                <Successful/>
                <span className={styles.text}>自動保存済みです</span>
            </>;
        case AutoSavedIndicatorStatus.HIDDEN:
            return null;
    }
}
