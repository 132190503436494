import React from "react";
import { GlobalContainer } from "../../components/GlobalContainer";
import { GlobalHeader } from "../../components/GlobalHeader";
import { DisplayNameChangeCard } from "./component/DisplayNameChangeCard";
import styles from "./DisplayNameChangePage.module.scss"

export function DisplayNameChangePage() {
    return (
        <GlobalContainer>
            <GlobalHeader />
            <div className={styles.container}><DisplayNameChangeCard /></div>
        </GlobalContainer>
    );
}
