import React, { useEffect, useState } from "react";
import styles from "./NotificationDetailPage.module.scss";
import { Link } from "react-router-dom";
import { Notification } from "../../../domain/notification/Notification";
import { useHistory, useParams } from "react-router";
import { Paths } from "../../../Paths";
import dayjs from "dayjs";
import { get, post } from "../../../state/api/functions";
import { GlobalContainer } from "../components/GlobalContainer";
import { GlobalHeader } from "../components/GlobalHeader";
import { Loading } from "../components/loading/Loading";
import { SecondaryButton } from "../components/button/SecondaryButton";
import useMounted from "../../hooks/common/useMounted";

export default function NotificationDetailPage() {

    const { notificationId } = useParams<PathParams>();

    const history = useHistory();

    const [notification, setNotification] = useState<Notification | null>(null);

    const mounted = useMounted();

    const onClick = () => {
        history.push(Paths.root);
    }

    useEffect(() => {
        if (!notification) return;
        const body = { notificationId: notification.id };
        // 詳細画面に来た時点でお知らせを既読にする
        post("/api/notificationRead", body).then(_ => {
            return;
        })
    }, [notification])

    useEffect(() => {
        get(`/api/notification/${notificationId}`).then(
            async res => {
                if (!mounted) return;
                const json = await res.json();
                setNotification(json);
            }
        )
    }, [notificationId, mounted])

    const render = () => {
        if (!notification) {
            return <Loading/>
        }
        return <>
            <div>
                <span className={styles.link}><Link to={Paths.root}>トップページ</Link></span>
                <span className={styles.delimiter}>&gt;</span>
                <span className={styles.link}><Link to={Paths.notifications}>お知らせ一覧</Link></span>
                <span className={styles.delimiter}>&gt;</span>
                <span className={styles.normalLink}>{notification.title}</span>
            </div>
            <div className={styles.contentContainer}>
                <div className={styles.title}>{notification.title}</div>
                <div className={styles.publishedDate}>{dayjs(notification.publishedDate).format("YYYY年MM月DD日")}</div>
                <div className={styles.content}>{notification.detail}</div>
            </div>
            <div className={styles.buttonArea}>
                <SecondaryButton width={256} onClick={onClick}>トップページに戻る</SecondaryButton>
            </div>
        </>
    }

    return <GlobalContainer>
        <GlobalHeader/>
        <div className={styles.container}>
            { render() }
        </div>
    </GlobalContainer>
}

type PathParams = {
    notificationId: string,
}
