import React from "react";
import styles from "./GlobalHeader.module.scss";
import { useSelector } from "react-redux";
import { State } from "../../../state/store";
import { AuthenticationStatus } from "../../../domain/authentication/AuthenticationStatus";
import { MedicalKnowledgeButton } from "./MedicalKnowledgeButton";
import { Link } from "react-router-dom";
import { Paths } from "../../../Paths";
import { AccountMenu } from "./AccountMenu";
import AdminPageButton from "../admin/AdminPageButton";

export function GlobalHeader(props: Props) {
    const authenticationStatus = useSelector((state: State) => state.authentication.status);
    const authenticated = authenticationStatus === AuthenticationStatus.AUTHENTICATED;
    var rsLoginIn = useSelector((state: State) => state.authentication.rsLoginIn);
    if (props.rsLoginView) {
        rsLoginIn = props.rsLoginView;
    }

    return <>
        <header className={styles.container}>
            { !rsLoginIn ?
            <Link to={Paths.root}>
                <img src={"/images/milmoplan_logo.svg"} width={"148"} height={"18.72"} alt={"ミルモぷらん"} className={styles.logo}/>
            </Link>
            :
            <img src={"/images/milmoplan_logo.svg"} width={"148"} height={"18.72"} alt={"ミルモぷらん"} className={styles.logo}/>
            }
            { authenticated ?
                <div className={styles.buttonArea}>
                    { !rsLoginIn && <AdminPageButton/>}
                    <MedicalKnowledgeButton/>
                    { !rsLoginIn && <AccountMenu/>}
                </div> : null }
        </header>
    </>;
}

type Props = {
    rsLoginView?: boolean,
};