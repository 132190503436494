import React, { useState } from "react";
import styles from "./CorporationList.module.scss";
import { PrimaryButton } from "../../components/button/PrimaryButton";
import CorporationListItem from "./CorporationListItem";
import { Modal } from "../../components/Modal";
import CorporationForm from "./CorporationForm";
import useCorporations from "../../../hooks/admin/corporations/useCorporations";

export default function CorporationList() {

    const { corporations, retrieveCorporations } = useCorporations();
    const [openCorporationRegister, setOpenCorporationRegister] = useState(false);

    const onRequestClose = async () => {
        setOpenCorporationRegister(false);
        retrieveCorporations()
    }

    return <div className={styles.container}>
        <div className={styles.registerButtonArea}>
            <PrimaryButton onClick={() => setOpenCorporationRegister(true)}>法人新規登録</PrimaryButton>
            <Modal open={openCorporationRegister} onRequestClose={onRequestClose}>
                <CorporationForm type={"register"} onRequestClose={onRequestClose} refreshCorporations={retrieveCorporations}/>
            </Modal>
        </div>
        <ul>
            {corporations && corporations.map(corporation => {
                return <CorporationListItem corporation={corporation} key={corporation.id} refreshCorporations={retrieveCorporations}/>
            })}
        </ul>
    </div>
}
