import React, { ChangeEvent, KeyboardEvent, ReactNode } from "react";
import { FieldProps } from "./AssessmentSheetUpdaterOf";
import { Label } from "./labels";
import styles from "./Section.module.scss";
import { TextField, TextFieldProps } from "../../../components/field/TextField";
import { MultilineTextField, MultilineTextFieldProps } from "../../../components/field/MultilineTextField";
import { PositiveNumberField, PositiveNumberFieldProps } from "../../../components/field/PositiveNumberField";
import { DateField, DateFieldProps } from "../../../components/field/DateField";
import { CheckboxField } from "../../../components/field/CheckboxField";
import { RadioField } from "../../../components/field/RadioField";
import { CalendarIcon } from "../../../components/icon/CalendarIcon";
import dayjs from "dayjs";

export function text(props: FieldProps<string | null>, textFieldProps: TextFieldProps = {}): ReactNode {
    const { value, update, disabled, readonly } = props;
    const set: Set = value => update(value === "" ? null : value);
    return <TextField get={value ?? ""}
        onBlur={setWith(set)}
        onKeyPress={setOnEnterWith(set)}
        disabled={disabled}
        readOnly={readonly}
        {...textFieldProps}/>;
}

export function textWith(textFieldProps: TextFieldProps = {}): (props: FieldProps<string | null>) => ReactNode {
    return props => text(props, textFieldProps);
}

export function label<T>(props: FieldProps<T>, values: Label<T>[] | null): ReactNode {
    const { value } = props;
    return values?.map(([val, label], index) => {
        if (value === val) {
            return <span key={index}>{label}</span>;
        }
    }) ?? <span>{value}</span>;
}

export function labelWith<T>(values: Label<T>[] | null): (props: FieldProps<T>) => ReactNode {
    return props => label(props, values);
}

export function dateLabelWith<T>(): (props: FieldProps<T>) => ReactNode {
    return props => dateLabel(props);
}

function dateLabel<T>(props: FieldProps<T>): ReactNode {
    const { value } = props;
    if (typeof value === "string") {
        return dayjs(value).toDate().toLocaleDateString("ja-JP-u-ca-japanese", { era: "narrow", year: "numeric", month: "long", day: "numeric" });
    }
    return <span>{value}</span>;
}

export function multilineText(props: FieldProps<string | null>, multilineTextFieldProps: MultilineTextFieldProps = {}): ReactNode {
    const { value, update, disabled, readonly } = props;
    const set: Set = value => update(value === "" ? null : value);
    return <MultilineTextField get={value ?? ""}
        onBlur={setWith(set)}
        onKeyPress={setOnShiftEnterWith(set)}
        disabled={disabled}
        readOnly={readonly}
        {...multilineTextFieldProps}/>;
}

export function multilineTextWith(multilineTextFieldProps: MultilineTextFieldProps = {}): (props: FieldProps<string | null>) => ReactNode {
    return props => multilineText(props, multilineTextFieldProps);
}

export function multilineLabel(props: FieldProps<string | null>, multilineTextFieldProps: MultilineTextFieldProps = {}): ReactNode {
    const { value } = props;
    return <span className={styles.multiField}>
        <span className={styles.focus}>
            <textarea disabled className={styles.input} defaultValue={value ?? ""} {...multilineTextFieldProps}/>
        </span>
    </span>;
}

export function multilineLabelWith(multilineTextFieldProps: MultilineTextFieldProps = {}): (props: FieldProps<string | null>) => ReactNode {
    return props => multilineLabel(props, multilineTextFieldProps);
}

export function positiveInteger(props: FieldProps<number | null>, positiveNumberFieldProps: PositiveNumberFieldProps = {}): ReactNode {
    const { value, update, disabled, readonly } = props;
    const set: Set = value => update(value === "" ? null : parseInt(value, 10));
    return <PositiveNumberField numberType={"integer"}
        get={value}
        onBlur={setWith(set)}
        onKeyPress={setOnEnterWith(set)}
        disabled={disabled}
        readOnly={readonly}
        {...positiveNumberFieldProps}/>;
}

export function positiveIntegerWith(positiveNumberFieldProps: PositiveNumberFieldProps = {}): (props: FieldProps<number | null>) => ReactNode {
    return props => positiveInteger(props, positiveNumberFieldProps);
}

export function positiveFloat(props: FieldProps<number | null>, positiveNumberFieldProps: PositiveNumberFieldProps = {}): ReactNode {
    const { value, update, disabled, readonly } = props;
    const set: Set = value => update(value === "" ? null : parseFloat(value));
    return <PositiveNumberField numberType={"float"}
        get={value}
        onBlur={setWith(set)}
        onKeyPress={setOnEnterWith(set)}
        disabled={disabled}
        readOnly={readonly}
        {...positiveNumberFieldProps}/>;
}

export function positiveFloatWith(positiveNumberFieldProps: PositiveNumberFieldProps = {}): (props: FieldProps<number | null>) => ReactNode {
    return props => positiveFloat(props, positiveNumberFieldProps);
}

export function date(props: FieldProps<string | null>, dateFieldProps: DateFieldProps = {}): ReactNode {
    const { value, update, disabled, readonly } = props;
    const set: Set = value => update(value === "" ? null : value);
    return <DateField get={value ?? ""}
        onBlur={setWith(set)}
        onKeyPress={setOnEnterWith(set)}
        disabled={disabled}
        readOnly={readonly}
        trailing={<CalendarIcon/>}
        onUpdate={update}
        {...dateFieldProps}/>;
}

export function dateWith(dateFieldProps: DateFieldProps = {}): (props: FieldProps<string | null>) => ReactNode {
    return props => date(props, dateFieldProps);
}

export function checkbox(props: FieldProps<boolean>, label?: ReactNode): ReactNode {
    const { value: get, update: set, disabled, readonly } = props;
    return <CheckboxField get={get ?? false} set={set} disabled={disabled} readOnly={readonly}>{label}</CheckboxField>;
}

export function checkboxWith(label?: ReactNode): (props: FieldProps<boolean>) => ReactNode {
    return props => checkbox(props, label);
}

export function radio<T>(props: FieldProps<T>, values: Label<T>[]): ReactNode {
    const { path: name, value: get, update: set, disabled, readonly } = props;
    return values.map(([value, label], index) => {
        return <RadioField key={index} for={value} name={name} get={get} set={set} disabled={disabled} readOnly={readonly}>{label}</RadioField>;
    });
}

export function radioWith<T>(values: Label<T>[]): (props: FieldProps<T>) => ReactNode {
    return props => radio(props, values);
}

type Set = (value: string) => void;

const setWith = (set: Set) => (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    set(event.target.value);
}

const setOnEnterWith = (set: Set) => (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
        set(event.currentTarget.value);
    }
};

const setOnShiftEnterWith = (set: Set) => (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && event.shiftKey) {
        set(event.currentTarget.value);
    }
};
