import { localDate, LocalDate } from "../../fundamental/LocalDate";

const CarePlanTableTwoPeriodType = Symbol();

export class CarePlanTableTwoPeriod {
    [CarePlanTableTwoPeriodType]: any;

    start: LocalDate;

    end: LocalDate;

    constructor(start: LocalDate, end: LocalDate) {
        this.start = start;
        this.end = end;
    }

    static from = (start: LocalDate, end: LocalDate): CarePlanTableTwoPeriod => new CarePlanTableTwoPeriod(
        start,
        end,
    );

    static fromStingOrNull = (
        start: string | null,
        end: string | null,
    ): CarePlanTableTwoPeriod | null => {
        if (start === null || end === null) return null;
        return new CarePlanTableTwoPeriod(
            localDate(start),
            localDate(end),
        );
    }
}
