import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styles from "./TableOneContent.module.scss";
import { TableOneUpdater } from "./TableOneUpdater";
import { TableOneField } from "./TableOneField";
import { TableOne } from "../../../../domain/careplan/tableone/TableOne";
import { textWith } from "../../assessmentsheet/components/sections/fields";
import { SanteiRiyu } from "./types";
import { santeiRiyuLabels } from "./labels";
import { SecondaryButton } from "../../components/button/SecondaryButton";
import { importItemByAssessmentSheet } from "../../../../state/usecase/tableone/action";
import { ImportIndicator, ImportIndicatorStatus } from "./ImportIndicator";

export function TableOneContent(props: Props) {

    const { projectId, tableOne, activated } = props;

    const [importIndicatorStatus, setImportIndicatorStatus] = useState(ImportIndicatorStatus.INITIAL);

    const dispatch = useDispatch();

    const tableOneUpdater = new TableOneUpdater(projectId, tableOne, activated);

    const tableOneField = new TableOneField(activated);

    const handleImportButtonClick = () => {
        const onSuccess = () => {
            setImportIndicatorStatus(ImportIndicatorStatus.VISIBLE);
            setTimeout(() => {
                setImportIndicatorStatus(ImportIndicatorStatus.HIDDEN);
            }, 2000);
        };
        const onFailure = () => {
            setImportIndicatorStatus(ImportIndicatorStatus.FAILED);
            setTimeout(() => {
                setImportIndicatorStatus(ImportIndicatorStatus.HIDDEN);
            }, 2000);
        };
        dispatch(importItemByAssessmentSheet(projectId, onSuccess, onFailure));
    }

    return <div className={styles.container}>
        <div className={styles.sheet}>
            <div className={styles.row}>
                <div className={styles.label}>
                    本人及び家族の生活に関する意向<br/>
                    (を踏まえた 課題分析の結果)<br/>
                    <span className={styles.button}>{activated && <SecondaryButton onClick={handleImportButtonClick}>アセスメントから取込</SecondaryButton>}</span>
                    <br/>
                    <ImportIndicator status={importIndicatorStatus}/>
                </div>
                <div className={styles.field}>
                    <div className={styles.iko}>
                    {tableOneUpdater.group(tableOneUpdater => {
                        return <div className={styles.ikoRow}>
                                    <div className={styles.ikolabel}>
                                        本人
                                        <div className={styles.saveIndicator}>{tableOneUpdater.saveIndicator}</div>
                                    </div>
                                    <div className={styles.ikoField}>
                                   {tableOneField.renderTextareaField(
                                        tableOneUpdater,
                                        "seikatsuIkoHonnin",
                                        { width: "auto", rows: 5, maxLength: 1000 }
                                    )}
                                    </div>
                                </div>
                    })}
                    {tableOneUpdater.group(tableOneUpdater => {
                        return <div className={styles.ikoRow}>
                                    <div className={styles.ikolabel}>
                                        家族
                                        <div className={styles.saveIndicator}>{tableOneUpdater.saveIndicator}</div>
                                    </div>
                                    <div className={styles.ikoField}>
                                   {tableOneField.renderTextareaField(
                                        tableOneUpdater,
                                        "seikatsuIkoKazoku",
                                        { width: "auto", rows: 5, maxLength: 1000 }
                                    )}
                                    </div>
                                </div>
                    })}
                    {tableOneUpdater.group(tableOneUpdater => {
                        return <div className={styles.ikoRow}>
                                    <div className={styles.ikolabel}>
                                        課題分析の結果
                                        <div className={styles.saveIndicator}>{tableOneUpdater.saveIndicator}</div>
                                    </div>
                                    <div className={styles.ikoField}>
                                   {tableOneField.renderDateField<string | null>(
                                        tableOneUpdater,
                                        "seikatsuIkoBunseki",
                                        textWith({ maxLength: 100 })
                                    )}
                                    </div>
                                </div>
                    })}
                    {tableOneUpdater.group(tableOneUpdater => {
                        return <div className={styles.ikoRow}>
                                    <div className={styles.ikolabel}>
                                        緊急連絡先
                                        <div className={styles.saveIndicator}>{tableOneUpdater.saveIndicator}</div>
                                    </div>
                                    <div className={styles.ikoField}>
                                   {tableOneField.renderDateField<string | null>(
                                        tableOneUpdater,
                                        "seikatsuIkoKinkyu",
                                        textWith({ maxLength: 100 })
                                    )}
                                    </div>
                                </div>
                    })}
                    {tableOneUpdater.group(tableOneUpdater => {
                        return <div className={styles.ikoRow}>
                                    <div className={styles.ikolabel}>
                                        主治医
                                        <div className={styles.saveIndicator}>{tableOneUpdater.saveIndicator}</div>
                                    </div>
                                    <div className={styles.ikoField}>
                                   {tableOneField.renderDateField<string | null>(
                                        tableOneUpdater,
                                        "seikatsuIkoShujii",
                                        textWith({ maxLength: 100 })
                                    )}
                                    </div>
                                </div>
                    })}
                    {tableOneUpdater.group(tableOneUpdater => {
                        return <div className={styles.ikoRow}>
                                    <div className={styles.ikolabel}>
                                        その他
                                        <div className={styles.saveIndicator}>{tableOneUpdater.saveIndicator}</div>
                                    </div>
                                    <div className={styles.ikoField}>
                                   {tableOneField.renderDateField<string | null>(
                                        tableOneUpdater,
                                        "seikatsuIkoSonota",
                                        textWith({ maxLength: 100 })
                                    )}
                                    </div>
                                </div>
                    })}
                    </div>
                </div>
            </div>
            {tableOneUpdater.group(tableOneUpdater => {
                return <div className={styles.row}>
                            <div className={styles.label}>
                                介護認定審査会の意見及び<br/>サービスの種類の指定
                                <div className={styles.saveIndicator}>{tableOneUpdater.saveIndicator}</div>
                            </div>
                            <div className={styles.field}>
                           {tableOneField.renderTextareaField(
                                tableOneUpdater,
                                "kaigoninteiShinsakai",
                                { width: "auto", rows: 5, maxLength: 1000 }
                            )}
                            </div>
                        </div>
            })}
            {tableOneUpdater.group(tableOneUpdater => {
                return <div className={styles.row}>
                            <div className={styles.label}>
                                総合的な援助の方針
                                <div className={styles.saveIndicator}>{tableOneUpdater.saveIndicator}</div>
                            </div>
                            <div className={styles.field}>
                           {tableOneField.renderTextareaField(
                                tableOneUpdater,
                                "hoshin",
                                { width: "auto", rows: 5, maxLength: 1000 }
                            )}
                            </div>
                        </div>
            })}
            {tableOneUpdater.group(tableOneUpdater => {
                return <div className={styles.row}>
                            <div className={styles.label}>
                                生活援助中心型の算定理由
                                <div className={styles.saveIndicator}>{tableOneUpdater.saveIndicator}</div>
                            </div>
                            <div className={styles.santeiRiyuField}>
                           {tableOneField.renderRadioField<SanteiRiyu | null>(
                                tableOneUpdater,
                                "santeiRiyu",
                                santeiRiyuLabels,
                                { radioPattern: "vertical" }
                            )}
                           {tableOneField.renderDateField<string | null>(
                                tableOneUpdater,
                                "santeiRiyuSonota",
                                textWith({ maxLength: 100 })
                            )}
                            </div>
                        </div>
            })}
        </div>
    </div>
}

type Props = {
    projectId: string,
    tableOne: TableOne,
    globalHeaderHeight: () => number,
    projectHeaderHeight: () => number,
    headerHeight: () => number,
    activated: boolean,
};