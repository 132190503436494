import React from "react";
import { FieldContainer } from "../../../components/field/FieldContainer";
import { TextField, TextFieldProps } from "../../../components/field/TextField";

export function NewEmailChangeTextField(props: TextFieldProps) {
    const { get, set } = props;

    return (
        <FieldContainer label="新しいメールアドレス">
            <TextField
                get={get}
                set={set}
                required={true}
                maxLength={100}
            />
        </FieldContainer>
    )
}
