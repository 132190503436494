import React from "react";
import styles from "./DisplayNameChangeCard.module.scss";
import { EnhancedDisplayNameChangeForm } from "./EnhancedDisplayNameChangeForm";


export function DisplayNameChangeCard() {
    return (
        <div className={styles.card}>
            <h2 className={styles.title}>表示名を変更する</h2>
            <div className={styles.row} />
            <EnhancedDisplayNameChangeForm />
        </div>
    );
}
