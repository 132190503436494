// THIS CODE IS GENERATED CODE. DO NOT EDIT.

import React from "react";
import styles from "./Section.module.scss";
import { AssessmentSheetUpdater } from "./AssessmentSheetUpdater";
import { AssessmentSheetField } from "./AssessmentSheetField";
import {
    SeinenKokenSeido,
    ShisetsuKubun,
    Umu,
} from "./types";
import {
    seinenKokenSeidoLabels,
    shisetsuKubunLabels,
    umuLabels,
} from "./labels";
import { checkboxWith, dateWith, positiveIntegerWith, textWith } from "./fields";

export function ServiceRiyoJokyoSection(props: Props) {

    const { assessmentSheetUpdater, activated } = props;
    const assessmentSheetField = new AssessmentSheetField(activated);

    return <>
        <div className={styles.card}>
            <h3 className={styles.title}>基準日</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        基準日
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "serviceRiyoJokyo.kijunBi",
                        dateWith({ width: 280 })
                    )}
                </div>;
            })}
        </div>
        <div className={styles.card}>
            <h3 className={styles.title}>在宅利用</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        訪問介護（ホームヘルプサービス）
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            <div className={styles.horizontal}>
                                {assessmentSheetUpdater.of<boolean>(
                                    "serviceRiyoJokyo.zaitakuRiyo.homonKaigo.riyo",
                                    checkboxWith()
                                )}
                            </div>
                            {assessmentSheetField.renderField<number | null>(
                                assessmentSheetUpdater,
                                "serviceRiyoJokyo.zaitakuRiyo.homonKaigo.kaisu",
                                positiveIntegerWith({ width: 120, leading: <span className={styles.modifier}>月</span>, trailing: <span className={styles.modifier}>回</span> }),
                                { tag: false, leading: <span className={styles.leading}>月</span>, trailing: <span className={styles.trailing}>回</span> }
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        （介護予防）訪問入浴介護
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            <div className={styles.horizontal}>
                                {assessmentSheetUpdater.of<boolean>(
                                    "serviceRiyoJokyo.zaitakuRiyo.homonNyuyokuKaigo.riyo",
                                    checkboxWith()
                                )}
                            </div>
                            {assessmentSheetField.renderField<number | null>(
                                assessmentSheetUpdater,
                                "serviceRiyoJokyo.zaitakuRiyo.homonNyuyokuKaigo.kaisu",
                                positiveIntegerWith({ width: 120, leading: <span className={styles.modifier}>月</span>, trailing: <span className={styles.modifier}>回</span> }),
                                { tag: false, leading: <span className={styles.leading}>月</span>, trailing: <span className={styles.trailing}>回</span> }
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        （介護予防）訪問看護
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            <div className={styles.horizontal}>
                                {assessmentSheetUpdater.of<boolean>(
                                    "serviceRiyoJokyo.zaitakuRiyo.homonKango.riyo",
                                    checkboxWith()
                                )}
                            </div>
                            {assessmentSheetField.renderField<number | null>(
                                assessmentSheetUpdater,
                                "serviceRiyoJokyo.zaitakuRiyo.homonKango.kaisu",
                                positiveIntegerWith({ width: 120, leading: <span className={styles.modifier}>月</span>, trailing: <span className={styles.modifier}>回</span> }),
                                { tag: false, leading: <span className={styles.leading}>月</span>, trailing: <span className={styles.trailing}>回</span> }
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        （介護予防）訪問リハビリテーション
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            <div className={styles.horizontal}>
                                {assessmentSheetUpdater.of<boolean>(
                                    "serviceRiyoJokyo.zaitakuRiyo.homonRehabilitation.riyo",
                                    checkboxWith()
                                )}
                            </div>
                            {assessmentSheetField.renderField<number | null>(
                                assessmentSheetUpdater,
                                "serviceRiyoJokyo.zaitakuRiyo.homonRehabilitation.kaisu",
                                positiveIntegerWith({ width: 120, leading: <span className={styles.modifier}>月</span>, trailing: <span className={styles.modifier}>回</span> }),
                                { tag: false, leading: <span className={styles.leading}>月</span>, trailing: <span className={styles.trailing}>回</span> },
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        （介護予防）居宅療養管理指導
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            <div className={styles.horizontal}>
                                {assessmentSheetUpdater.of<boolean>(
                                    "serviceRiyoJokyo.zaitakuRiyo.kyotakuRyoyoKanriShido.riyo",
                                    checkboxWith()
                                )}
                            </div>
                            {assessmentSheetField.renderField<number | null>(
                                assessmentSheetUpdater,
                                "serviceRiyoJokyo.zaitakuRiyo.kyotakuRyoyoKanriShido.kaisu",
                                positiveIntegerWith({ width: 120, leading: <span className={styles.modifier}>月</span>, trailing: <span className={styles.modifier}>回</span> }),
                                { tag: false, leading: <span className={styles.leading}>月</span>, trailing: <span className={styles.trailing}>回</span> }
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        通所介護（デイサービス）
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            <div className={styles.horizontal}>
                                {assessmentSheetUpdater.of<boolean>(
                                    "serviceRiyoJokyo.zaitakuRiyo.tsushoKaigo.riyo",
                                    checkboxWith()
                                )}
                            </div>
                            {assessmentSheetField.renderField<number | null>(
                                assessmentSheetUpdater,
                                "serviceRiyoJokyo.zaitakuRiyo.tsushoKaigo.kaisu",
                                positiveIntegerWith({ width: 120, leading: <span className={styles.modifier}>月</span>, trailing: <span className={styles.modifier}>回</span> }),
                                { tag: false, leading: <span className={styles.leading}>月</span>, trailing: <span className={styles.trailing}>回</span> }
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        （介護予防）通所リハビリテーション（デイケア）
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            <div className={styles.horizontal}>
                                {assessmentSheetUpdater.of<boolean>(
                                    "serviceRiyoJokyo.zaitakuRiyo.tsushoRehabilitation.riyo",
                                    checkboxWith()
                                )}
                            </div>
                            {assessmentSheetField.renderField<number | null>(
                                assessmentSheetUpdater,
                                "serviceRiyoJokyo.zaitakuRiyo.tsushoRehabilitation.kaisu",
                                positiveIntegerWith({ width: 120, leading: <span className={styles.modifier}>月</span>, trailing: <span className={styles.modifier}>回</span> }),
                                { tag: false, leading: <span className={styles.leading}>月</span>, trailing: <span className={styles.trailing}>回</span> }
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        （介護予防）短期入所生活介護（特養等）
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            <div className={styles.horizontal}>
                                {assessmentSheetUpdater.of<boolean>(
                                    "serviceRiyoJokyo.zaitakuRiyo.tankiNyushoSeikatsuKaigo.riyo",
                                    checkboxWith()
                                )}
                            </div>
                            {assessmentSheetField.renderField<number | null>(
                                assessmentSheetUpdater,
                                "serviceRiyoJokyo.zaitakuRiyo.tankiNyushoSeikatsuKaigo.nissu",
                                positiveIntegerWith({ width: 120, leading: <span className={styles.modifier}>月</span>, trailing: <span className={styles.modifier}>日</span> }),
                                { tag: false, leading: <span className={styles.leading}>月</span>, trailing: <span className={styles.trailing}>日</span> },
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        （介護予防）短期入所療養介護（老健・診療所）
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            <div className={styles.horizontal}>
                                {assessmentSheetUpdater.of<boolean>(
                                    "serviceRiyoJokyo.zaitakuRiyo.tankiNyushoRyoyoKaigo.riyo",
                                    checkboxWith()
                                )}
                            </div>
                            {assessmentSheetField.renderField<number | null>(
                                assessmentSheetUpdater,
                                "serviceRiyoJokyo.zaitakuRiyo.tankiNyushoRyoyoKaigo.nissu",
                                positiveIntegerWith({ width: 120, leading: <span className={styles.modifier}>月</span>, trailing: <span className={styles.modifier}>日</span> }),
                                { tag: false, leading: <span className={styles.leading}>月</span>, trailing: <span className={styles.trailing}>日</span> }
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        （介護予防）特定施設入居者生活介護
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            <div className={styles.horizontal}>
                                {assessmentSheetUpdater.of<boolean>(
                                    "serviceRiyoJokyo.zaitakuRiyo.tokuteiShisetsuNyukyoShaSeikatsuKaigo.riyo",
                                    checkboxWith()
                                )}
                            </div>
                            {assessmentSheetField.renderField<number | null>(
                                assessmentSheetUpdater,
                                "serviceRiyoJokyo.zaitakuRiyo.tokuteiShisetsuNyukyoShaSeikatsuKaigo.nissu",
                                positiveIntegerWith({ width: 120, leading: <span className={styles.modifier}>月</span>, trailing: <span className={styles.modifier}>日</span> }),
                                { tag: false, leading: <span className={styles.leading}>月</span>, trailing: <span className={styles.trailing}>日</span> }
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        看護小規模多機能型居宅介護
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            <div className={styles.horizontal}>
                                {assessmentSheetUpdater.of<boolean>(
                                    "serviceRiyoJokyo.zaitakuRiyo.kangoShokiboTaKinoGataKyotakuKaigo.riyo",
                                    checkboxWith()
                                )}
                            </div>
                            {assessmentSheetField.renderField<number | null>(
                                assessmentSheetUpdater,
                                "serviceRiyoJokyo.zaitakuRiyo.kangoShokiboTaKinoGataKyotakuKaigo.nissu",
                                positiveIntegerWith({ width: 120, leading: <span className={styles.modifier}>月</span>, trailing: <span className={styles.modifier}>日</span> }),
                                { tag: false, leading: <span className={styles.leading}>月</span>, trailing: <span className={styles.trailing}>日</span> }
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        （介護予防）福祉用具貸与
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            <div className={styles.horizontal}>
                                {assessmentSheetUpdater.of<boolean>(
                                    "serviceRiyoJokyo.zaitakuRiyo.fukushiYoguTaiyo.riyo",
                                    checkboxWith()
                                )}
                            </div>
                            {assessmentSheetField.renderField<number | null>(
                                assessmentSheetUpdater,
                                "serviceRiyoJokyo.zaitakuRiyo.fukushiYoguTaiyo.himmokusu",
                                positiveIntegerWith({ width: 120, trailing: <span className={styles.modifier}>品目</span> }),
                                { tag: false, trailing: <span className={styles.trailing}>品目</span> }
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        特定（介護予防）福祉用具販売
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            <div className={styles.horizontal}>
                                {assessmentSheetUpdater.of<boolean>(
                                    "serviceRiyoJokyo.zaitakuRiyo.tokuteiFukushiYoguHambai.riyo",
                                    checkboxWith()
                                )}
                            </div>
                            {assessmentSheetField.renderField<number | null>(
                                assessmentSheetUpdater,
                                "serviceRiyoJokyo.zaitakuRiyo.tokuteiFukushiYoguHambai.himmokusu",
                                positiveIntegerWith({ width: 120, trailing: <span className={styles.modifier}>品目</span> }),
                                { tag: false, trailing: <span className={styles.trailing}>品目</span> }
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        住宅改修
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            <div className={styles.horizontal}>
                                {assessmentSheetUpdater.of<boolean>(
                                    "serviceRiyoJokyo.zaitakuRiyo.jutakuKaishu.riyo",
                                    checkboxWith()
                                )}
                            </div>
                            {assessmentSheetField.renderRadioField<Umu | null>(
                                assessmentSheetUpdater,
                                "serviceRiyoJokyo.zaitakuRiyo.jutakuKaishu.umu",
                                umuLabels,
                                { tag: false }
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        夜間対応型訪問介護
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            <div className={styles.horizontal}>
                                {assessmentSheetUpdater.of<boolean>(
                                    "serviceRiyoJokyo.zaitakuRiyo.yakanTaioGataHomonKaigo.riyo",
                                    checkboxWith()
                                )}
                            </div>
                            {assessmentSheetField.renderField<number | null>(
                                assessmentSheetUpdater,
                                "serviceRiyoJokyo.zaitakuRiyo.yakanTaioGataHomonKaigo.nissu",
                                positiveIntegerWith({ width: 120, leading: <span className={styles.modifier}>月</span>, trailing: <span className={styles.modifier}>日</span> }),
                                { tag: false, leading: <span className={styles.leading}>月</span>, trailing: <span className={styles.trailing}>日</span> }
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        （介護予防）認知症対応型通所介護
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            <div className={styles.horizontal}>
                                {assessmentSheetUpdater.of<boolean>(
                                    "serviceRiyoJokyo.zaitakuRiyo.ninchishoTaioGataTsushoKaigo.riyo",
                                    checkboxWith()
                                )}
                            </div>
                            {assessmentSheetField.renderField<number | null>(
                                assessmentSheetUpdater,
                                "serviceRiyoJokyo.zaitakuRiyo.ninchishoTaioGataTsushoKaigo.nissu",
                                positiveIntegerWith({ width: 120, leading: <span className={styles.modifier}>月</span>, trailing: <span className={styles.modifier}>日</span> }),
                                { tag: false, leading: <span className={styles.leading}>月</span>, trailing: <span className={styles.trailing}>日</span> }
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        （介護予防）小規模多機能型居宅介護
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            <div className={styles.horizontal}>
                                {assessmentSheetUpdater.of<boolean>(
                                    "serviceRiyoJokyo.zaitakuRiyo.shokiboTaKinoGataKyotakuKaigo.riyo",
                                    checkboxWith()
                                )}
                            </div>
                            {assessmentSheetField.renderField<number | null>(
                                assessmentSheetUpdater,
                                "serviceRiyoJokyo.zaitakuRiyo.shokiboTaKinoGataKyotakuKaigo.nissu",
                                positiveIntegerWith({ width: 120, leading: <span className={styles.modifier}>月</span>, trailing: <span className={styles.modifier}>日</span> }),
                                { tag: false, leading: <span className={styles.leading}>月</span>, trailing: <span className={styles.trailing}>日</span> }
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        （介護予防） 認知症対応型共同生活介護
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            <div className={styles.horizontal}>
                                {assessmentSheetUpdater.of<boolean>(
                                    "serviceRiyoJokyo.zaitakuRiyo.ninchishoTaioGataKyodoSeikatsuKaigo.riyo",
                                    checkboxWith()
                                )}
                            </div>
                            {assessmentSheetField.renderField<number | null>(
                                assessmentSheetUpdater,
                                "serviceRiyoJokyo.zaitakuRiyo.ninchishoTaioGataKyodoSeikatsuKaigo.nissu",
                                positiveIntegerWith({ width: 120, leading: <span className={styles.modifier}>月</span>, trailing: <span className={styles.modifier}>日</span> }),
                                { tag: false, leading: <span className={styles.leading}>月</span>, trailing: <span className={styles.trailing}>日</span> }
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        定期巡回・随時対応型訪問介護看護
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            <div className={styles.horizontal}>
                                {assessmentSheetUpdater.of<boolean>(
                                    "serviceRiyoJokyo.zaitakuRiyo.teikiJunkaiZuijiTaioGataHomonKaigoKango.riyo",
                                    checkboxWith()
                                )}
                            </div>
                            {assessmentSheetField.renderField<number | null>(
                                assessmentSheetUpdater,
                                "serviceRiyoJokyo.zaitakuRiyo.teikiJunkaiZuijiTaioGataHomonKaigoKango.kaisu",
                                positiveIntegerWith({ width: 120, leading: <span className={styles.modifier}>月</span>, trailing: <span className={styles.modifier}>回</span> }),
                                { tag: false, leading: <span className={styles.leading}>月</span>, trailing: <span className={styles.trailing}>回</span> }
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        配食サービス
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            <div className={styles.horizontal}>
                                {assessmentSheetUpdater.of<boolean>(
                                    "serviceRiyoJokyo.zaitakuRiyo.haishokuService.riyo",
                                    checkboxWith()
                                )}
                            </div>
                            {assessmentSheetField.renderField<number | null>(
                                assessmentSheetUpdater,
                                "serviceRiyoJokyo.zaitakuRiyo.haishokuService.kaisu",
                                positiveIntegerWith({ width: 120, leading: <span className={styles.modifier}>月</span>, trailing: <span className={styles.modifier}>回</span> }),
                                { tag: false, leading: <span className={styles.leading}>月</span>, trailing: <span className={styles.trailing}>回</span> }
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        洗濯サービス
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            <div className={styles.horizontal}>
                                {assessmentSheetUpdater.of<boolean>(
                                    "serviceRiyoJokyo.zaitakuRiyo.sentakuService.riyo",
                                    checkboxWith()
                                )}
                            </div>
                            {assessmentSheetField.renderField<number | null>(
                                assessmentSheetUpdater,
                                "serviceRiyoJokyo.zaitakuRiyo.sentakuService.kaisu",
                                positiveIntegerWith({ width: 120, leading: <span className={styles.modifier}>月</span>, trailing: <span className={styles.modifier}>回</span> }),
                                { tag: false, leading: <span className={styles.leading}>月</span>, trailing: <span className={styles.trailing}>回</span> }
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        移動または外出支援
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            <div className={styles.horizontal}>
                                {assessmentSheetUpdater.of<boolean>(
                                    "serviceRiyoJokyo.zaitakuRiyo.idoMatahaGaishutsuShien.riyo",
                                    checkboxWith()
                                )}
                            </div>
                            {assessmentSheetField.renderField<number | null>(
                                assessmentSheetUpdater,
                                "serviceRiyoJokyo.zaitakuRiyo.idoMatahaGaishutsuShien.kaisu",
                                positiveIntegerWith({ width: 120, leading: <span className={styles.modifier}>月</span>, trailing: <span className={styles.modifier}>回</span> }),
                                { tag: false, leading: <span className={styles.leading}>月</span>, trailing: <span className={styles.trailing}>回</span> }
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        友愛訪問
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            <div className={styles.horizontal}>
                                {assessmentSheetUpdater.of<boolean>(
                                    "serviceRiyoJokyo.zaitakuRiyo.yuaiHomon.riyo",
                                    checkboxWith()
                                )}
                            </div>
                            {assessmentSheetField.renderField<number | null>(
                                assessmentSheetUpdater,
                                "serviceRiyoJokyo.zaitakuRiyo.yuaiHomon.kaisu",
                                positiveIntegerWith({ width: 120, leading: <span className={styles.modifier}>月</span>, trailing: <span className={styles.modifier}>回</span> }),
                                { tag: false, leading: <span className={styles.leading}>月</span>, trailing: <span className={styles.trailing}>回</span> }
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        老人福祉センター
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            <div className={styles.horizontal}>
                                {assessmentSheetUpdater.of<boolean>(
                                    "serviceRiyoJokyo.zaitakuRiyo.rojinFukushiCenter.riyo",
                                    checkboxWith()
                                )}
                            </div>
                            {assessmentSheetField.renderField<number | null>(
                                assessmentSheetUpdater,
                                "serviceRiyoJokyo.zaitakuRiyo.rojinFukushiCenter.kaisu",
                                positiveIntegerWith({ width: 120, leading: <span className={styles.modifier}>月</span>, trailing: <span className={styles.modifier}>回</span> }),
                                { tag: false, leading: <span className={styles.leading}>月</span>, trailing: <span className={styles.trailing}>回</span> }
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        老人憩いの家
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            <div className={styles.horizontal}>
                                {assessmentSheetUpdater.of<boolean>(
                                    "serviceRiyoJokyo.zaitakuRiyo.rojinIkoiNoIe.riyo",
                                    checkboxWith()
                                )}
                            </div>
                            {assessmentSheetField.renderField<number | null>(
                                assessmentSheetUpdater,
                                "serviceRiyoJokyo.zaitakuRiyo.rojinIkoiNoIe.kaisu",
                                positiveIntegerWith({ width: 120, leading: <span className={styles.modifier}>月</span>, trailing: <span className={styles.modifier}>回</span> }),
                                { tag: false, leading: <span className={styles.leading}>月</span>, trailing: <span className={styles.trailing}>回</span> }
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        ガイドヘルパー
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            <div className={styles.horizontal}>
                                {assessmentSheetUpdater.of<boolean>(
                                    "serviceRiyoJokyo.zaitakuRiyo.guideHelper.riyo",
                                    checkboxWith()
                                )}
                            </div>
                            {assessmentSheetField.renderField<number | null>(
                                assessmentSheetUpdater,
                                "serviceRiyoJokyo.zaitakuRiyo.guideHelper.kaisu",
                                positiveIntegerWith({ width: 120, leading: <span className={styles.modifier}>月</span>, trailing: <span className={styles.modifier}>回</span> }),
                                { tag: false, leading: <span className={styles.leading}>月</span>, trailing: <span className={styles.trailing}>回</span> }
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        身障/補装具・日常生活用具
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            <div className={styles.horizontal}>
                                {assessmentSheetUpdater.of<boolean>(
                                    "serviceRiyoJokyo.zaitakuRiyo.shinshoHosoguNichijoSeikatsuYogu.riyo",
                                    checkboxWith()
                                )}
                            </div>
                            {assessmentSheetField.renderField<string | null>(
                                assessmentSheetUpdater,
                                "serviceRiyoJokyo.zaitakuRiyo.shinshoHosoguNichijoSeikatsuYogu.naiyo",
                                textWith({ width: 280 }),
                                { tag: false }
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        生活支援員の訪問（日常生活自立支援事業）
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            <div className={styles.horizontal}>
                                {assessmentSheetUpdater.of<boolean>(
                                    "serviceRiyoJokyo.zaitakuRiyo.seikatsuShienInNoHomon.riyo",
                                    checkboxWith()
                                )}
                            </div>
                            {assessmentSheetField.renderField<number | null>(
                                assessmentSheetUpdater,
                                "serviceRiyoJokyo.zaitakuRiyo.seikatsuShienInNoHomon.kaisu",
                                positiveIntegerWith({ width: 120, leading: <span className={styles.modifier}>月</span>, trailing: <span className={styles.modifier}>回</span> }),
                                { tag: false, leading: <span className={styles.leading}>月</span>, trailing: <span className={styles.trailing}>回</span> }
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        ふれあい・いきいきサロン
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            <div className={styles.horizontal}>
                                {assessmentSheetUpdater.of<boolean>(
                                    "serviceRiyoJokyo.zaitakuRiyo.fureaiIkiikiSalon.riyo",
                                    checkboxWith()
                                )}
                            </div>
                            {assessmentSheetField.renderField<number | null>(
                                assessmentSheetUpdater,
                                "serviceRiyoJokyo.zaitakuRiyo.fureaiIkiikiSalon.kaisu",
                                positiveIntegerWith({ width: 120, leading: <span className={styles.modifier}>月</span>, trailing: <span className={styles.modifier}>回</span> }),
                                { tag: false, leading: <span className={styles.leading}>月</span>, trailing: <span className={styles.trailing}>回</span> }
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        市町村特別給付
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            <div className={styles.horizontal}>
                                {assessmentSheetUpdater.of<boolean>(
                                    "serviceRiyoJokyo.zaitakuRiyo.shichosonTokubetsuKyufu.riyo",
                                    checkboxWith()
                                )}
                            </div>
                            {assessmentSheetField.renderField<string | null>(
                                assessmentSheetUpdater,
                                "serviceRiyoJokyo.zaitakuRiyo.shichosonTokubetsuKyufu.naiyo",
                                textWith({ width: 280 }),
                                { tag: false },
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        その他1
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            <div className={styles.horizontal}>
                                {assessmentSheetUpdater.of<boolean>(
                                    "serviceRiyoJokyo.zaitakuRiyo.sonota[0].riyo",
                                    checkboxWith()
                                )}
                            </div>
                            {assessmentSheetField.renderField<string | null>(
                                assessmentSheetUpdater,
                                "serviceRiyoJokyo.zaitakuRiyo.sonota[0].naiyo",
                                textWith({ width: 280 }),
                                { tag: false },
                            )}
                            {assessmentSheetField.renderField<number | null>(
                                assessmentSheetUpdater,
                                "serviceRiyoJokyo.zaitakuRiyo.sonota[0].kaisu",
                                positiveIntegerWith({ width: 120, leading: <span className={styles.modifier}>月</span>, trailing: <span className={styles.modifier}>回</span> }),
                                { tag: false, leading: <span className={styles.leading}>月</span>, trailing: <span className={styles.trailing}>回</span> }
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        その他2
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            <div className={styles.horizontal}>
                                {assessmentSheetUpdater.of<boolean>(
                                    "serviceRiyoJokyo.zaitakuRiyo.sonota[1].riyo",
                                    checkboxWith()
                                )}
                            </div>
                            {assessmentSheetField.renderField<string | null>(
                                assessmentSheetUpdater,
                                "serviceRiyoJokyo.zaitakuRiyo.sonota[1].naiyo",
                                textWith({ width: 280 }),
                                { tag: false },
                            )}
                            {assessmentSheetField.renderField<number | null>(
                                assessmentSheetUpdater,
                                "serviceRiyoJokyo.zaitakuRiyo.sonota[1].kaisu",
                                positiveIntegerWith({ width: 120, leading: <span className={styles.modifier}>月</span>, trailing: <span className={styles.modifier}>回</span> }),
                                { tag: false, leading: <span className={styles.leading}>月</span>, trailing: <span className={styles.trailing}>回</span> }
                            )}
                        </div>
                    </div>
                </div>;
            })}
        </div>
        <div className={styles.card}>
            <h3 className={styles.title}>直近の入所・入院</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        施設区分
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<ShisetsuKubun | null>(
                        assessmentSheetUpdater,
                        "serviceRiyoJokyo.chokkinNoNyushoNyuin.shisetsuKubun",
                        shisetsuKubunLabels,
                        { radioPattern: "vertical" }
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        施設・機関名
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "serviceRiyoJokyo.chokkinNoNyushoNyuin.shisetsuKikanMei",
                        textWith({ width: 280 })
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        郵便番号
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "serviceRiyoJokyo.chokkinNoNyushoNyuin.yubinBango",
                        textWith({ width: 280 })
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        住所
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "serviceRiyoJokyo.chokkinNoNyushoNyuin.jusho",
                        textWith({ width: "auto" })
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        電話番号
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "serviceRiyoJokyo.chokkinNoNyushoNyuin.denwaBango",
                        textWith({ width: 280 })
                    )}
                </div>;
            })}
        </div>
        <div className={styles.card}>
            <h3 className={styles.title}>制度利用状況</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        老齢関係
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            <div className={styles.horizontal}>
                                {assessmentSheetUpdater.of<boolean>(
                                    "serviceRiyoJokyo.seidoRiyoJokyo.nenkin.roreiKankei.riyo",
                                    checkboxWith()
                                )}
                            </div>
                            {assessmentSheetField.renderField<string | null>(
                                assessmentSheetUpdater,
                                "serviceRiyoJokyo.seidoRiyoJokyo.nenkin.roreiKankei.naiyo",
                                textWith({ width: 280 }),
                                { tag: false },
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        障害関係
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            <div className={styles.horizontal}>
                                {assessmentSheetUpdater.of<boolean>(
                                    "serviceRiyoJokyo.seidoRiyoJokyo.nenkin.shogaiKankei.riyo",
                                    checkboxWith()
                                )}
                            </div>
                            {assessmentSheetField.renderField<string | null>(
                                assessmentSheetUpdater,
                                "serviceRiyoJokyo.seidoRiyoJokyo.nenkin.shogaiKankei.naiyo",
                                textWith({ width: 280 }),
                                { tag: false },
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        遺族・寡婦
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            <div className={styles.horizontal}>
                                {assessmentSheetUpdater.of<boolean>(
                                    "serviceRiyoJokyo.seidoRiyoJokyo.nenkin.izokuKafu.riyo",
                                    checkboxWith()
                                )}
                            </div>
                            {assessmentSheetField.renderField<string | null>(
                                assessmentSheetUpdater,
                                "serviceRiyoJokyo.seidoRiyoJokyo.nenkin.izokuKafu.naiyo",
                                textWith({ width: 280 }),
                                { tag: false },
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.vertical}>
                            {assessmentSheetUpdater.of<boolean>(
                                "serviceRiyoJokyo.seidoRiyoJokyo.onkyu",
                                checkboxWith("恩給")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "serviceRiyoJokyo.seidoRiyoJokyo.tokubetsuShogaiShaTeate",
                                checkboxWith("特別障害者手当")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "serviceRiyoJokyo.seidoRiyoJokyo.seikatsuHogo",
                                checkboxWith("生活保護")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "serviceRiyoJokyo.seidoRiyoJokyo.seikatsuFukushiShikinKashitsuke",
                                checkboxWith("生活福祉資金貸付")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "serviceRiyoJokyo.seidoRiyoJokyo.koreiShaJutakuSeibiShikinKashitsuke",
                                checkboxWith("高齢者住宅整備資金貸付")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "serviceRiyoJokyo.seidoRiyoJokyo.nichijoSeikatsuJiritsuShienJigyo",
                                checkboxWith("日常生活自立支援事業（地域福祉権利擁護事業）")
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        成年後見制度
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.vertical}>
                            <div className={styles.horizontal}>
                                {assessmentSheetUpdater.of<boolean>(
                                    "serviceRiyoJokyo.seidoRiyoJokyo.seinenKokenSeido.riyo",
                                    checkboxWith()
                                )}
                            </div>
                            {assessmentSheetField.renderRadioField<SeinenKokenSeido | null>(
                                assessmentSheetUpdater,
                                "serviceRiyoJokyo.seidoRiyoJokyo.seinenKokenSeido.seido",
                                seinenKokenSeidoLabels,
                                { tag: false },
                            )}
                            {assessmentSheetField.renderField<string | null>(
                                assessmentSheetUpdater,
                                "serviceRiyoJokyo.seidoRiyoJokyo.seinenKokenSeido.seinenKokenNinNado",
                                textWith({ width: 280 }),
                                { tag: false },
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        健康保険
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.vertical}>
                            {assessmentSheetUpdater.of<boolean>(
                                "serviceRiyoJokyo.seidoRiyoJokyo.kenkoHoken.kokuho",
                                checkboxWith("国保")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "serviceRiyoJokyo.seidoRiyoJokyo.kenkoHoken.kumiaiKempo",
                                checkboxWith("組合健保")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "serviceRiyoJokyo.seidoRiyoJokyo.kenkoHoken.kokkoKyosai",
                                checkboxWith("国公共済")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "serviceRiyoJokyo.seidoRiyoJokyo.kenkoHoken.shiritsuGakkoKyosai",
                                checkboxWith("私立学校共済")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "serviceRiyoJokyo.seidoRiyoJokyo.kenkoHoken.kokiKoreiShaIryo",
                                checkboxWith("後期高齢者医療")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "serviceRiyoJokyo.seidoRiyoJokyo.kenkoHoken.kyokaiKempo",
                                checkboxWith("協会けんぽ（旧・政管健保）")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "serviceRiyoJokyo.seidoRiyoJokyo.kenkoHoken.hiyatoi",
                                checkboxWith("日雇い")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "serviceRiyoJokyo.seidoRiyoJokyo.kenkoHoken.chihoKyosai",
                                checkboxWith("地方共済")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "serviceRiyoJokyo.seidoRiyoJokyo.kenkoHoken.senin",
                                checkboxWith("船員")
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        労災保険
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            <div className={styles.horizontal}>
                                {assessmentSheetUpdater.of<boolean>(
                                    "serviceRiyoJokyo.seidoRiyoJokyo.rosaiHoken.riyo",
                                    checkboxWith()
                                )}
                            </div>
                            {assessmentSheetField.renderField<string | null>(
                                assessmentSheetUpdater,
                                "serviceRiyoJokyo.seidoRiyoJokyo.rosaiHoken.naiyo",
                                textWith({ width: 280 }),
                                { tag: false }
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        その他1
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            <div className={styles.horizontal}>
                                {assessmentSheetUpdater.of<boolean>(
                                    "serviceRiyoJokyo.seidoRiyoJokyo.sonota[0].riyo",
                                    checkboxWith()
                                )}
                            </div>
                            {assessmentSheetField.renderField<string | null>(
                                assessmentSheetUpdater,
                                "serviceRiyoJokyo.seidoRiyoJokyo.sonota[0].naiyo",
                                textWith({ width: 280 }),
                                { tag: false }
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        その他2
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            <div className={styles.horizontal}>
                                {assessmentSheetUpdater.of<boolean>(
                                    "serviceRiyoJokyo.seidoRiyoJokyo.sonota[1].riyo",
                                    checkboxWith()
                                )}
                            </div>
                            {assessmentSheetField.renderField<string | null>(
                                assessmentSheetUpdater,
                                "serviceRiyoJokyo.seidoRiyoJokyo.sonota[1].naiyo",
                                textWith({ width: 280 }),
                                { tag: false }
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        その他3
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            <div className={styles.horizontal}>
                                {assessmentSheetUpdater.of<boolean>(
                                    "serviceRiyoJokyo.seidoRiyoJokyo.sonota[2].riyo",
                                    checkboxWith()
                                )}
                            </div>
                            {assessmentSheetField.renderField<string | null>(
                                assessmentSheetUpdater,
                                "serviceRiyoJokyo.seidoRiyoJokyo.sonota[2].naiyo",
                                textWith({ width: 280 }),
                                { tag: false }
                            )}
                        </div>
                    </div>
                </div>;
            })}
        </div>
    </>;
}

type Props = {
    assessmentSheetUpdater: AssessmentSheetUpdater,
    activated: boolean,
};
