// THIS CODE IS GENERATED CODE. DO NOT EDIT.

import React from "react";
import styles from "./Section.module.scss";
import { AssessmentSheetUpdater } from "./AssessmentSheetUpdater";
import { AssessmentSheetField } from "./AssessmentSheetField";
import {
    GenzaiNoEiyoJotai,
    IgakuTekiKanriNoHitsuyoSei,
    KansenshoNoUmu,
    KurumaisuNoShiyo,
    OkugaiHoko,
    ServiceRiyoNiyoruSeikatsuKinoNoIjiKaizenNoMitoshi,
    ShokujiKoi,
    TokuninashiAri,
} from "./types";
import {
    genzaiNoEiyoJotaiLabels,
    igakuTekiKanriNoHitsuyoSeiLabels,
    kansenshoNoUmuLabels,
    kurumaisuNoShiyoLabels,
    okugaiHokoLabels,
    serviceRiyoNiyoruSeikatsuKinoNoIjiKaizenNoMitoshiLabels,
    shokujiKoiLabels,
    tokuninashiAriLabels,
} from "./labels";
import { checkboxWith, textWith } from "./fields";

export function KaigoNikansuruIshiNoIkenSection(props: Props) {

    const { assessmentSheetUpdater, activated } = props;
    const assessmentSheetField = new AssessmentSheetField(activated);

    return <>
        <div className={styles.card}>
            <h3 className={styles.title}>移動</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        屋外歩行
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<OkugaiHoko | null>(
                        assessmentSheetUpdater,
                        "kaigoNikansuruIshiNoIken.ido.okugaiHoko",
                        okugaiHokoLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        車いすの使用
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<KurumaisuNoShiyo | null>(
                        assessmentSheetUpdater,
                        "kaigoNikansuruIshiNoIken.ido.kurumaisuNoShiyo",
                        kurumaisuNoShiyoLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        歩行補助具・装具の使用
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            {assessmentSheetUpdater.of<boolean>(
                                "kaigoNikansuruIshiNoIken.ido.hokoHojoGuSoguNoShiyo.mochiiTeINai",
                                checkboxWith("用いていない")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "kaigoNikansuruIshiNoIken.ido.hokoHojoGuSoguNoShiyo.okugaiDeShiyo",
                                checkboxWith("屋外で使用")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "kaigoNikansuruIshiNoIken.ido.hokoHojoGuSoguNoShiyo.okunaiDeShiyo",
                                checkboxWith("屋内で使用")
                            )}
                        </div>
                    </div>
                </div>;
            })}
        </div>
        <div className={styles.card}>
            <h3 className={styles.title}>栄養・食生活</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        食事行為
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<ShokujiKoi | null>(
                        assessmentSheetUpdater,
                        "kaigoNikansuruIshiNoIken.eiyoShokuseikatsu.shokujiKoi",
                        shokujiKoiLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        現在の栄養状態
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<GenzaiNoEiyoJotai | null>(
                        assessmentSheetUpdater,
                        "kaigoNikansuruIshiNoIken.eiyoShokuseikatsu.genzaiNoEiyoJotai",
                        genzaiNoEiyoJotaiLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        栄養・食生活上の留意点
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "kaigoNikansuruIshiNoIken.eiyoShokuseikatsu.eiyoShokuseikatsuJoNoRyuiTen",
                        textWith({ width: "auto" })
                    )}
                </div>;
            })}
        </div>
        <div className={styles.card}>
            <h3 className={styles.title}>現在あるかまたは今後発生の可能性の高い状態とその対処方針</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.vertical}>
                            <div className={styles.vertical}>
                                {assessmentSheetUpdater.of<boolean>(
                                    "kaigoNikansuruIshiNoIken.genzaiAruKaMatahaKongoHasseiNoKanoSeiNoTakaiJotaiToSonoTaishoHoshin.nyoshikkin",
                                    checkboxWith("尿失禁")
                                )}
                                {assessmentSheetUpdater.of<boolean>(
                                    "kaigoNikansuruIshiNoIken.genzaiAruKaMatahaKongoHasseiNoKanoSeiNoTakaiJotaiToSonoTaishoHoshin.tentoKossetsu",
                                    checkboxWith("転倒・骨折")
                                )}
                                {assessmentSheetUpdater.of<boolean>(
                                    "kaigoNikansuruIshiNoIken.genzaiAruKaMatahaKongoHasseiNoKanoSeiNoTakaiJotaiToSonoTaishoHoshin.idoNoryokuNoTeika",
                                    checkboxWith("移動能力の低下")
                                )}
                                {assessmentSheetUpdater.of<boolean>(
                                    "kaigoNikansuruIshiNoIken.genzaiAruKaMatahaKongoHasseiNoKanoSeiNoTakaiJotaiToSonoTaishoHoshin.jokuso",
                                    checkboxWith("褥瘡")
                                )}
                                {assessmentSheetUpdater.of<boolean>(
                                    "kaigoNikansuruIshiNoIken.genzaiAruKaMatahaKongoHasseiNoKanoSeiNoTakaiJotaiToSonoTaishoHoshin.shimpaiKinoNoTeika",
                                    checkboxWith("心肺機能の低下")
                                )}
                                {assessmentSheetUpdater.of<boolean>(
                                    "kaigoNikansuruIshiNoIken.genzaiAruKaMatahaKongoHasseiNoKanoSeiNoTakaiJotaiToSonoTaishoHoshin.tojikomori",
                                    checkboxWith("閉じこもり")
                                )}
                                {assessmentSheetUpdater.of<boolean>(
                                    "kaigoNikansuruIshiNoIken.genzaiAruKaMatahaKongoHasseiNoKanoSeiNoTakaiJotaiToSonoTaishoHoshin.iyokuTeika",
                                    checkboxWith("意欲低下")
                                )}
                                {assessmentSheetUpdater.of<boolean>(
                                    "kaigoNikansuruIshiNoIken.genzaiAruKaMatahaKongoHasseiNoKanoSeiNoTakaiJotaiToSonoTaishoHoshin.haikai",
                                    checkboxWith("徘徊")
                                )}
                                {assessmentSheetUpdater.of<boolean>(
                                    "kaigoNikansuruIshiNoIken.genzaiAruKaMatahaKongoHasseiNoKanoSeiNoTakaiJotaiToSonoTaishoHoshin.teiEiyo",
                                    checkboxWith("低栄養")
                                )}
                                {assessmentSheetUpdater.of<boolean>(
                                    "kaigoNikansuruIshiNoIken.genzaiAruKaMatahaKongoHasseiNoKanoSeiNoTakaiJotaiToSonoTaishoHoshin.sesshokuEngeKinoTeika",
                                    checkboxWith("摂食・嚥下機能低下")
                                )}
                                {assessmentSheetUpdater.of<boolean>(
                                    "kaigoNikansuruIshiNoIken.genzaiAruKaMatahaKongoHasseiNoKanoSeiNoTakaiJotaiToSonoTaishoHoshin.dassui",
                                    checkboxWith("脱水")
                                )}
                                {assessmentSheetUpdater.of<boolean>(
                                    "kaigoNikansuruIshiNoIken.genzaiAruKaMatahaKongoHasseiNoKanoSeiNoTakaiJotaiToSonoTaishoHoshin.ikansensei",
                                    checkboxWith("易感染性")
                                )}
                                {assessmentSheetUpdater.of<boolean>(
                                    "kaigoNikansuruIshiNoIken.genzaiAruKaMatahaKongoHasseiNoKanoSeiNoTakaiJotaiToSonoTaishoHoshin.ganNadoNiyoruTotsu",
                                    checkboxWith("がん等による疼痛")
                                )}
                                {assessmentSheetUpdater.of<boolean>(
                                    "kaigoNikansuruIshiNoIken.genzaiAruKaMatahaKongoHasseiNoKanoSeiNoTakaiJotaiToSonoTaishoHoshin.sonota",
                                    checkboxWith("その他")
                                )}
                            </div>
                            {assessmentSheetField.renderField<string | null>(
                                assessmentSheetUpdater,
                                "kaigoNikansuruIshiNoIken.genzaiAruKaMatahaKongoHasseiNoKanoSeiNoTakaiJotaiToSonoTaishoHoshin.sonotaNaiyo",
                                textWith({ width: 280 }),
                                { tag: false }
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        対処方針
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "kaigoNikansuruIshiNoIken.genzaiAruKaMatahaKongoHasseiNoKanoSeiNoTakaiJotaiToSonoTaishoHoshin.taishoHoshin",
                        textWith({ width: "auto" })
                    )}
                </div>;
            })}
        </div>
        <div className={styles.card}>
            <h3 className={styles.title}>サービス利用による生活機能の維持・改善の見通し</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        サービス利用による生活機能の維持・改善の見通し
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<ServiceRiyoNiyoruSeikatsuKinoNoIjiKaizenNoMitoshi | null>(
                        assessmentSheetUpdater,
                        "kaigoNikansuruIshiNoIken.serviceRiyoNiyoruSeikatsuKinoNoIjiKaizenNoMitoshi",
                        serviceRiyoNiyoruSeikatsuKinoNoIjiKaizenNoMitoshiLabels
                    )}
                </div>;
            })}
        </div>
        <div className={styles.card}>
            <h3 className={styles.title}>医学的管理の必要性</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        訪問診療
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<IgakuTekiKanriNoHitsuyoSei | null>(
                        assessmentSheetUpdater,
                        "kaigoNikansuruIshiNoIken.igakuTekiKanriNoHitsuyoSei.homonShinryo",
                        igakuTekiKanriNoHitsuyoSeiLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        訪問看護
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<IgakuTekiKanriNoHitsuyoSei | null>(
                        assessmentSheetUpdater,
                        "kaigoNikansuruIshiNoIken.igakuTekiKanriNoHitsuyoSei.homonKango",
                        igakuTekiKanriNoHitsuyoSeiLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        看護職員による相談・支援
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<IgakuTekiKanriNoHitsuyoSei | null>(
                        assessmentSheetUpdater,
                        "kaigoNikansuruIshiNoIken.igakuTekiKanriNoHitsuyoSei.kangoShokuinNiyoruSodanShien",
                        igakuTekiKanriNoHitsuyoSeiLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        訪問歯科診療
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<IgakuTekiKanriNoHitsuyoSei | null>(
                        assessmentSheetUpdater,
                        "kaigoNikansuruIshiNoIken.igakuTekiKanriNoHitsuyoSei.homonShikaShinryo",
                        igakuTekiKanriNoHitsuyoSeiLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        訪問薬剤管理指導
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<IgakuTekiKanriNoHitsuyoSei | null>(
                        assessmentSheetUpdater,
                        "kaigoNikansuruIshiNoIken.igakuTekiKanriNoHitsuyoSei.homonYakuzaiKanriShido",
                        igakuTekiKanriNoHitsuyoSeiLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        訪問リハビリテーション
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<IgakuTekiKanriNoHitsuyoSei | null>(
                        assessmentSheetUpdater,
                        "kaigoNikansuruIshiNoIken.igakuTekiKanriNoHitsuyoSei.homonRehabilitation",
                        igakuTekiKanriNoHitsuyoSeiLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        短期入所療養介護
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<IgakuTekiKanriNoHitsuyoSei | null>(
                        assessmentSheetUpdater,
                        "kaigoNikansuruIshiNoIken.igakuTekiKanriNoHitsuyoSei.tankiNyushoRyoyoKaigo",
                        igakuTekiKanriNoHitsuyoSeiLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        訪問歯科衛生指導
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<IgakuTekiKanriNoHitsuyoSei | null>(
                        assessmentSheetUpdater,
                        "kaigoNikansuruIshiNoIken.igakuTekiKanriNoHitsuyoSei.homonShikaEiseiShido",
                        igakuTekiKanriNoHitsuyoSeiLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        訪問栄養食事指導
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<IgakuTekiKanriNoHitsuyoSei | null>(
                        assessmentSheetUpdater,
                        "kaigoNikansuruIshiNoIken.igakuTekiKanriNoHitsuyoSei.homonEiyoShokujiShido",
                        igakuTekiKanriNoHitsuyoSeiLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        通所リハビリテーション
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<IgakuTekiKanriNoHitsuyoSei | null>(
                        assessmentSheetUpdater,
                        "kaigoNikansuruIshiNoIken.igakuTekiKanriNoHitsuyoSei.tsushoRehabilitation",
                        igakuTekiKanriNoHitsuyoSeiLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        その他の医療系サービス
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            {assessmentSheetField.renderRadioField<IgakuTekiKanriNoHitsuyoSei | null>(
                                assessmentSheetUpdater,
                                "kaigoNikansuruIshiNoIken.igakuTekiKanriNoHitsuyoSei.sonotaNoIryoKeiService",
                                igakuTekiKanriNoHitsuyoSeiLabels,
                                { tag: false }
                            )}
                            {assessmentSheetField.renderField<string | null>(
                                assessmentSheetUpdater,
                                "kaigoNikansuruIshiNoIken.igakuTekiKanriNoHitsuyoSei.sonotaNoIryoKeiServiceNaiyo",
                                textWith({ width: 280 }),
                                { tag: false }
                            )}
                        </div>
                    </div>
                </div>;
            })}
        </div>
        <div className={styles.card}>
            <h3 className={styles.title}>サービス提供時における医学的観点からの留意事項</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        血圧
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            {assessmentSheetField.renderRadioField<TokuninashiAri | null>(
                                assessmentSheetUpdater,
                                "kaigoNikansuruIshiNoIken.serviceTeikyoJiNiokeruIgakuTekiKantenKaraNoRyuiJiko.ketsuatsu.umu",
                                tokuninashiAriLabels,
                                { tag: false }
                            )}
                            {assessmentSheetField.renderField<string | null>(
                                assessmentSheetUpdater,
                                "kaigoNikansuruIshiNoIken.serviceTeikyoJiNiokeruIgakuTekiKantenKaraNoRyuiJiko.ketsuatsu.naiyo",
                                textWith({ width: 280 }),
                                { tag: false }
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        移動
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            {assessmentSheetField.renderRadioField<TokuninashiAri | null>(
                                assessmentSheetUpdater,
                                "kaigoNikansuruIshiNoIken.serviceTeikyoJiNiokeruIgakuTekiKantenKaraNoRyuiJiko.ido.umu",
                                tokuninashiAriLabels,
                                { tag: false }
                            )}
                            {assessmentSheetField.renderField<string | null>(
                                assessmentSheetUpdater,
                                "kaigoNikansuruIshiNoIken.serviceTeikyoJiNiokeruIgakuTekiKantenKaraNoRyuiJiko.ido.naiyo",
                                textWith({ width: 280 }),
                                { tag: false }
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        摂食
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            {assessmentSheetField.renderRadioField<TokuninashiAri | null>(
                                assessmentSheetUpdater,
                                "kaigoNikansuruIshiNoIken.serviceTeikyoJiNiokeruIgakuTekiKantenKaraNoRyuiJiko.sesshoku.umu",
                                tokuninashiAriLabels,
                                { tag: false }
                            )}
                            {assessmentSheetField.renderField<string | null>(
                                assessmentSheetUpdater,
                                "kaigoNikansuruIshiNoIken.serviceTeikyoJiNiokeruIgakuTekiKantenKaraNoRyuiJiko.sesshoku.naiyo",
                                textWith({ width: 280 }),
                                { tag: false }
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        運動
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            {assessmentSheetField.renderRadioField<TokuninashiAri | null>(
                                assessmentSheetUpdater,
                                "kaigoNikansuruIshiNoIken.serviceTeikyoJiNiokeruIgakuTekiKantenKaraNoRyuiJiko.undo.umu",
                                tokuninashiAriLabels,
                                { tag: false }
                            )}
                            {assessmentSheetField.renderField<string | null>(
                                assessmentSheetUpdater,
                                "kaigoNikansuruIshiNoIken.serviceTeikyoJiNiokeruIgakuTekiKantenKaraNoRyuiJiko.undo.naiyo",
                                textWith({ width: 280 }),
                                { tag: false }
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        嚥下
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            {assessmentSheetField.renderRadioField<TokuninashiAri | null>(
                                assessmentSheetUpdater,
                                "kaigoNikansuruIshiNoIken.serviceTeikyoJiNiokeruIgakuTekiKantenKaraNoRyuiJiko.enge.umu",
                                tokuninashiAriLabels,
                                { tag: false }
                            )}
                            {assessmentSheetField.renderField<string | null>(
                                assessmentSheetUpdater,
                                "kaigoNikansuruIshiNoIken.serviceTeikyoJiNiokeruIgakuTekiKantenKaraNoRyuiJiko.enge.naiyo",
                                textWith({ width: 280 }),
                                { tag: false }
                            )}
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        その他
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "kaigoNikansuruIshiNoIken.serviceTeikyoJiNiokeruIgakuTekiKantenKaraNoRyuiJiko.sonota",
                        textWith({ width: 280 })
                    )}
                </div>;
            })}
        </div>
        <div className={styles.card}>
            <h3 className={styles.title}>感染症の有無</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        感染症の有無
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.vertical}>
                            {assessmentSheetField.renderRadioField<KansenshoNoUmu | null>(
                                assessmentSheetUpdater,
                                "kaigoNikansuruIshiNoIken.kansenshoNoUmu.umu",
                                kansenshoNoUmuLabels,
                                { tag: false }
                            )}
                            {assessmentSheetField.renderField<string | null>(
                                assessmentSheetUpdater,
                                "kaigoNikansuruIshiNoIken.kansenshoNoUmu.naiyo",
                                textWith({ width: "auto" }),
                                { tag: false }
                            )}
                        </div>
                    </div>
                </div>;
            })}
        </div>
    </>;
}

type Props = {
    assessmentSheetUpdater: AssessmentSheetUpdater,
    activated: boolean,
};
