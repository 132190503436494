import { Office } from "../../../../domain/office/Office";

export class OfficesResponse {
    type: "SUCCESS" = "SUCCESS";

    offices: Office[]

    constructor(offices: Office[]) {
        this.offices = offices;
    }

    static async extract(response: Response): Promise<OfficesResponse> {
        const json = await response.json()
        return new OfficesResponse(json.offices);
    }
}
