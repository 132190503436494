import React, { ReactElement, RefObject } from "react";
import styles from "./Result.module.scss";
import { Card } from "./Card";
import { Loading } from "../../../../../components/loading/Loading";
import { SupportServiceTypeCategory } from "../../../../../../../domain/establishment/SupportServiceTypeCategory";
import { toLabel } from "../../components/ServiceType";
import { Establishment } from "../../../../../../../domain/establishment/Establishment";
import { EmptyCard } from "./EmptyCard";
import { replaceKanaHalfToFull, replaceHalfToFull } from "./ReplaceCharacter";
import { EstablishmentFavorite } from "../../../../../../../domain/establishment/EstablishmentFavorite";

export function Result(props: Props) {

    const {
        category,
        selectEstablishment,
        deselectEstablishment,
        selectedEstablishments,
        setScrollTop,
        scrollBody,
        establishmentList,
        onSearching,
        setEstablishment,
        setDetailVisible,
        keyword,
        projectId,
        establishmentFavoriteList,
        setEstablishmentFavoriteList,
        filterFavorite
    } = props;

    const result = () => {
        if (!establishmentList || !establishmentList.length) return zeroResult();

        let filteredList;
        switch (category) {
            case "通所系":
                filteredList = establishmentList.filter((establishment) => ["6", "7", "8", "9", "33"].includes(establishment.jigyoshoJoho.serviceShubetsuCode))
                break;
            case "訪問系":
                filteredList = establishmentList.filter((establishment) => ["1", "3", "4", "5", "31"].includes(establishment.jigyoshoJoho.serviceShubetsuCode))
                break;
            case "短期入所系":
                filteredList = establishmentList.filter((establishment) => ["25", "28", "30"].includes(establishment.jigyoshoJoho.serviceShubetsuCode))
                break;
        }

        // keyword検索
        const replaceKeyword = replaceKanaHalfToFull(replaceHalfToFull(keyword));
        filteredList = filteredList.filter(establishment =>
            replaceKeyword.split(/[\s]+/).every(value =>
                replaceKanaHalfToFull(replaceHalfToFull(establishment.jigyoshoJoho.jigyoshomei.replace(/[\s]+/g, ""))).includes(value)
            )
        );

        const jigyoshobangoList = establishmentFavoriteList.map(value => value.jigyoshobango);
        if (filterFavorite) {
            filteredList = filteredList.filter((establishment) => jigyoshobangoList.includes(establishment.jigyoshoJoho.jigyoshobango))
        }

        if (!filteredList || !filteredList.length) return zeroResult();

        return <ul className={styles.container}>
            {filteredList.slice(0, MAX_SEARCH_COUNT).map(establishment => {
                return <Card establishment={establishment}
                    category={category}
                    key={`${establishment.jigyoshoJoho.jigyoshobango}${establishment.jigyoshoJoho.serviceShubetsuCode}`}
                    selectEstablishment={selectEstablishment}
                    deselectEstablishment={deselectEstablishment}
                    checked={selectedEstablishments?.some((e) => e.text === toLabel(establishment.jigyoshoJoho.serviceShubetsuCode) && e.establishmentName === establishment.jigyoshoJoho.jigyoshomei)}
                    setScrollTop={setScrollTop}
                    scrollBody={scrollBody}
                    setDetailVisible={setDetailVisible}
                    setEstablishment={setEstablishment}
                    projectId={projectId}
                    establishmentFavoriteList={establishmentFavoriteList}
                    setEstablishmentFavoriteList={setEstablishmentFavoriteList}
                />
            })}{
                emptyCards(filteredList)
            }
        </ul>
    }

    // 検索結果はjustify-content:centerを使用して中央寄せにしたいが、検索結果の最終行のCardが2以下の場合に左寄せにならずに中央によって表示されてしまう。
    // それを避けるために空のCardを追加している https://qiita.com/QUANON/items/e14949abab3711ca8646
    const emptyCards = (filteredList: Establishment[]): ReactElement[] | null => {
        if (!filteredList || filteredList.length % 3 === 0) return null;
        const makeCount = 3 - (filteredList.length % 3);
        let arr = [];
        for (let i = 0; i < makeCount; i++) {
            arr.push(<EmptyCard key={i}/>)
        }
        return arr;
    }

    const zeroResult = () => {
        return <div className={styles.zeroResultContainer}>
            <div className={styles.zeroResultText}>該当する事業所が0件でした。</div>
        </div>;
    }

    return <>
        {onSearching ? <Loading/> : result()}
    </>
}

const MAX_SEARCH_COUNT = 99;

type Props = {
    category: SupportServiceTypeCategory,
    selectEstablishment: (establishment: {text: string, establishmentName: string}) => void
    deselectEstablishment: (establishment: {text: string, establishmentName: string}) => void
    selectedEstablishments: {text: string, establishmentName: string}[]
    setScrollTop: (scrollTop: number) => void
    scrollBody: RefObject<HTMLDivElement> | null,
    establishmentList: Establishment[],
    onSearching: boolean,
    setEstablishment: (establishment: Establishment) => void
    setDetailVisible: (value: boolean) => void
    keyword: string
    projectId: string,
    establishmentFavoriteList: EstablishmentFavorite[]
    setEstablishmentFavoriteList: (value: EstablishmentFavorite[]) => void
    filterFavorite: boolean
}
