import React, { MouseEvent, useState } from "react";
import styles from "./AccountMenu.module.scss";
import { DownArrowIcon } from "./icon/DownArrowIcon";
import { LogoutButton } from "./LogoutButton";
import { logout } from "../../../state/usecase/authentication/action";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Paths } from "../../../Paths";
import { State } from "../../../state/store";
import OutsideClickHandler from "react-outside-click-handler";

export function AccountMenu() {

    const history = useHistory();
    const dispatch = useDispatch();
    const [menuVisible, setMenuVisible] = useState<boolean>(false)
    const displayName = useSelector((state: State) => state.authentication.user?.displayName);
    const toggle = (): void => {
        setMenuVisible(!menuVisible)
    }
    const onClickLogout = (_event: MouseEvent): void => {
        dispatch(logout());
    }
    const moveToSettings = (): void => {
        history.push(Paths.userSettings)
    }
    const hideUserMenu = (): void => {
        setMenuVisible(false)
    }

    return (
        <OutsideClickHandler onOutsideClick={hideUserMenu}>
            <button className={styles.displayName} onClick={toggle}>
                <span>{displayName}</span>
                <DownArrowIcon/>
            </button>
            {menuVisible && (
                <div className={styles.dropDownMenu}>
                    <div className={styles.settings} onClick={moveToSettings}>アカウント設定</div>
                    <LogoutButton onClick={onClickLogout}/>
                </div>
            )}
        </OutsideClickHandler>
    )
}