import React, { ReactNode, useEffect, useState } from "react";
import { SelectField, SelectFieldProps } from "../../components/field/SelectField";
import { displayForResponsibleUser } from "../../misc/display";
import { User } from "../../../../domain/user/User";
import { userApi } from "../../../../state/api/user/UserApi";
import { GetList } from "../../../../state/api/user/GetList";

export function CareRecipientResponsibleUserField(props: SelectFieldProps) {
    const { disabled: givenDisabled, ...rest } = props;

    // values
    const [responsibleUsers, setResponsibleUsers] = useState<User[]>([])
    const values = responsibleUsers.map(responsibleUser => {
        return [responsibleUser.id, displayForResponsibleUser(responsibleUser)] as [string, ReactNode];
    });

    const disabled = givenDisabled || responsibleUsers.length === 0;

    useEffect(() => {
        let mounted = true;
        userApi.getList()
            .then(res => {
                if (res instanceof GetList && mounted) {
                    setResponsibleUsers(res.users);
                }
            })
        return () => {
            mounted = false;
        }
    }, []);

    return <SelectField values={values} disabled={disabled} {...rest}/>
}
