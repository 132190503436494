import React, { Attributes, RefObject } from "react";
import styles from "./ShortTermGoalComponent.module.scss";
import { CarePlanTableTwoPeriod } from "../../../../domain/careplan/tabletwo/CarePlanTableTwoPeriod";
import { LongTermGoal } from "../../../../domain/careplan/tabletwo/longtermgoal/LongTermGoal";
import { Need } from "../../../../domain/careplan/tabletwo/need/Need";
import { ShortTermGoal } from "../../../../domain/careplan/tabletwo/shorttermgoal/ShortTermGoal";
import { TableTwoPeriod } from "./TableTwoPeriod";
import { SuggestionShortTermGoalDialog } from "../../suggestion/components/SuggestionShortTermGoalDialog";
import { ShiftAction } from "../../../../domain/careplan/tabletwo/ShiftAction";
import { TableTwoCellWithIcons } from "./TableTwoCellWithIcons";
import { SupportServiceComponent } from "./SupportServiceComponent";
import { ShortTermGoalApi } from "../../../../state/api/careplan/tabletwo/shorttermgoal/ShortTermGoalApi";
import { CarePlanTableTwo } from "../../../../domain/careplan/tabletwo/CarePlanTableTwo";
import { ChangeLog } from "../../../../domain/changelog/ChangeLog";
import { LongTermGoalApi } from "../../../../state/api/careplan/tabletwo/longtermgoal/LongTermGoalApi";
import { EditedLongTermGoal } from "../../../../state/api/careplan/tabletwo/longtermgoal/EditedLongTermGoal";
import { EditedCaraPlanTableTwo } from "../../../../state/api/careplan/tabletwo/EditedCaraPlanTableTwo";
import { EditedShortTermGoal } from "../../../../state/api/careplan/tabletwo/shorttermgoal/EditedShortTermGoal";

export function ShortTermGoalComponent(props: Props) {
    const { projectId, carePlanTableTwo, need, longTermGoal, shortTermGoal, activated, refresh, tableTwoHeaderRef } = props;

    const update = (id: string, text: string | null, period: CarePlanTableTwoPeriod | null) => {
        ShortTermGoalApi.update(id, text, period)
            .then(res => {
                if (res instanceof EditedShortTermGoal) {
                    refresh(carePlanTableTwo.replaceShortTermGoal(need, longTermGoal, res.shortTermGoal), res.changeLog);
                }
            })
    }

    const handleTextUpdate = (text: string | null) => {
        update(shortTermGoal.id, text, shortTermGoal.period)
    };

    const handlePeriodUpdate = (period: CarePlanTableTwoPeriod) => {
        update(shortTermGoal.id, shortTermGoal.text, period)
    };

    const handleAdd = () => {
        LongTermGoalApi.addShortTermGoal(longTermGoal.id, shortTermGoal.id)
            .then(res => {
                if (res instanceof EditedLongTermGoal) {
                    refresh(carePlanTableTwo.replaceLongTermGoal(need, res.longTermGoal), res.changeLog);
                }
            });
    };

    const shiftShortTermGoal = (longTermGoalId: string, shortTermGoalId: string, shiftAction: ShiftAction) => {
        LongTermGoalApi.shiftShortTermGoal(longTermGoalId, shortTermGoalId, shiftAction)
            .then(res => {
                if (res instanceof EditedLongTermGoal) {
                    refresh(carePlanTableTwo.replaceLongTermGoal(need, res.longTermGoal), res.changeLog)
                }
            });
    }

    const handleShiftUp = () => {
        shiftShortTermGoal(longTermGoal.id, shortTermGoal.id, ShiftAction.UP)
    };

    const handleShiftDown = () => {
        shiftShortTermGoal(longTermGoal.id, shortTermGoal.id, ShiftAction.DOWN)
    };

    const handleRemove = () => {
        LongTermGoalApi.removeShortTermGoal(longTermGoal.id, shortTermGoal.id)
            .then(res => {
                if (res instanceof EditedLongTermGoal) {
                    refresh(carePlanTableTwo.replaceLongTermGoal(need, res.longTermGoal), res.changeLog);
                }
            });
    };

    const onClickCopyPeriodButton = () => {
        ShortTermGoalApi.copyPeriod(shortTermGoal.id)
            .then(res => {
                if (res instanceof EditedCaraPlanTableTwo) {
                    refresh(res.carePlanTableTwo, res.changeLog)
                }
            });
    };

    return <div className={styles.container}>
        <div className={styles.textCell}>
            <TableTwoCellWithIcons
                initialText={shortTermGoal.text}
                isFirstRowCell={longTermGoal.isFirstSubItem(shortTermGoal)}
                isLastRowCell={longTermGoal.isLastSubItem(shortTermGoal)}
                suggestionDialog={(openSuggestionDialog, onSelect) => {
                    return <SuggestionShortTermGoalDialog
                        open={openSuggestionDialog} projectId={projectId}
                        needText={need.text} longTermGoalText={longTermGoal.text}
                        onSelect={onSelect}/>;
                }}
                handleTextUpdate={handleTextUpdate}
                handleAdd={handleAdd}
                handleShiftUp={handleShiftUp}
                handleShiftDown={handleShiftDown}
                handleRemove={handleRemove}
                activated={activated}
                tableTwoHeaderRef={tableTwoHeaderRef}
            />
        </div>
        <div className={styles.periodCell}>
            <TableTwoPeriod
                period={shortTermGoal.period}
                category={"ShortTermGoal"}
                onChange={handlePeriodUpdate}
                onClickCopyPeriod={onClickCopyPeriodButton}
                activated={activated}/>
        </div>
        <div className={styles.rest}>
            {shortTermGoal.subItems.items.map(supportService => {
                return <SupportServiceComponent
                    key={supportService.id}
                    projectId={projectId}
                    carePlanTableTwo={carePlanTableTwo}
                    need={need}
                    longTermGoal={longTermGoal}
                    shortTermGoal={shortTermGoal}
                    supportService={supportService}
                    activated={activated}
                    refresh={refresh}
                    tableTwoHeaderRef={tableTwoHeaderRef}
                />;
            })}
        </div>
    </div>;
}

type Props = Attributes & {
    projectId: string,
    carePlanTableTwo: CarePlanTableTwo,
    need: Need,
    longTermGoal: LongTermGoal,
    shortTermGoal: ShortTermGoal,
    activated: boolean,
    refresh: (carePlanTableTwo: CarePlanTableTwo, changeLog: ChangeLog) => void,
    tableTwoHeaderRef: RefObject<HTMLDivElement>
};
