import React, { useEffect, useRef } from "react";
import styles from "./AssessmentSheetFragment.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../../state/store";
import { get } from "../../../state/usecase/assessmentsheet/action";
import { AssessmentSheetHeader } from "./components/AssessmentSheetHeader";
import { AssessmentSheetContent } from "./components/AssessmentSheetContent";

export function AssessmentSheetFragment(props: Props) {
    const { projectId, globalHeaderHeight, projectHeaderHeight, activated } = props;

    const dispatch = useDispatch();
    const { assessmentSheet, latestChangeLog } = useSelector((state: State) => state.assessmentSheet.slots[projectId]) ?? { assessmentSheet: null, latestChangeLog: null };
    const headerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        dispatch(get(projectId));
    }, [dispatch, projectId]);

    return <div className={styles.container}>
        <div ref={headerRef}>
            <AssessmentSheetHeader projectId={projectId} latestChangeLog={latestChangeLog}/>
        </div>
        <AssessmentSheetContent projectId={projectId}
            assessmentSheet={assessmentSheet}
            globalHeaderHeight={globalHeaderHeight}
            projectHeaderHeight={projectHeaderHeight}
            headerHeight={() => headerRef.current?.clientHeight ?? 0}
            activated={activated}/>
    </div>;
}

type Props = {
    projectId: string,
    globalHeaderHeight: () => number,
    projectHeaderHeight: () => number,
    activated: boolean,
};
