import React from "react";
import { Notification } from "../../../../domain/notification/Notification";
import styles from "./AdminNotificationListItem.module.scss";
import { DangerButton } from "../../components/button/DangerButton";
import { PrimaryButton } from "../../components/button/PrimaryButton";
import { Modal } from "../../components/Modal";
import DeleteNotificationDialog from "./DeleteNotificationDialog";
import dayjs from "dayjs";
import NotificationForm from "./NotificationForm";
import useModalOpener from "../../../hooks/common/useModalOpener";
import { today } from "../../../../domain/fundamental/LocalDate";

export default function AdminNotificationListItem(props: Props) {

    const { notification, refreshNotifications } = props;
    const [isOpenEditDialog, openEditDialog, closeEditDialog] = useModalOpener();
    const [isOpenDeleteDialog, openDeleteDialog, closeDeleteDialog] = useModalOpener();

    const buttonDisabled = dayjs(notification.publishedDate).isBefore(today());

    return <li className={styles.row}>
        <div className={styles.information}>
            <div className={styles.publishedDateArea}>
                {dayjs(notification.publishedDate).format("YYYY月MM月DD日")}
            </div>
            <div className={styles.notificationTitle}>
                {notification.title}
            </div>
        </div>
        <div className={styles.buttonArea}>
            <div><PrimaryButton onClick={openEditDialog} disabled={buttonDisabled}>更新</PrimaryButton></div>
            <div className={styles.deleteButton}><DangerButton onClick={openDeleteDialog} disabled={buttonDisabled}>削除</DangerButton></div>
        </div>
        <Modal open={isOpenEditDialog} onRequestClose={closeEditDialog}>
            <NotificationForm
                onRequestClose={closeEditDialog}
                notification={notification}
                refreshNotifications={refreshNotifications}
                type={"更新"}
            />
        </Modal>
        <Modal open={isOpenDeleteDialog} onRequestClose={closeEditDialog}>
            <DeleteNotificationDialog
                notification={notification}
                onRequestClose={closeDeleteDialog}
                refreshNotifications={refreshNotifications}
            />
        </Modal>
    </li>

}

type Props = {
    notification: Notification,
    refreshNotifications: () => void
}