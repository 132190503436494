import React from "react";

export function CircledCheckIcon() {
    return <svg width="18" height="18" viewBox="0 0 320 320" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd" clipRule="evenodd"
            d="M264.845,45.441C235.542,16.139,196.583,0,155.143,0C113.703,0,74.743,16.139,45.44,45.441 C16.138,74.743,0,113.703,0,155.143c0,41.439,16.138,80.399,45.44,109.701c29.303,29.303,68.262,45.44,109.702,45.44 c41.44,0,80.399-16.138,109.702-45.44c29.302-29.302,45.44-68.262,45.44-109.701C310.285,113.703,294.147,74.743,264.845,45.441z M242.521,121.654l-87.878,98.027c-2.456,2.739-5.987,4.311-9.688,4.311c0,0,0,0-0.001,0c-3.211-0.001-6.29-1.176-8.67-3.309 l-65.52-58.736c-5.338-4.785-5.787-13.021-1.002-18.357l14.257-15.904c2.456-2.739,5.987-4.311,9.688-4.311 c3.211,0,6.29,1.175,8.669,3.308l34.724,31.131c1.278,1.145,2.934,1.775,4.664,1.775c1.999,0,3.902-0.845,5.221-2.316l60.27-67.231 c2.456-2.739,5.987-4.311,9.688-4.311c3.211,0,6.29,1.175,8.669,3.309l15.904,14.258 C246.857,108.082,247.307,116.317,242.521,121.654z"
            fill="none"/>
    </svg>;
}
