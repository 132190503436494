import React from "react";
import { RouteProps } from "react-router-dom";
import { useSelector } from "react-redux";
import { State } from "../../../state/store";
import { NotFoundPage } from "../misc/NotFoundPage";

export default function RsLoginDeny(props: RouteProps) {

    const rsLoginIn = useSelector((state: State) => state.authentication.rsLoginIn)

    const { children } = props;

    if (rsLoginIn) {
        return <NotFoundPage/>
    }
    return <>
        {children}
    </>
}
