import React, { useEffect, useState, MouseEvent } from "react";
import styles from "./UserLimitEditDialog.module.scss";
import { PrimaryButton } from "../../../components/button/PrimaryButton";
import { UserDetail } from "../../../../../domain/user/UserDetail";
import { CloseIcon } from "../../../components/icon/CloseIcon";
import { CheckboxField } from "../../../components/field/CheckboxField";
import { PositiveNumberField } from "../../../components/field/PositiveNumberField";
import { ChatGptApi } from "../../../../../state/api/chatgpt/ChatGptApi";
import { ChatgptManageUsage } from "../../../../../domain/chatgpt/ChatgptManageUsage";

export default function UserLimitEditDialog(props: Props) {

    const { user, usageInfo, onRequestClose, refresh } = props;

    const [checkUsage, setCheckUsage] = useState( false);
    const [textLimit, setTextLimit] = useState<number | null>(0);
    const [fileLimit, setFileLimit] = useState<number | null>(0);
    const [sendLimit, setSendLimit] = useState<number | null>(0);
    // 入力 有効/無効
    const [inputEnable, setInputEnable] = useState(false);  

    const [hasError, setHasError] = useState(false);

    // 更新ボタン押下
    const onClick = () => {

        const usageType = checkUsage? 1 : 0;
        const argTextLimit = textLimit == null? 0 : textLimit;
        const argFileLimit = fileLimit == null? 0 : fileLimit;
        const argSendLimit = sendLimit == null? 0 : sendLimit;

        ChatGptApi.setManageSetting(
            user!!.id,
            usageType,
            argTextLimit,
            argFileLimit,
            argSendLimit,
        ).then(() => {
            setHasError(false);
            refresh();
            onRequestClose();
        }).catch((error) => {
            if (error) {
                return;
            }
            setHasError(true);
        });
    }

    const onClickContainer = (e: MouseEvent<any>) => {
        e.stopPropagation();
    }

    // 入力エリアの有効/無効
    useEffect(() => {
        let isUsage = false;
        if (usageInfo != null ) {
            isUsage = usageInfo.usageType > 0 ? true : false;
            setTextLimit(usageInfo.textLimit);
            setFileLimit(usageInfo.fileLimit);
            setSendLimit(usageInfo.sendLimit);
        }
        setCheckUsage(isUsage);

    }, [])


    // 入力エリアの有効/無効
    useEffect(() => {
        setInputEnable(!checkUsage);
    }, [checkUsage])


    return <div className={ styles.container } onClick={ onClickContainer }>
        <div className={ styles.description }>ChatGPT利用制限</div>

        <div className={ styles.checkArea }>
            <CheckboxField set={setCheckUsage} get={checkUsage}>CHatGPT使用有効</CheckboxField>
        </div>

        <div className={ styles.textArea }>
            <div className={ styles.title }>文字数</div>
            <PositiveNumberField get={textLimit} set={setTextLimit} disabled={inputEnable}/>
        </div>
        <div className={ styles.textArea }>
            <div className={ styles.title }>ファイル数</div>
            <PositiveNumberField get={fileLimit} set={setFileLimit} disabled={inputEnable}/>
        </div>
        <div className={ styles.textArea }>
            <div className={ styles.title }>送信数</div>
            <PositiveNumberField get={sendLimit} set={setSendLimit} disabled={inputEnable}/>
        </div>

        {hasError && <div className={styles.errorMessage}>
        ChatGPT利用制限の更新に失敗しました。
        </div>}
        <div className={ styles.buttonArea }>
            <PrimaryButton onClick={ onClick } width={ 200 } >更新</PrimaryButton>
        </div>
        <button onClick={ onRequestClose } className={ styles.closeIcon }>
            <CloseIcon/>
        </button>
    </div>
}

type Props = {
    user?: UserDetail,
    usageInfo?: ChatgptManageUsage | undefined,
    onRequestClose: () => void,
    refresh: () => void,
}
