import React, { useState } from "react";
import styles from "./ProjectSwitcher.module.scss";
import { Project } from "../../../../domain/project/Project";
import { DownArrowIcon } from "../../components/icon/DownArrowIcon";
import { displayForProject } from "../../misc/display";
import { Modeless } from "../../components/Modeless";
import { ProjectDropdown } from "./ProjectDropdown";

export function ProjectSwitcher(props: Props) {
    const { projects, selectedProject, onChange: givenOnChange } = props;
    const [latestProject] = projects ?? [];

    const [openDropdown, setOpenDropdown] = useState(false);

    const onClickSwitchButton = () => {
        setOpenDropdown(true);
    };

    const onChange = (projectId: string) => {
        if (projectId !== selectedProject?.id) {
            givenOnChange?.(projectId);
        }
        setOpenDropdown(false);
    };

    const onRequestClose = () => {
        setOpenDropdown(false);
    };

    return <div className={styles.container}>
        <span className={styles.current}>表示中：{selectedProject && displayForProject(selectedProject)}</span>
        {latestProject && selectedProject?.id === latestProject.id ? <span className={styles.latestTag}>最新</span> : null}
        <button className={styles.switchButton} onClick={onClickSwitchButton}>
            <span>版切替</span>
            <DownArrowIcon/>
        </button>
        <Modeless open={openDropdown} onRequestClose={onRequestClose} className={styles.dropdownModeless}>
            <ProjectDropdown projects={projects} selectedProject={selectedProject} onChange={onChange}/>
        </Modeless>
    </div>;
}

type Props = {
    projects: Project[] | null,
    selectedProject: Project | null,
    onChange?: (projectId: string) => void,
};
