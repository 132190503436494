
import { CarePlanTableTwo } from "../../../../domain/careplan/tabletwo/CarePlanTableTwo";
import { CarePlanTableTwoPeriod } from "../../../../domain/careplan/tabletwo/CarePlanTableTwoPeriod";
import { LongTermGoal } from "../../../../domain/careplan/tabletwo/longtermgoal/LongTermGoal";
import { Need } from "../../../../domain/careplan/tabletwo/need/Need";
import { ShortTermGoal } from "../../../../domain/careplan/tabletwo/shorttermgoal/ShortTermGoal";
import { SubItems } from "../../../../domain/careplan/tabletwo/SubItems";
import { SupportService } from "../../../../domain/careplan/tabletwo/supportservice/SupportService";
import { SupportServiceType } from "../../../../domain/careplan/tabletwo/supportservicetype/SupportServiceType";
import { insuranceBenefit, InsuranceBenefitString } from "./supportservicetype/SupportServiceTypeObject";
import { ChangeLog } from "../../../../domain/changelog/ChangeLog";
import { changeLog } from "../../changelog/LatestChangeLog";

export class CarePlanTableTwoResponse {
    carePlanTableTwo: CarePlanTableTwo;

    latestChangeLog: ChangeLog | null;

    constructor(
        carePlanTableTwo: CarePlanTableTwo,
        latestChangeLog: ChangeLog | null,
    ) {
        this.carePlanTableTwo = carePlanTableTwo;
        this.latestChangeLog = latestChangeLog;
    }

    static async extract(response: Response): Promise<CarePlanTableTwoResponse> {
        const json = await response.json();
        const latestChangeLog = json.latestChangeLog ? changeLog(json.latestChangeLog) : null;
        return new CarePlanTableTwoResponse(carePlanTableTwo(json), latestChangeLog);
    }
}

type Item = {
    tableTwo: NeedsJson,
    project: {
        id: string,
    },
};

type NeedsJson = {
    id: string,
    text: string | null,
    longTermGoals: LongTermGoalsJson,
}[];

type LongTermGoalsJson = {
    id: string,
    text: string | null,
    periodStartDate: string | null,
    periodEndDate: string | null,
    shortTermGoals: ShortTermGoalsJson,
}[];

type ShortTermGoalsJson = {
    id: string,
    text: string | null,
    periodStartDate: string | null,
    periodEndDate: string | null,
    supportServices: SupportServicesJson,
}[];

type SupportServicesJson = {
    id: string,
    text: string | null,
    supportServiceTypes: SupportServiceTypesJson,
}[];

type SupportServiceTypesJson = {
    id: string,
    code: string | null,
    text: string | null,
    insuranceBenefit: InsuranceBenefitString | null,
    establishment: string | null,
    frequency: string | null,
    periodStartDate: string | null,
    periodEndDate: string | null,
}[];

function carePlanTableTwo(item: Item): CarePlanTableTwo {
    return new CarePlanTableTwo(
        new SubItems(needs(item.tableTwo)),
        item.project.id,
    )
}

function needs(needs: NeedsJson): Need[] {
    return needs.map(need => new Need(
        need.id,
        need.text,
        new SubItems(longTermGoals(need.longTermGoals)),
    ));
}

function longTermGoals(longTermGoals: LongTermGoalsJson): LongTermGoal[] {
    return longTermGoals.map(longTermGoal => new LongTermGoal(
        longTermGoal.id,
        longTermGoal.text,
        CarePlanTableTwoPeriod.fromStingOrNull(longTermGoal.periodStartDate, longTermGoal.periodEndDate),
        new SubItems(shortTermGoals(longTermGoal.shortTermGoals)),
    ));
}

function shortTermGoals(shortTermGoals: ShortTermGoalsJson): ShortTermGoal[] {
    return shortTermGoals.map(shortTermGoal => new ShortTermGoal(
        shortTermGoal.id,
        shortTermGoal.text,
        CarePlanTableTwoPeriod.fromStingOrNull(shortTermGoal.periodStartDate, shortTermGoal.periodEndDate),
        new SubItems(supportServices(shortTermGoal.supportServices)),
    ));
}

function supportServices(supportServices: SupportServicesJson): SupportService[] {
    return supportServices.map(supportService => new SupportService(
        supportService.id,
        supportService.text,
        new SubItems(supportServiceTypes(supportService.supportServiceTypes)),
    ));
}

function supportServiceTypes(supportServiceTypes: SupportServiceTypesJson): SupportServiceType[] {
    return supportServiceTypes.map(supportServiceType => new SupportServiceType(
        supportServiceType.id,
        supportServiceType.code,
        supportServiceType.text,
        supportServiceType.insuranceBenefit ? insuranceBenefit(supportServiceType.insuranceBenefit) : null,
        supportServiceType.establishment,
        supportServiceType.frequency,
        CarePlanTableTwoPeriod.fromStingOrNull(supportServiceType.periodStartDate, supportServiceType.periodEndDate),
    ));
}
