import React, { MouseEvent, useEffect, useRef, useState } from "react";
import styles from "./DiseaseKnowledgeDialog.module.scss";
import { DiseaseKnowledgeListDialog } from "./components/DiseaseKnowledgeListDialog";
import { DiseaseKnowledgeDetailDialog } from "./components/DiseaseKnowledgeDetailDialog";
import { Modal } from "../components/Modal";
import { CloseIcon } from "../components/icon/CloseIcon";
import { DiseaseKnowledgeDialogMode } from "../components/MedicalKnowledgeButton";

export function DiseaseKnowledgeDialog(props: Props) {

    const { dialogMode, setDialogMode, onClose } = props;
    const dialogContainerRef = useRef<HTMLDivElement>(null);
    const [newKeyword, setNewKeyword] = useState<string>("");
    const onClickContainer = (event: MouseEvent<any>) => event.stopPropagation();

    const diseaseKnowledgeDialog = (dialogMode: DiseaseKnowledgeDialogMode) => {
        switch (dialogMode) {
            case null:
                return <></>
            case "list":
                return <DiseaseKnowledgeListDialog
                    keyword={newKeyword}
                    setNewKeyword={setNewKeyword}
                    setDialogMode={setDialogMode}/>
            default:
                return <DiseaseKnowledgeDetailDialog
                    diseaseId={dialogMode?.diseaseId}
                    dialogContainerRef={dialogContainerRef}
                    setDialogMode={setDialogMode}/>;
        }
    };

    useEffect(() => {
        if (!dialogMode) return
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "";
        };
    }, [dialogMode]);

    return <Modal open={dialogMode !== null} onRequestClose={onClose}>
        <div className={styles.container} onClick={onClickContainer} ref={dialogContainerRef}>
            {dialogMode && diseaseKnowledgeDialog(dialogMode)}
            <button className={styles.closeButton} onClick={onClose}><CloseIcon/></button>
        </div>
    </Modal>;
}

type Props = {
    dialogMode: DiseaseKnowledgeDialogMode,
    setDialogMode: (dialogMode: DiseaseKnowledgeDialogMode) => void,
    onClose: () => void,
};
