export enum AssessmentSheetSection {
    FACE_SHEET = "faceSheet",
    KAZOKU_JOKYO_TO_INFORMAL_NA_SHIEN_NO_JOKYO = "KazokuJokyoToInformalNaShienNoJokyo",
    SERVICE_RIYO_JOKYO = "serviceRiyoJokyo",
    JUKYO_NADO_NO_JOKYO = "jukyoNadoNoJokyo",
    HONNIN_NO_KENKO_JOTAI_JUSHIN_NADO_NO_JOKYO = "honninNoKenkoJotaiJushinNadoNoJokyo",
    KIHON_DOSA = "kihonDosa",
    SEIKATSU_KINO = "seikatsuKino",
    NINCHI_KINO = "ninchiKino",
    SEISHIN_KODO_SHOGAI = "seishinKodoShogai",
    NINCHI_KINO_SEISHIN_KODOSHOGAI_ZEMPAN = "ninchiKinoSeishinKodoshogaiZempan",
    SHAKAI_SEIKATSU_RYOKU = "shakaiSeikatsuRyoku",
    IRYO_KENKO_KANKEI = "iryoKenkoKankei",
    KAIGO_NIKANSURU_ISHI_NO_IKEN = "kaigoNikansuruIshiNoIken",
    ZENTAI_NO_MATOME = "zentaiNoMatome",
}

export function assessmentSheetSectionId(section: AssessmentSheetSection): string {
    return `assessmentSheetSection_${section}`;
}
