import { LongTermGoal } from "../../../../../domain/careplan/tabletwo/longtermgoal/LongTermGoal";
import { longTermGoal } from "./LongTermGoalObject";
import { ChangeLog } from "../../../../../domain/changelog/ChangeLog";
import { changeLog } from "../../../changelog/LatestChangeLog";

export class EditedLongTermGoal {
    longTermGoal: LongTermGoal;

    changeLog: ChangeLog;

    constructor(longTermGoal: LongTermGoal, changeLog: ChangeLog) {
        this.longTermGoal = longTermGoal;
        this.changeLog = changeLog;
    }

    static async extract(response: Response): Promise<EditedLongTermGoal> {
        const json = await response.json();
        return new EditedLongTermGoal(longTermGoal(json.longTermGoal), changeLog(json.changeLog));
    }
}
