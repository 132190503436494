import React, { RefObject, useState } from "react";
import styles from "./ProjectCreation.module.scss";
import { Project } from "../../../../domain/project/Project";
import { PrimaryButton } from "../../components/button/PrimaryButton";
import { Modal } from "../../components/Modal";
import { ProjectCreationDialog } from "./ProjectCreationDialog";

export function ProjectCreation(props: Props) {
    const { selectedProject, onCreate, globalHeaderRef } = props;

    const [openDialog, setOpenDialog] = useState(false);

    const onClick = () => {
        setOpenDialog(true);
        if (globalHeaderRef.current?.style != null) {
            globalHeaderRef.current.style.zIndex = "100";
        }
    };

    const onRequestClose = () => {
        setOpenDialog(false);
        if (globalHeaderRef.current?.style != null) {
            globalHeaderRef.current.style.zIndex = "150";
        }
    };

    return <div className={styles.container}>
        <PrimaryButton onClick={onClick}>新規作成</PrimaryButton>
        <Modal open={openDialog} onRequestClose={onRequestClose}>
            <ProjectCreationDialog selectedProject={selectedProject} onCreate={onCreate} onRequestClose={onRequestClose}/>
        </Modal>
    </div>;
}

type Props = {
    selectedProject: Project | null,
    onCreate?: (projectId: string) => void,
    globalHeaderRef: RefObject<HTMLDivElement>
};
