import { FetchSuggestionEstablishmentSearchCondition } from "./FetchSuggestionEstablishmentSearchCondition";
import { ApiError } from "../../ApiError";
import { del, get, post } from "../../functions";
import { FetchEstablishmentList } from "./FetchEstablishmentList";
import { FetchEstablishmentDetail } from "./FetchEstablishmentDetail";
import { FetchEstablishmentFavoriteList } from "./FetchEstablishmentFavoriteList";

export const FetchEstablishmentApi = new class {
    async fetchEstablishmentList(
        projectId: string,
        queryString: string,
    ): Promise<FetchEstablishmentList | ApiError> {
        const url = `/api/suggestion/establishmentList/${projectId}?${queryString}`
        const response = await get(url);
        return (response.ok ? FetchEstablishmentList.extract : ApiError.extract)(response);
    }

    async fetchSuggestedEstablishmentSearchCondition(
        projectId: string,
        supportServiceText: string,
    ): Promise<FetchSuggestionEstablishmentSearchCondition | ApiError> {
        const body = {
            projectId,
            supportServiceText
        };
        const response = await post("/api/suggestion/establishmentSearchCondition", body);
        return (response.ok ? FetchSuggestionEstablishmentSearchCondition.extract : ApiError.extract)(response);
    }

    async fetchEstablishmentDetail(
        projectId: string,
        jigyoshoBango: string,
        serviceShubetsuCode: string
    ): Promise<FetchEstablishmentDetail | ApiError> {
        const url = `/api/suggestion/establishmentDetail/${projectId}?jigyoshoBango=${jigyoshoBango}&serviceShubetsuCode=${serviceShubetsuCode}`;
        const response = await get(url);
        return (response.ok ? FetchEstablishmentDetail.extract : ApiError.extract)(response);
    }

    async fetchEstablishmentFavoriteList(
        projectId: string,
    ): Promise<FetchEstablishmentFavoriteList| ApiError> {
        const url = `/api/suggestion/favorite/${projectId}/getList`;
        const response = await get(url);
        return (response.ok ? FetchEstablishmentFavoriteList.extract : ApiError.extract)(response);
    }

    async establishmentFavoriteRegister(projectId: string, jigyoshobango: string): Promise<void> {
        const body = { projectId, jigyoshobango };
        const response = await post("/api/suggestion/favorite/register", body)
        if (!response.ok) {
            throw new ApiError(response.status.toString(), response.statusText);
        }
    }

    async establishmentFavoriteDelete(projectId: string, jigyoshobango: string): Promise<void> {
        const response = await del(`/api/suggestion/favorite/${projectId}/${jigyoshobango}`)
        if (!response.ok) {
            throw new ApiError(response.status.toString(), response.statusText);
        }
    }
}();
