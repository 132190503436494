import React, { FormEvent, MouseEvent } from "react";
import styles from "./TableTwoRowRemoveConfirmDialog.module.scss";
import { CancelButton } from "../../components/button/CancelButton";
import { DangerButton } from "../../components/button/DangerButton";
import { CloseIcon } from "../../components/icon/CloseIcon";

export function TableTwoRowRemoveConfirmDialog(props: Props) {
    const { onRemove, onRequestClose } = props;

    const onClickContainer = (event: MouseEvent<any>) => event.stopPropagation();

    const onSubmit = (event: FormEvent) => {
        event.preventDefault();
    };

    return <div className={styles.container} onClick={onClickContainer}>
        <h2 className={styles.title}>選択した行を削除する</h2>
        <p className={styles.description}>選択した行を削除します。この操作は取り消せません。キャンセルを押すと削除を中止できます。</p>
        <form onSubmit={onSubmit}>
            <div className={styles.action}>
                <DangerButton width={256} onClick={onRemove}>削除する</DangerButton>
            </div>
            <div className={styles.action}>
                <CancelButton width={256} onClick={onRequestClose}>キャンセル</CancelButton>
            </div>
        </form>
        <button className={styles.close} onClick={onRequestClose}><CloseIcon/></button>
    </div>;
}

type Props = {
    onRemove?: () => void,
    onRequestClose?: () => void,
};
