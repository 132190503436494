import { ChatGptSupportService } from "../supportservice/ChatGptSupportService";

// 2表叩き台 短期目標
export class ChatGptShortTermGoal {
    text: string; // 内容
    periodStartDate: string; // 期間開始日
    periodEndDate: string; // 期間終了日
    supportServices: ChatGptSupportService[] | null; // サービス内容

    constructor(
        text: string,
        periodStartDate: string, // 期間開始日
        periodEndDate: string, // 期間終了日
        supportServices: ChatGptSupportService[] | null
    ) {
        this.text = text;
        this.periodStartDate = periodStartDate;
        this.periodEndDate = periodEndDate;
        this.supportServices = supportServices;
    }
}
