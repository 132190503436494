import React, { useState, MouseEvent, RefObject } from "react";
import { Establishment } from "../../../../../../../domain/establishment/Establishment";
import { Features } from "./cardcomponent/Features";
import { AddressInfo } from "./cardcomponent/AddressInfo";
import styles from "./Card.module.scss";
import { Header } from "./cardcomponent/Header";
import { SupportServiceTypeCategory } from "../../../../../../../domain/establishment/SupportServiceTypeCategory";
import { SelectButton } from "../../components/SelectButton";
import { toLabel } from "../../components/ServiceType";
import { ThumbnailsRow } from "./cardcomponent/ThumbnailsRow";
import { EstablishmentFavorite } from "../../../../../../../domain/establishment/EstablishmentFavorite";

export function Card(props: Props) {

    const {
        establishment,
        category,
        selectEstablishment,
        deselectEstablishment,
        checked,
        setScrollTop,
        scrollBody,
        setEstablishment,
        setDetailVisible,
        projectId,
        establishmentFavoriteList,
        setEstablishmentFavoriteList,
    } = props;

    const [selected, setSelected] = useState(checked);

    const onClickCard = () => {
        setScrollTop(scrollBody?.current?.scrollTop ?? 0);
        setEstablishment(establishment);
        setDetailVisible(true);
    }

    const onClickSelect = (event: MouseEvent) => {
        event.stopPropagation();
        if (selected) {
            deselectEstablishment({ text: toLabel(establishment.jigyoshoJoho.serviceShubetsuCode), establishmentName: establishment.jigyoshoJoho.jigyoshomei });
        } else {
            selectEstablishment({ text: toLabel(establishment.jigyoshoJoho.serviceShubetsuCode), establishmentName: establishment.jigyoshoJoho.jigyoshomei });
        }
        setSelected(!selected);
    }

    return <>
        <li className={styles.container} onClick={onClickCard}>
            <Header
                serviceTypeCode={establishment.jigyoshoJoho.serviceShubetsuCode}
                saturday={establishment.jigyoshoJoho.eigyobiDoyo} sunday={establishment.jigyoshoJoho.eigyobiNichiyo}
                publicHoliday={establishment.jigyoshoJoho.eigyobiShukujitsu}
                establishment={establishment}
                projectId={projectId}
                establishmentFavoriteList={establishmentFavoriteList}
                setEstablishmentFavoriteList={setEstablishmentFavoriteList}
            />
            <div className={styles.establishmentName}>
                <div>{establishment.jigyoshoJoho.jigyoshomei}</div>
            </div>
            <Features tokucho={establishment.tokucho} category={category}/>
            <div className={styles.thumbnailsArea}>
                <ThumbnailsRow thumbnails={establishment.jigyoshoJoho.thumbnails}/>
            </div>
            <AddressInfo
                postalCode={establishment.jigyoshoJoho.yubimbango} address={establishment.jigyoshoJoho.jusho}
                establishmentNumber={establishment.jigyoshoJoho.jigyoshobango}
                tel={establishment.jigyoshoJoho.tel}/>
            <div className={styles.buttonArea}>
                <SelectButton selected={selected} onClick={onClickSelect}/>
            </div>
        </li>
    </>
}

type Props = {
    establishment: Establishment,
    category: SupportServiceTypeCategory,
    key: string,
    selectEstablishment: (establishment: {text: string, establishmentName: string}) => void
    deselectEstablishment: (establishment: {text: string, establishmentName: string}) => void
    checked: boolean
    setScrollTop: (scrollTop: number) => void
    scrollBody: RefObject<HTMLDivElement> | null
    setEstablishment: (establishment: Establishment) => void
    setDetailVisible: (value: boolean) => void
    projectId: string,
    establishmentFavoriteList: EstablishmentFavorite[]
    setEstablishmentFavoriteList: (value: EstablishmentFavorite[]) => void
}
