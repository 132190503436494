import React from "react";
import styles from "./EmailChangeCard.module.scss";
import { EnhancedEmailChangeForm } from "./EnhancedEmailChangeForm";

export function EmailChangeCard() {
    return (
        <div className={styles.card}>
            <h2 className={styles.title}>メールアドレスを変更する</h2>
            <div className={styles.row}/>
            <div className={styles.content}>
                <EnhancedEmailChangeForm />
            </div>
        </div>
    )
}
