import React from "react";
import { EstablishmentDetail } from "../../../../../../../domain/establishment/EstablishmentDetail";
import styles from "./JigyoshoInfo.module.scss";
import { HolidayStatus } from "../../components/HolidayStatus";
import { ServiceType } from "../../components/ServiceType";
import { Thumbnails } from "./Thumbnails";
import classNames from "classnames";
import { Features } from "./Features";
import { Establishment } from "../../../../../../../domain/establishment/Establishment";
import { Favorite } from "../../components/Favorite";
import { EstablishmentFavorite } from "../../../../../../../domain/establishment/EstablishmentFavorite";

export function JigyoshoInfo(props: Props) {

    const {
        establishment,
        establishmentDetail,
        projectId,
        establishmentFavoriteList,
        setEstablishmentFavoriteList
    } = props
    const { jigyoshoJoho, eigyojikan } = establishmentDetail;

    const { tokucho } = establishment;

    const teiin = (teiin : number | null) => {
        if (!teiin)  return null;
        return teiin + "人"
    }

    return <div className={styles.container}>
        <Thumbnails thumbnails={jigyoshoJoho.thumbnailList}/>
        <div className={classNames({ [styles.info]: jigyoshoJoho.thumbnailList })}>
            <div className={styles.group}>
                <HolidayStatus saturday={eigyojikan?.eigyobiDoyo ?? false} sunday={eigyojikan?.eigyobiNichiyo ?? false} publicHoliday={eigyojikan?.eigyobiShukujitsu ?? false}/>
                <Favorite
                    establishment={establishment}
                    projectId={projectId}
                    establishmentFavoriteList={establishmentFavoriteList}
                    setEstablishmentFavoriteList={setEstablishmentFavoriteList}
                />
            </div>
            <ServiceType serviceTypeCode={jigyoshoJoho.serviceShubetsuCode} style={styles.serviceType}/>
            <div className={styles.jigyoshoName}>{jigyoshoJoho.jigyoshomei}</div>
            <Features tokucho={tokucho} categoryCode={jigyoshoJoho.serviceShubetsuCategoryCode}/>
            <div className={styles.basicInfo}>
                <div className={styles.row}>
                    <div className={styles.title}>住所</div><div className={styles.content}>〒{jigyoshoJoho.yubimbango}{"　"}{jigyoshoJoho.jusho}</div>
                </div>
                <div className={styles.row}>
                    <div className={styles.title}>事業所番号</div><div className={styles.content}>{jigyoshoJoho.jigyoshobango}</div>
                </div>
                <div className={styles.row}>
                    <div className={styles.title}>電話番号</div><div className={styles.content}>{jigyoshoJoho.tel}</div>
                </div>
                <div className={styles.row}>
                    <div className={styles.title}>サービス提供地域</div><div className={styles.content}>{jigyoshoJoho.serviceTeikyochiiki}</div>
                </div>
                <div className={styles.row}>
                    <div className={styles.title}>特化分野</div><div className={styles.content}>{jigyoshoJoho.tokkabunya}</div>
                </div>
                <div className={styles.row}>
                    <div className={styles.title}>定員</div><div className={styles.content}>{teiin(jigyoshoJoho.teiin)}</div>
                </div>
            </div>
        </div>
    </div>
}

type Props = {
    establishment: Establishment,
    establishmentDetail: EstablishmentDetail,
    projectId: string,
    establishmentFavoriteList: EstablishmentFavorite[]
    setEstablishmentFavoriteList: (value: EstablishmentFavorite[]) => void
}
