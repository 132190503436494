// ※1
export enum InsuranceBenefit {
    INCLUDED,
    EXCLUDED,
}

export const changeInsuranceBenefit = (insuranceBenefit: InsuranceBenefit | null):InsuranceBenefit => {
    if (insuranceBenefit === null) return InsuranceBenefit.INCLUDED
    return insuranceBenefit === InsuranceBenefit.INCLUDED ? InsuranceBenefit.EXCLUDED : InsuranceBenefit.INCLUDED;
}
