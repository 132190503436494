import { CarePlanTableTwo } from "../../../../domain/careplan/tabletwo/CarePlanTableTwo";
import { carePlanTableTwo } from "./CarePlanTableTwoObject";
import { ChangeLog } from "../../../../domain/changelog/ChangeLog";
import { changeLog } from "../../changelog/LatestChangeLog";

export class EditedCaraPlanTableTwo {
    carePlanTableTwo: CarePlanTableTwo;

    changeLog: ChangeLog

    constructor(carePlanTableTwo: CarePlanTableTwo, changeLog: ChangeLog) {
        this.carePlanTableTwo = carePlanTableTwo;
        this.changeLog = changeLog
    }

    static async extract(response: Response): Promise<EditedCaraPlanTableTwo> {
        const json = await response.json();
        return new EditedCaraPlanTableTwo(carePlanTableTwo(json.carePlanTableTwo), changeLog(json.changeLog));
    }
}
