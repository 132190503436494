import React, { MouseEvent } from "react";
import styles from "./SuggestionEstablishmentDialog.module.scss";
import { NoShadowPrimaryButton } from "../../components/button/NoShadowPrimaryButton";

export function SuggestionEstablishmentDialog(props: Props) {

    const { open, onRequestEstablishmentPageOpen } = props;

    if (!open) return null;

    const handleClick = () => {
        onRequestEstablishmentPageOpen();
    }

    const mouseDown = (event: MouseEvent<any>) => {
        event.preventDefault();
    }

    return <div className={styles.container} onMouseDown={mouseDown}>
        <div className={styles.titleArea}>
            <h2 className={styles.title}>サービス種別提案</h2>
        </div>
        <div className={styles.button}>
            <NoShadowPrimaryButton onClick={handleClick}>事業所提案機能</NoShadowPrimaryButton>
        </div>
    </div>
}

type Props = {
    open: boolean,
    projectId: string,
    shortTermGoal: string | null,
    supportServiceText: string | null,
    onRequestEstablishmentPageOpen: () => void
}