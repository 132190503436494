import React, { FormEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { User } from "../../../../../domain/user/User";
import { Paths } from "../../../../../Paths";
import { RootState } from "../../../../../state/store";
import { clearMyselfFailure, updateEmail } from "../../../../../state/usecase/authentication/action";
import { EmailChangeForm } from "./EmailChangeForm";

export function EnhancedEmailChangeForm() {
    const dispatch = useDispatch();
    const myself: User = useSelector((store: RootState) => store.authentication.user ?? { id: "", displayName: "", email: "", isAdmin: false });
    const updateFailed: boolean = useSelector((store: RootState) => store.authentication.updateFailed);
    const [email, setEmail] = useState<string>(myself.email);
    const history = useHistory();

    useEffect(() => () => { dispatch(clearMyselfFailure()) }, [dispatch]);

    const validate = (): boolean => email !== "";

    const onSubmit = (event: FormEvent) => {
        event.preventDefault();

        const updatedMyself: User = {
            ...myself,
            email,
        };
        dispatch(updateEmail(updatedMyself, () => backToSettingPage("email")));
    };

    const backToSettingPage = (type?: string) => 
        type? history.push(Paths.userSetting.build({ type })) : history.push(Paths.userSettings);

    const onCancel = (event: FormEvent) => {
        event.preventDefault();

        backToSettingPage();
    };

    return (
        <EmailChangeForm
            email={email}
            setEmail={setEmail}
            validate={validate}
            onSubmit={onSubmit}
            updateFailed={updateFailed}
            onCancel={onCancel}
        />
    )
}
