// THIS CODE IS GENERATED CODE. DO NOT EDIT.

import React from "react";
import styles from "./Section.module.scss";
import { AssessmentSheetUpdater } from "./AssessmentSheetUpdater";
import { AssessmentSheetField } from "./AssessmentSheetField";

export function NinchiKinoSeishinKodoshogaiZempanSection(props: Props) {

    const { assessmentSheetUpdater, activated } = props;
    const assessmentSheetField = new AssessmentSheetField(activated);

    return <>
        <div className={styles.card}>
            <h3 className={styles.title}>家族等からの情報と観察</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        家族等からの情報と観察
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderTextareaField(
                        assessmentSheetUpdater,
                        "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.ninchiKinoSeishinKodoshogaiZempan.kazokuNadoKaraNoJohoToKansatsu",
                        { width: "auto", rows: 5 }
                    )}
                </div>;
            })}
        </div>
        <div className={styles.card}>
            <h3 className={styles.title}>援助の現状</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        家族
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderTextareaField(
                        assessmentSheetUpdater,
                        "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.ninchiKinoSeishinKodoshogaiZempan.enjoNoGenjo.kazoku",
                        { width: "auto", rows: 5 }
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        サービス
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderTextareaField(
                        assessmentSheetUpdater,
                        "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.ninchiKinoSeishinKodoshogaiZempan.enjoNoGenjo.service",
                        { width: "auto", rows: 5 }
                    )}
                </div>;
            })}
        </div>
        <div className={styles.card}>
            <h3 className={styles.title}>援助の希望</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        本人
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderTextareaField(
                        assessmentSheetUpdater,
                        "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.ninchiKinoSeishinKodoshogaiZempan.enjoNoKiboHonnin",
                        { width: "auto", rows: 5 }
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        家族
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderTextareaField(
                        assessmentSheetUpdater,
                        "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.ninchiKinoSeishinKodoshogaiZempan.enjoNoKiboKazoku",
                        { width: "auto", rows: 5 }
                    )}
                </div>;
            })}
        </div>
        <div className={styles.card}>
            <h3 className={styles.title}>援助の計画</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        援助の計画
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderTextareaField(
                        assessmentSheetUpdater,
                        "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.ninchiKinoSeishinKodoshogaiZempan.enjoNoKeikaku",
                        { width: "auto", rows: 5 }
                    )}
                </div>;
            })}
        </div>
        <div className={styles.card}>
            <h3 className={styles.title}>特記、解決すべき課題など</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        特記、解決すべき課題など
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderTextareaField(
                        assessmentSheetUpdater,
                        "honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai.ninchiKinoSeishinKodoshogaiZempan.tokkiKaiketsuSuBekiKadaiNado",
                        { width: "auto", rows: 5 }
                    )}
                </div>;
            })}
        </div>
    </>;
}

type Props = {
    assessmentSheetUpdater: AssessmentSheetUpdater,
    activated: boolean,
};
