// THIS CODE IS GENERATED CODE. DO NOT EDIT.

import React from "react";
import styles from "./Section.module.scss";
import { AssessmentSheetUpdater } from "./AssessmentSheetUpdater";
import { AssessmentSheetField } from "./AssessmentSheetField";
import {
    JushinJokyo,
    TeikiFuTeiki,
    TsukiShu,
    Umu,
} from "./types";
import {
    jushinJokyoLabels,
    teikiFuTeikiLabels,
    tsukiShuLabels,
    umuLabels,
} from "./labels";
import { checkboxWith, positiveFloatWith, textWith } from "./fields";

export function HonninNoKenkoJotaiJushinNadoNoJokyoSection(props: Props) {

    const { assessmentSheetUpdater, activated } = props;
    const assessmentSheetField = new AssessmentSheetField(activated);

    return <>
        <div className={styles.card}>
            <h3 className={styles.title}>既往歴・現症</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        既往歴・現症
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderTextareaField(
                        assessmentSheetUpdater,
                        "honninNoKenkoJotaiJushinNadoNoJokyo.kioRekiGensho",
                        { width: "auto", rows: 5 }
                    )}
                </div>;
            })}
        </div>
        <div className={styles.card}>
            <h3 className={styles.title}>身長</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        身長
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<number | null>(
                        assessmentSheetUpdater,
                        "honninNoKenkoJotaiJushinNadoNoJokyo.shincho",
                        positiveFloatWith({ width: 120, trailing: <span className={styles.modifier}>cm</span> }),
                        { trailing: <span className={styles.trailing}>cm</span> }
                    )}
                </div>;
            })}
        </div>
        <div className={styles.card}>
            <h3 className={styles.title}>体重</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        体重
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<number | null>(
                        assessmentSheetUpdater,
                        "honninNoKenkoJotaiJushinNadoNoJokyo.taiju",
                        positiveFloatWith({ width: 120, trailing: <span className={styles.modifier}>kg</span> }),
                        { trailing: <span className={styles.trailing}>kg</span> }
                    )}
                </div>;
            })}
        </div>
        <div className={styles.card}>
            <h3 className={styles.title}>歯の状況</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        歯の状況
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.horizontal}>
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKenkoJotaiJushinNadoNoJokyo.haNoJokyo.haAri",
                                checkboxWith("歯あり")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKenkoJotaiJushinNadoNoJokyo.haNoJokyo.haNashi",
                                checkboxWith("歯なし")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKenkoJotaiJushinNadoNoJokyo.haNoJokyo.soIreba",
                                checkboxWith("総入れ歯")
                            )}
                            {assessmentSheetUpdater.of<boolean>(
                                "honninNoKenkoJotaiJushinNadoNoJokyo.haNoJokyo.kyokubuGishi",
                                checkboxWith("局部義歯")
                            )}
                        </div>
                    </div>
                </div>;
            })}
        </div>
        <div className={styles.card}>
            <h3 className={styles.title}>特記事項（病気やけが、障害等に関わる事項。改善の可能性等）</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        特記事項（病気やけが、障害等に関わる事項。改善の可能性等）
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderTextareaField(
                        assessmentSheetUpdater,
                        "honninNoKenkoJotaiJushinNadoNoJokyo.tokkiJiko",
                        { width: "auto", rows: 5 }
                    )}
                </div>;
            })}
        </div>
        <div className={styles.card}>
            <h3 className={styles.title}>現在の受診状況（歯科含む）1</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        病名
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "honninNoKenkoJotaiJushinNadoNoJokyo.genzaiNoJushinJokyo[0].byomei",
                        textWith({ width: 280 })
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        薬の有無
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<Umu | null>(
                        assessmentSheetUpdater,
                        "honninNoKenkoJotaiJushinNadoNoJokyo.genzaiNoJushinJokyo[0].kusuriNoUmu",
                        umuLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        発症時期
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "honninNoKenkoJotaiJushinNadoNoJokyo.genzaiNoJushinJokyo[0].jushinJokyo.hasshoJiki",
                        textWith({ width: 280 })
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        受診頻度
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.vertical}>
                            {assessmentSheetField.renderRadioField<TeikiFuTeiki | null>(
                                assessmentSheetUpdater,
                                "honninNoKenkoJotaiJushinNadoNoJokyo.genzaiNoJushinJokyo[0].jushinJokyo.jushinHindo.teikiFuTeiki",
                                teikiFuTeikiLabels,
                                { tag: false }
                            )}
                            <div className={styles.horizontal}>
                                {assessmentSheetField.renderRadioField<TsukiShu | null>(
                                    assessmentSheetUpdater,
                                    "honninNoKenkoJotaiJushinNadoNoJokyo.genzaiNoJushinJokyo[0].jushinJokyo.jushinHindo.tsukiShu",
                                    tsukiShuLabels,
                                    { tag: false }
                                )}
                                {assessmentSheetField.renderField<string | null>(
                                    assessmentSheetUpdater,
                                    "honninNoKenkoJotaiJushinNadoNoJokyo.genzaiNoJushinJokyo[0].jushinJokyo.jushinHindo.kaisu",
                                    textWith({ width: 120, trailing: <span className={styles.modifier}>回</span> }),
                                    { tag: false, trailing: <span className={styles.trailing}>回</span> }
                                )}
                            </div>
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        受診状況
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<JushinJokyo | null>(
                        assessmentSheetUpdater,
                        "honninNoKenkoJotaiJushinNadoNoJokyo.genzaiNoJushinJokyo[0].jushinJokyo.jushinJokyo",
                        jushinJokyoLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        医療機関
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "honninNoKenkoJotaiJushinNadoNoJokyo.genzaiNoJushinJokyo[0].jushinByoin.iryoKikan",
                        textWith({ width: 280 })
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        診療科
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "honninNoKenkoJotaiJushinNadoNoJokyo.genzaiNoJushinJokyo[0].jushinByoin.shinryoKa",
                        textWith({ width: 280 })
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        主治医
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "honninNoKenkoJotaiJushinNadoNoJokyo.genzaiNoJushinJokyo[0].jushinByoin.shujii",
                        textWith({ width: 280 })
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        連絡先
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "honninNoKenkoJotaiJushinNadoNoJokyo.genzaiNoJushinJokyo[0].jushinByoin.renrakuSaki",
                        textWith({ width: 280 })
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        受診方法留意点等
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "honninNoKenkoJotaiJushinNadoNoJokyo.genzaiNoJushinJokyo[0].jushinHohoRyuiTenNado",
                        textWith({ width: "auto" })
                    )}
                </div>;
            })}
        </div>
        <div className={styles.card}>
            <h3 className={styles.title}>現在の受診状況（歯科含む）2</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        病名
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "honninNoKenkoJotaiJushinNadoNoJokyo.genzaiNoJushinJokyo[1].byomei",
                        textWith({ width: 280 })
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        薬の有無
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<Umu | null>(
                        assessmentSheetUpdater,
                        "honninNoKenkoJotaiJushinNadoNoJokyo.genzaiNoJushinJokyo[1].kusuriNoUmu",
                        umuLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        発症時期
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "honninNoKenkoJotaiJushinNadoNoJokyo.genzaiNoJushinJokyo[1].jushinJokyo.hasshoJiki",
                        textWith({ width: 280 })
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        受診頻度
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.vertical}>
                            {assessmentSheetField.renderRadioField<TeikiFuTeiki | null>(
                                assessmentSheetUpdater,
                                "honninNoKenkoJotaiJushinNadoNoJokyo.genzaiNoJushinJokyo[1].jushinJokyo.jushinHindo.teikiFuTeiki",
                                teikiFuTeikiLabels,
                                { tag: false }
                            )}
                            <div className={styles.horizontal}>
                                {assessmentSheetField.renderRadioField<TsukiShu | null>(
                                    assessmentSheetUpdater,
                                    "honninNoKenkoJotaiJushinNadoNoJokyo.genzaiNoJushinJokyo[1].jushinJokyo.jushinHindo.tsukiShu",
                                    tsukiShuLabels,
                                    { tag: false }
                                )}
                                {assessmentSheetField.renderField<string | null>(
                                    assessmentSheetUpdater,
                                    "honninNoKenkoJotaiJushinNadoNoJokyo.genzaiNoJushinJokyo[1].jushinJokyo.jushinHindo.kaisu",
                                    textWith({ width: 120, trailing: <span className={styles.modifier}>回</span> }),
                                    { tag: false, trailing: <span className={styles.trailing}>回</span> }
                                )}
                            </div>
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        受診状況
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<JushinJokyo | null>(
                        assessmentSheetUpdater,
                        "honninNoKenkoJotaiJushinNadoNoJokyo.genzaiNoJushinJokyo[1].jushinJokyo.jushinJokyo",
                        jushinJokyoLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        医療機関
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "honninNoKenkoJotaiJushinNadoNoJokyo.genzaiNoJushinJokyo[1].jushinByoin.iryoKikan",
                        textWith({ width: 280 })
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        診療科
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "honninNoKenkoJotaiJushinNadoNoJokyo.genzaiNoJushinJokyo[1].jushinByoin.shinryoKa",
                        textWith({ width: 280 })
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        主治医
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "honninNoKenkoJotaiJushinNadoNoJokyo.genzaiNoJushinJokyo[1].jushinByoin.shujii",
                        textWith({ width: 280 })
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        連絡先
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "honninNoKenkoJotaiJushinNadoNoJokyo.genzaiNoJushinJokyo[1].jushinByoin.renrakuSaki",
                        textWith({ width: 280 })
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        受診方法留意点等
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "honninNoKenkoJotaiJushinNadoNoJokyo.genzaiNoJushinJokyo[1].jushinHohoRyuiTenNado",
                        textWith({ width: "auto" })
                    )}
                </div>;
            })}
        </div>
        <div className={styles.card}>
            <h3 className={styles.title}>現在の受診状況（歯科含む）3</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        病名
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "honninNoKenkoJotaiJushinNadoNoJokyo.genzaiNoJushinJokyo[2].byomei",
                        textWith({ width: 280 })
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        薬の有無
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<Umu | null>(
                        assessmentSheetUpdater,
                        "honninNoKenkoJotaiJushinNadoNoJokyo.genzaiNoJushinJokyo[2].kusuriNoUmu",
                        umuLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        発症時期
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "honninNoKenkoJotaiJushinNadoNoJokyo.genzaiNoJushinJokyo[2].jushinJokyo.hasshoJiki",
                        textWith({ width: 280 })
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        受診頻度
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.vertical}>
                            {assessmentSheetField.renderRadioField<TeikiFuTeiki | null>(
                                assessmentSheetUpdater,
                                "honninNoKenkoJotaiJushinNadoNoJokyo.genzaiNoJushinJokyo[2].jushinJokyo.jushinHindo.teikiFuTeiki",
                                teikiFuTeikiLabels,
                                { tag: false }
                            )}
                            <div className={styles.horizontal}>
                                {assessmentSheetField.renderRadioField<TsukiShu | null>(
                                    assessmentSheetUpdater,
                                    "honninNoKenkoJotaiJushinNadoNoJokyo.genzaiNoJushinJokyo[2].jushinJokyo.jushinHindo.tsukiShu",
                                    tsukiShuLabels,
                                    { tag: false }
                                )}
                                {assessmentSheetField.renderField<string | null>(
                                    assessmentSheetUpdater,
                                    "honninNoKenkoJotaiJushinNadoNoJokyo.genzaiNoJushinJokyo[2].jushinJokyo.jushinHindo.kaisu",
                                    textWith({ width: 120, trailing: <span className={styles.modifier}>回</span> }),
                                    { tag: false, trailing: <span className={styles.trailing}>回</span> }
                                )}
                            </div>
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        受診状況
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<JushinJokyo | null>(
                        assessmentSheetUpdater,
                        "honninNoKenkoJotaiJushinNadoNoJokyo.genzaiNoJushinJokyo[2].jushinJokyo.jushinJokyo",
                        jushinJokyoLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        医療機関
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "honninNoKenkoJotaiJushinNadoNoJokyo.genzaiNoJushinJokyo[2].jushinByoin.iryoKikan",
                        textWith({ width: 280 })
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        診療科
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "honninNoKenkoJotaiJushinNadoNoJokyo.genzaiNoJushinJokyo[2].jushinByoin.shinryoKa",
                        textWith({ width: 280 })
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        主治医
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "honninNoKenkoJotaiJushinNadoNoJokyo.genzaiNoJushinJokyo[2].jushinByoin.shujii",
                        textWith({ width: 280 })
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        連絡先
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "honninNoKenkoJotaiJushinNadoNoJokyo.genzaiNoJushinJokyo[2].jushinByoin.renrakuSaki",
                        textWith({ width: 280 })
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        受診方法留意点等
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "honninNoKenkoJotaiJushinNadoNoJokyo.genzaiNoJushinJokyo[2].jushinHohoRyuiTenNado",
                        textWith({ width: "auto" })
                    )}
                </div>;
            })}
        </div>
        <div className={styles.card}>
            <h3 className={styles.title}>現在の受診状況（歯科含む）4</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        病名
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "honninNoKenkoJotaiJushinNadoNoJokyo.genzaiNoJushinJokyo[3].byomei",
                        textWith({ width: 280 })
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        薬の有無
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<Umu | null>(
                        assessmentSheetUpdater,
                        "honninNoKenkoJotaiJushinNadoNoJokyo.genzaiNoJushinJokyo[3].kusuriNoUmu",
                        umuLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        発症時期
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "honninNoKenkoJotaiJushinNadoNoJokyo.genzaiNoJushinJokyo[3].jushinJokyo.hasshoJiki",
                        textWith({ width: 280 })
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        受診頻度
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.vertical}>
                            {assessmentSheetField.renderRadioField<TeikiFuTeiki | null>(
                                assessmentSheetUpdater,
                                "honninNoKenkoJotaiJushinNadoNoJokyo.genzaiNoJushinJokyo[3].jushinJokyo.jushinHindo.teikiFuTeiki",
                                teikiFuTeikiLabels,
                                { tag: false }
                            )}
                            <div className={styles.horizontal}>
                                {assessmentSheetField.renderRadioField<TsukiShu | null>(
                                    assessmentSheetUpdater,
                                    "honninNoKenkoJotaiJushinNadoNoJokyo.genzaiNoJushinJokyo[3].jushinJokyo.jushinHindo.tsukiShu",
                                    tsukiShuLabels,
                                    { tag: false }
                                )}
                                {assessmentSheetField.renderField<string | null>(
                                    assessmentSheetUpdater,
                                    "honninNoKenkoJotaiJushinNadoNoJokyo.genzaiNoJushinJokyo[3].jushinJokyo.jushinHindo.kaisu",
                                    textWith({ width: 120, trailing: <span className={styles.modifier}>回</span> }),
                                    { tag: false, trailing: <span className={styles.trailing}>回</span> }
                                )}
                            </div>
                        </div>
                    </div>
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        受診状況
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<JushinJokyo | null>(
                        assessmentSheetUpdater,
                        "honninNoKenkoJotaiJushinNadoNoJokyo.genzaiNoJushinJokyo[3].jushinJokyo.jushinJokyo",
                        jushinJokyoLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        医療機関
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "honninNoKenkoJotaiJushinNadoNoJokyo.genzaiNoJushinJokyo[3].jushinByoin.iryoKikan",
                        textWith({ width: 280 })
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        診療科
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "honninNoKenkoJotaiJushinNadoNoJokyo.genzaiNoJushinJokyo[3].jushinByoin.shinryoKa",
                        textWith({ width: 280 })
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        主治医
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "honninNoKenkoJotaiJushinNadoNoJokyo.genzaiNoJushinJokyo[3].jushinByoin.shujii",
                        textWith({ width: 280 })
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        連絡先
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "honninNoKenkoJotaiJushinNadoNoJokyo.genzaiNoJushinJokyo[3].jushinByoin.renrakuSaki",
                        textWith({ width: 280 })
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        受診方法留意点等
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "honninNoKenkoJotaiJushinNadoNoJokyo.genzaiNoJushinJokyo[3].jushinHohoRyuiTenNado",
                        textWith({ width: "auto" })
                    )}
                </div>;
            })}
        </div>
        <div className={styles.card}>
            <h3 className={styles.title}>往診可能な医療機関</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        有無
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<Umu | null>(
                        assessmentSheetUpdater,
                        "honninNoKenkoJotaiJushinNadoNoJokyo.oshinKanoNaIryoKikan.umu",
                        umuLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        医療機関名
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "honninNoKenkoJotaiJushinNadoNoJokyo.oshinKanoNaIryoKikan.iryoKikanMei",
                        textWith({ width: 280 })
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        電話番号
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "honninNoKenkoJotaiJushinNadoNoJokyo.oshinKanoNaIryoKikan.denwaBango",
                        textWith({ width: 280 })
                    )}
                </div>;
            })}
        </div>
        <div className={styles.card}>
            <h3 className={styles.title}>緊急入院できる医療機関</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        有無
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<Umu | null>(
                        assessmentSheetUpdater,
                        "honninNoKenkoJotaiJushinNadoNoJokyo.kinkyuNyuinDekiruIryoKikan.umu",
                        umuLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        医療機関名
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "honninNoKenkoJotaiJushinNadoNoJokyo.kinkyuNyuinDekiruIryoKikan.iryoKikanMei",
                        textWith({ width: 280 })
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        電話番号
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "honninNoKenkoJotaiJushinNadoNoJokyo.kinkyuNyuinDekiruIryoKikan.denwaBango",
                        textWith({ width: 280 })
                    )}
                </div>;
            })}
        </div>
        <div className={styles.card}>
            <h3 className={styles.title}>相談、処方を受けている薬局（かかりつけ薬局）</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        有無
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderRadioField<Umu | null>(
                        assessmentSheetUpdater,
                        "honninNoKenkoJotaiJushinNadoNoJokyo.sodanShohoOUkeTeIruYakkyoku.umu",
                        umuLabels
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        医療機関名
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "honninNoKenkoJotaiJushinNadoNoJokyo.sodanShohoOUkeTeIruYakkyoku.iryoKikanMei",
                        textWith({ width: 280 })
                    )}
                </div>;
            })}
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        電話番号
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderField<string | null>(
                        assessmentSheetUpdater,
                        "honninNoKenkoJotaiJushinNadoNoJokyo.sodanShohoOUkeTeIruYakkyoku.denwaBango",
                        textWith({ width: 280 })
                    )}
                </div>;
            })}
        </div>
        <div className={styles.card}>
            <h3 className={styles.title}>特記、生活上配慮すべき課題など</h3>
            {assessmentSheetUpdater.group(assessmentSheetUpdater => {
                return <div className={styles.row}>
                    <div className={styles.label}>
                        特記、生活上配慮すべき課題など
                        <div className={styles.saveIndicator}>{assessmentSheetUpdater.saveIndicator}</div>
                    </div>
                    {assessmentSheetField.renderTextareaField(
                        assessmentSheetUpdater,
                        "honninNoKenkoJotaiJushinNadoNoJokyo.tokkiSeikatsuJoHairyoSuBekiKadaiNado",
                        { width: "auto", rows: 5 }
                    )}
                </div>;
            })}
        </div>
    </>;
}

type Props = {
    assessmentSheetUpdater: AssessmentSheetUpdater,
    activated: boolean,
};
