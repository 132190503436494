import ReactPaginate from "react-paginate";
import React from "react";
import styles from "./Pager.module.scss";
import { LeftArrowIcon } from "../icon/LeftArrowIcon";
import { RightArrowIcon } from "../icon/RightArrowIcon";

export function Pager(props: Props) {

    const { pageCount, onChangePage, currentPage } = props;

    return <>
        <ReactPaginate
            pageCount={pageCount}
            containerClassName={styles.container}
            pageClassName={styles.button}
            previousClassName={styles.previousButton}
            nextClassName={styles.nextButton}
            previousLabel={<><LeftArrowIcon/>前へ</>}
            nextLabel={<>次へ<RightArrowIcon/></>}
            breakClassName={styles.break}
            disabledClassName={styles.disabled}
            activeClassName={styles.activePage}
            onPageChange={selectedItem => {onChangePage(selectedItem.selected)}}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            forcePage={currentPage}
        />
    </>
}

type Props = {
    pageCount: number
    onChangePage: (page: number) => void
    currentPage?: number
}
