import React, { MouseEvent } from "react";
import { InDialogSecondaryButton } from "../../../../components/button/InDialogSecondaryButton";
import { NewIcon } from "../../../../components/icon/NewIcon";
import { SelectedButton } from "./SelectedButton";

export function SelectButton(props: Props) {

    const { selected, onClick } = props;

    const renderButton = () => {
        if (selected) {
            return <SelectedButton onClick={onClick}/>
        } else {
            return <InDialogSecondaryButton width={256} leading={<NewIcon/>} onClick={onClick}>
                選択する
            </InDialogSecondaryButton>
        }
    }

    return <>
        {renderButton()}
    </>

}

type Props = {
    selected: boolean,
    onClick?: (event: MouseEvent) => void
}
