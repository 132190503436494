export class FetchSuggestionSupportServices {
    supportServices: string[];

    constructor(supportServices: string[]) {
        this.supportServices = supportServices;
    }

    static async extract(response: Response): Promise<FetchSuggestionSupportServices> {
        const json = await response.json();
        return new FetchSuggestionSupportServices(json.supportServices);
    }
}
