import "whatwg-fetch";
import { Create } from "./Create";
import { ApiError } from "../ApiError";
import { get, post } from "../functions";
import { GetSumCount } from "./GetSumCount";
import { GetManageList } from "./GetManageList";
import { ChatgptManageUsage } from "../../../domain/chatgpt/ChatgptManageUsage";
import { ChatgptUsageCount } from "../../../domain/chatgpt/ChatgptUsageCount";
import { PromptHistory } from "../../../domain/chatgpt/PromptHistory";
import { SimpleCareRecipient } from "../../../domain/carerecipient/SimpleCareRecipient";

export const ChatGptApi = new (class {
    async create(
        userContent: string,
        userConsultation: string,
        familyConsultation: string,
        assistantContent: string,
        fileDataURL: string,
        file: File | undefined,
        careRecipient: SimpleCareRecipient | null,
        projectId: string
    ): Promise<Create | ApiError> {
        let fileName = ""
        let tmpFileData = fileDataURL
        let contentType = ""
        if (file) {
            fileName = file.name
            // データ部のみ抜き出し
            if (fileDataURL.length > 0) {
                const index = fileDataURL.indexOf(",")
                if ( index >= 0) {
                    tmpFileData = fileDataURL.substring(index + 1)
                    let ctEndIndex = fileDataURL.indexOf(";")
                    if (ctEndIndex < 0) {
                        ctEndIndex = index
                    }
                    let ctStartIndex = fileDataURL.indexOf("data:")
                    if (ctStartIndex >= 0) {
                        ctStartIndex += "data:".length
                    } else {
                        ctStartIndex = 0
                    }
                    contentType = fileDataURL.substring(ctStartIndex, ctEndIndex)
                }
            }
        }
        // JSON データを追加
        const promptData = {
            systemContent: "",
            userContent,
            assistantContent,
            userConsultation,
            familyConsultation,
            chatGptTmpFileData: tmpFileData,
            chatGptTmpFileName: fileName,
            chatGptTmpContentType: contentType
        };

        // APIにリクエストを送信
        // API ファイルデータはBase64で設定
        const body = {
            chatGptInputData:promptData,
            careRecipientId:careRecipient?.id,
            projectId:projectId
        };
        const response = await post("/api/tableTwo/draft/draftTableTwo", body);
        return (response.ok ? Create.extract : ApiError.extract)(response);
    }

    async getSumCount(): Promise<GetSumCount | ApiError> {
        const response = await get("/api/tableTwo/draft/getSumCount");
        return (response.ok ? GetSumCount.extract : ApiError.extract)(response);
    }

    /** 直近の履歴を取得(現ログインユーザ) */
    async getLastPromptHistory(): Promise<PromptHistory | ApiError> {
        const response = await get("/api/tableTwo/draft/getLastHistory");
        return (response.ok ? PromptHistory.extract : ApiError.extract)(response);
    }

    /** ChatGPT使用情報を取得(指定UserID) */
    async getUserUsageCount(userId: string): Promise<ChatgptUsageCount | ApiError> {
        const response = await get(`/api/tableTwo/draft/getCount/${userId}`);
        return (response.ok ? ChatgptUsageCount.extract : ApiError.extract)(response);
    }

    /** ChatGPT使用情報を取得(現ログインユーザ) */
    async getUsageCount(): Promise<ChatgptUsageCount | ApiError> {
        const response = await get("/api/tableTwo/draft/getUsageCount");
        return (response.ok ? ChatgptUsageCount.extract : ApiError.extract)(response);
    }

    /** 利用制限の設定情報を取得(全て) */
    async getManageSettings(): Promise<GetManageList | ApiError> {
        const response = await get("/api/chatgpt/manage/getAllSettings");
        return (response.ok ? GetManageList.extract : ApiError.extract)(response);
    }

    /** 利用制限の設定情報を取得 現ログインユーザ */
    async getManageSetting(): Promise<ChatgptManageUsage | ApiError> {
        const response = await get("/api/chatgpt/manage/getSetting");
        return (response.ok ? ChatgptManageUsage.extract : ApiError.extract)(response);
    }

    /** 利用制限の設定情報を取得(指定UserID) */
    async findManageSetting(userId: string): Promise<ChatgptManageUsage | ApiError> {
        const response = await get(`/api/chatgpt/manage/get/${userId}`);
        return (response.ok ? ChatgptManageUsage.extract : ApiError.extract)(response);
    }

    /** 利用制限の設定情報を設定 */
    async setManageSetting(
        userId: string,
        usageType: number, 
        textLimit: number, 
        fileLimit: number,
        sendLimit: number,
    ): Promise<void | ApiError> {
        const body = { userId, usageType, textLimit, fileLimit, sendLimit };
        const response = await post("/api/chatgpt/manage/register", body);
        if (!response.ok) {
            throw new ApiError(response.status.toString(), response.statusText);
        }
    }

})();
