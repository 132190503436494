import { Disease } from "./Disease";
import { DiseaseCategory } from "./DiseaseCategory";
import { Watch } from "./Watch";
import { AssessmentCheckpoint } from "./AssessmentCheckpoint";
import { SuggestingService } from "./SuggestingService";

const diseaseDetailType = Symbol();

export class DiseaseDetail extends Disease {
    [diseaseDetailType]: any;

    category: DiseaseCategory;

    description: string;

    abstractDiseases: string[];

    symptoms: string[];

    misleadingDiseases: string[];

    watch: Watch;

    assessmentCheckpoint: AssessmentCheckpoint;

    suggestingServices: SuggestingService[];

    advices: string[];

    constructor(
        disease: Disease,
        category: DiseaseCategory,
        description: string,
        abstractDiseases: string[],
        symptoms: string[],
        misleadingDiseases: string[],
        watch: Watch,
        assessmentCheckpoint: AssessmentCheckpoint,
        suggestingServices: SuggestingService[],
        advices: string[],
    ) {
        super(
            disease.id,
            disease.name,
        )
        this.category = category;
        this.description = description;
        this.abstractDiseases = abstractDiseases;
        this.symptoms = symptoms;
        this.misleadingDiseases = misleadingDiseases;
        this.watch = watch;
        this.assessmentCheckpoint = assessmentCheckpoint;
        this.suggestingServices = suggestingServices;
        this.advices = advices;
    }
}
