import { User } from "../../../domain/user/User";
import { ApiError } from "../ApiError";
import { get, post, put } from "../functions";
import { UpdateEmailRequestBody, UpdateDisplayNameRequestBody } from "./MyselfApiRequestBody";

export const MyselfApi = new class {
    updateDisplayName = async (requestBody: UpdateDisplayNameRequestBody): Promise<void | ApiError> => {
        const response: Response = await post("/api/myself/updateDisplayName", requestBody);
        if (!response.ok) {
            throw new ApiError(response.status.toString(), response.statusText);
        }
    };

    updateEmail = async (requestBody: UpdateEmailRequestBody): Promise<void | ApiError> => {
        const response: Response = await post("/api/myself/updateEmail", requestBody);
        if (!response.ok) {
            throw new ApiError(response.status.toString(), response.statusText);
        }
    };

    updatePassword(currentPassword: string, newPassword: string, success: () => void, failure: () => void) {
        const body = {
            currentPassword,
            newPassword,
        }
        post("/api/myself/updatePassword", body)
            .then((response) => {
                if (!response.ok) {
                    failure()
                    return
                }
                success()
            })
    }

    retrieveProfile = async (): Promise<User> => {
        return await get("/api/myself")
            .then((response) => response.json())
    }

    requestPasswordReset = (email: string) => {
        const body = {
            email: email
        }
        post("/api/myself/password/reset", body)
            .then(() => {})
    }

    confirmToken = (token: string, onSuccess: () => void, onFailure: () => void) => {
        get(`/api/myself/password/token/${token}`)
            .then((response) => {
                if (!response.ok) {
                    onFailure()
                    return
                }
                onSuccess()
            })
    }

    resetNewPassword = (password: string, token: string, onSuccess: () => void, onFailure: () => void) => {
        const body = {
            token: token,
            newPassword: password
        }
        put("/api/myself/password", body)
            .then(response => {
                if (!response.ok) {
                    onFailure()
                    return
                }
                onSuccess()
            })
    }
}()
