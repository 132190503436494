import React, { useCallback, useLayoutEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { State } from "../../../../state/store";
import styles from "./AssessmentSheetContent.module.scss";
import { AssessmentSheet } from "../../../../domain/assessmentsheet/AssessmentSheet";
import { AssessmentSheetSection } from "../AssessmentSheetSection";
import { AssessmentSheetSectionTitle } from "./AssessmentSheetSectionTitle";
import { AssessmentSheetIndex } from "./AssessmentSheetIndex";
import { AssessmentSheetUpdater } from "./sections/AssessmentSheetUpdater";
import { FaceSheetSection } from "./sections/FaceSheetSection";
import { FaceSheetRsLoginSection } from "./sections/FaceSheetRsLoginSection";
import { KazokuJokyoToInformalNaShienNoJokyoSection } from "./sections/KazokuJokyoToInformalNaShienNoJokyoSection";
import { ServiceRiyoJokyoSection } from "./sections/ServiceRiyoJokyoSection";
import { JukyoNadoNoJokyoSection } from "./sections/JukyoNadoNoJokyoSection";
import { HonninNoKenkoJotaiJushinNadoNoJokyoSection } from "./sections/HonninNoKenkoJotaiJushinNadoNoJokyoSection";
import { KihonDosaSection } from "./sections/KihonDosaSection";
import { SeikatsuKinoSection } from "./sections/SeikatsuKinoSection";
import { NinchiKinoSection } from "./sections/NinchiKinoSection";
import { SeishinKodoShogaiSection } from "./sections/SeishinKodoShogaiSection";
import { NinchiKinoSeishinKodoshogaiZempanSection } from "./sections/NinchiKinoSeishinKodoshogaiZempanSection";
import { ShakaiSeikatsuRyokuSection } from "./sections/ShakaiSeikatsuRyokuSection";
import { IryoKenkoKankeiSection } from "./sections/IryoKenkoKankeiSection";
import { KaigoNikansuruIshiNoIkenSection } from "./sections/KaigoNikansuruIshiNoIkenSection";
import { ZentaiNoMatomeSection } from "./sections/ZentaiNoMatomeSection";

export function AssessmentSheetContent(props: Props) {
    const { projectId, assessmentSheet, globalHeaderHeight, projectHeaderHeight, headerHeight, activated } = props;
    const assessmentSheetUpdater = new AssessmentSheetUpdater(projectId, assessmentSheet, activated);

    const [activeSection, setActiveSection] = useState<AssessmentSheetSection | null>(AssessmentSheetSection.FACE_SHEET);
    const faceSheetSectionRef = useRef<HTMLDivElement>(null);
    const kazokuJokyoToInformalNaShienNoJokyoSectionRef = useRef<HTMLDivElement>(null);
    const serviceRiyoJokyoRef = useRef<HTMLDivElement>(null);
    const jukyoNadoNoJokyoRef = useRef<HTMLDivElement>(null);
    const honninNoKenkoJotaiJushinNadoNoJokyoRef = useRef<HTMLDivElement>(null);
    const kihonDosaRef = useRef<HTMLDivElement>(null);
    const seikatsuKinoRef = useRef<HTMLDivElement>(null);
    const ninchiKinoRef = useRef<HTMLDivElement>(null);
    const seishinKodoShogaiRef = useRef<HTMLDivElement>(null);
    const ninchiKinoSeishinKodoshogaiZempanRef = useRef<HTMLDivElement>(null);
    const shakaiSeikatsuRyokuRef = useRef<HTMLDivElement>(null);
    const iryoKenkoKankeiRef = useRef<HTMLDivElement>(null);
    const kaigoNikansuruIshiNoIkenRef = useRef<HTMLDivElement>(null);
    const zentaiNoMatomeRef = useRef<HTMLDivElement>(null);
    const sectionRefs = [
        { section: AssessmentSheetSection.FACE_SHEET, value: faceSheetSectionRef },
        { section: AssessmentSheetSection.KAZOKU_JOKYO_TO_INFORMAL_NA_SHIEN_NO_JOKYO, value: kazokuJokyoToInformalNaShienNoJokyoSectionRef },
        { section: AssessmentSheetSection.SERVICE_RIYO_JOKYO, value: serviceRiyoJokyoRef },
        { section: AssessmentSheetSection.JUKYO_NADO_NO_JOKYO, value: jukyoNadoNoJokyoRef },
        { section: AssessmentSheetSection.HONNIN_NO_KENKO_JOTAI_JUSHIN_NADO_NO_JOKYO, value: honninNoKenkoJotaiJushinNadoNoJokyoRef },
        { section: AssessmentSheetSection.KIHON_DOSA, value: kihonDosaRef },
        { section: AssessmentSheetSection.SEIKATSU_KINO, value: seikatsuKinoRef },
        { section: AssessmentSheetSection.NINCHI_KINO, value: ninchiKinoRef },
        { section: AssessmentSheetSection.SEISHIN_KODO_SHOGAI, value: seishinKodoShogaiRef },
        { section: AssessmentSheetSection.NINCHI_KINO_SEISHIN_KODOSHOGAI_ZEMPAN, value: ninchiKinoSeishinKodoshogaiZempanRef },
        { section: AssessmentSheetSection.SHAKAI_SEIKATSU_RYOKU, value: shakaiSeikatsuRyokuRef },
        { section: AssessmentSheetSection.IRYO_KENKO_KANKEI, value: iryoKenkoKankeiRef },
        { section: AssessmentSheetSection.KAIGO_NIKANSURU_ISHI_NO_IKEN, value: kaigoNikansuruIshiNoIkenRef },
        { section: AssessmentSheetSection.ZENTAI_NO_MATOME, value: zentaiNoMatomeRef },
    ];
    const indexRef = useRef<HTMLDivElement>(null);
    const onScroll = useCallback(() => {
        const scrollTop = (document.scrollingElement ?? document.documentElement).scrollTop;
        if (sectionRefs.every(sectionRef => sectionRef.value.current)) {
            const sectionElements = sectionRefs.map(sectionRef => {
                const { section, value: ref } = sectionRef;
                const element = ref.current!;
                return { section, value: element };
            });
            const activeSection = activeSectionAt(scrollTop, projectHeaderHeight, sectionElements);
            setActiveSection(activeSection);
        }
        if (indexRef.current) {
            stickyIndexIf(scrollTop, globalHeaderHeight, projectHeaderHeight, headerHeight, indexRef.current);
        }
    }, [sectionRefs, indexRef, globalHeaderHeight, projectHeaderHeight, headerHeight]);

    useLayoutEffect(() => {
        document.addEventListener("scroll", onScroll);
        return () => document.removeEventListener("scroll", onScroll);
    }, [onScroll]);

    const rsLoginIn = useSelector((state: State) => state.authentication.rsLoginIn);

    return <div className={styles.container}>
        <div className={styles.sheet}>
            <div className={styles.section} ref={faceSheetSectionRef}>
                {!rsLoginIn ?
                <FaceSheetSection assessmentSheetUpdater={assessmentSheetUpdater} activated={activated}/>
                :
                <FaceSheetRsLoginSection assessmentSheetUpdater={assessmentSheetUpdater} activated={activated}/>
                }
            </div>
            {!rsLoginIn &&
            <>
            <AssessmentSheetSectionTitle section={AssessmentSheetSection.KAZOKU_JOKYO_TO_INFORMAL_NA_SHIEN_NO_JOKYO}>
                家族状況とインフォーマルな支援の状況
            </AssessmentSheetSectionTitle>
            <div className={styles.section} ref={kazokuJokyoToInformalNaShienNoJokyoSectionRef}>
                <KazokuJokyoToInformalNaShienNoJokyoSection assessmentSheetUpdater={assessmentSheetUpdater} activated={activated}/>
            </div>
            <AssessmentSheetSectionTitle section={AssessmentSheetSection.SERVICE_RIYO_JOKYO}>
                サービス利用状況
            </AssessmentSheetSectionTitle>
            <div className={styles.section} ref={serviceRiyoJokyoRef}>
                <ServiceRiyoJokyoSection assessmentSheetUpdater={assessmentSheetUpdater} activated={activated}/>
            </div>
            <AssessmentSheetSectionTitle section={AssessmentSheetSection.JUKYO_NADO_NO_JOKYO}>
                住居等の状況
            </AssessmentSheetSectionTitle>
            <div className={styles.section} ref={jukyoNadoNoJokyoRef}>
                <JukyoNadoNoJokyoSection assessmentSheetUpdater={assessmentSheetUpdater} activated={activated}/>
            </div>
            <AssessmentSheetSectionTitle section={AssessmentSheetSection.HONNIN_NO_KENKO_JOTAI_JUSHIN_NADO_NO_JOKYO}>
                本人の健康状態・受診等の状況
            </AssessmentSheetSectionTitle>
            <div className={styles.section} ref={honninNoKenkoJotaiJushinNadoNoJokyoRef}>
                <HonninNoKenkoJotaiJushinNadoNoJokyoSection assessmentSheetUpdater={assessmentSheetUpdater} activated={activated}/>
            </div>
            <AssessmentSheetSectionTitle section={AssessmentSheetSection.KIHON_DOSA}>
                本人の基本動作等の状況と援助内容の詳細 / 1. 基本（身体機能・起居）動作
            </AssessmentSheetSectionTitle>
            <div className={styles.section} ref={kihonDosaRef}>
                <KihonDosaSection assessmentSheetUpdater={assessmentSheetUpdater} activated={activated}/>
            </div>
            <AssessmentSheetSectionTitle section={AssessmentSheetSection.SEIKATSU_KINO}>
                本人の基本動作等の状況と援助内容の詳細 / 2. 生活機能（食事・排泄等）
            </AssessmentSheetSectionTitle>
            <div className={styles.section} ref={seikatsuKinoRef}>
                <SeikatsuKinoSection assessmentSheetUpdater={assessmentSheetUpdater} activated={activated}/>
            </div>
            <AssessmentSheetSectionTitle section={AssessmentSheetSection.NINCHI_KINO}>
                本人の基本動作等の状況と援助内容の詳細 / 3. 認知機能
            </AssessmentSheetSectionTitle>
            <div className={styles.section} ref={ninchiKinoRef}>
                <NinchiKinoSection assessmentSheetUpdater={assessmentSheetUpdater} activated={activated}/>
            </div>
            <AssessmentSheetSectionTitle section={AssessmentSheetSection.SEISHIN_KODO_SHOGAI}>
                本人の基本動作等の状況と援助内容の詳細 / 4. 精神・行動障害
            </AssessmentSheetSectionTitle>
            <div className={styles.section} ref={seishinKodoShogaiRef}>
                <SeishinKodoShogaiSection assessmentSheetUpdater={assessmentSheetUpdater} activated={activated}/>
            </div>
            <AssessmentSheetSectionTitle section={AssessmentSheetSection.NINCHI_KINO_SEISHIN_KODOSHOGAI_ZEMPAN}>
                本人の基本動作等の状況と援助内容の詳細 / 3. 認知機能、4. 精神・行動障害 全般
            </AssessmentSheetSectionTitle>
            <div className={styles.section} ref={ninchiKinoSeishinKodoshogaiZempanRef}>
                <NinchiKinoSeishinKodoshogaiZempanSection assessmentSheetUpdater={assessmentSheetUpdater} activated={activated}/>
            </div>
            <AssessmentSheetSectionTitle section={AssessmentSheetSection.SHAKAI_SEIKATSU_RYOKU}>
                本人の基本動作等の状況と援助内容の詳細 / 5. 社会生活（への適応）力
            </AssessmentSheetSectionTitle>
            <div className={styles.section} ref={shakaiSeikatsuRyokuRef}>
                <ShakaiSeikatsuRyokuSection assessmentSheetUpdater={assessmentSheetUpdater} activated={activated}/>
            </div>
            <AssessmentSheetSectionTitle section={AssessmentSheetSection.IRYO_KENKO_KANKEI}>
                本人の基本動作等の状況と援助内容の詳細 / 6. 医療・健康関係
            </AssessmentSheetSectionTitle>
            <div className={styles.section} ref={iryoKenkoKankeiRef}>
                <IryoKenkoKankeiSection assessmentSheetUpdater={assessmentSheetUpdater} activated={activated}/>
            </div>
            <AssessmentSheetSectionTitle section={AssessmentSheetSection.KAIGO_NIKANSURU_ISHI_NO_IKEN}>
                本人の基本動作等の状況と援助内容の詳細 / 介護に関する医師の意見
            </AssessmentSheetSectionTitle>
            <div className={styles.section} ref={kaigoNikansuruIshiNoIkenRef}>
                <KaigoNikansuruIshiNoIkenSection assessmentSheetUpdater={assessmentSheetUpdater} activated={activated}/>
            </div>
            <AssessmentSheetSectionTitle section={AssessmentSheetSection.ZENTAI_NO_MATOME}>
                全体のまとめ
            </AssessmentSheetSectionTitle>
            <div className={styles.section} ref={zentaiNoMatomeRef}>
                <ZentaiNoMatomeSection assessmentSheetUpdater={assessmentSheetUpdater} activated={activated}/>
            </div>
            </>
            }
        </div>
        {!rsLoginIn &&
        <div className={styles.index}>

            <div ref={indexRef}>
                <AssessmentSheetIndex activeSection={activeSection} scrollOffset={() => -(globalHeaderHeight() + projectHeaderHeight())}/>
            </div>
            {/*
              * indexをfixedにした際、要素サイズが無くなりsheetが広がってしまう。
              * それを防ぐため、indexと同等のサイズのものを配置することによってsheetが広がるのを防ぐ。
              * もちろん目に見えてはいけないため非表示。
              */}
            <div className={styles.hidden}>
                <AssessmentSheetIndex activeSection={AssessmentSheetSection.FACE_SHEET} scrollOffset={() => 0}/>
            </div>
        </div>
        }
    </div>;
}

type Props = {
    projectId: string,
    assessmentSheet: AssessmentSheet,
    globalHeaderHeight: () => number,
    projectHeaderHeight: () => number,
    headerHeight: () => number,
    activated: boolean,
};

type SectionWith<T> = {
    section: AssessmentSheetSection,
    value: T,
};

function activeSectionAt(scrollTop: number, projectHeaderHeight: () => number, sectionElements: SectionWith<HTMLDivElement>[]): AssessmentSheetSection | null {
    if (!sectionElements.length) return null;
    const sectionOverlappingDegrees = sectionElements.map(convertSectionOverlappingDegree(projectHeaderHeight));
    const maxSectionOverlappingDegree = sectionOverlappingDegrees.reduce((max, current) => current.value > max.value ? current : max);
    return maxSectionOverlappingDegree.section;
}

function convertSectionOverlappingDegree(projectHeaderHeight: () => number): (sectionElement: SectionWith<HTMLDivElement>) => SectionWith<number> {
    const windowTop = projectHeaderHeight(); // 本来stickyじゃないときはglobalHeaderの高さも考慮する必要があるが、現実的に問題ないので割り切る
    const windowBottom = window.innerHeight;
    return sectionElement => {
        // windowとelementがどれだけ重なっているか（overlappingDegree）を計算。期間の重なり判定と同様のことを行っている。
        // 参考: https://qiita.com/takkkun/items/7477cfc552f40e2616d4
        const { section, value: element } = sectionElement;
        const { top, bottom } = element.getBoundingClientRect();
        const start = Math.max(top, windowTop);
        const end = Math.min(bottom, windowBottom);
        const overlappingDegree = start < end ? end - start : 0;
        return { section, value: overlappingDegree };
    };
}

function stickyIndexIf(
    scrollTop: number,
    globalHeaderHeight: () => number,
    projectHeaderHeight: () => number,
    headerHeight: () => number,
    index: HTMLDivElement
) {
    const offset = 16; // projectSheetTabBarの上部余白と同じサイズ。この値を取得するのは困難なため、もう決め打ち
    const threshold = globalHeaderHeight() + headerHeight();
    if (scrollTop >= threshold) {
        index.style.position = "fixed";
        index.style.top = `${globalHeaderHeight() + projectHeaderHeight() + offset}px`;
    } else {
        index.style.position = "static";
        index.style.top = "0";
    }
}
