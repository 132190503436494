import { useCallback, useEffect, useState } from "react";
import { Office } from "../../../../domain/office/Office";
import { AdminOfficeApi } from "../../../../state/api/admin/office/AdminOfficeApi";
import useMounted from "../../common/useMounted";

export default function useOffices(): { offices: Office[], retrieveOffices: () => void } {

    const [offices, setOffices] = useState<Office[]>([])
    const mounted = useMounted();

    const retrieveOffices = useCallback(() => {
        AdminOfficeApi.offices()
            .then(res => {
                mounted.current && setOffices(res)
            });
    }, [mounted]);

    useEffect(() => {
        retrieveOffices();
    }, [retrieveOffices]);

    return { offices, retrieveOffices };
}
