import { SearchedDisease } from "../../../domain/diseaseknowledge/SearchedDisease";
import { Disease } from "../../../domain/diseaseknowledge/Disease";
import { DiseaseCategory } from "../../../domain/diseaseknowledge/DiseaseCategory";

export class SearchResult {

    searchedDiseases: SearchedDisease[];

    constructor(searchedDiseases: SearchedDisease[]) {
        this.searchedDiseases = searchedDiseases;
    }

    static async extract(response: Response): Promise<SearchResult> {
        const json = await response.json();
        const searchedDiseases = json.map(itemSearchedDiseases);
        return new SearchResult(searchedDiseases);
    }
}

type Item = {
    disease: Disease,
    category: DiseaseCategory,
    symptoms: string[],
}

function itemSearchedDiseases(item: Item): SearchedDisease {
    return new SearchedDisease(
        item.disease,
        item.category,
        item.symptoms,
    )
}
