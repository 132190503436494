import React, { useState } from "react";
import styles from "./MedicalKnowledgeButton.module.scss";
import { DiseaseKnowledgeDialog } from "../diseaseknowledge/DiseaseKnowledgeDialog";

type List = "list";
type Detail = { diseaseId: string };
export type DiseaseKnowledgeDialogMode = List | Detail | null;

export function MedicalKnowledgeButton() {

    const [dialogMode, setDialogMode] = useState<DiseaseKnowledgeDialogMode>(null);
    const onClickMedicalKnowledgeButton = () => {
        setDialogMode("list");
    }

    return  <>
        <button onClick={onClickMedicalKnowledgeButton}  className={styles.button}>
            <img src={"/images/medical_knowledge_logo.svg"} alt={""} className={styles.logo}/>
            <div>医療知識</div>
        </button>

        <DiseaseKnowledgeDialog
            dialogMode={dialogMode}
            setDialogMode={setDialogMode}
            onClose={() => setDialogMode(null)} />
    </>
}
