import { Dispatch } from "react";
import { GetActions, UpdateItemActions, ImportItemActions } from "./type";
import { TableOneApi } from "../../api/careplan/tableone/TableOneApi";
import { TableOnePath } from "../../../domain/careplan/tableone/TableOnePath";
import { AssessmentSheetApi } from "../../api/assessmentsheet/AssessmentSheetApi";
import { ChangeLog } from "../../../domain/changelog/ChangeLog";

export const get = (projectId: string) => async (dispatch: Dispatch<GetActions>) => {
    dispatch({
        type: "TABLEONE_GET",
        payload: {},
    });
    const result = await TableOneApi.get(projectId);
    switch (result.type) {
        case "SUCCESS":
            dispatch({
                type: "TABLEONE_GET_SUCCESS",
                payload: {
                    projectId: result.projectId,
                    tableOne: result.tableOne,
                    latestChangeLog: result.latestChangeLog,
                },
            })
            break;
        case "ERROR":
            dispatch({
                type: "TABLEONE_GET_FAILURE",
                payload: {},
            })
            break;
    }
};

export const updateItem = (
    projectId: string,
    path: TableOnePath,
    value: any,
    originalValue: any,
    onSuccess: (changeLog: ChangeLog) => void,
    onFailure: () => void,
) => async (dispatch: Dispatch<UpdateItemActions>) => {
    dispatch({
        type: "TABLEONE_UPDATE_ITEM",
        payload: {
            projectId,
            path,
            value,
        },
    });
    const result = await TableOneApi.updateItem(projectId, path, value);
    switch (result.type) {
        case "SUCCESS":
            dispatch({
                type: "TABLEONE_UPDATE_ITEM_SUCCESS",
                payload: {
                    projectId,
                    changeLog: result.changeLog,
                },
            });
            onSuccess(result.changeLog);
            break;
        case "ERROR":
            dispatch({
                type: "TABLEONE_UPDATE_ITEM_FAILURE",
                payload: {
                    projectId,
                    path,
                    value: originalValue,
                },
            });
            onFailure();
            break;
    }
};

export const importItemByAssessmentSheet = (
    projectId: string,
    onSuccess: (changeLog: ChangeLog) => void,
    onFailure: () => void,
) => async (dispatch: Dispatch<ImportItemActions>)  => {

    const resultGet = await AssessmentSheetApi.get(projectId);
    switch (resultGet.type) {
        case "SUCCESS":
            const honninPath =  new TableOnePath("seikatsuIkoHonnin");
            const honninValue = resultGet.assessmentSheet.content.faceSheet.sodanNaiyo.honnin;
            const kaigoShaKazokuPath =  new TableOnePath("seikatsuIkoKazoku")
            const kaigoShaKazokuValue = resultGet.assessmentSheet.content.faceSheet.sodanNaiyo.kaigoShaKazoku;

            if ((honninValue != null && honninValue.length > 1000)
                || (kaigoShaKazokuValue != null && kaigoShaKazokuValue.length > 1000 )) {
                dispatch({
                    type: "TABLEONE_IMPORT_ITEM_FAILURE",
                    payload: {
                        projectId
                    },
                });
                onFailure();
            } else {
                dispatch({
                    type: "TABLEONE_IMPORT_ITEM",
                    payload: {
                        projectId,
                        honninPath,
                        honninValue,
                        kaigoShaKazokuPath,
                        kaigoShaKazokuValue,
                     },
                });
                const resultUpdateHonnin = await TableOneApi.updateItem(projectId, honninPath , honninValue);
                const resultUpdateKazoku = await TableOneApi.updateItem(projectId, kaigoShaKazokuPath, kaigoShaKazokuValue);
                if (resultUpdateHonnin.type === "SUCCESS"
                    && resultUpdateKazoku.type === "SUCCESS") {
                    dispatch({
                        type: "TABLEONE_IMPORT_ITEM_SUCCESS",
                        payload: {
                            projectId,
                            changeLog: resultUpdateKazoku.changeLog,
                        },
                    });
                    onSuccess(resultUpdateKazoku.changeLog);
                } else {
                    dispatch({
                        type: "TABLEONE_IMPORT_ITEM_FAILURE",
                        payload: {
                            projectId
                        },
                    });
                    onFailure();
                }
            }
            break;
        case "ERROR":
            dispatch({
                type: "TABLEONE_IMPORT_ITEM_FAILURE",
                payload: {
                    projectId
                },
            });
            onFailure();
            break;
    }
};
