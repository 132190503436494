import { ChatGptNeed } from "../../../domain/careplan/tabletwo/need/ChatGptTableTwo";
import { ChatGptLongTermGoal } from "../../../domain/careplan/tabletwo/longtermgoal/ChatGptLongTermGoal";
import { ChatGptShortTermGoal } from "../../../domain/careplan/tabletwo/shorttermgoal/ChatGptShortTermGoal";
import { ChatGptSupportService } from "../../../domain/careplan/tabletwo/supportservice/ChatGptSupportService";
import { ChatGptSupportServiceType } from "../../../domain/careplan/tabletwo/supportservicetype/ChatGptSupportServiceType";

export class Create {
    result: Result;
    tableTwo: ChatGptNeed[];
    completionMessage: string;

    constructor(result: Result, tableTwo: ChatGptNeed[], completionMessage: string) {
        this.result = result;
        this.tableTwo = tableTwo;
        this.completionMessage = completionMessage;
    }

    static async extract(response: Response): Promise<Create> {
        const json = await response.json();
        return new Create(json.result, needs(json.tableTwo), json.completionMessage);
    }
}

type NeedsJson = {
    text: string;
    longTermGoals: LongTermGoalsJson;
}[];

type LongTermGoalsJson = {
    text: string;
    periodStartDate: string;
    periodEndDate: string;
    shortTermGoals: ShortTermGoalsJson;
}[];

type ShortTermGoalsJson = {
    text: string;
    periodStartDate: string;
    periodEndDate: string;
    supportServices: SupportServicesJson;
}[];

type SupportServicesJson = {
    text: string;
    supportServiceTypes: SupportServiceTypesJson;
}[];

type SupportServiceTypesJson = {
    text: string;
    insuranceBenefit: string;
    establishment: string;
    frequency: string;
    periodStartDate: string;
    periodEndDate: string;
}[];

function needs(needs: NeedsJson): ChatGptNeed[] {
    return needs.map((need) => new ChatGptNeed(need.text, longTermGoals(need.longTermGoals)));
}

function longTermGoals(longTermGoals: LongTermGoalsJson): ChatGptLongTermGoal[] {
    return longTermGoals.map((longTermGoal) => new ChatGptLongTermGoal(longTermGoal.text, "", "", shortTermGoals(longTermGoal.shortTermGoals)));
}

function shortTermGoals(shortTermGoals: ShortTermGoalsJson): ChatGptShortTermGoal[] {
    return shortTermGoals.map((shortTermGoal) => new ChatGptShortTermGoal(shortTermGoal.text, "", "", supportServices(shortTermGoal.supportServices)));
}

function supportServices(supportServices: SupportServicesJson): ChatGptSupportService[] {
    return [supportServices.map((supportService) => new ChatGptSupportService("", supportServiceTypes(supportService.supportServiceTypes)))[0]];
}

function supportServiceTypes(supportServiceTypes: SupportServiceTypesJson): ChatGptSupportServiceType[] {
    return [supportServiceTypes.map((supportServiceType) => new ChatGptSupportServiceType("", "", "", "", "", ""))[0]];
}

export type Result = "OK" | "RETRY" | "NG";
