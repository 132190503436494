import React from "react";

export function RightArrowIcon() {
    return <svg width="56" height="72" viewBox="0 0 56 72" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d)">
            <rect x="16" y="16" width="32" height="32" rx="16" fill="#000111" fillOpacity="0.5"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M28.1312 27.2638C28.3491 26.973 28.7621 26.9135 29.0535 27.1309L34.6544 31.3091L34.6607 31.3138C34.7662 31.3946 34.8517 31.4984 34.9105 31.6174C34.9694 31.7364 35 31.8673 35 32C35 32.1327 34.9694 32.2636 34.9105 32.3826C34.8517 32.5016 34.7662 32.6054 34.6607 32.6862L34.6544 32.6909L29.0535 36.8691C28.7621 37.0865 28.3491 37.027 28.1312 36.7362C27.9133 36.4454 27.9729 36.0334 28.2644 35.8159L33.3797 32L28.2644 28.1841C27.9729 27.9666 27.9133 27.5546 28.1312 27.2638Z" fill="white"/>
        </g>
        <defs>
            <filter id="filter0_d" x="0" y="0" width="72" height="72" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                <feOffset dx="4" dy="4"/>
                <feGaussianBlur stdDeviation="10"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.239216 0 0 0 0 0.239216 0 0 0 0 0.247059 0 0 0 0.08 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
            </filter>
        </defs>
    </svg>

}
