import React, { ReactElement } from "react";
import styles from "./BaseContent.module.scss";

export function BaseContent(props: Props) {

    const { children } = props;

    return <div className={styles.container}>
        { children }
    </div>
}

type Props = {
    children: ReactElement
}