const diseaseType = Symbol();

export class Disease {
    [diseaseType]: any;

    id: string;

    name: string;

    constructor(
        id: string,
        name: string,
    ) {
        this.id = id;
        this.name = name;
    }
}
