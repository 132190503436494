import React, { ReactNode } from "react";
import styles from "./Modal.module.scss";

export function Modal(props: Props) {
    const { open, onRequestClose, children } = props;

    if (!open) return null;

    return <div className={styles.overlay} onClick={onRequestClose}>
        {children}
    </div>;
}

type Props = {
    open: boolean,
    onRequestClose?: () => void,
    children?: ReactNode,
};
