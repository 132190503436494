import React from "react";
import styles from "./Header.module.scss";

export function Header(props: Props) {
    const { supportServiceText } = props;

    return (
        <>
            <div className={styles.row}>
                <div className={styles.title}>サービス内容</div>
                <div className={styles.content}>{supportServiceText}</div>
            </div>
        </>
    );
}

type Props = {
    supportServiceText: string | null;
};
