import React, { ChangeEvent, CompositionEvent, HTMLAttributes, ReactNode, useEffect, useState } from "react";
import styles from "./InputField.module.scss";
import { styleForWidth, Width } from "../element/Width";
import classNames from "classnames";

export function InputField<A extends HTMLAttributes<any>, T>(props: InputFieldProps<A, T> & ValueProps<T> & ChildrenProps) {
    const {
        width,
        leading,
        trailing,
        get,
        set,
        onChange: givenOnChange,
        onCompositionStart: givenOnCompositionStart,
        onCompositionEnd: givenOnCompositionEnd,
        encode,
        decode,
        transform: givenTransform,
        children,
        error,
        ...rest
    } = props;
    const fieldStyle = styleForWidth(width ?? "auto");
    const transform = givenTransform ?? (value => value);

    const [value, setValue] = useState("");
    const [composing, setComposing] = useState(false);

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        givenOnChange?.(event);
        const value = transform(event.target.value);
        setValue(value);
        if (!composing) set?.(decode(value));
    };

    const onCompositionStart = (event: CompositionEvent<HTMLInputElement>) => {
        givenOnCompositionStart?.(event);
        setComposing(true);
    };

    const onCompositionEnd = (event: CompositionEvent<HTMLInputElement>) => {
        givenOnCompositionEnd?.(event);
        const value = transform(event.currentTarget.value);
        setValue(value);
        set?.(decode(value));
        setComposing(false);
    };

    useEffect(() => {
        setValue(get === undefined ? "" : encode(get));
    }, [get, encode]);

    return <span className={styles.field} style={fieldStyle}>
        <label>
            <span className={classNames(styles.focus, { [styles.danger]: error })}>
                {leading && <span className={styles.leading}>{leading}</span>}
                {children?.({ className: styles.input, onChange, onCompositionStart, onCompositionEnd, value, ...rest })}
                {trailing && <span className={styles.trailing}>{trailing}</span>}
            </span>
        </label>
    </span>;
}

type Props<T> = {
    width?: Width,
    leading?: ReactNode,
    trailing?: ReactNode,
    get?: T,
    set?: (value: T) => void,
    error?: boolean,
};

export type InputFieldProps<A extends HTMLAttributes<any>, T> = A & Props<T>;

type ValueProps<T> = {
    encode: (value: T) => string,
    decode: (value: string) => T,
    transform?: (value: string) => string,
};

type ChildrenProps = {
    children?: (props: HTMLAttributes<any>) => ReactNode,
};
