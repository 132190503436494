import React, { MouseEvent, useState } from "react";
import styles from "./TableTwoPeriodDialog.module.scss";
import { CarePlanTableTwoPeriod } from "../../../../domain/careplan/tabletwo/CarePlanTableTwoPeriod";
import { afterNMonths, LocalDate, today } from "../../../../domain/fundamental/LocalDate";
import { SelectField } from "../../components/field/SelectField";
import { PrimaryButton } from "../../components/button/PrimaryButton";
import { CloseIcon } from "../../components/icon/CloseIcon";

export function TableTwoPeriodDialog(props: Props) {
    const { period, category, forShortTermGoalPeriodCopy, onChange, onRequestClose } = props;
    const dialogObject = fromCategory(category);

    const [start, setStart] = useState(period?.start ?? today());
    const [end, setEnd] = useState(period?.end ?? today());
    const [invalidMessage, setInvalidMessage] = useState<string | null>(null);

    const onClickContainer = (event: MouseEvent<any>) => event.stopPropagation();

    const renderEndFromStart = () => {
        if (!dialogObject.choiceEndFromStart.visible) return null;
        const onClick = (months: number) => () => {
            setEnd(afterNMonths(start, months));
        };
        return <div className={styles.endPeriodUpdaters}>
            {dialogObject.choiceEndFromStart.content.map(choice => {
                const { label, months } = choice;
                return <button key={`${category}_${label}`} className={styles.endPeriodUpdater} onClick={onClick(months)}>{label}</button>;
            })}
        </div>;
    };

    const updatePeriod = () => {
        if (end.isAfter(start)) {
            onChange(CarePlanTableTwoPeriod.from(start, end));
            onRequestClose?.();
        } else {
            setInvalidMessage("「終了日」は「開始日」より後の日付を選択してください。");
        }
    };

    return <div className={styles.container} onClick={onClickContainer}>
        <h3 className={styles.title}>期間選択</h3>
        {renderShortTermGoalPeriodCopy(dialogObject, forShortTermGoalPeriodCopy, setStart, setEnd)}
        <div className={styles.fieldContainer}>
            <h4 className={styles.dateTitle}>開始日</h4>
            <div className={styles.fields}>
                {renderYearSelect(start, setStart)}
                {renderMonthSelect(start, setStart)}
                {renderDateSelect(start, setStart)}
            </div>
        </div>
        <div className={styles.fieldContainer}>
            <h4 className={styles.dateTitle}>終了日</h4>
            {renderEndFromStart()}
            <div className={styles.fields}>
                {renderYearSelect(end, setEnd)}
                {renderMonthSelect(end, setEnd)}
                {renderDateSelect(end, setEnd)}
            </div>
        </div>
        {invalidMessage && <div className={styles.invalidMessage}>{invalidMessage}</div>}
        <div className={styles.buttonContainer}>
            <PrimaryButton width={256} onClick={updatePeriod}>変更</PrimaryButton>
        </div>
        <button className={styles.close} onClick={onRequestClose}><CloseIcon/></button>
    </div>
}

export type TableTwoPeriodCategory = "LongTermGoal" | "ShortTermGoal" | "SupportServiceType";

type Props = {
    period: CarePlanTableTwoPeriod | null,
    category: TableTwoPeriodCategory,
    forShortTermGoalPeriodCopy?: CarePlanTableTwoPeriod | null,
    onChange: (period: CarePlanTableTwoPeriod) => void,
    onRequestClose?: () => void,
};

// 期間コピー情報
type TableTwoPeriodDialogObject = {
    shortTermGoalPeriodCopy: {
        visible: boolean,
    },
    choiceEndFromStart: {
        visible: boolean,
        content: {
            label: string,
            months: number,
        }[],
    },
};

function fromCategory(category: TableTwoPeriodCategory): TableTwoPeriodDialogObject {
    switch (category) {
        case "LongTermGoal":
            return {
                shortTermGoalPeriodCopy: {
                    visible: false,
                },
                choiceEndFromStart: {
                    visible: true,
                    content: [
                        { label: "6ヶ月間", months: 6 },
                        { label: "1年間", months: 12 },
                        { label: "2年間", months: 24 },
                    ],
                },
            };
        case "ShortTermGoal":
            return {
                shortTermGoalPeriodCopy: {
                    visible: false,
                },
                choiceEndFromStart: {
                    visible: true,
                    content: [
                        { label: "3ヶ月間", months: 3 },
                        { label: "6ヶ月間", months: 6 },
                        { label: "1年間", months: 12 },
                    ],
                },
            };
        case "SupportServiceType":
            return {
                shortTermGoalPeriodCopy: {
                    visible: true,
                },
                choiceEndFromStart: {
                    visible: false,
                    content: [],
                },
            };
    }
}

const years = (() => {
    const baseYear = new Date().getFullYear();
    return [-10, -9, -8, -7, -6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(offset => {
        const value = baseYear + offset;
        const label =
            new Intl.DateTimeFormat("ja-JP-u-ca-japanese", { era: "long", year: "numeric" })
                .format(new Date(value, 1, 1))
                .replace("年", "")
        return [String(value), label] as [string, string];
    });
})();

function renderYearSelect(get: LocalDate, set: (value: LocalDate) => void) {
    return <div className={styles.field}>
        <SelectField
            values={years}
            get={get.year().toString()}
            set={value => set(get.year(parseInt(value, 10)))}
            width={80}/>
        <span className={styles.unit}>年</span>
    </div>;
}

const months = [...Array(12).keys()].map(n => {
    const value = n;
    return [value.toString(), value + 1] as [string, number];
});

function renderMonthSelect(get: LocalDate, set: (value: LocalDate) => void) {
    return <div className={styles.field}>
        <SelectField
            values={months}
            get={get.month().toString()}
            set={value => set(get.month(parseInt(value, 10)))}
            width={80}/>
        <span className={styles.unit}>月</span>
    </div>;
}

function renderDateSelect(get: LocalDate, set: (value: LocalDate) => void) {
    const dates = [...Array(get.daysInMonth()).keys()].map(n => {
        const value = n + 1;
        return [value.toString(), value] as [string, number];
    });
    return <div className={styles.field}>
        <SelectField
            values={dates}
            get={get.date().toString()}
            set={value => set(get.date(parseInt(value, 10)))}
            width={80}/>
        <span className={styles.unit}>日</span>
    </div>;
}

function renderShortTermGoalPeriodCopy(
    dialogObject: TableTwoPeriodDialogObject,
    forShortTermGoalPeriodCopy: CarePlanTableTwoPeriod | null | undefined,
    setStart: React.Dispatch<React.SetStateAction<LocalDate>>,
    setEnd: React.Dispatch<React.SetStateAction<LocalDate>>,
) {
    if (!dialogObject.shortTermGoalPeriodCopy.visible) return null;
    const onClick = () => {
        if (!forShortTermGoalPeriodCopy) return;
        setStart(forShortTermGoalPeriodCopy.start);
        setEnd(forShortTermGoalPeriodCopy.end);
    }
    return <button className={styles.copyShortTermGoalPeriod} disabled={!forShortTermGoalPeriodCopy} onClick={onClick}>短期目標の期間をコピー</button>;
}
