export class PromptHistory {

    date: string;
    content: string;
    userConsultation: string;
    familyConsultation: string;

    constructor(
        date: string,
        content: string,
        userConsultation: string,
        familyConsultation: string
    ) {
        this.date = date;
        this.content = content;
        this.userConsultation = userConsultation;
        this.familyConsultation = familyConsultation;
    }

    static async extract(response: Response): Promise<PromptHistory> {
        const json = await response.json();
        return new PromptHistory(
            json.date,
            json.content,
            json.userConsultation,
            json.familyConsultation
        );
    }
}
