import React from "react";
import { Office } from "../../../../domain/office/Office";
import styles from "./OfficeListItem.module.scss";
import { PrimaryButton } from "../../components/button/PrimaryButton";
import { DangerButton } from "../../components/button/DangerButton";
import { Modal } from "../../components/Modal";
import DeleteOfficeConfirmation from "./DeleteOfficeConfirmation";
import OfficeForm from "./OfficeForm";
import useModalOpener from "../../../hooks/common/useModalOpener";

export default function OfficeListItem(props: Props) {

    const { office, refreshOffices } = props;

    const [isOpenEditDialog, openEditDialog, closeEditDialog] = useModalOpener();
    const [isOpenDeleteDialog, openDeleteDialog, closeDeleteDialog] = useModalOpener();

    return <li className={styles.row}>
        <div>
            <div className={styles.officeName}>
                {office.displayName}
            </div>
            <div className={styles.address}>
                {office.address}
            </div>
        </div>
        <div className={styles.buttonArea}>
            <div className={styles.editButtonArea}>
                <PrimaryButton onClick={openEditDialog}>編集</PrimaryButton>
                <Modal open={isOpenEditDialog} onRequestClose={closeEditDialog}>
                    <OfficeForm office={office} type={"update"} onRequestClose={closeEditDialog} refreshOffices={refreshOffices}/>
                </Modal>
            </div>
            <div>
                <DangerButton onClick={openDeleteDialog}>削除</DangerButton>
                <Modal open={isOpenDeleteDialog} onRequestClose={closeDeleteDialog}>
                    <DeleteOfficeConfirmation office={office} onRequestClose={closeDeleteDialog} refreshOffices={refreshOffices}/>
                </Modal>
            </div>
        </div>
    </li>

}

type Props = {
    office: Office,
    refreshOffices: () => void
}
