import { ChatGptSupportServiceType } from "../supportservicetype/ChatGptSupportServiceType";

// 2表叩き台 サービス内容
export class ChatGptSupportService {
    text: string; // 内容
    supportServiceTypes: ChatGptSupportServiceType[] | null; // サービス種別

    constructor(text: string, supportServiceTypes: ChatGptSupportServiceType[] | null) {
        this.text = text;
        this.supportServiceTypes = supportServiceTypes;
    }
}
