import React from "react";
import { Tokucho } from "../../../../../../../../domain/establishment/Establishment";
import styles from "./Features.module.scss";
import { SupportServiceTypeCategory } from "../../../../../../../../domain/establishment/SupportServiceTypeCategory";
import { Feature } from "../../../components/Feature";

export function Features(props: Props) {

    const { tokucho, category } = props;

    const tsusho = () => {
        return <div className={styles.container}>
            <div className={styles.row}>
                <Feature active={tokucho.kinokunren}>機能訓練</Feature>
                <Feature active={tokucho.nyuyokuKoyoku}>個浴</Feature>
                <Feature active={tokucho.nyuyokuKikaiyoku}>機械浴</Feature>
                <Feature active={tokucho.shukuhaku}>宿泊</Feature>
            </div>
            <div className={styles.row}>
                <Feature active={tokucho.staffKangoshi}>Ns</Feature>
                <Feature active={tokucho.staffPt}>PT</Feature>
                <Feature active={tokucho.staffOt}>OT</Feature>
                <Feature active={tokucho.staffSt}>ST</Feature>
                <Feature active={tokucho.staffKanrieiyoshi}>管理栄養士</Feature>
                <Feature active={tokucho.staffShikaeiseishi}>歯科衛生士</Feature>
            </div>
        </div>
    }

    const homon = () => {
        return <div className={styles.container}>
            <div className={styles.row}>
                <Feature active={tokucho.tsuinJokokaijo}>通院等乗降介護</Feature>
                <Feature active={tokucho.tanjikankaijo}>短時間介助可</Feature>
                <Feature active={tokucho.taio24jikan}>24時間対応</Feature>
                <Feature active={tokucho.staffKangoshi}>Ns</Feature>
            </div>
            <div className={styles.row}>
                <Feature active={tokucho.staffPt}>PT</Feature>
                <Feature active={tokucho.staffOt}>OT</Feature>
                <Feature active={tokucho.staffSt}>ST</Feature>
                <Feature active={tokucho.terminalCareKasan}>ターミナルケア加算</Feature>
            </div>
        </div>
    }

    const tanki = () => {
        return <div className={styles.container}>
            <div className={styles.row}>
                <Feature active={tokucho.koshitsu}>個室</Feature>
                <Feature active={tokucho.kyoshitsuUnit}>ユニット型</Feature>
                <Feature active={tokucho.kinokunren}>機能訓練</Feature>
                <Feature active={tokucho.nyuyokuKoyoku}>個浴</Feature>
                <Feature active={tokucho.sogei}>送迎</Feature>
                <Feature active={tokucho.staffKangoshi}>Ns</Feature>
            </div>
            <div className={styles.row}>
                <Feature active={tokucho.staffPt}>PT</Feature>
                <Feature active={tokucho.staffOt}>OT</Feature>
                <Feature active={tokucho.staffSt}>ST</Feature>
                <Feature active={tokucho.staffKanrieiyoshi}>管理栄養士</Feature>
                <Feature active={tokucho.kinkyujiukeire}>緊急時受入可</Feature>
            </div>
        </div>
    }

    switch (category) {
        case "通所系":
            return tsusho();
        case "訪問系":
            return homon();
        case "短期入所系":
            return tanki();
    }
}

type Props = {
    tokucho: Tokucho,
    category: SupportServiceTypeCategory
}
