import { Disease } from "./Disease";
import { DiseaseCategory } from "./DiseaseCategory";

const diseaseCategoryWithDiseaseType = Symbol();

export class DiseaseCategoryWithDisease extends DiseaseCategory {
    [diseaseCategoryWithDiseaseType]: any;

    diseases: Disease[];

    constructor(
        category: DiseaseCategory,
        diseases: Disease[],
    ) {
        super(
            category.id,
            category.name,
        )
        this.diseases = diseases;
    }
}
