import React, { MouseEvent, useEffect, useState } from "react";
import styles from "./SuggestionDialog.module.scss";
import { Loading } from "../../components/loading/Loading";
import { Link } from "react-router-dom";
import { SuggestionNeedApi } from "../../../../state/api/suggestion/need/SuggestionNeedApi";
import { FetchSuggestionNeeds } from "../../../../state/api/suggestion/need/FetchSuggestionNeeds";

export function SuggestionNeedDialog(props: Props) {
    const { open, projectId, onSelect } = props;

    const [onFetching, setOnFetching] = useState<boolean>(false)
    const [errorCode, setErrorCode] = useState<string>("");
    const [errorDetail, setErrorDetail] = useState<boolean>(false)

    const [suggestedNeeds, setSuggestedNeeds] = useState<string[]>([])

    useEffect(() => {
        if (!open) return;
        let mounted = true;
        setOnFetching(true);
        setErrorCode("")
        SuggestionNeedApi.fetchSuggestionNeeds(projectId)
            .then(res => {
                if (!mounted) return;
                setOnFetching(false);
                if (res instanceof FetchSuggestionNeeds) {
                    setSuggestedNeeds(res.needs);
                } else {
                    setErrorCode(res.code)
                }
            });
        return () => {
            mounted = false;
        }
    }, [open, projectId]);

    // blurはmousedown時に発生するので、mousedownを抑止することによりTableTwoTextareaのblurが発生するのを防ぐ
    const onMouseDown = (event: MouseEvent<any>) => {
        event.preventDefault();
    };

    const onClick = (needText: string) => () => {
        onSelect?.(needText);
    };

    const renderSuggestionErrorDetail = () => {
        if (!errorDetail) {
            return <Link className={styles.errorShow} to={"#"} onClick={() => setErrorDetail(true)}>理由を表示する</Link>
        } else if (errorCode === "MISSING_ASSESSMENT_SHEET_ITEMS") {
            return <div className={styles.errorDetail}>・アセスメントシート内の「要介護認定項目」の入力が不十分です。</div>
        }
    }

    const renderSuggestionError = () => {
        return (
            <div>
                <div className={styles.error}>提案文言が表示できませんでした。</div>
                {renderSuggestionErrorDetail()}
            </div>
        )
    }

    const renderSuggestion = () => {
        if (onFetching) {
            return <Loading />
        } else if (errorCode) {
            return renderSuggestionError()
        } else {
            return suggestedNeeds.map((need, index) => {
                if(index < 5){
                    return <label key={index} className={styles.suggestion} onClick={onClick(need)}>{need}</label>;
                }
                return;
            })
        }
    }

    if (!open) return null;

    return <div className={styles.container} onMouseDown={onMouseDown}>
        <h2 className={styles.title}>文言提案</h2>
        {renderSuggestion()}
    </div>;
}

type Props = {
    open: boolean,
    projectId: string,
    onSelect?: (needText: string) => void,
};
