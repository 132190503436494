import React, { ReactNode, SelectHTMLAttributes } from "react";
import styles from "./SelectField.module.scss";
import { InputField, InputFieldProps } from "./InputField";
import { CaretDownIcon } from "../icon/CaretDownIcon";

export function SelectField(props: SelectFieldProps) {
    const { values, ...rest } = props;

    const encode = (value: string) => value;
    const decode = (value: string) => value;

    return <InputField encode={encode} decode={decode} {...rest}>
        {props => <div className={styles.container}>
            <select {...props}>
                {(values ?? []).map(([value, label]) => {
                    return <option key={value} value={value}>{label}</option>;
                })}
            </select>
            <CaretDownIcon/>
        </div>}
    </InputField>;
}

type Value = [string, ReactNode];

type Props = {
    values?: Value[],
};

export type SelectFieldProps = InputFieldProps<SelectHTMLAttributes<any>, string> & Props;
