import React from "react";
import styles from "./ExcelDownloadButton.module.scss";
import { Link } from "react-router-dom";
import { DownloadIcon } from "../icon/DownloadIcon";

export function ExcelDownLoadButton(props: Props) {
    const { path } = props;
    return <Link className={styles.excelDownload} to={path} target={"_top"}>
        <DownloadIcon/>
        <span>EXCEL</span>
    </Link>;
}

type Props = {
    path: string,
};
