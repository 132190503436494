import { SupportServiceTypeCode } from "../../../../domain/establishment/SupportServiceTypeCode";

export class FetchSuggestionEstablishmentSearchCondition {
    suggestedSupportServiceTypes: SupportServiceTypeCode[];
    seikatsuhogo: boolean;
    nyuyokukaijo: boolean;
    tsuintojokokaijo: boolean;
    kobetsukinokunren1: boolean;
    kobetsukinokunren2: boolean;
    staffPt: boolean;
    staffOt: boolean;
    staffSt: boolean;
    kokuCare: boolean;
    kinkyujiHomonKaigo: boolean;
    torokukakutankyuin: boolean;
    shintaikaigo20min: boolean;
    kinkyujiHomonKango: boolean;
    teizuigataHomonkaigoRenkei: boolean;
    kyuByojiHomonkango: boolean;
    zaitakuMitori: boolean;
    terminalCare: boolean;
    denwasodan24: boolean;
    rehabilitation: boolean;
    zaitakuchudoukeire: boolean;
    joshiBenjoKurumaisu: boolean;
    kyoyoBenjoKurumaisu: boolean;
    danseiBenjoKurumaisu: boolean;
    jakuneseininchishoukeire: boolean;
    kobetsukinokunren: boolean;
    shikaeiseishi: boolean;
    kanrieiyoshi: boolean;
    ryoyoshoku: boolean;
    tokubetsukanri1: boolean;
    tokubetsukanri2: boolean;
    keikaneiyoho: boolean;
    zaitakuchushinjomyakueiyoho: boolean;
    zaitakusansoryoho: boolean;
    jinkokokyuryoho: boolean;
    kikankanyure: boolean;
    jinroHokorou: boolean;
    bokoryuKateteru: boolean;
    jinkokomon: boolean;
    jinkoboko: boolean;
    sogejikyotakunaikaijo: boolean;
    kyuin: boolean;
    mayaku: boolean;
    seikatsukoikojoRehabilitation: boolean;
    teikijunkaiservice: boolean

    constructor(
        suggestedSupportServiceTypes: SupportServiceTypeCode[],
        seikatsuhogo: boolean,
        nyuyokukaijo: boolean,
        tsuintojokokaijo: boolean,
        kobetsukinokunren1: boolean,
        kobetsukinokunren2: boolean,
        staffPt: boolean,
        staffOt: boolean,
        staffSt: boolean,
        kokuCare: boolean,
        kinkyujiHomonKaigo: boolean,
        torokukakutankyuin: boolean,
        shintaikaigo20min: boolean,
        kinkyujiHomonKango: boolean,
        teizuigataHomonkaigoRenkei: boolean,
        kyuByojiHomonkango: boolean,
        zaitakuMitori: boolean,
        terminalCare: boolean,
        denwasodan24: boolean,
        rehabilitation: boolean,
        zaitakuchudoukeire: boolean,
        joshiBenjoKurumaisu: boolean,
        kyoyoBenjoKurumaisu: boolean,
        danseiBenjoKurumaisu: boolean,
        jakuneseininchishoukeire: boolean,
        kobetsukinokunren: boolean,
        shikaeiseishi: boolean,
        kanrieiyoshi: boolean,
        ryoyoshoku: boolean,
        tokubetsukanri1: boolean,
        tokubetsukanri2: boolean,
        keikaneiyoho: boolean,
        zaitakuchushinjomyakueiyoho: boolean,
        zaitakusansoryoho: boolean,
        jinkokokyuryoho: boolean,
        kikankanyure: boolean,
        jinroHokorou: boolean,
        bokoryuKateteru: boolean,
        jinkokomon: boolean,
        jinkoboko: boolean,
        sogejikyotakunaikaijo: boolean,
        kyuin: boolean,
        mayaku: boolean,
        seikatsukoikojoRehabilitation: boolean,
        teikijunkaiservice: boolean
    ) {
        this.suggestedSupportServiceTypes = suggestedSupportServiceTypes;
        this.seikatsuhogo = seikatsuhogo;
        this.nyuyokukaijo = nyuyokukaijo;
        this.tsuintojokokaijo = tsuintojokokaijo;
        this.kobetsukinokunren1 = kobetsukinokunren1;
        this.kobetsukinokunren2 = kobetsukinokunren2;
        this.staffPt = staffPt;
        this.staffOt = staffOt;
        this.staffSt = staffSt;
        this.kokuCare = kokuCare;
        this.kinkyujiHomonKaigo = kinkyujiHomonKaigo;
        this.torokukakutankyuin = torokukakutankyuin;
        this.shintaikaigo20min = shintaikaigo20min;
        this.kinkyujiHomonKango = kinkyujiHomonKango;
        this.teizuigataHomonkaigoRenkei = teizuigataHomonkaigoRenkei;
        this.kyuByojiHomonkango = kyuByojiHomonkango;
        this.zaitakuMitori = zaitakuMitori;
        this.terminalCare = terminalCare;
        this.denwasodan24 = denwasodan24;
        this.rehabilitation = rehabilitation;
        this.zaitakuchudoukeire = zaitakuchudoukeire;
        this.joshiBenjoKurumaisu = joshiBenjoKurumaisu;
        this.kyoyoBenjoKurumaisu = kyoyoBenjoKurumaisu;
        this.danseiBenjoKurumaisu = danseiBenjoKurumaisu;
        this.jakuneseininchishoukeire = jakuneseininchishoukeire;
        this.kobetsukinokunren = kobetsukinokunren;
        this.shikaeiseishi = shikaeiseishi;
        this.kanrieiyoshi = kanrieiyoshi;
        this.ryoyoshoku = ryoyoshoku;
        this.tokubetsukanri1 = tokubetsukanri1;
        this.tokubetsukanri2 = tokubetsukanri2;
        this.keikaneiyoho = keikaneiyoho;
        this.zaitakuchushinjomyakueiyoho = zaitakuchushinjomyakueiyoho;
        this.zaitakusansoryoho = zaitakusansoryoho;
        this.jinkokokyuryoho = jinkokokyuryoho;
        this.kikankanyure = kikankanyure;
        this.jinroHokorou = jinroHokorou;
        this.bokoryuKateteru = bokoryuKateteru;
        this.jinkokomon = jinkokomon;
        this.jinkoboko = jinkoboko;
        this.sogejikyotakunaikaijo = sogejikyotakunaikaijo;
        this.kyuin = kyuin;
        this.mayaku = mayaku;
        this.seikatsukoikojoRehabilitation = seikatsukoikojoRehabilitation;
        this.teikijunkaiservice = teikijunkaiservice;
    }

    static async extract(response: Response): Promise<FetchSuggestionEstablishmentSearchCondition> {
        const json = await response.json();
        return new FetchSuggestionEstablishmentSearchCondition(
            json.supportServiceTypes,
            json.seikatsuhogo,
            json.nyuyokukaijo,
            json.tsuintojokokaijo,
            json.kobetsukinokunren1,
            json.kobetsukinokunren2,
            json.staffPt,
            json.staffOt,
            json.staffSt,
            json.kokuCare,
            json.kinkyujiHomonKaigo,
            json.torokukakutankyuin,
            json.shintaikaigo20min,
            json.kinkyujiHomonKango,
            json.teizuigataHomonkaigoRenkei,
            json.kyuByojiHomonkango,
            json.zaitakuMitori,
            json.terminalCare,
            json.denwasodan24,
            json.rehabilitation,
            json.zaitakuchudoukeire,
            json.joshiBenjoKurumaisu,
            json.kyoyoBenjoKurumaisu,
            json.danseiBenjoKurumaisu,
            json.jakuneseininchishoukeire,
            json.kobetsukinokunren,
            json.shikaeiseishi,
            json.kanrieiyoshi,
            json.ryoyoshoku,
            json.tokubetsukanri1,
            json.tokubetsukanri2,
            json.keikaneiyoho,
            json.zaitakuchushinjomyakueiyoho,
            json.zaitakusansoryoho,
            json.jinkokokyuryoho,
            json.kikankanyure,
            json.jinroHokorou,
            json.bokoryuKateteru,
            json.jinkokomon,
            json.jinkoboko,
            json.sogejikyotakunaikaijo,
            json.kyuin,
            json.mayaku,
            json.seikatsukoikojoRehabilitation,
            json.teikijunkaiservice
        );
    }
}
