const SubItemsType = Symbol();

export class SubItems<T> {
    [SubItemsType]: any;

    items: T[];

    constructor(items: T[]) {
        this.items = items;
    }
}
