import { User } from "../user/User";
import { SyllabaryColumn, syllabaryColumnFor } from "./SyllabaryColumn";
import LatestProject from "../project/LatestProject";

const careRecipientRowType = Symbol();

export class CareRecipientRow {
    [careRecipientRowType]: any;

    id: string;

    name: string;

    phonetic: string;

    responsibleUser: User | null;

    activated: boolean;

    latestProject: LatestProject;

    constructor(
        id: string,
        name: string,
        phonetic: string,
        responsibleUser: User | null,
        activated: boolean,
        latestProject: LatestProject
    ) {
        this.id = id;
        this.name = name;
        this.phonetic = phonetic;
        this.responsibleUser = responsibleUser;
        this.activated = activated;
        this.latestProject = latestProject;
    }

    get syllabaryColumn(): SyllabaryColumn {
        return syllabaryColumnFor(this.phonetic.charAt(0));
    }

    matches(keyword: string): boolean {
        return keyword.split(/[\s]+/).every(keyword => this.matchesName(keyword) || this.matchesPhonetic(keyword));
    }

    private matchesName(keyword: string): boolean {
        return this.name.replace(/[\s]+/g, "").includes(keyword);
    }

    private matchesPhonetic(keyword: string): boolean {
        return this.phonetic.includes(keyword);
    }
}
