import React from "react";

export function CategoryHifuShikkanIcon() {
    return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.067 17.4095C10.906 17.4095 9.96143 16.307 9.96143 14.953C9.99057 14.2311 10.1979 13.5277 10.5649 12.9055C10.7723 12.5386 10.9256 12.1436 11.0198 11.7328C11.5828 8.55667 12.9287 5.60287 14.6198 3.83077C14.7639 3.70766 14.9464 3.63854 15.1359 3.63525C15.3254 3.63197 15.5101 3.69473 15.6584 3.81277C15.7862 3.89017 15.8804 4.01253 15.9225 4.15583C15.9646 4.29913 15.9516 4.453 15.8861 4.58722L15.6611 5.00842C14.6837 6.85342 13.4674 9.14842 13.4674 11.4434C13.4742 12.0682 13.6031 12.6856 13.8467 13.261C14.0872 13.8015 14.1973 14.3909 14.168 14.9818C14.1725 16.3075 13.228 17.4095 12.067 17.4095ZM15.1094 4.33567C13.5182 6.01417 12.2488 8.82217 11.7101 11.8556C11.6058 12.3335 11.4313 12.7933 11.1922 13.22C10.8752 13.745 10.6934 14.3405 10.663 14.953C10.663 15.92 11.293 16.708 12.0665 16.708C12.8401 16.708 13.4701 15.9205 13.4701 14.953C13.4932 14.4545 13.3976 13.9578 13.1911 13.5035C12.9179 12.8507 12.7744 12.151 12.7685 11.4434C12.7685 8.97427 14.0285 6.59377 15.0437 4.68037L15.2125 4.36132C15.1807 4.34449 15.1454 4.33568 15.1094 4.33567Z" fill="#7B7C88"/>
        <path d="M12.0669 18.8369C10.1319 18.8369 8.55693 17.1053 8.55693 14.9763C8.54537 14.0465 8.87867 13.1454 9.49249 12.4469C9.5884 12.3448 9.64737 12.2136 9.66002 12.0741C9.67266 11.9347 9.63824 11.795 9.56224 11.6774C9.51926 11.6126 9.46087 11.5594 9.39231 11.5228C9.32374 11.4861 9.24715 11.467 9.16939 11.4672H3.99551C3.80154 11.4672 3.64429 11.31 3.64429 11.116C3.64429 10.922 3.80154 10.7648 3.99551 10.7648H9.16983C9.36325 10.7645 9.55373 10.8121 9.72422 10.9034C9.89471 10.9948 10.0399 11.1269 10.1468 11.2881C10.3072 11.535 10.3817 11.8278 10.3587 12.1213C10.3357 12.4148 10.2164 12.6925 10.0194 12.9113C9.51974 13.4821 9.24914 14.2178 9.25984 14.9763C9.25984 16.7183 10.5198 18.1349 12.0674 18.1349C13.6149 18.1349 14.8749 16.7183 14.8749 14.9763C14.8855 14.2177 14.6147 13.4821 14.1149 12.9113C13.9179 12.6924 13.7986 12.4147 13.7756 12.1211C13.7526 11.8275 13.8271 11.5346 13.9875 11.2877C14.0945 11.1266 14.2397 10.9945 14.4102 10.9032C14.5807 10.812 14.7711 10.7644 14.9645 10.7648H20.1388C20.3328 10.7648 20.49 10.922 20.49 11.116C20.49 11.31 20.3328 11.4672 20.1388 11.4672H14.9645C14.8868 11.4671 14.8103 11.4861 14.7417 11.5227C14.6732 11.5593 14.6147 11.6123 14.5716 11.6769C14.4961 11.7948 14.4619 11.9344 14.4745 12.0739C14.4872 12.2133 14.5459 12.3445 14.6414 12.4469C15.2552 13.1454 15.5885 14.0465 15.5769 14.9763C15.5765 17.1053 14.0019 18.8369 12.0669 18.8369Z" fill="#7B7C88"/>
        <path d="M20.1386 19.5389H3.99529C3.80143 19.5389 3.64429 19.6961 3.64429 19.8899C3.64429 20.0838 3.80144 20.2409 3.99529 20.2409H20.1386C20.3324 20.2409 20.4896 20.0838 20.4896 19.8899C20.4896 19.6961 20.3324 19.5389 20.1386 19.5389Z" fill="#7B7C88"/>
        <path d="M7.08096 17.1286C6.69084 17.1209 6.31554 16.9777 6.01941 16.7236C5.88419 16.5893 5.70913 16.5023 5.52038 16.4757C5.33164 16.4491 5.13935 16.4843 4.97226 16.576L4.47112 16.8464C4.30058 16.9384 4.08774 16.8746 3.99585 16.704C3.90407 16.5336 3.96768 16.3211 4.13798 16.2291L4.63972 15.9582C4.92626 15.8022 5.25404 15.7386 5.57813 15.7761C5.90223 15.8136 6.20678 15.9505 6.45006 16.1679C6.61287 16.3289 6.83121 16.4214 7.06015 16.4263C7.28908 16.4313 7.51121 16.3483 7.68082 16.1944L8.69332 15.402L9.12577 15.955L8.11327 16.747C7.82237 16.9876 7.45837 17.1221 7.08096 17.1286Z" fill="#7B7C88"/>
        <path d="M17.0498 17.1255C16.6744 17.1192 16.3122 16.9858 16.0225 16.747L15.0095 15.955L15.442 15.402L16.4545 16.1944C16.6215 16.3474 16.8405 16.4309 17.067 16.4279C17.2934 16.4249 17.5102 16.3356 17.6731 16.1782C17.9153 15.9549 18.2217 15.8135 18.5488 15.774C18.8759 15.7345 19.2071 15.7989 19.4956 15.9582L19.997 16.229C20.1675 16.321 20.2311 16.5337 20.1391 16.7042C20.0471 16.8747 19.8343 16.9384 19.6638 16.8464L19.1626 16.576C18.9981 16.485 18.8087 16.4493 18.6223 16.4741C18.436 16.4989 18.2626 16.5829 18.1276 16.7137C17.827 16.9719 17.446 17.1175 17.0498 17.1255Z" fill="#7B7C88"/>
    </svg>
    ;
}
