import React from "react";

export function CategoryKankakukikeiShikkanIcon() {
    return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M12.4951 10.3999C12.2402 10.3179 11.9699 10.3066 11.712 10.3646C11.4965 10.4131 11.2825 10.2776 11.234 10.0621C11.1856 9.84657 11.321 9.63256 11.5366 9.5841C11.9328 9.49501 12.3482 9.51233 12.74 9.63826C13.2733 9.8097 13.7257 10.1705 14.0132 10.6525C14.3007 11.1345 14.4038 11.7049 14.3038 12.2575C14.2038 12.8101 13.9073 13.3078 13.4688 13.6572C13.0303 14.0066 12.48 14.1837 11.9207 14.1548C11.3614 14.1259 10.8321 13.8929 10.4316 13.5003C10.0312 13.1076 9.78695 12.5822 9.74372 12.0222C9.71968 11.7107 9.75874 11.4002 9.85579 11.1086C9.92554 10.899 10.152 10.7856 10.3616 10.8553C10.5712 10.9251 10.6846 11.1515 10.6149 11.3612C10.5512 11.5524 10.5256 11.7562 10.5414 11.9606C10.5697 12.3283 10.7301 12.6725 10.9917 12.9291C11.2533 13.1856 11.5983 13.337 11.962 13.3558C12.3257 13.3747 12.6841 13.2596 12.9703 13.0315C13.2565 12.8034 13.451 12.4778 13.5166 12.115C13.5823 11.7523 13.5144 11.378 13.3261 11.0623C13.1379 10.7467 12.8423 10.5115 12.4951 10.3999Z" fill="#7B7C88"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M12.038 7.65107C10.9351 7.65107 9.87695 8.09206 9.09633 8.87787C8.31561 9.66378 7.87654 10.7303 7.87654 11.8428C7.87654 12.6725 8.12094 13.4833 8.57854 14.1727C9.03612 14.862 9.68622 15.3989 10.4463 15.7159C11.2064 16.0328 12.0426 16.1157 12.8493 15.9541C13.6561 15.7926 14.3974 15.3938 14.9796 14.8078C15.5618 14.2217 15.9586 13.4747 16.1193 12.6611C16.2801 11.8475 16.1976 11.0041 15.8823 10.2378C15.567 9.4716 15.0332 8.81711 14.3489 8.35683C13.6646 7.89657 12.8604 7.65107 12.038 7.65107ZM8.52877 8.31407C9.45901 7.37764 10.7213 6.85107 12.038 6.85107C13.0198 6.85107 13.9794 7.14418 14.7954 7.69302C15.6113 8.24184 16.2469 9.02161 16.6221 9.93343C16.9973 10.8452 17.0954 11.8484 16.9042 12.8162C16.7129 13.784 16.2408 14.6733 15.5472 15.3716C14.8535 16.0699 13.9693 16.5458 13.0064 16.7386C12.0434 16.9314 11.0454 16.8324 10.1384 16.4543C9.23156 16.0761 8.45686 15.4359 7.91201 14.6151C7.36718 13.7943 7.07654 12.8295 7.07654 11.8428C7.07654 10.5198 7.59862 9.2504 8.52877 8.31407Z" fill="#7B7C88"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M7.22589 9.11524C5.84782 9.93077 4.76899 10.9011 4.15562 11.5124C3.96664 11.7008 3.96661 11.9851 4.15556 12.1735C4.76888 12.785 5.84772 13.7557 7.22581 14.5716C8.6055 15.3884 10.2619 16.0364 12.0379 16.0364C13.8139 16.0364 15.4703 15.3885 16.8499 14.5717C18.228 13.7558 19.3069 12.7851 19.9202 12.1735C20.1092 11.9851 20.1091 11.7008 19.9201 11.5124C19.3066 10.9011 18.2276 9.93077 16.8495 9.11523C15.4698 8.29875 13.8135 7.65107 12.0379 7.65107C10.2619 7.65107 8.60555 8.29877 7.22589 9.11524ZM6.81845 8.42677C8.27134 7.56695 10.0698 6.85107 12.0379 6.85107C14.0057 6.85107 15.8041 7.56695 17.257 8.42675C18.7115 9.2875 19.843 10.3062 20.4848 10.9457C20.9875 11.4467 20.9876 12.2389 20.4851 12.74C19.8434 13.3798 18.712 14.3989 17.2575 15.2601C15.8046 16.1202 14.0061 16.8364 12.0379 16.8364C10.0697 16.8364 8.27116 16.1202 6.81825 15.26C5.36374 14.3989 4.2323 13.3797 3.59069 12.74C3.08819 12.2389 3.08827 11.4467 3.59087 10.9458C4.23253 10.3062 5.36397 9.28752 6.81845 8.42677Z" fill="#7B7C88"/>
    </svg>
    ;
}
