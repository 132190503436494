import React, { ReactNode } from "react";
import styles from "./GlobalContainer.module.scss";
import { GlobalFooter } from "./GlobalFooter";

export function GlobalContainer(props: Props) {
    const { children } = props;
    return <div className={styles.wrapper}>
        <div className={styles.container}>
            {children}
        </div>
        <GlobalFooter {...props} />
    </div>;
}

type Props = {
    children?: ReactNode,
    rsLoginView?: boolean,
}
