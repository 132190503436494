import React, { useEffect, useState, useRef, RefObject } from "react";
import styles from "./TableTwoFragment.module.scss";
import { TableTwoBody } from "./components/TableTwoBody";
import { TableTwoFooter } from "./components/TableTwoFooter";
import { TableTwoHeader } from "./components/TableTwoHeader";
import { CarePlanTableTwo } from "../../../domain/careplan/tabletwo/CarePlanTableTwo";
import { ChangeLog } from "../../../domain/changelog/ChangeLog";
import { CarePlanTableTwoApi } from "../../../state/api/careplan/tabletwo/CarePlanTableTwoApi";
import { CarePlanTableTwoResponse } from "../../../state/api/careplan/tabletwo/CarePlanTableTwoResponse";
import { Modal } from "../components/Modal";
import { ChatGptDialog } from "./components/ChatGptDialog";
import { ChatgptManageUsage } from "../../../domain/chatgpt/ChatgptManageUsage";
import { Need } from "../../../domain/careplan/tabletwo/need/Need";
import { ChatGptApi } from "../../../state/api/chatgpt/ChatGptApi";
import { SimpleCareRecipient } from "../../../domain/carerecipient/SimpleCareRecipient";

export function TableTwoFragment(props: Props) {
    const { projectId, globalHeaderHeight, projectHeaderHeight, activated, globalHeaderRef, projectHeaderRef, careRecipient } = props;
    const [carePlanTableTwo, setCarePlanTableTwo] = useState<CarePlanTableTwo>();
    const [latestChangeLog, setLatestChangeLog] = useState<ChangeLog | null>(null);

    const [chatGptManageUsage, setChatGptManageUsage] = useState<ChatgptManageUsage | undefined>();
    const [prevAnswer, setPrevAnswer] = useState("");
    const [prevNeeds, setPrevNeeds] = useState<Need[]>();
    const [openChatGptDialog, setOpenChatGptDialog] = useState(false);

    const [prevCarePlanTableTwo, setPrevCarePlanTableTwo] = useState(carePlanTableTwo);

    useEffect(() => {
        let mounted = true;
        CarePlanTableTwoApi.get(projectId).then((res) => {
            if (res instanceof CarePlanTableTwoResponse && mounted) {
                setCarePlanTableTwo(res.carePlanTableTwo);
                setLatestChangeLog(res.latestChangeLog);
            }
        });
        ChatGptApi.getManageSetting()
            .then(res => {
                if (mounted) {
                    if (res instanceof ChatgptManageUsage) {
                        setChatGptManageUsage(res)
                    }
                }

            })
        return () => {
            mounted = false;
        };
    }, [projectId]);

    if (prevAnswer && prevNeeds && prevCarePlanTableTwo !== carePlanTableTwo && !prevNeeds.every((prevNeed) => carePlanTableTwo?.needs.items.some((tableTwo) => JSON.stringify(tableTwo) === JSON.stringify(prevNeed)))) {
        setPrevNeeds(undefined);
        setPrevCarePlanTableTwo(carePlanTableTwo);
    }

    const refreshCarePlanTableTwo = (carePlanTableTwo: CarePlanTableTwo, changeLog: ChangeLog) => {
        setCarePlanTableTwo(carePlanTableTwo);
        setLatestChangeLog(changeLog);
    };

    const headerRef = useRef<HTMLDivElement>(null);

    const onRequestOpenChatGptDialog = () => {
        setOpenChatGptDialog(true);
    };

    const onRequestCloseDialog = () => {
        setOpenChatGptDialog(false);
    };

    return (
        <div className={styles.container}>
            <div ref={headerRef} className={styles.header}>
                <TableTwoHeader 
                    latestChangeLog={latestChangeLog} 
                    projectId={projectId} 
                    globalHeaderRef={globalHeaderRef} 
                    projectHeaderRef={projectHeaderRef} 
                    activated={activated} 
                    chatGptManageUsage={chatGptManageUsage}
                    onRequestOpenChatGptDialog={onRequestOpenChatGptDialog}
                />
            </div>
            {carePlanTableTwo && (
                <TableTwoBody projectId={props.projectId} carePlanTableTwo={carePlanTableTwo} activated={activated} globalHeaderHeight={globalHeaderHeight} projectHeaderHeight={projectHeaderHeight} headerHeight={() => headerRef.current?.clientHeight ?? 0} refresh={refreshCarePlanTableTwo} />
            )}
            <TableTwoFooter activated={activated} />
            <Modal open={openChatGptDialog} onRequestClose={onRequestCloseDialog}>
                <ChatGptDialog projectId={projectId} carePlanTableTwo={carePlanTableTwo} setCarePlanTableTwo={setCarePlanTableTwo} prevNeeds={prevNeeds} setPrevNeeds={setPrevNeeds} onRequestCloseDialog={onRequestCloseDialog} prevAnswer={prevAnswer} setPrevAnswer={setPrevAnswer} usageInfo={chatGptManageUsage} careRecipient={careRecipient}/>
            </Modal>
        </div>
    );
}

type Props = {
    projectId: string;
    globalHeaderHeight: () => number;
    projectHeaderHeight: () => number;
    globalHeaderRef: RefObject<HTMLDivElement>;
    projectHeaderRef: RefObject<HTMLDivElement>;
    activated: boolean;
    careRecipient: SimpleCareRecipient | null;
};
