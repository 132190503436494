import React from "react";
import { useParams } from "react-router";
import { GlobalContainer } from "../components/GlobalContainer";
import { GlobalHeader } from "../components/GlobalHeader";
import { CareRecipientHeader } from "./components/CareRecipientHeader";

export function CareRecipientPage() {
    const { careRecipientId } = useParams<PathParams>();
    return <GlobalContainer>
        <GlobalHeader/>
        <CareRecipientHeader careRecipientId={careRecipientId}/>
    </GlobalContainer>
}

type PathParams = {
    careRecipientId: string,
};
