import React from "react";
import { Link } from "react-router-dom";
import styles from "./AdminPageButton.module.scss"
import { useSelector } from "react-redux";
import { State } from "../../../state/store";
import { Paths } from "../../../Paths";

export default function AdminPageButton() {

    const user = useSelector((state: State) => state.authentication.user);

    if (!user || !user.isAdmin) return null;

    return <>
        {<button className={styles.button}>
            <Link to={Paths.admin}>管理者ページ</Link>
        </button>}
    </>
}