import { fromPeriodObject, PeriodObject } from "../PeriodObject";
import { shortTermGoal, ShortTermGoalObject } from "../shorttermgoal/ShortTermGoalObject";
import { LongTermGoal } from "../../../../../domain/careplan/tabletwo/longtermgoal/LongTermGoal";
import { SubItems } from "../../../../../domain/careplan/tabletwo/SubItems";

export type LongTermGoalObject = {
    id: string,
    details: {
        text: string | null,
        period: PeriodObject | null,
    },
    shortTermGoals: ShortTermGoalObject[]
};

export function longTermGoal(object: LongTermGoalObject): LongTermGoal {
    return new LongTermGoal(
        object.id,
        object.details.text,
        object.details.period && fromPeriodObject(object.details.period),
        new SubItems(object.shortTermGoals.map(shortTermGoal)),
    );
}
