import React, { MouseEvent } from "react";
import styles from "./CareRecipientSettingDialog.module.scss";

type Props = {
    openEditDialog: (event: MouseEvent) => void;
    openDeletionDialog: (event: MouseEvent) => void;
    responsibleFor: boolean;
};

export function CareRecipientSettingDialog(props: Props) {
    const { openEditDialog, openDeletionDialog, responsibleFor } = props;

    return (
        <div className={styles.dialog}>
            <button className={styles.button} onClick={openEditDialog}>利用者情報編集</button>
            <button
                className={styles.alertButton}
                onClick={openDeletionDialog}
                disabled={!responsibleFor}
                data-tooltip="担当外の利用者は 削除できません"
            >
              利用者を削除する
            </button>
        </div>
    );
}
