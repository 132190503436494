import React, { MouseEvent, useState } from "react";
import { DangerButton } from "../../components/button/DangerButton";
import { CancelButton } from "../../components/button/CancelButton";
import styles from "./DeleteUserConfirmation.module.scss";
import { CloseIcon } from "../../components/icon/CloseIcon";
import { UserDetail } from "../../../../domain/user/UserDetail";
import { AdminUserApi } from "../../../../state/api/admin/user/AdminUserApi";

export default function DeleteUserConfirmation(props: Props) {

    const { user, onRequestClose, refresh } = props;
    const [hasError, setHasError] = useState(false);

    const onClick = (e: MouseEvent<any>) => {
        e.stopPropagation();
        AdminUserApi.delete(user.id)
            .then(() => {
                setHasError(false);
                refresh()
                onRequestClose();
            })
            .catch(() => {
                setHasError(true);
            });
    }

    const onClickContainer = (e: MouseEvent<any>) => {
        e.stopPropagation();
    }

    return <div className={ styles.container } onClick={e => onClickContainer(e)}>
        <div className={ styles.title }>
            ユーザーを削除する
        </div>
        <div className={ styles.description }>
            以下のユーザーを削除します。この操作は取り消せません。
            <p>関連するデータは全て削除されます。</p>
            <p>キャンセルを押すと削除を中止できます。</p>
            <div className={ styles.userName }>
                { user.displayName}
            </div>
            { hasError && <div className={styles.errorMessage}>
                ユーザー削除に失敗しました。
            </div> }
        </div>
        <div className={ styles.buttonArea }>
            <div className={ styles.dangerButtonArea }>
                <DangerButton width={ 256 } onClick={ (e) => onClick(e) }>削除する</DangerButton>
            </div>
            <div className={ styles.cancelButtonArea }>
                <CancelButton width={ 256 } onClick={ onRequestClose }>キャンセル</CancelButton>
            </div>
        </div>
        <button onClick={ onRequestClose } className={ styles.closeIcon }>
            <CloseIcon/>
        </button>
    </div>
}

type Props = {
    user: UserDetail,
    onRequestClose: () => void,
    refresh: () => void,
}
