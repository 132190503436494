import { SubItems } from "../../../../domain/careplan/tabletwo/SubItems";
import { CarePlanTableTwo } from "../../../../domain/careplan/tabletwo/CarePlanTableTwo";
import { need, NeedObject } from "./need/NeedObject";

export type CarePlanTableTwoObject = {
    needs: NeedObject[],
    projectId: string,
};

export function carePlanTableTwo(object: CarePlanTableTwoObject): CarePlanTableTwo {
    return new CarePlanTableTwo(
        new SubItems(object.needs.map(need)),
        object.projectId,
    );
}
