import React, { MouseEvent, useState } from "react";
import { Corporation } from "../../../../domain/corporation/Corporation";
import { DangerButton } from "../../components/button/DangerButton";
import { CancelButton } from "../../components/button/CancelButton";
import styles from "./DeleteCorporationConfirmation.module.scss";
import { CloseIcon } from "../../components/icon/CloseIcon";
import { AdminCorporationApi } from "../../../../state/api/admin/corporation/AdminCorporationApi";

export default function DeleteCorporationConfirmation(props: Props) {

    const { corporation, onRequestClose, refreshCorporations } = props;
    const [hasError, setHasError] = useState(false);

    const onClick = (e: MouseEvent<any>) => {
        e.stopPropagation();
        AdminCorporationApi.delete(corporation.id)
            .then(() => {
                setHasError(false);
                refreshCorporations();
                onRequestClose();
            })
            .catch(() => {
                setHasError(true);
            });
    }

    const onClickContainer = (e: MouseEvent<any>) => {
        e.stopPropagation();
    }

    return <div className={ styles.container } onClick={ e => onClickContainer(e) }>
        <div className={ styles.title }>
            法人を削除する
        </div>
        <div className={ styles.description }>
            以下の法人を削除します。この操作は取り消せません。キャンセルを押すと削除を中止できます。
            <div className={ styles.corporationName }>
                { corporation.name }
            </div>
            { hasError && <div className={ styles.errorMessage }>
                法人削除に失敗しました。
            </div> }
        </div>
        <div className={ styles.buttonArea }>
            <div className={ styles.dangerButtonArea }>
                <DangerButton width={ 256 } onClick={ (e) => onClick(e) }>削除する</DangerButton>
            </div>
            <div className={ styles.cancelButtonArea }>
                <CancelButton width={ 256 } onClick={ onRequestClose }>キャンセル</CancelButton>
            </div>
        </div>
        <button onClick={ onRequestClose } className={ styles.closeIcon }>
            <CloseIcon/>
        </button>
    </div>
}

type Props = {
    corporation: Corporation
    onRequestClose: () => void,
    refreshCorporations: () => void,
}
