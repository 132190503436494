import { useCallback, useEffect, useState } from "react";

export default function usePager<T>(
    countPerPage: number = 10,
) {

    const [currentPage, setCurrentPage] = useState(0);
    const [originalList, setOriginalList] = useState<T[]>([]);
    const [displayList, setDisplayList] = useState<T[]>([])

    const onChangePage = useCallback((page: number) => {
        setCurrentPage(page);
    }, []);

    const pageCount = Math.ceil(originalList.length / countPerPage);

    useEffect(() => {
        if (originalList.length === 0) return;
        const begin = currentPage * countPerPage;
        const end = begin + countPerPage;
        const displayList = originalList.slice(begin, end)
        // 要素の削除時などに空リストを表示し続けてしまうため、表示リストが0件となった場合は1ページ戻す
        if (displayList.length === 0) {
            onChangePage(currentPage - 1);
            return;
        }
        setDisplayList(displayList);
    }, [currentPage, originalList, setDisplayList, onChangePage, countPerPage])

    return [pageCount, currentPage, originalList, setOriginalList, displayList, onChangePage] as const;
}
