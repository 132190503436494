import "whatwg-fetch";
import { ApiError } from "../../ApiError";
import { post } from "../../functions";
import { FetchSuggestionShortTermGoals } from "./FetchSuggestionShortTermGoals";

export const SuggestionShortTermGoalApi = new class {
    async fetchSuggestionShortTermGoals(
        projectId: string,
        needText: string,
        longTermGoalText: string,
    ): Promise<FetchSuggestionShortTermGoals | ApiError> {
        const body = {
            projectId,
            needText,
            longTermGoalText,
        };
        const response = await post("/api/suggestion/shortTermGoals", body);
        return (response.ok ? FetchSuggestionShortTermGoals.extract : ApiError.extract)(response);
    }
}();
