import React, { MouseEvent, RefObject } from "react";
import { Establishment } from "../../../../../domain/establishment/Establishment";
import { Features } from "./cardcomponent/Features";
import { AddressInfo } from "./cardcomponent/AddressInfo";
import styles from "./Card.module.scss";
import { Header } from "./cardcomponent/Header";
import { SupportServiceTypeCategory } from "../../../../../domain/establishment/SupportServiceTypeCategory";
import { toLabel } from "../../../suggestion/components/establishment/components/ServiceType";
import { ThumbnailsRow } from "./cardcomponent/ThumbnailsRow";
import { EstablishmentFavorite } from "../../../../../domain/establishment/EstablishmentFavorite";
import { PrimaryButton } from "../../../components/button/PrimaryButton";

export function Card(props: Props) {
    const {
        establishment,
        category,
        setScrollTop,
        scrollBody,
        setEstablishment,
        setDetailVisible,
        projectId,
        establishmentFavoriteList,
        setEstablishmentFavoriteList,
        confirmEstablishments,
    } = props;

    const onClickCard = () => {
        setScrollTop(scrollBody?.current?.scrollTop ?? 0);
        setEstablishment(establishment);
        setDetailVisible(true);
    };

    const onClickSelect = (event: MouseEvent) => {
        event.stopPropagation();
        confirmEstablishments(event, {
            supportServiceTypeId: parseInt(establishment.jigyoshoJoho.serviceShubetsuCode),
            supportServiceType: toLabel(establishment.jigyoshoJoho.serviceShubetsuCode),
            establishmentName: establishment.jigyoshoJoho.jigyoshomei,
            establishmentId: establishment.jigyoshoJoho.jigyoshobango
        });
    };

    return (
        <>
            <li className={styles.container} onClick={onClickCard}>
                <Header
                    serviceTypeCode={establishment.jigyoshoJoho.serviceShubetsuCode}
                    saturday={establishment.jigyoshoJoho.eigyobiDoyo}
                    sunday={establishment.jigyoshoJoho.eigyobiNichiyo}
                    publicHoliday={establishment.jigyoshoJoho.eigyobiShukujitsu}
                    establishment={establishment}
                    projectId={projectId}
                    establishmentFavoriteList={establishmentFavoriteList}
                    setEstablishmentFavoriteList={setEstablishmentFavoriteList}
                />
                <div className={styles.establishmentName}>
                    <div>{establishment.jigyoshoJoho.jigyoshomei}</div>
                </div>
                <Features tokucho={establishment.tokucho} category={category} />
                <div className={styles.thumbnailsArea}>
                    <ThumbnailsRow thumbnails={establishment.jigyoshoJoho.thumbnails} />
                </div>
                <AddressInfo
                    postalCode={establishment.jigyoshoJoho.yubimbango}
                    address={establishment.jigyoshoJoho.jusho}
                    establishmentNumber={establishment.jigyoshoJoho.jigyoshobango}
                    tel={establishment.jigyoshoJoho.tel}
                />
                <div className={styles.buttonArea}>
                    <PrimaryButton onClick={onClickSelect} width={256}>
                        選択する
                    </PrimaryButton>
                </div>
            </li>
        </>
    );
}

type Props = {
    establishment: Establishment;
    category: SupportServiceTypeCategory;
    key: string;
    setScrollTop: (scrollTop: number) => void;
    scrollBody: RefObject<HTMLDivElement> | null;
    setEstablishment: (establishment: Establishment) => void;
    setDetailVisible: (value: boolean) => void;
    projectId: string;
    establishmentFavoriteList: EstablishmentFavorite[];
    setEstablishmentFavoriteList: (value: EstablishmentFavorite[]) => void;
    confirmEstablishments: (event: MouseEvent, establishment: { supportServiceType: string; supportServiceTypeId: number; establishmentName: string; establishmentId: string }) => void;
};
