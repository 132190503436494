import { UserDetail } from "../../../../domain/user/UserDetail";

export class UsersResponse {
    type: "SUCCESS" = "SUCCESS";

    users: UserDetail[]

    constructor(users: UserDetail[]) {
        this.users = users;
    }

    static async extract(response: Response): Promise<UsersResponse> {
        const json = await response.json()
        return new UsersResponse(json.users);
    }
}
