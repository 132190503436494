import React from "react";
import styles from "./CareRecipientActivation.module.scss"
import { SimpleCareRecipient } from "../../../../domain/carerecipient/SimpleCareRecipient";
import { ToggleField } from "../../components/field/ToggleField";
import { CareRecipientApi } from "../../../../state/api/carerecipient/CareRecipientApi";
import { Deactivate } from "../../../../state/api/carerecipient/Deactivate";
import { Activate } from "../../../../state/api/carerecipient/Activate";

export function CareRecipientActivation(props: Props) {
    const { careRecipient, refresh } = props;

    const deactivated = careRecipient ? !careRecipient.activated : false;

    const onChange = !careRecipient ? () => {} : (checked: boolean) => {
        if (checked) {
            CareRecipientApi.deactivate(careRecipient.id)
                .then(res => {
                    if (res instanceof Deactivate) {
                        refresh();
                    }
                })
        } else {
            CareRecipientApi.activate(careRecipient.id)
                .then(res => {
                    if (res instanceof Activate) {
                        refresh();
                    }
                })
        }
    };

    return <div className={styles.container}>
        <ToggleField get={deactivated} set={onChange} >利用終了</ToggleField>
    </div>;
}

type Props = {
    careRecipient: SimpleCareRecipient | null,
    refresh: () => void,
};
