import React, { useState } from "react";
import { useSelector } from "react-redux";
import { AutoSavedIndicator, AutoSavedIndicatorStatus } from "./AutoSavedIndicator";
import { SecondaryButton } from "../../components/button/SecondaryButton";
import styles from "./TableTwoFooter.module.scss";
import { State } from "../../../../state/store";

export function TableTwoFooter(props: Props) {
    const activated = props.activated
    const [autoSavedIndicatorStatus, setAutoSavedIndicatorStatus] = useState(AutoSavedIndicatorStatus.INITIAL);
    const rsLoginIn = useSelector((state: State) => state.authentication.rsLoginIn);

    const handleSaveButtonClick = () => {
        if (autoSavedIndicatorStatus === AutoSavedIndicatorStatus.VISIBLE) return; // setTimeoutの重複を防ぐ
        setAutoSavedIndicatorStatus(AutoSavedIndicatorStatus.VISIBLE);
        setTimeout(() => {
            setAutoSavedIndicatorStatus(AutoSavedIndicatorStatus.HIDDEN);
        }, 2000);
    }

    const renderSave = () => {
        if (activated && !rsLoginIn) {
            return <div className={styles.save}>
                <AutoSavedIndicator status={autoSavedIndicatorStatus}/>
                <SecondaryButton onClick={handleSaveButtonClick}>保存</SecondaryButton>
            </div>
        }
    };

    return <div className={styles.container}>
        <ul className={styles.messages}>
            <li>※１「保険給付の対象となるかどうかの区分」について、保険給付対象内サービスについては◯印を付す。</li>
            <li>※２「当該サービス提供を行う事業所」について記入する。</li>
        </ul>
        {renderSave()}
    </div>;
}

type Props = {
    activated: boolean,
};
