import React, { MouseEvent, useEffect, useRef, useState } from "react";
import { Result } from "./components/Result";
import { Header } from "./components/Header";
import { TabBar } from "./components/TabBar";
import { SupportServiceTypeCategory } from "../../../../../../domain/establishment/SupportServiceTypeCategory";
import { Condition } from "./components/Condition";
import styles from "./List.module.scss";
import { PrimaryButton } from "../../../../components/button/PrimaryButton";
import { ShortTermGoal } from "../../../../../../domain/careplan/tabletwo/shorttermgoal/ShortTermGoal";
import { Loading } from "../../../../components/loading/Loading";
import { EstablishmentSearchCondition } from "../../../../../../domain/establishment/SearchCondition";
import classNames from "classnames";
import { Establishment } from "../../../../../../domain/establishment/Establishment";
import { EstablishmentFavorite } from "../../../../../../domain/establishment/EstablishmentFavorite";

export function List(props: Props) {

    const {
        shortTermGoal,
        supportServiceText,
        projectId,
        selectEstablishment,
        deselectEstablishment,
        confirmEstablishments,
        selectedEstablishments,
        category,
        onChangeTab,
        condition,
        setCondition,
        scrollTop,
        setScrollTop,
        tsushokeiCount,
        homonkeiCount,
        shortstayCount,
        establishmentList,
        setTsushokeiCount,
        setHomonkeiCount,
        setShortstayCount,
        setEstablishmentList,
        onGettingSearchCondition,
        setEstablishment,
        setDetailVisible,
        onSearchingList,
        setOnSearchingList,
        establishmentFavoriteList,
        setEstablishmentFavoriteList
    } = props;

    const scrollBody = useRef<HTMLDivElement>(null);
    const [keyword, setKeyword] = useState<string>("");
    const [filterFavorite, setFilterFavorite] = useState<boolean>(false);

    useEffect(() => {
        // 詳細画面から戻った際に、スクロール位置を元の場所にしたいため
        if (scrollBody.current) {
            scrollBody.current.scrollTop = scrollTop;
        }
    }, [category, projectId, scrollTop]);

    return <div className={classNames(styles.container)} ref={scrollBody}>
        <Header shortTermGoal={shortTermGoal.text} supportServiceText={supportServiceText} />
        { onGettingSearchCondition ? <Loading/> :
            <>
                <TabBar
                    category={category}
                    onChangeTab={onChangeTab} setScrollTop={setScrollTop}
                    tsushokeiCount={tsushokeiCount}
                    homonkeiCount={homonkeiCount}
                    shortstayCount={shortstayCount}
                />
                <Condition
                    category={ category }
                    projectId={ projectId }
                    condition={condition}
                    setCondition={setCondition}
                    setTsushokeiCount={setTsushokeiCount}
                    setHomonkeiCount={setHomonkeiCount}
                    setShortstayCount={setShortstayCount}
                    setEstablishmentList={setEstablishmentList}
                    setOnSearching={setOnSearchingList}
                    keyword={keyword}
                    setKeyword={setKeyword}
                    filterFavorite={filterFavorite}
                    setFilterFavorite={setFilterFavorite}
                />
                <Result
                    category={category}
                    selectEstablishment={selectEstablishment}
                    deselectEstablishment={deselectEstablishment}
                    selectedEstablishments={selectedEstablishments}
                    setScrollTop={setScrollTop} scrollBody={scrollBody}
                    establishmentList={establishmentList}
                    onSearching={onSearchingList}
                    setDetailVisible={setDetailVisible}
                    setEstablishment={setEstablishment}
                    keyword={keyword}
                    projectId={projectId}
                    establishmentFavoriteList={establishmentFavoriteList}
                    setEstablishmentFavoriteList={setEstablishmentFavoriteList}
                    filterFavorite={filterFavorite}
                />
                <div className={ styles.confirmButton }>
                    <PrimaryButton disabled={ selectedEstablishments.length < 1 } onClick={ confirmEstablishments } width={256}>
                        { selectedEstablishments.length > 0 ? `選択した事業所${ selectedEstablishments.length }件で確定` : "事業所を選択してください" }
                    </PrimaryButton>
                </div>
            </>
        }
    </div>
}

type Props = {
    shortTermGoal: ShortTermGoal,
    supportServiceText: string | null,
    projectId: string,
    selectEstablishment: (establishment: {text: string, establishmentName: string}) => void,
    deselectEstablishment: (establishment: {text: string, establishmentName: string}) => void,
    confirmEstablishments: (event: MouseEvent) => void,
    selectedEstablishments: {text: string, establishmentName: string}[],
    category: SupportServiceTypeCategory,
    onChangeTab: (category: SupportServiceTypeCategory) => void
    condition: EstablishmentSearchCondition
    setCondition: (condition: EstablishmentSearchCondition) => void
    scrollTop: number
    setScrollTop: (scrollTop: number) => void
    tsushokeiCount: number,
    homonkeiCount: number,
    shortstayCount: number,
    establishmentList: Establishment[],
    setTsushokeiCount: (count: number) => void,
    setHomonkeiCount: (count: number) => void,
    setShortstayCount: (count: number) => void,
    setEstablishmentList: (establishments: Establishment[]) => void,
    onGettingSearchCondition: boolean,
    setEstablishment: (establishment: Establishment) => void
    setDetailVisible: (value: boolean) => void
    onSearchingList: boolean,
    setOnSearchingList: (value: boolean) => void
    establishmentFavoriteList: EstablishmentFavorite[]
    setEstablishmentFavoriteList: (value: EstablishmentFavorite[]) => void
}
