import React from "react";

export function CategoryKansenshoIcon() {
    return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.7159 16.7189C9.53389 16.7191 8.37836 16.3688 7.39546 15.7122C6.41256 15.0557 5.64644 14.1224 5.19396 13.0304C4.74149 11.9384 4.623 10.7368 4.85348 9.57746C5.08396 8.41813 5.65305 7.3532 6.48879 6.51732C7.32453 5.68144 8.38939 5.11218 9.54867 4.88151C10.708 4.65084 11.9096 4.76913 13.0017 5.22142C14.0937 5.67371 15.0271 6.43968 15.6838 7.42247C16.3405 8.40527 16.6911 9.56073 16.6911 10.7427C16.6892 12.327 16.0592 13.8458 14.939 14.9661C13.8189 16.0865 12.3001 16.7168 10.7159 16.7189ZM10.7159 5.50925C9.68082 5.50925 8.66897 5.81619 7.80833 6.39126C6.94768 6.96632 6.27689 7.78367 5.88078 8.73997C5.48467 9.69627 5.38103 10.7485 5.58296 11.7637C5.7849 12.7789 6.28334 13.7115 7.01526 14.4434C7.74718 15.1753 8.67969 15.6737 9.69489 15.8757C10.7101 16.0776 11.7624 15.974 12.7187 15.5779C13.675 15.1817 14.4923 14.511 15.0674 13.6503C15.6425 12.7897 15.9494 11.7778 15.9494 10.7427C15.9477 9.3553 15.3958 8.02517 14.4146 7.04415C13.4335 6.06312 12.1033 5.51131 10.7159 5.50974V5.50925Z" fill="#7B7C88"/>
        <path d="M11.2809 4.76707V3.57677C11.2809 3.37169 11.1146 3.20544 10.9095 3.20544C10.7045 3.20544 10.5382 3.37169 10.5382 3.57677V4.76707C10.5382 4.97215 10.7045 5.1384 10.9095 5.1384C11.1146 5.1384 11.2809 4.97215 11.2809 4.76707Z" fill="#7B7C88"/>
        <path d="M15.6579 5.54994L14.8163 6.39161C14.6713 6.53662 14.6713 6.77173 14.8163 6.91674C14.9613 7.06175 15.1964 7.06175 15.3414 6.91674L16.1831 6.07507C16.3281 5.93006 16.3281 5.69495 16.1831 5.54994C16.0381 5.40493 15.803 5.40493 15.6579 5.54994Z" fill="#7B7C88"/>
        <path d="M17.8828 10.5649H16.6925C16.4874 10.5649 16.3212 10.7312 16.3212 10.9363C16.3212 11.1413 16.4874 11.3076 16.6925 11.3076H17.8828C18.0879 11.3076 18.2541 11.1413 18.2541 10.9363C18.2541 10.7312 18.0879 10.5649 17.8828 10.5649Z" fill="#7B7C88"/>
        <path d="M15.9098 15.6847L15.0681 14.843C14.9231 14.698 14.688 14.698 14.543 14.843C14.3979 14.988 14.398 15.2231 14.543 15.3682L15.3846 16.2098C15.5296 16.3548 15.7648 16.3548 15.9098 16.2098C16.0548 16.0648 16.0548 15.8297 15.9098 15.6847Z" fill="#7B7C88"/>
        <path d="M10.8941 17.9096V16.7193C10.8941 16.5143 10.7279 16.348 10.5228 16.348C10.3177 16.348 10.1515 16.5143 10.1515 16.7193V17.9096C10.1515 18.1147 10.3177 18.281 10.5228 18.281C10.7279 18.281 10.8941 18.1147 10.8941 17.9096Z" fill="#7B7C88"/>
        <path d="M6.09144 14.5699L5.25011 15.4113C5.1051 15.5563 5.1051 15.7914 5.25011 15.9364C5.39513 16.0814 5.63023 16.0814 5.77525 15.9364L6.61657 15.0951C6.76158 14.9501 6.76158 14.715 6.61657 14.5699C6.47156 14.4249 6.23645 14.4249 6.09144 14.5699Z" fill="#7B7C88"/>
        <path d="M4.74022 10.1783H3.54991C3.34484 10.1783 3.17859 10.3446 3.17859 10.5497C3.17859 10.7547 3.34484 10.921 3.54991 10.921H4.74022C4.94529 10.921 5.11154 10.7547 5.11154 10.5497C5.11154 10.3446 4.94529 10.1783 4.74022 10.1783Z" fill="#7B7C88"/>
        <path d="M6.88962 6.11792L6.04795 5.27625C5.90294 5.13124 5.66783 5.13124 5.52282 5.27625C5.37781 5.42126 5.37781 5.65637 5.52282 5.80138L6.36449 6.64306C6.5095 6.78807 6.74461 6.78807 6.88962 6.64306C7.03463 6.49804 7.03463 6.26294 6.88962 6.11792Z" fill="#7B7C88"/>
        <path d="M11.0326 8.35917C10.7863 8.35948 10.5489 8.26753 10.367 8.10144C10.1852 7.93534 10.0722 7.70716 10.0503 7.46187C10.0284 7.21658 10.0992 6.97197 10.2487 6.77628C10.3982 6.58059 10.6156 6.448 10.858 6.40466C11.1004 6.36132 11.3503 6.41037 11.5583 6.54214C11.7664 6.67391 11.9175 6.87883 11.982 7.11652C12.0464 7.35421 12.0194 7.60742 11.9064 7.82622C11.7934 8.04503 11.6025 8.21356 11.3714 8.29859C11.2629 8.33841 11.1482 8.35891 11.0326 8.35917ZM11.0326 7.13636C11.0044 7.13644 10.9764 7.14144 10.9499 7.15114C10.9052 7.1674 10.8664 7.19657 10.8383 7.23494C10.8103 7.27331 10.7942 7.31915 10.7923 7.36664C10.7903 7.41414 10.8025 7.46114 10.8273 7.50169C10.8522 7.54223 10.8884 7.57449 10.9316 7.59436C10.9603 7.60877 10.9916 7.61727 11.0237 7.61935C11.0557 7.62143 11.0879 7.61706 11.1182 7.60649C11.1485 7.59592 11.1764 7.57937 11.2002 7.55782C11.224 7.53627 11.2432 7.51016 11.2568 7.48103C11.2703 7.4519 11.2778 7.42036 11.2789 7.38826C11.28 7.35617 11.2747 7.32418 11.2632 7.2942C11.2517 7.26421 11.2343 7.23684 11.212 7.2137C11.1898 7.19057 11.1631 7.17214 11.1335 7.15951C11.102 7.14431 11.0676 7.13623 11.0326 7.13587V7.13636Z" fill="#7B7C88"/>
        <path d="M13.2605 9.84392C13.0142 9.84423 12.7768 9.75228 12.595 9.58619C12.4131 9.42009 12.3001 9.1919 12.2782 8.94661C12.2563 8.70132 12.3271 8.45672 12.4766 8.26102C12.6261 8.06533 12.8435 7.93274 13.0859 7.8894C13.3283 7.84606 13.5782 7.89511 13.7862 8.02688C13.9943 8.15865 14.1454 8.36358 14.2099 8.60127C14.2743 8.83896 14.2474 9.09217 14.1343 9.31097C14.0213 9.52978 13.8305 9.69831 13.5993 9.78334C13.4908 9.82338 13.3761 9.84388 13.2605 9.84392ZM13.2605 8.62111C13.2322 8.62142 13.2043 8.62658 13.1778 8.63637C13.1482 8.64705 13.1211 8.66347 13.0979 8.68468C13.0748 8.7059 13.0561 8.73149 13.0428 8.75999C13.0163 8.81787 13.0137 8.88386 13.0354 8.94368C13.0516 8.98837 13.0808 9.02723 13.1191 9.05534C13.1575 9.08344 13.2033 9.09952 13.2508 9.10152C13.2983 9.10353 13.3453 9.09137 13.3859 9.06659C13.4264 9.04182 13.4587 9.00555 13.4787 8.96239C13.4955 8.92582 13.5029 8.88562 13.5001 8.84546C13.4973 8.80531 13.4845 8.76648 13.4629 8.73256C13.4412 8.69864 13.4114 8.6707 13.3761 8.65131C13.3408 8.63192 13.3012 8.62171 13.261 8.6216L13.2605 8.62111Z" fill="#7B7C88"/>
        <path d="M9.91858 10.5861C9.67231 10.5864 9.43486 10.4945 9.25303 10.3284C9.07121 10.1623 8.95819 9.93409 8.93628 9.6888C8.91437 9.4435 8.98514 9.1989 9.13465 9.00321C9.28416 8.80752 9.50156 8.67493 9.74399 8.63159C9.98641 8.58825 10.2363 8.6373 10.4443 8.76906C10.6524 8.90083 10.8035 9.10576 10.8679 9.34345C10.9324 9.58114 10.9054 9.83435 10.7924 10.0532C10.6794 10.272 10.4885 10.4405 10.2574 10.5255C10.1489 10.5656 10.0342 10.5861 9.91858 10.5861ZM9.91858 9.36379C9.89036 9.36393 9.86237 9.36893 9.83585 9.37856C9.791 9.39463 9.75197 9.42373 9.72376 9.46211C9.69554 9.5005 9.67941 9.54644 9.67745 9.59404C9.6755 9.64164 9.6878 9.68874 9.71277 9.72931C9.73774 9.76989 9.77425 9.80209 9.81763 9.82179C9.85028 9.83708 9.88595 9.8448 9.92201 9.8444C9.95806 9.844 9.99357 9.83548 10.0259 9.81946C10.0582 9.80344 10.0864 9.78035 10.1086 9.7519C10.1307 9.72344 10.1462 9.69036 10.1538 9.65511C10.1614 9.61986 10.1609 9.58336 10.1525 9.54831C10.144 9.51326 10.1277 9.48057 10.1049 9.45267C10.0821 9.42477 10.0532 9.40238 10.0206 9.38716C9.98787 9.37195 9.95217 9.36429 9.91612 9.36477L9.91858 9.36379Z" fill="#7B7C88"/>
        <path d="M19.1183 18.3203C18.8461 18.3205 18.5771 18.261 18.3304 18.146C17.9932 17.9897 17.7099 17.7369 17.5163 17.4196C17.3227 17.1023 17.2276 16.7348 17.2429 16.3634C17.2583 15.992 17.3834 15.6336 17.6024 15.3333C17.8215 15.033 18.1247 14.8045 18.4736 14.6765C18.8226 14.5486 19.2017 14.527 19.5629 14.6144C19.9241 14.7019 20.2513 14.8945 20.5031 15.1679C20.7548 15.4414 20.9199 15.7833 20.9773 16.1505C21.0347 16.5177 20.982 16.8937 20.8258 17.231C20.6746 17.5564 20.4336 17.8319 20.131 18.0249C19.8285 18.2179 19.4772 18.3204 19.1183 18.3203ZM19.1218 15.3034C18.9178 15.3033 18.7176 15.358 18.542 15.4618C18.3665 15.5656 18.222 15.7147 18.1239 15.8936C18.0258 16.0724 17.9776 16.2743 17.9844 16.4781C17.9911 16.682 18.0526 16.8803 18.1623 17.0522C18.272 17.2242 18.4259 17.3634 18.608 17.4554C18.79 17.5474 18.9934 17.5888 19.1969 17.5751C19.4005 17.5615 19.5965 17.4933 19.7647 17.3778C19.9328 17.2624 20.0668 17.1038 20.1525 16.9187C20.2327 16.7455 20.2679 16.5549 20.2551 16.3645C20.2423 16.1741 20.1817 15.99 20.0791 15.8291C19.9764 15.6682 19.8349 15.5357 19.6676 15.4439C19.5004 15.352 19.3126 15.3037 19.1218 15.3034Z" fill="#7B7C88"/>
        <path d="M16.7803 21.1797C16.4648 21.1786 16.1581 21.0753 15.9063 20.8853C15.6544 20.6952 15.4709 20.4287 15.3833 20.1256C15.2956 19.8226 15.3085 19.4992 15.42 19.2041C15.5316 18.909 15.7357 18.6579 16.0019 18.4885C16.2681 18.3192 16.582 18.2406 16.8965 18.2646C17.2111 18.2886 17.5095 18.4139 17.7469 18.6217C17.9843 18.8295 18.148 19.1086 18.2134 19.4172C18.2789 19.7259 18.2426 20.0474 18.1099 20.3337C17.9922 20.5869 17.8044 20.8011 17.5687 20.951C17.3331 21.101 17.0595 21.1803 16.7803 21.1797ZM16.7837 19.0025C16.655 19.0022 16.5285 19.0366 16.4176 19.102C16.3067 19.1674 16.2154 19.2615 16.1534 19.3743C16.0913 19.4872 16.0608 19.6146 16.065 19.7433C16.0691 19.872 16.1079 19.9972 16.1771 20.1058C16.2463 20.2143 16.3435 20.3023 16.4584 20.3604C16.5733 20.4185 16.7017 20.4446 16.8302 20.436C16.9586 20.4273 17.0824 20.3843 17.1885 20.3114C17.2947 20.2384 17.3792 20.1383 17.4333 20.0214C17.484 19.9122 17.5063 19.792 17.4984 19.6719C17.4904 19.5518 17.4522 19.4356 17.3875 19.3341C17.3228 19.2326 17.2336 19.149 17.128 19.0911C17.0225 19.0331 16.9041 19.0027 16.7837 19.0025Z" fill="#7B7C88"/>
    </svg>;
}
