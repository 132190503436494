import React, { ReactNode } from "react";
import classNames from "classnames";
import styles from "./Feature.module.scss";

export function Feature(props: Props) {
    const { active, children } = props;
    return <div className={classNames(styles.cell, active ? styles.active : styles.inactive)}>
        {children}
    </div>
}

type Props = {
    active: boolean | null | undefined,
    children: ReactNode
}