import React, { MouseEvent, ReactNode, useRef, useState } from "react";
import styles from "./TableTwoPeriod.module.scss";
import { CarePlanTableTwoPeriod } from "../../../../domain/careplan/tabletwo/CarePlanTableTwoPeriod";
import { Modal } from "../../components/Modal";
import { TableTwoPeriodCategory, TableTwoPeriodDialog } from "./TableTwoPeriodDialog";
import { Link } from "react-router-dom";
import { CopyButtonIcon } from "../../components/icon/CopyButtonIcon";
import classNames from "classnames";
import { jpFormat } from "dayjs-jp-format"
import dayjs from "dayjs";

dayjs.extend(jpFormat)

export function TableTwoPeriod(props: Props) {
    const { period, category, forShortTermGoalPeriodCopy, onChange, onClickCopyPeriod, activated } = props;

    const [openTableTwoPeriodDialog, setOpenTableTwoPeriodDialog] = useState(false);

    const boxScopeRef = useRef<HTMLDivElement>(null);

    const onClickTableTwoPeriod = () => {
        setOpenTableTwoPeriodDialog(true);
    };

    const onRequestCloseTableTwoPeriodDialog = () => {
        setOpenTableTwoPeriodDialog(false);
    };

    const onClickCopyPeriodButton = (event: MouseEvent<HTMLInputElement>) => {
        event.stopPropagation();
        if (onClickCopyPeriod && period) {
            onClickCopyPeriod(period)
        }
    }

    const BORDER_WIDTH = 2
    const handleCopyPeriodMouseEnter = (event: MouseEvent<HTMLButtonElement>) => {
        const boxScope = boxScopeRef.current;
        const ownCell = event.currentTarget.closest("[data-period-container]");
        const tableTwoBody = ownCell?.closest("[data-table-two-body]")
        if (boxScope && ownCell && tableTwoBody) {
            boxScope.className = classNames(styles.boxScope, styles.visible);
            const { width, top } = ownCell.getBoundingClientRect();
            const tableTwoBodyTop = tableTwoBody.getBoundingClientRect().top;
            const height = tableTwoBody.clientHeight - (top - tableTwoBodyTop);
            boxScope.style.left = `${-BORDER_WIDTH}px`;
            boxScope.style.top = `${-BORDER_WIDTH}px`;
            boxScope.style.height = `${height + BORDER_WIDTH * 2}px`;
            boxScope.style.width = `${width + BORDER_WIDTH * 2}px`;
        }
    }

    const handleCopyPeriodMouseLeave = () => {
        const boxScope = boxScopeRef.current;
        if (boxScope) {
            boxScope.className = styles.boxScope;
        }
    }

    const renderTableTwoPeriod = () => {
        if (activated) {
            return <div className={styles.display} onClick={onClickTableTwoPeriod}>
                <Link to={"#"} className={styles.period} >
                    {period && renderPeriod(period)}
                </Link>
                {period &&
                <div className={styles.copyButtonArea} onClick={onClickCopyPeriodButton} >
                    <button className={styles.copyButton} onMouseEnter={handleCopyPeriodMouseEnter} onMouseLeave={handleCopyPeriodMouseLeave}>
                        <CopyButtonIcon/>
                        <div className={styles.text}>コピー</div>
                    </button>
                </div>}</div>
        } else {
            return <div className={styles.display}>{period && renderPeriod(period)}</div>
        }
    };

    return <div className={styles.container} data-period-container={"true"}>
        {renderTableTwoPeriod()}
        <div className={styles.boxScope} ref={boxScopeRef}/>
        <Modal open={openTableTwoPeriodDialog} onRequestClose={onRequestCloseTableTwoPeriodDialog}>
            <TableTwoPeriodDialog period={period} category={category} forShortTermGoalPeriodCopy={forShortTermGoalPeriodCopy} onChange={onChange} onRequestClose={onRequestCloseTableTwoPeriodDialog}/>
        </Modal>
    </div>
}

type Props = {
    period: CarePlanTableTwoPeriod | null,
    category: TableTwoPeriodCategory,
    forShortTermGoalPeriodCopy?: CarePlanTableTwoPeriod | null,
    onChange: (period: CarePlanTableTwoPeriod) => void,
    onClickCopyPeriod: (period: CarePlanTableTwoPeriod) => void,
    activated: boolean,
};

function renderPeriod(period: CarePlanTableTwoPeriod): ReactNode {

    const formatDayjs = (day: dayjs.Dayjs) => {
        return day.toDate().toLocaleDateString(
            "ja-JP-u-ca-japanese", { era: "narrow", year: "numeric", month: "long", day: "numeric" })
    };

    return <>
        <span>{formatDayjs(period.start)}</span>
        <div className={styles.separator}>〜</div>
        <span>{formatDayjs(period.end)}</span>
    </>;
}
