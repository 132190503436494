import React, { useState, MouseEvent } from "react";
import { Thumbnail } from "../../../../../../domain/establishment/EstablishmentDetail";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./ThumbnailsRow.module.scss";
import "./ThumbnailsRow.scss";
import { LeftArrowButton } from "../../../../suggestion/components/establishment/components/LeftArrowButton";
import { RightArrowButton } from "../../../../suggestion/components/establishment/components/RightArrowButton";

export function ThumbnailsRow(props: Props) {
    const { thumbnails } = props;

    const [nav, setNav] = useState<Slider | null>(null);
    const [showSlideButton, setShowSlideButton] = useState(false);

    if (!thumbnails || !thumbnails.length) return null;

    const settings = {
        // react-slickのbug対応(https://github.com/akiran/react-slick/issues/1171)
        infinite: thumbnails.length > 3,
        speed: 500,
        slidesToScroll: 1,
        arrows: false,
    };

    const previousImage = (event: MouseEvent) => {
        event.stopPropagation();
        nav?.slickPrev();
    };

    const nextImage = (event: MouseEvent) => {
        event.stopPropagation();
        nav?.slickNext();
    };

    const onMouseOver = () => {
        setShowSlideButton(true);
    };

    const onMouseLeave = () => {
        setShowSlideButton(false);
    };

    return (
        <div className={styles.container} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
            <Slider {...settings} ref={(slider) => setNav(slider)} slidesToShow={4}>
                {thumbnails.map((thumbnail) => {
                    return (
                        <div key={thumbnail.imageUrl} className={styles.sliderImage}>
                            <img src={thumbnail.imageUrl} alt={thumbnail.imageDescription} className={styles.img} />
                        </div>
                    );
                })}
            </Slider>
            {thumbnails.length > 4 && (
                /* デフォルトで画像は4枚まで見えるので、4枚以下の場合はスライドボタンを出力する必要がない*/
                <>
                    <div className={styles.previousButton}>
                        <LeftArrowButton onClick={(e: MouseEvent) => previousImage(e)} visible={showSlideButton} />
                    </div>
                    <div className={styles.nextButton}>
                        <RightArrowButton onClick={(e: MouseEvent) => nextImage(e)} visible={showSlideButton} />
                    </div>
                </>
            )}
        </div>
    );
}

type Props = {
    thumbnails: Thumbnail[] | null;
};
